import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import { styled, Theme, createStyles,css } from  '@mui/material/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { auth, emailAndPasswordSignIn } from "../../firebase/firebase";
import { postRequest } from "../../apis/axiosAction";
import { setUserInfo } from "../../data/userInfo";

const root = css`
  height: 100vh;
  width: 100vw;
  background-color: #d7fff1;
`;
const card = css`
  height: 80vh;
  width: 120vw;
  margin: auto;
`;
const button = css`
  color: #fff;
  background-color: #1ba649;
  margin: 80px 0 0 auto;
  &:hover {
    background: #1ba649;
  }
`;
const errorMessage = css`
  color: red;
  margin-top: 10px;
  font-size: 13px;
  display: block;
  text-align: center;
`;

const invisibleMessage = css`
  height: 20px;
  margin-top: 10px;
`;

const Auth: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [authFlg, setAuthFlg] = useState(true);
  const [loginMessage, setLoginMessage] = useState("");
  const signIn = async () => {
    let inputUserId: string = userid;
    if (inputUserId.indexOf("@") === -1) {
      // 「@」が含まれない場合は「@opview.7view.jp」を付与する
      inputUserId = userid + "@opview.7view.jp";
    }
    await emailAndPasswordSignIn(auth, inputUserId, password)
      .then(() => {
        setLoginMessage("ログインに成功しました");
        // ログイン画面からアクセスしたときのユーザー情報取得
        postRequest(
          process.env.REACT_APP_COMMON_API!,
          {
            "api-name": "user-info",
          },
          false
        )
          .then((response: any) => {
            const json = JSON.parse(response.data.dataList[0]);
            setUserInfo(json);
            navigate("/daily-management?tenpocode=190029");
          })
          .catch((error: any) => {
            console.error("Error App initialOperation1: ", error);
          });
      })
      .catch((error: any) => {
        setAuthFlg(false);
        if (error.code === "auth/wrong-password") {
          setLoginMessage("パスワードが違います");
        } else if (error.code === "auth/invalid-email") {
          setLoginMessage("登録されていないIDです");
        } else {
          setLoginMessage("ログインに失敗しました");
        }
        console.log(error.code);
        console.log(error.message);
      });
  };

  // 開発画面以外ではログイン画面を非表示化する(本番環境はSSO共通基盤認証よりログイン)
  const isAuthDisplay: boolean =
    process.env.REACT_APP_MODE === "develop" ||
    process.env.REACT_APP_MODE === "cloud-dev";

  return (
    <Grid container css={root} justifyContent="center">
      <Grid container item xs={12} md={10} lg={8} xl={6}>
        {isAuthDisplay ? (
          <Card css={card}>
            <CardHeader title="OFCダッシュボード" />
            <Grid container justifyContent="center">
              <img
                src={`${window.location.origin}/image/sejlogo.png`}
                alt="SEJアイコン"
                style={{ height: "15vh" }}
              />
            </Grid>
            <CardContent>
              <Grid container justifyContent="center">
                <TextField
                  margin="dense"
                  required
                  label="ユーザーID"
                  variant="outlined"
                  style={{ width: "72vw" }}
                  id="userid"
                  name="userid"
                  autoComplete="userid"
                  value={userid}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUserid(e.target.value);
                  }}
                />
                <TextField
                  margin="dense"
                  required
                  label="パスワード"
                  type="password"
                  variant="outlined"
                  style={{ width: "72vw" }}
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                  }}
                />
              </Grid>
              <Typography css={!authFlg ? errorMessage : invisibleMessage}>
                {loginMessage}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="large"
                variant="contained"
                css={button}
                onClick={async () => {
                  try {
                    await signIn();
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                ログイン
              </Button>
            </CardActions>
          </Card>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default Auth;
