// import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import React, { useState } from "react";
import { Grid, Card, CardContent } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const modal = css`
  width: 60%;
  margin:  64px auto; 
`;
const card = css`
  padding: 8px;
`;
const style = css`
  text-align: center;
  font-size: 24px;
`;

const header = css`
  ${style}
  padding: 4px;
  background-color: #e0ffee;
  border: 1px #333333;
`;

const p = css`
  ${style}
  margin: 32px 10px;
`;

const icon = css`
  color: red;
`;

const APIErrorModal: React.FC<{}> = ({}) => {
  return (
    <>
      <Card variant="outlined" css={modal}>
        <CardContent css={card}>
          <div css={header}>
            <WarningAmberIcon css={icon} />
            データが取得できませんでした。
          </div>
          <div css={p}>大変申し訳ありませんが、もう一度試してください。</div>
        </CardContent>
      </Card>
    </>
  );
};
export default APIErrorModal;
