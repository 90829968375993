import React, { useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Tooltip,
  IconButton,
  ClickAwayListener,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 460,
  },
});

const InfoButton: React.FC<{
  windowName: string;
}> = ({ windowName }) => {
  const [TooltipOpen, setTooltipOpen] = useState(false);
  const toolTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            textAlign: "left",
            width: "460px",
            margin: 0,
          },
        },
      },
    },
  });

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div style={{ textAlign: "right" }}>
        <ThemeProvider theme={toolTheme}>
          <CustomWidthTooltip
            PopperProps={{
              disablePortal: true,
              placement: "left",
            }}
            onClose={handleTooltipClose}
            open={TooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={(() => {
              if (windowName === "日別管理表") {
                return (
                  <span style={{ width: "350px", fontSize: "12px" }}>
                    ・商品売上高および客数は売上日報締め時点、
                    <br />
                    　商品廃棄は在庫変更報告書締め時点、
                    <br />
                    　客単価、買上点数、朝/昼/夜帯売上は0-24時を
                    <br />
                    　1日として表示しています。
                    <br />
                    ・前日までに承認した売上日報、在庫変更報告書が
                    <br />
                    　反映されます。
                  </span>
                );
              } else if (windowName === "廃棄検証（PMA）") {
                return (
                  <span style={{ width: "350px", fontSize: "12px" }}>
                    ・在庫変更報告書を参考としているため、
                    <br />
                    　「商品廃棄」と各PMAの合計値は一致しない場合があります。
                    <br />
                    ・在庫変更報告書の承認済みデータを参考としているため、
                    <br />
                    　承認のタイミングによっては正しくデータが表示されない場合があります。
                  </span>
                );
              } else if (windowName === "POS-PMA分析_PMA" || windowName === "POS-PMA分析_サマリ" || windowName.includes("情報分類")) {
                return (
                  <span style={{ width: "460px", fontSize: "12px" }}>
                    ・PMA「クーポン」、「消耗品」、「消耗品（本部）」、
                    <br />
                    　「試食、試飲、展示」は表示しておりません。
                    <br />
                    ・PMA「サービス商品」には「コピー・プリント」が含まれておりません。
                    <br />
                    ・情報分類「コピー・プリント」は表示されておりません。
                    <br />
                    ・自店の期間平均の値は、以下理由により本部情報分析の値と一致いたしません。
                    <br />
                    ※本部情報分析は休業日を含む、OFCダッシュボードは休業日を除く。
                    <br />
                    ・2023年9月以前のPMAタバコ及び、その配下の情報分類の値は、
                    <br />
                    　以下理由により本部情報分析の値と一致いたしません。
                    <br />
                    ※本部情報分析はバラで税抜き換算、OFCダッシュボードはカートンで税抜き換算。
                  </span>
                );
              } else if (windowName === "タバコ") {
                return (
                  <span style={{ width: "350px", fontSize: "12px" }}>
                    ・2023年9月以前のPMAタバコ及び、その配下の情報分類の値は、
                    <br />
                    　以下理由により本部情報分析の値と一致いたしません。
                    <br />
                    ※本部情報分析はバラで税抜き換算、OFCダッシュボードはカートンで税抜き換算。
                  </span>
                );
              }
            })()}
          >
            <IconButton
              onClick={handleTooltipOpen}
              style={{ padding: "2px 8px" }}
            >
              <InfoIcon fontSize="small" />
            </IconButton>
          </CustomWidthTooltip>
        </ThemeProvider>
      </div>
    </ClickAwayListener>
  );
};

export default InfoButton;
