import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Button,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import Header from "../common/Header";
import TimeOutDisplay from "../common/TimeOutDisplay";
import {
  timer,
  dataChange,
  getHolidayStyle,
  fontTheme,
} from "../common/utils";
import { apiRequest } from "../../apis/util";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../data/userInfo";
import { ThemeProvider } from "@mui/material/styles";
import APIErrorModal from "../common/APIErrorModal";
import { userAuthCheck } from "../auth/authUser";

const card = css`
  margin: 80px 16px 16px 16px;
  width: 100%;
  @media screen and (min-width: 1194px) {
    height: 620px;
  }
  @media screen and (min-width: 1280px) {
    margin: 70px 16px 16px 16px;
    height: 580x;
  }
  @media screen and (min-width: 1366px) {
    height: 525px;
  }
`;
const tableContainer = css`
  position: relative;
  z-index: 100;
  overflow: scroll;
  border-spacing: 1;
  @media screen and (min-width: 1194px) {
    height: 545px;
  }
  @media screen and (min-width: 1280px) {
    height: 450px;
  }
  @media screen and (min-width: 1366px) {
    height: 460px;
  }
`;
const commonCell = css`
  margin: 0;
  padding: 0;
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 13px;
  box-sizing: border-box;
`;
const header = css`
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  border-top: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  border-bottom: 1px #c4c4c4;
  border-left: none;
  background-color: #e0ffee;
  z-index: 5;
`;
const changeButtonCell = css`
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  padding: 4px;
  max-width: 78px;
  min-width: 78px;
  font-weight: bold;
  text-align: center;
  background-color: #e0ffee;
  border: 2px solid #c4c4c4;
  z-index: 6;
`;
const yearDateCell = css`
  ${changeButtonCell}
  background-color: #e0ffee;
  border-left: 0;
  width: 95%;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  z-index: 5;
`;
const mainHeaderTotalCell = css`
  ${changeButtonCell}
  left: 78px;
  border-left: 0;
  max-width: 140px;
  min-width: 140px;
  font-weight: bold;
  text-align: center;
  background-color: #e0ffee;
  border-top: 1px #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  border-left: 0;
  z-index: 4;
`;
const mainHeaderCell = css`
  ${changeButtonCell}
  max-width: 140px;
  min-width: 140px;
  font-weight: bold;
  text-align: center;
  background-color: #e0ffee;
  border-top: 1px #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  border-left: 0;
  z-index: 3;
`;
const thisYearDateCell = css`　　　　　　
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  font-weight: bold;
  text-align: center;
  background-color: #e0ffee;
  max-width: 78px;
  min-width: 78px;
  border-top: 1px #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  border-left: 2px solid #c4c4c4;
  z-index: 5;
`;
const lastYearDateCell = css`
  ${thisYearDateCell}
  left: 76px;
  border-left: 0;
`;
const averageTotalCell = css`
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  font-weight: normal;
  background-color: #F2FCF7;
  text-align: center;
  border-top: 0;
  border-right: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  border-left: 2px solid #c4c4c4;
  z-index: 4;
`;
const thisYearDailyCell = css`
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  text-align: center;
  height: 26px;
  background-color: #ffffff;
  border-left: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  z-index: 3;
`;
const borderNumberTotalCell = css`
  left: 78px;
  border-left: 0;
  position: sticky;
  margin: 0;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px;
  font-weight: normal;
  background-color: #ffffff;
  border-right: 2px solid #c4c4c4;
  border-left: 0px;
  border-bottom: 1px solid #c4c4c4;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
  text-align: right;
  z-index: 2;
`;
const borderNumberCell = css`
  margin: 0;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px;
  font-weight: normal;
  background-color: #ffffff;
  border-right: 2px solid #c4c4c4;
  border-left: 0px;
  border-bottom: 1px solid #c4c4c4;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
  text-align: right;
  z-index: 1;
`;
const averageBorderNumberTotalCell = css`
  ${commonCell}
  left: 78px;
  border-left: 0;
  font-weight: normal;
  padding-right: 4px;
  background-color: #F2FCF7;
  border-right: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  text-align: right;
  z-index: 3;
`;
const averageBorderNumberCell = css`
  ${commonCell}
  font-weight: normal;
  padding-right: 4px;
  background-color: #F2FCF7;
  border-right: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  text-align: right;
`;
const calendar = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  box-shadow: 24px;
  text-align: center;
`;
const change = css`
  font-size: 13px;
  border-radius: 12px;
  background-color: #00cc5b;
  color: #fff;
  display: block;
  margin: auto;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #00cc5bb3;
  }
`;
const circularProgress = css`
  position: absolute;
  top: 45%;
  left: 45%;
  opacity: 0.8;
  color: #ccc;
`;

const DisposalVerificationPma: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [dateCount, setDateCount] = useState<number>(0);
  const [tenpoCode, setTenpoCode] = useState<string | null>("");
  const [tenpoName, setTenpoName] = useState<string>("");
  const [getWidthLeft, setGetWidthLeft] = useState("");
  const [date, setDate] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [timeout, setTimeout] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [errorAPI, setErrorAPI] = useState(false);
  const modalClose = () => setErrorAPI(false);

  const search = useLocation().search;
  const usp = new URLSearchParams(search);
  const windowName = "廃棄検証（PMA）";
  let res: any = {};
  let arrangeData: any = [];
  let arrangedData: any = [];
  let resSort: any = [];

  useEffect(() => {
    const tc: string | null = usp.get("tenpocode");
    const userInfo = getUserInfo();
    if (process.env.REACT_APP_MODE !== "develop") {
      // 担当店と職種で画面表示を制御
      if (tc !== null && userAuthCheck(userInfo, tc)) {
        setTenpoCode(tc);
      } else {
        navigate("/tenpo-error");
      }
    } else {
      // ローカルでは制御しない
      if (tc !== null) {
        setTenpoCode(tc);
      }
    }
    let dt = new Date();
    const year = dt.getFullYear();
    const month = ("00" + (dt.getMonth() + 1)).slice(-2);
    setDate(year + month);
    window.setTimeout(dispMsg, timer);
  }, []);

  useEffect(() => {
    const params = {
      mode: "disporsalVerificationPma",
      tenpoCode: tenpoCode,
      yearMonth: date,
    };
    if (tenpoCode && date) {
      (async () => {
        try {
          setLoading(true);
          res = await apiRequest(params);
          resSort = res.data.data.DisposalPmaSearchByParams;
          if (resSort.length) {
            arrangeData = parseJsonFields(resSort);
            arrangedData = arrangeData.sort((a: any, b: any) => {
              return new Date(a.date).getTime() - new Date(b.date).getTime();
            
          });
          } else {
            setData([]);
            throw new Error("API response error");
          }
        } catch (e) {
          setErrorAPI(true);
        }
        if (resSort.length !== 0) {
          setData(arrangedData);
          setDateCount(arrangedData.length - 1);
          if (resSort[0]?.tenpo_name !== null) {
            setTenpoName(resSort[0]?.tenpo_name);
          }
        }
        setLoading(false);
      })();
    }
  }, [tenpoCode, date]);

  useEffect(() => {
    let changeButtonCell = document.getElementById("changeButtonCell");
    let x = "76";
    if (data !== null) {
      x = changeButtonCell?.getBoundingClientRect().width + "px";
    }
    setGetWidthLeft(x);
  }, [loading]);

  useEffect(() => {
    if (!timeout) {
      window.setTimeout(dispMsg, timer);
    }
  }, [timeout]);

  const parseJsonFields = (data: any) => {
    return data.map((item: any) => {
      const keysToParse = ['monthly_disposal_average_price', 'monthly_disposal_total_price', 'monthly_disposal_budget_price', 'daily_disposal_total_price', 'monthly_disposal_pma_average_price', 'monthly_disposal_pma_total_price', 'monthly_disposal_pma_budget_price', 'daily_disposal_pma_total_price'];
      keysToParse.forEach(key => {
        if (item[key] && typeof item[key] === 'string') {
          item[key] = JSON.parse(item[key]);
        }
      });
      return item;
    });
  };

  const week = (data: any) => {
    const mm = Number(data.date.slice(-2));
    let weekNum = "";
    switch (data.day_of_week) {
      case 1:
        weekNum = "(日)";
        break;
      case 2:
        weekNum = "(月)";
        break;
      case 3:
        weekNum = "(火)";
        break;
      case 4:
        weekNum = "(水)";
        break;
      case 5:
        weekNum = "(木)";
        break;
      case 6:
        weekNum = "(金)";
        break;
      case 7:
        weekNum = "(土)";
        break;
    }
    const newDate = mm + weekNum;
    return newDate;
  };

  const yyyymm = (date: string) => {
    const yyyy = date.slice(0, 4);
    const mm = date.slice(4);
    const daily = yyyy + "年" + mm + "月度";
    return daily;
  };

  const dispMsg = () => {
    setTimeout(true);
  };

  const mainClassification = [
    "商品廃棄",
    "米飯",
    "調理パン",
    "麺類・その他",
    "デリカテッセン",
    "スイーツ",
    "ＦＦおでん",
    "ＦＦフライヤーその他",
    "牛乳・乳飲料",
    "生活デイリー",
    "パン",
    "ペストリー",
  ];

  //　過去12か月分取得
  let arr: string[] = [];
  for (let i = 0; i < 13; i++) {
    let dt = new Date();
    let startDate = new Date(dt.getFullYear(), dt.getMonth(), 1);
    startDate.setMonth(startDate.getMonth() - i);
    const year = startDate.getFullYear();
    const month = ("00" + (startDate.getMonth() + 1)).slice(-2);
    arr.push(year + month);
  }

  const thisY = new Date().getFullYear();
  const lastY = thisY - 1;

  // 去年の月一覧
  const lastYearMonths = [
    lastY + "01",
    lastY + "02",
    lastY + "03",
    lastY + "04",
    lastY + "05",
    lastY + "06",
    lastY + "07",
    lastY + "08",
    lastY + "09",
    lastY + "10",
    lastY + "11",
    lastY + "12",
  ];

  // 今年度の月一覧
  const thisYearMonths = [
    thisY + "01",
    thisY + "02",
    thisY + "03",
    thisY + "04",
    thisY + "05",
    thisY + "06",
    thisY + "07",
    thisY + "08",
    thisY + "09",
    thisY + "10",
    thisY + "11",
    thisY + "12",
  ];

  // ボタン活性化
  const activeLastYear = (lastMonth: string) => {
    let disabled: boolean = false;
    if (arr.includes(lastMonth)) {
      disabled = true;
    }
    return disabled;
  };

  const activeThisYear = (thisMonth: string) => {
    let disabled: boolean = false;
    if (arr.includes(thisMonth)) {
      disabled = true;
    }
    return disabled;
  };

  const getColor = (dailyValue: number, budgetValue: number) => 
    dailyValue > budgetValue ? 'red' : 'inherit';

  return timeout ? (
    <TimeOutDisplay setTimeout={setTimeout} />
  ) : (
    <>
      <ThemeProvider theme={fontTheme()}>
        <Header
          windowName={windowName}
          tenpoCode={tenpoCode ? tenpoCode : ""}
          tenpoName={tenpoName ? tenpoName : ""}
        />
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Card css={card} variant="outlined">
              <Modal open={errorAPI} onClose={modalClose}>
                <APIErrorModal />
              </Modal>
              <CardContent>
                {loading && (
                  <div>
                    <CircularProgress css={circularProgress} size={120} />
                  </div>
                )}
                <TableContainer>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow css>
                        <TableCell css={changeButtonCell} id="changeButtonCell">
                          <Button onClick={handleOpen} css={change}>
                            月度変更
                          </Button>
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <div css={calendar}>
                              <Table>
                                <TableHead>
                                  <TableCell css={lastYearDateCell} colSpan={4}>
                                    {lastY}
                                  </TableCell>
                                </TableHead>
                                <TableBody>
                                  {lastYearMonths.map((lastMonth, index) => (
                                    <Button
                                      disabled={!activeLastYear(lastMonth)}
                                      onClick={() => {
                                        setDate(lastMonth);
                                        setOpen(false);
                                      }}
                                      key={index}
                                    >
                                      {index + 1}月
                                    </Button>
                                  ))}
                                </TableBody>
                              </Table>
                              <Table>
                                <TableHead>
                                  <TableCell css={lastYearDateCell} colSpan={4}>
                                    {thisY}
                                  </TableCell>
                                </TableHead>
                                <TableBody>
                                  {thisYearMonths.map((thisMonth, index) => (
                                    <Button
                                      disabled={!activeThisYear(thisMonth)}
                                      onClick={() => {
                                        setDate(thisMonth);
                                        setOpen(false);
                                      }}
                                      key={index}
                                    >
                                      {index + 1}月
                                    </Button>
                                  ))}
                                </TableBody>
                              </Table>
                            </div>
                          </Modal>
                        </TableCell>
                        <TableCell
                          css={yearDateCell}
                        >
                          {yyyymm(date)}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
                <TableContainer css={tableContainer}>
                  <Table stickyHeader>
                    <TableHead css={header}>
                      <TableRow>
                        <TableCell css={thisYearDateCell} id={"id"}>
                          本年日付
                        </TableCell>
                        {mainClassification.map(
                          (mainClassification: any, index) => (
                            <TableCell
                              css={index === 0 ? mainHeaderTotalCell : mainHeaderCell}
                              key={index}
                            >
                              {mainClassification}<br/>（円）
                            </TableCell>
                          )
                        )}
                      </TableRow>
                      {data.slice(0, 1).map((data: any) => (
                      <TableRow>
                        <TableCell css={averageTotalCell}>
                          平均
                        </TableCell>
                        
                        <TableCell css={averageBorderNumberTotalCell}>
                          {data.monthly_disposal_average_price ? dataChange(data.monthly_disposal_average_price['00'].value) : ''}
                        </TableCell>
                      
                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_average_price ? dataChange(data.monthly_disposal_pma_average_price['04'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_average_price ? dataChange(data.monthly_disposal_pma_average_price['05'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_average_price ? dataChange(data.monthly_disposal_pma_average_price['09'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_average_price ? dataChange(data.monthly_disposal_pma_average_price['10'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_average_price ? dataChange(data.monthly_disposal_pma_average_price['11'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_average_price ? dataChange(data.monthly_disposal_pma_average_price['12'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_average_price ? dataChange(data.monthly_disposal_pma_average_price['15'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_average_price ? dataChange(data.monthly_disposal_pma_average_price['21'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_average_price ? dataChange(data.monthly_disposal_pma_average_price['25'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_average_price ? dataChange(data.monthly_disposal_pma_average_price['29'].value) : ''}
                        </TableCell>
                        
                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_average_price ? dataChange(data.monthly_disposal_pma_average_price['30'].value) : ''}
                        </TableCell>
                      </TableRow>
                      ))}
                      {data.slice(0, 1).map((data: any) => (
                      <TableRow>
                        <TableCell css={averageTotalCell}>
                          累計
                        </TableCell>
                        
                        <TableCell css={averageBorderNumberTotalCell}>
                          {data.monthly_disposal_total_price ? dataChange(data.monthly_disposal_total_price['00'].value) : ''}
                        </TableCell>
                      
                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_total_price ? dataChange(data.monthly_disposal_pma_total_price['04'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_total_price ? dataChange(data.monthly_disposal_pma_total_price['05'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_total_price ? dataChange(data.monthly_disposal_pma_total_price['09'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_total_price ? dataChange(data.monthly_disposal_pma_total_price['10'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_total_price ? dataChange(data.monthly_disposal_pma_total_price['11'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_total_price ? dataChange(data.monthly_disposal_pma_total_price['12'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_total_price ? dataChange(data.monthly_disposal_pma_total_price['15'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_total_price ? dataChange(data.monthly_disposal_pma_total_price['21'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_total_price ? dataChange(data.monthly_disposal_pma_total_price['25'].value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_total_price ? dataChange(data.monthly_disposal_pma_total_price['29'].value) : ''}
                        </TableCell>
                        
                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_total_price ? dataChange(data.monthly_disposal_pma_total_price['30'].value) : ''}
                        </TableCell>
                      </TableRow>
                      ))}
                      {data.slice(0, 1).map((data: any) => (
                      <TableRow>
                        <TableCell css={averageTotalCell}>
                          予算
                        </TableCell>
                        
                        <TableCell css={averageBorderNumberTotalCell}>
                          {data.monthly_disposal_budget_price ? dataChange(data.monthly_disposal_budget_price['00']?.value) : ''}
                        </TableCell>
                      
                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_budget_price ? dataChange(data.monthly_disposal_pma_budget_price['04']?.value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_budget_price ? dataChange(data.monthly_disposal_pma_budget_price['05']?.value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_budget_price ? dataChange(data.monthly_disposal_pma_budget_price['09']?.value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_budget_price ? dataChange(data.monthly_disposal_pma_budget_price['10']?.value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_budget_price ? dataChange(data.monthly_disposal_pma_budget_price['11']?.value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_budget_price ? dataChange(data.monthly_disposal_pma_budget_price['12']?.value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_budget_price ? dataChange(data.monthly_disposal_pma_budget_price['15']?.value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_budget_price ? dataChange(data.monthly_disposal_pma_budget_price['21']?.value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_budget_price ? dataChange(data.monthly_disposal_pma_budget_price['25']?.value) : ''}
                        </TableCell>

                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_budget_price ? dataChange(data.monthly_disposal_pma_budget_price['29']?.value) : ''}
                        </TableCell>
                        
                        <TableCell css={averageBorderNumberCell}>
                          {data.monthly_disposal_pma_budget_price ? dataChange(data.monthly_disposal_pma_budget_price['30']?.value) : ''}
                        </TableCell>
                      </TableRow>
                      ))}
                    </TableHead>
                    <TableBody>
                      {data.map((data: any, index: number) => (
                        <TableRow>
                          <TableCell
                            css={thisYearDailyCell}
                            style={{ color: getHolidayStyle(data) }}
                            key={index}
                          >
                            {week(data)}
                          </TableCell>

                          <TableCell 
                            css={borderNumberTotalCell} 
                            style={{ color: getColor(data.daily_disposal_total_price?.['00']?.value, data.monthly_disposal_budget_price?.['00']?.value) }}
                          >
                            {data.daily_disposal_total_price ? dataChange(data.daily_disposal_total_price['00']?.value) : ''}
                          </TableCell>

                          <TableCell 
                            css={borderNumberCell}
                            style={{ color: getColor(data.daily_disposal_pma_total_price?.['04']?.value, data.monthly_disposal_pma_budget_price?.['04']?.value) }}
                          >
                            {dataChange(data.daily_disposal_pma_total_price?.['04']?.value) ?? ''}
                          </TableCell>

                          <TableCell 
                            css={borderNumberCell}
                            style={{ color: getColor(data.daily_disposal_pma_total_price?.['05']?.value, data.monthly_disposal_pma_budget_price?.['05']?.value) }}
                          >
                            {dataChange(data.daily_disposal_pma_total_price?.['05']?.value) ?? ''}
                          </TableCell>

                          <TableCell 
                            css={borderNumberCell}
                            style={{ color: getColor(data.daily_disposal_pma_total_price?.['09']?.value, data.monthly_disposal_pma_budget_price?.['09']?.value) }}
                          >
                            {dataChange(data.daily_disposal_pma_total_price?.['09']?.value) ?? ''}
                          </TableCell>

                          <TableCell 
                            css={borderNumberCell}
                            style={{ color: getColor(data.daily_disposal_pma_total_price?.['10']?.value, data.monthly_disposal_pma_budget_price?.['10']?.value) }}
                          >
                            {dataChange(data.daily_disposal_pma_total_price?.['10']?.value) ?? ''}
                          </TableCell>

                          <TableCell 
                            css={borderNumberCell}
                            style={{ color: getColor(data.daily_disposal_pma_total_price?.['11']?.value, data.monthly_disposal_pma_budget_price?.['11']?.value) }}
                          >
                            {dataChange(data.daily_disposal_pma_total_price?.['11']?.value) ?? ''}
                          </TableCell>

                          <TableCell 
                            css={borderNumberCell}
                            style={{ color: getColor(data.daily_disposal_pma_total_price?.['12']?.value, data.monthly_disposal_pma_budget_price?.['12']?.value) }}
                          >
                            {dataChange(data.daily_disposal_pma_total_price?.['12']?.value) ?? ''}
                          </TableCell>

                          <TableCell 
                            css={borderNumberCell}
                            style={{ color: getColor(data.daily_disposal_pma_total_price?.['15']?.value, data.monthly_disposal_pma_budget_price?.['15']?.value) }}
                          >
                            {dataChange(data.daily_disposal_pma_total_price?.['15']?.value) ?? ''}
                          </TableCell>

                          <TableCell 
                            css={borderNumberCell}
                            style={{ color: getColor(data.daily_disposal_pma_total_price?.['21']?.value, data.monthly_disposal_pma_budget_price?.['21']?.value) }}
                          >
                            {dataChange(data.daily_disposal_pma_total_price?.['21']?.value) ?? ''}
                          </TableCell>

                          <TableCell 
                            css={borderNumberCell}
                            style={{ color: getColor(data.daily_disposal_pma_total_price?.['25']?.value, data.monthly_disposal_pma_budget_price?.['25']?.value) }}
                          >
                            {dataChange(data.daily_disposal_pma_total_price?.['25']?.value) ?? ''}
                          </TableCell>

                          <TableCell 
                            css={borderNumberCell}
                            style={{ color: getColor(data.daily_disposal_pma_total_price?.['29']?.value, data.monthly_disposal_pma_budget_price?.['29']?.value) }}
                          >
                            {dataChange(data.daily_disposal_pma_total_price?.['29']?.value) ?? ''}
                          </TableCell>

                          <TableCell 
                            css={borderNumberCell}
                            style={{ color: getColor(data.daily_disposal_pma_total_price?.['30']?.value, data.monthly_disposal_pma_budget_price?.['30']?.value) }}
                          >
                            {dataChange(data.daily_disposal_pma_total_price?.['30']?.value) ?? ''}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
export default DisposalVerificationPma;
