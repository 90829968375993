import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Card,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Modal,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Header from "../common/Header";
import { timer, fontTheme } from "../common/utils";
import {
  getStringDiffValue,
  getColoredRationValue,
  getIntegerValue,
} from "../common/Utils";
import { apiRequest } from "../../apis/util";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../data/userInfo";
import TimeOutDisplay from "../common/TimeOutDisplay";
import APIErrorModal from "../common/APIErrorModal";
import { userAuthCheck } from "../auth/authUser";
import ToggleButtonComponent from "../common/ToggleButtonComponent";

const card = css`
  background-color: #f7f9f8;
  margin-top: 75px;
  width: 100%;
  @media screen and (min-width: 1194px) {
    height: 630px;
  }
  @media screen and (min-width: 1280px) {
    height: 520px;
  }
  @media screen and (min-width: 1366px) {
    height: 560px;
  }
`;
const cardContent = css`
  padding: 4px 16px 16px 16px;
`;
const tableContainer = css`
  border: 0;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1194px) {
    max-height: 552px;
  }
  @media screen and (min-width: 1280px) {
    max-height: 456px;
  }
  @media screen and (min-width: 1366px) {
    max-height: 500px;
  }
`;
const circularProgress = css`
  position: absolute;
  top: 45%;
  left: 45%;
  color: #ccc;
  z-index: 10;
`;
const operationSpace = css`
  padding : 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const operationRight = css`
  padding: 4px;
  text-align: right;
`;
const operationLeft = css`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
`;
const headerCell = css`
  font-weight: 700;
  font-size: 14px;
  background-color: #e0ffee;
  border-bottom: 2px solid #c4c4c4;
  box-sizing: border-box;
  padding: 2px;
  z-index: 3;
  min-width: 160px;
  max-height: 40px;
  height: 40px;
  white-space: nowrap;
  text-align: center;
  align-items: center;
  -webkit-appearance: none;
`;
const secondHeaderCell = css`
  font-size: 12px;
  font-weight: 700;
  top: 40px;
  max-width: 40px;
  min-width: 40px;
  border-right: 1px dashed #c4c4c4;
  background-color: #e0ffee;
  border-bottom: 2px solid #c4c4c4;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  padding: 2px;
`;
const headerFixedNameCell = css`
  font-size: 12px;
  top: 70px;
  width: 50px;
  background-color: #fff;
  border-right: 2px solid #c4c4c4;
  text-align: left;
  padding: 4px;
  z-index: 2;
  box-sizing: border-box;
  white-space: nowrap;
  -webkit-appearance: none;
`;
const headerFixedNameSecondCell = css`
${headerFixedNameCell}
  top: 103px;
`;
const headerFixedNameThirdCell = css`
${headerFixedNameCell}
  top: 136px;
`;
const headerFixedNameFourthCell = css`
${headerFixedNameCell}
  top: 169px;
`;
const headerFixedNameFifthCell = css`
${headerFixedNameCell}
  top: 202px;
  border-bottom: 2px solid #c4c4c4;
`;
const headerFixedCell = css`
  top: 70px;
  width: 50px;
  background-color: #fff;
  border-right: 1px dashed #c4c4c4;
  text-align: right;
  padding: 4px;
  z-index: 2;
  box-sizing: border-box;
  white-space: nowrap;
  -webkit-appearance: none;
`;
const headerFixedSecondCell = css`
${headerFixedCell}
  top: 103px;
`;
const headerFixedThirdCell = css`
${headerFixedCell}
  top: 136px;
`;
const headerFixedFourthCell = css`
${headerFixedCell}
  top: 169px;
`;
const headerFixedFifthCell = css`
${headerFixedCell}
  top: 202px;
  border-bottom: 2px solid #c4c4c4;
`;
const overrideWidth = css`
  width: 220px;
  max-width: 220px;
`;
const overrideItemCell = css`
  position: sticky;
  right: 10px;
  z-index: 5;
`;
const rightThickBorder = css`
  border-right: 2px solid #c4c4c4;
`;
const itemNameCell = css`
  font-size: 12px;
  position: sticky;
  padding: 4px;
  left: 0;
  border-collapse: collapse;
  background-color: #fff;
  border-right: 2px solid #c4c4c4;
  box-sizing: border-box;
  white-space: nowrap;
  -webkit-appearance: none;
`;
const valueCell = css`
  width: 50px;
  border-right: 1px dashed #c4c4c4;
  text-align: right;
  padding: 4px;
  -webkit-appearance: none;
`;

const pmacdAsc = [
  { pma_cd: "01", pma_name: "ビール" },
  { pma_cd: "02", pma_name: "日本酒" },
  { pma_cd: "03", pma_name: "洋酒・ワイン・雑酒" },
  { pma_cd: "04", pma_name: "米飯" },
  { pma_cd: "05", pma_name: "調理パン" },
  { pma_cd: "09", pma_name: "麺類・その他" },
  { pma_cd: "10", pma_name: "デリカテッセン" },
  { pma_cd: "11", pma_name: "スイーツ" },
  { pma_cd: "12", pma_name: "ＦＦおでん" },
  { pma_cd: "13", pma_name: "ＦＦカップ" },
  { pma_cd: "14", pma_name: "カウントＦＦ" },
  { pma_cd: "15", pma_name: "ＦＦフライヤーその他" },
  { pma_cd: "21", pma_name: "牛乳・乳飲料" },
  { pma_cd: "22", pma_name: "乳製品" },
  { pma_cd: "24", pma_name: "玉子" },
  { pma_cd: "25", pma_name: "生活デイリー" },
  { pma_cd: "29", pma_name: "パン" },
  { pma_cd: "30", pma_name: "ペストリー" },
  { pma_cd: "31", pma_name: "和風菓子・豆菓子" },
  { pma_cd: "32", pma_name: "ポケット・洋風菓子" },
  { pma_cd: "33", pma_name: "スナック菓子" },
  { pma_cd: "34", pma_name: "加工食品" },
  { pma_cd: "35", pma_name: "調味料・乾物" },
  { pma_cd: "36", pma_name: "米穀" },
  { pma_cd: "37", pma_name: "し好品" },
  { pma_cd: "38", pma_name: "珍味・缶詰" },
  { pma_cd: "41", pma_name: "冷凍食品" },
  { pma_cd: "45", pma_name: "アイスクリーム" },
  { pma_cd: "46", pma_name: "ソフトドリンク" },
  { pma_cd: "47", pma_name: "栄養ドリンク" },
  { pma_cd: "48", pma_name: "健康栄養食品" },
  { pma_cd: "49", pma_name: "催事・進物ギフト" },
  { pma_cd: "50", pma_name: "くらしのギフト" },
  { pma_cd: "53", pma_name: "ネット（出版その他）" },
  { pma_cd: "55", pma_name: "お中元ギフト" },
  { pma_cd: "56", pma_name: "お歳暮ギフト" },
  { pma_cd: "60", pma_name: "文庫・書籍" },
  { pma_cd: "61", pma_name: "タバコ" },
  { pma_cd: "62", pma_name: "雑誌" },
  { pma_cd: "63", pma_name: "新聞" },
  { pma_cd: "64", pma_name: "コミックス" },
  { pma_cd: "66", pma_name: "映像・音楽ソフト" },
  { pma_cd: "67", pma_name: "軽衣料" },
  { pma_cd: "68", pma_name: "健康・医療品" },
  { pma_cd: "69", pma_name: "紙・生理用品" },
  { pma_cd: "70", pma_name: "オーラル・シャンプー" },
  { pma_cd: "71", pma_name: "洗剤・防虫・芳香" },
  { pma_cd: "72", pma_name: "生活雑貨・行楽用品" },
  { pma_cd: "73", pma_name: "化粧品" },
  { pma_cd: "74", pma_name: "玩具" },
  { pma_cd: "75", pma_name: "文具" },
  { pma_cd: "76", pma_name: "その他雑貨" },
  { pma_cd: "77", pma_name: "切手葉書印紙" },
  { pma_cd: "78", pma_name: "サービス商品" },
  { pma_cd: "79", pma_name: "委託商品" },
  { pma_cd: "90", pma_name: "その他売上" }
];
const summarycdAsc = [
  { summary_cd: "05", summary_name: "酒類" },
  { summary_cd: "06", summary_name: "オープンケース" },
  { summary_cd: "07", summary_name: "カウンターＦＦ" },
  { summary_cd: "08", summary_name: "パン・ペストリー" },
  { summary_cd: "09", summary_name: "菓子" },
  { summary_cd: "10", summary_name: "加工食品" },
  { summary_cd: "11", summary_name: "フローズン" },
  { summary_cd: "12", summary_name: "ソフドリ・栄ドリ" },
  { summary_cd: "13", summary_name: "雑誌・コミック・新聞" },
  { summary_cd: "14", summary_name: "雑貨" }
];

type Order = "asc" | "desc";
type flagPS = typeof pmacdAsc | typeof summarycdAsc;

const PosTransitionPma: React.FC<{}> = ({ }) => {
  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [lastWeek, setLastWeek] = useState<any>([]);
  const [towWeekAgo, setTowWeekAgo] = useState<any>([]);
  const [threeWeekAgo, setThreeWeekAgo] = useState<any>([]);
  const [fourWeekAgo, setFourWeekAgo] = useState<any>([]);
  const [lastWeekDate, setLastWeekDate] = useState<string>("");
  const [towWeekAgoDate, setTowWeekAgoDate] = useState<string>("");
  const [threeWeekAgoDate, setThreeWeekAgoDate] = useState<string>("");
  const [fourWeekAgoDate, setFourWeekAgoDate] = useState<string>("");
  const [fixedLastWeek, setFixedLastWeek] = useState<any>([]);
  const [fixedTowWeekAgo, setFixedTowWeekAgo] = useState<any>([]);
  const [fixedThreeWeekAgo, setFixedThreeWeekAgo] = useState<any>([]);
  const [fixedFourWeekAgo, setFixedFourWeekAgo] = useState<any>([]);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("pma_cd");
  const [flagPS, setFlagPS] = useState("PosTrasitionPma");
  const [flagWM, setFlagWM] = useState("Weekly");
  const [tenpoCode, setTenpoCode] = useState<string | null>("008616");
  const [tenpoName, setTenpoName] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [timeout, setTimeout] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);
  const windowName = "POS-PMA推移";
  const modalClose = () => setErrorAPI(false);
  const ekodanePma = ["04", "05", "09", "10", "11", "12", "15", "21", "22", "24", "25", "29", "30"];
  const pmaKeys: any = ["999"];
  const summaryKeys: any = ["01", "02", "15", "03", "04"];
  const [mapData, setMapData] = useState<flagPS>();

  type PmaWeeklyDataEntry = {
    code: string;
    date: string;
    name: string;
    pma_weekly_sales_average: string;
    pma_weekly_sales_average_yoy: string;
    pma_weekly_sales_average_yearly_diff: string;
    pma_weekly_sales_average_class_rate: string;
  };
  type TransformedPmaWeeklyEntry = {
    cd: string;
    name: string;
    pma_weekly_sales_average: number;
    pma_weekly_sales_average_yoy: number;
    pma_weekly_sales_average_yearly_diff: number;
    pma_weekly_sales_average_class_rate: number;
  };
  type SummaryDataWeeklyEntry = {
    code: string;
    date: string;
    name: string;
    pma_summary_weekly_sales_average: string;
    pma_summary_weekly_sales_average_yoy: string;
    pma_summary_weekly_sales_average_yearly_diff: string;
    pma_summary_weekly_sales_average_class_rate: string;
  };
  type TransformedSummaryWeeklyEntry = {
    cd: string;
    name: string;
    pma_summary_weekly_sales_average: number;
    pma_summary_weekly_sales_average_yoy: number;
    pma_summary_weekly_sales_average_yearly_diff: number;
    pma_summary_weekly_sales_average_class_rate: number;
  };

  type PmaMonthlyDataEntry = {
    code: string;
    date: string;
    name: string;
    pma_monthly_sales_average: string;
    pma_monthly_sales_average_yoy: string;
    pma_monthly_sales_average_yearly_diff: string;
    pma_monthly_sales_average_class_rate: string;
  };
  type TransformedPmaMonthlyEntry = {
    cd: string;
    name: string;
    pma_monthly_sales_average: number;
    pma_monthly_sales_average_yoy: number;
    pma_monthly_sales_average_yearly_diff: number;
    pma_monthly_sales_average_class_rate: number;
  };
  type SummaryDataMonthlyEntry = {
    code: string;
    date: string;
    name: string;
    pma_summary_monthly_sales_average: string;
    pma_summary_monthly_sales_average_yoy: string;
    pma_summary_monthly_sales_average_yearly_diff: string;
    pma_summary_monthly_sales_average_class_rate: string;
  };
  type TransformedSummaryMonthlyEntry = {
    cd: string;
    name: string;
    pma_summary_monthly_sales_average: number;
    pma_summary_monthly_sales_average_yoy: number;
    pma_summary_monthly_sales_average_yearly_diff: number;
    pma_summary_monthly_sales_average_class_rate: number;
  };
  const search = useLocation().search;
  const usp = new URLSearchParams(search);

  useEffect(() => {
    const tc: string | null = usp.get("tenpocode");
    const userInfo = getUserInfo();
    if (process.env.REACT_APP_MODE !== "develop") {
      // 担当店と職種で画面表示を制御
      if (tc !== null && userAuthCheck(userInfo, tc)) {
        setTenpoCode(tc);
      } else {
        navigate("/tenpo-error");
      }
    } else {
      // ローカルでは制御しない
      if (tc !== null) {
        setTenpoCode(tc);
      }
    }
    window.setTimeout(dispMsg, timer);
    window.setTimeout(dispMsg, timer);
  }, []);

  useEffect(() => {
    if (!timeout) {
      window.setTimeout(dispMsg, timer);
    }
  }, [timeout]);

  useEffect(() => {
    let res: any = {};
    let date: any = "";
    let newDate: string = "";
    let formatedData: any = [];
    let dataName = flagPS + flagWM;
    setLastWeek([]);
    setTowWeekAgo([]);
    setThreeWeekAgo([]);
    setFourWeekAgo([]);
    setFixedLastWeek([]);
    setFixedTowWeekAgo([]);
    setFixedThreeWeekAgo([]);
    setFixedFourWeekAgo([]);
    let dt = new Date();
    const dtYear = dt.getFullYear();
    const dtMonth = ("00" + (dt.getMonth() + 1)).slice(-2);
    const dtDate = ("00" + (dt.getDate() + 1)).slice(-2);
    if (flagWM === "Weekly") {
      date = dtYear + dtMonth + dtDate;
    } else {
      date = dtYear + dtMonth;
    }
    if (tenpoCode) {
      newDate = date.replace(/\//g, "-");
      const params = {
        mode: dataName,
        tenpoCode: tenpoCode,
        date: newDate,
      };
      console.log("param");
      console.log(params);
      (async () => {
        try {
          setLoading(true);
          res = await apiRequest(params);
          let dataName = flagPS + flagWM;
          switch (dataName) {
            case "PosTrasitionPmaWeekly":
              res = res.data.data.PosPmaWeeklyTransitionSearchByParams;
              break;
            case "PosTrasitionPmaMonthly":
              res = res.data.data.PosPmaMonthlyTransitionSearchByParams;
              break;
            case "PosTrasitionPmaSummaryWeekly":
              res = res.data.data.PosPmaSummaryWeeklyTransitionSearchByParams;
              break;
            case "PosTrasitionPmaSummaryMonthly":
              res = res.data.data.PosPmaSummaryMonthlyTransitionSearchByParams;
              break;
          }
          if (res === undefined || res.length === 0) {
            throw new Error("API response error");
          }
          formatedData = formatData(res, flagPS, flagWM);
          console.log("formate");
          console.log(formatedData);
          if (flagPS === "PosTrasitionPma") {
            setFixedLastWeek(formatedData[3]?.filter((item: any) => pmaKeys.includes(item.pma_cd)));
            setFixedTowWeekAgo(formatedData[2]?.filter((item: any) => pmaKeys.includes(item.pma_cd)));
            setFixedThreeWeekAgo(formatedData[1]?.filter((item: any) => pmaKeys.includes(item.pma_cd)));
            setFixedFourWeekAgo(formatedData[0]?.filter((item: any) => pmaKeys.includes(item.pma_cd)));
            const sortByPmaCd = (a: { pma_cd: string; pma_name: string; }, b: { pma_cd: string; pma_name: string; }) => {
              return a.pma_cd.localeCompare(b.pma_cd);
            };
            const lastWeek = formatedData[3]
              ?.filter((item: { pma_cd: string; pma_name: string; }) => !pmaKeys.includes(item.pma_cd))
              .sort(sortByPmaCd);
            const towWeekAgo = formatedData[2]
              ?.filter((item: { pma_cd: string; pma_name: string; }) => !pmaKeys.includes(item.pma_cd))
              .sort(sortByPmaCd);
            const threeWeekAgo = formatedData[1]
              ?.filter((item: { pma_cd: string; pma_name: string; }) => !pmaKeys.includes(item.pma_cd))
              .sort(sortByPmaCd);
            const fourWeekAgo = formatedData[0]
              ?.filter((item: { pma_cd: string; pma_name: string; }) => !pmaKeys.includes(item.pma_cd))
              .sort(sortByPmaCd);
            setLastWeek(lastWeek);
            setTowWeekAgo(towWeekAgo);
            setThreeWeekAgo(threeWeekAgo);
            setFourWeekAgo(fourWeekAgo);
            setMapData(pmacdAsc);
          } else if (flagPS === "PosTrasitionPmaSummary") {
            setFixedLastWeek(formatedData[3]?.filter((item: any) => summaryKeys.includes(item.summary_cd)));
            setFixedTowWeekAgo(formatedData[2]?.filter((item: any) => summaryKeys.includes(item.summary_cd)));
            setFixedThreeWeekAgo(formatedData[1]?.filter((item: any) => summaryKeys.includes(item.summary_cd)));
            setFixedFourWeekAgo(formatedData[0]?.filter((item: any) => summaryKeys.includes(item.summary_cd)));
            const sortBySummaryCd = (a: { summary_cd: string; summary_name: string; }, b: { summary_cd: string; summary_name: string; }) => {
              return a.summary_cd.localeCompare(b.summary_cd);
            };
            const fixedLastWeek = formatedData[3]
              ?.filter((item: { summary_cd: string; summary_name: string; }) => !summaryKeys.includes(item.summary_cd))
              .sort(sortBySummaryCd);
            const fixedTowWeekAgo = formatedData[2]
              ?.filter((item: { summary_cd: string; summary_name: string; }) => !summaryKeys.includes(item.summary_cd))
              .sort(sortBySummaryCd);
            const fixedThreeWeekAgo = formatedData[1]
              ?.filter((item: { summary_cd: string; summary_name: string; }) => !summaryKeys.includes(item.summary_cd))
              .sort(sortBySummaryCd);
            const fixedFourWeekAgo = formatedData[0]
              ?.filter((item: { summary_cd: string; summary_name: string; }) => !summaryKeys.includes(item.summary_cd))
              .sort(sortBySummaryCd);
            setLastWeek(fixedLastWeek);
            setTowWeekAgo(fixedTowWeekAgo);
            setThreeWeekAgo(fixedThreeWeekAgo);
            setFourWeekAgo(fixedFourWeekAgo);
            setMapData(summarycdAsc);
          }
        } catch (e) {
          setLastWeek([]);
          setTowWeekAgo([]);
          setThreeWeekAgo([]);
          setFourWeekAgo([]);
          setFixedLastWeek([]);
          setFixedTowWeekAgo([]);
          setFixedThreeWeekAgo([]);
          setFixedFourWeekAgo([]);
          setErrorAPI(true);
        }
        setLoading(false);
      })();
    }
  }, [flagPS, flagWM]);

  // APIで取得したデータの成形
  const formatData = (data: any, flagPS: string, flagWM: string): TransformedPmaWeeklyEntry[][] | TransformedSummaryWeeklyEntry[][] | TransformedPmaMonthlyEntry[][] | TransformedSummaryMonthlyEntry[][] => {
    let pma_sales_average: any = [];
    let pma_sales_average_yoy: any = [];
    let pma_sales_average_yearly_diff: any = [];
    let pma_sales_average_class_rate: any = [];
    let keys: any = [];
    setLastWeekDate(data[3]?.date);
    setTowWeekAgoDate(data[2]?.date);
    setThreeWeekAgoDate(data[1]?.date);
    setFourWeekAgoDate(data[0]?.date);
    if (data[0]?.tenpo_name !== null) {
      setTenpoName(data[0]?.tenpo_name);
    }
    if (flagPS === "PosTrasitionPma") {
      if (flagWM === "Weekly") {
        return data?.map((entry: PmaWeeklyDataEntry) => {
          pma_sales_average = entry.pma_weekly_sales_average ? JSON.parse(entry.pma_weekly_sales_average) : {};
          pma_sales_average_yoy = entry.pma_weekly_sales_average_yoy ? JSON.parse(entry.pma_weekly_sales_average_yoy) : {};
          pma_sales_average_yearly_diff = entry.pma_weekly_sales_average_yearly_diff ? JSON.parse(entry.pma_weekly_sales_average_yearly_diff) : {};
          pma_sales_average_class_rate = entry.pma_weekly_sales_average_class_rate ? JSON.parse(entry.pma_weekly_sales_average_class_rate) : {};
          keys = Object.keys(pma_sales_average);
          return keys?.map((key: any) => ({
            pma_cd: key,
            name: pma_sales_average[key].name,
            sales_average: pma_sales_average[key].value,
            sales_average_yoy: pma_sales_average_yoy[key].value,
            sales_average_yearly_diff: pma_sales_average_yearly_diff[key].value,
            sales_average_class_rate: pma_sales_average_class_rate[key].value,
          }));
        });
      } else {
        return data?.map((entry: PmaMonthlyDataEntry) => {
          pma_sales_average = entry.pma_monthly_sales_average ? JSON.parse(entry.pma_monthly_sales_average) : {};
          pma_sales_average_yoy = entry.pma_monthly_sales_average_yoy ? JSON.parse(entry.pma_monthly_sales_average_yoy) : {};
          pma_sales_average_yearly_diff = entry.pma_monthly_sales_average_yearly_diff ? JSON.parse(entry.pma_monthly_sales_average_yearly_diff) : {};
          pma_sales_average_class_rate = entry.pma_monthly_sales_average_class_rate ? JSON.parse(entry.pma_monthly_sales_average_class_rate) : {};
          keys = Object.keys(pma_sales_average);
          return keys?.map((key: any) => ({
            pma_cd: key,
            name: pma_sales_average[key].name,
            sales_average: pma_sales_average[key].value,
            sales_average_yoy: pma_sales_average_yoy[key].value,
            sales_average_yearly_diff: pma_sales_average_yearly_diff[key].value,
            sales_average_class_rate: pma_sales_average_class_rate[key].value,
          }));
        });
      }
    } else if (flagPS === "PosTrasitionPmaSummary") {
      if (flagWM === "Weekly") {
        return data.map((entry: SummaryDataWeeklyEntry) => {
          pma_sales_average = entry.pma_summary_weekly_sales_average ? JSON.parse(entry.pma_summary_weekly_sales_average) : {};
          pma_sales_average_yoy = entry.pma_summary_weekly_sales_average_yoy ? JSON.parse(entry.pma_summary_weekly_sales_average_yoy) : {};
          pma_sales_average_yearly_diff = entry.pma_summary_weekly_sales_average_yearly_diff ? JSON.parse(entry.pma_summary_weekly_sales_average_yearly_diff) : {};
          pma_sales_average_class_rate = entry.pma_summary_weekly_sales_average_class_rate ? JSON.parse(entry.pma_summary_weekly_sales_average_class_rate) : {};
          keys = Object.keys(pma_sales_average);
          return keys.map((key: any) => ({
            summary_cd: key,
            name: pma_sales_average[key].name,
            sales_average: pma_sales_average[key].value,
            sales_average_yoy: pma_sales_average_yoy[key].value,
            sales_average_yearly_diff: pma_sales_average_yearly_diff[key].value,
            sales_average_class_rate: pma_sales_average_class_rate[key].value,
          }));
        });
      } else {
        return data.map((entry: SummaryDataMonthlyEntry) => {
          pma_sales_average = entry.pma_summary_monthly_sales_average ? JSON.parse(entry.pma_summary_monthly_sales_average) : {};
          pma_sales_average_yoy = entry.pma_summary_monthly_sales_average_yoy ? JSON.parse(entry.pma_summary_monthly_sales_average_yoy) : {};
          pma_sales_average_yearly_diff = entry.pma_summary_monthly_sales_average_yearly_diff ? JSON.parse(entry.pma_summary_monthly_sales_average_yearly_diff) : {};
          pma_sales_average_class_rate = entry.pma_summary_monthly_sales_average_class_rate ? JSON.parse(entry.pma_summary_monthly_sales_average_class_rate) : {};
          keys = Object.keys(pma_sales_average);
          return keys.map((key: any) => ({
            summary_cd: key,
            name: pma_sales_average[key].name,
            sales_average: pma_sales_average[key].value,
            sales_average_yoy: pma_sales_average_yoy[key].value,
            sales_average_yearly_diff: pma_sales_average_yearly_diff[key].value,
            sales_average_class_rate: pma_sales_average_class_rate[key].value,
          }));
        });
      }
    }
    return [];
  };

  const getDiffValue = (value: number) => {
    if (value === null || value === undefined) {
      return "-";
    }

    const roundValue = Math.round(value * 10) / 10;
    return getStringDiffValue(roundValue);
  };

  const getRationValue = (value: number) => {
    if (value === null || value === undefined) {
      return "-";
    }

    const roundValue = Math.round(value * 10) / 10;
    return getColoredRationValue(roundValue);
  };

  // PMA,サマリの切り替え
  const changePS = (value: string) => {
    setFlagPS(value);
  };

  // 週別,月別の切り替え
  const changeWM = (value: string) => {
    setFlagWM(value);
  };

  // 昇順,降順の切り替え
  const changeAD = (newOrder: any) => {
    setOrder(newOrder);
    sortData(newOrder, orderBy);
  };

  // ソートする項目の切り替え
  const changeData = (newOrderBy: any) => {
    setOrderBy(newOrderBy);
    sortData(order, newOrderBy);
    if (newOrderBy === "pma_cd") {
      setOrder("asc");
    }
  };

  // ソートした際にデータ全ての順番を入れ替える
  const sortData = (order: string, orderBy: string) => {
    const sortedLastWeek = [...lastWeek].sort((a, b) => {
      if (order === "asc") {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    });

    const sortByLastWeek = (weekData: any) => {
      return sortedLastWeek.map((sortedItem: any) => {
        if (flagPS === "PosTrasitionPma") {
          return weekData.find((item: any) => item.pma_cd === sortedItem.pma_cd);
        } else if (flagPS === "PosTrasitionPmaSummary") {
          return weekData.find((item: any) => item.summary_cd === sortedItem.summary_cd);
        }
      });
    };
    const sortByDataName = (dataName: any) => {
      const sortedData = sortedLastWeek.map((sortedItem: any) => {
        if (flagPS === "PosTrasitionPma") {
          const foundItem = dataName.find((item: any) => item.pma_cd === sortedItem.pma_cd);
          return foundItem ? foundItem : { pma_cd: sortedItem.pma_cd, pma_name: sortedItem.pma_name };
        } else if (flagPS === "PosTrasitionPmaSummary") {
          const foundItem = dataName.find((item: any) => item.summary_cd === sortedItem.summary_cd);
          return foundItem ? foundItem : { summary_cd: sortedItem.summary_cd, summary_name: sortedItem.summary_name };
        }
      });
      // sortedLastWeek に存在しない項目を末尾に追加
      const remainingItems = dataName.filter((item: any) => {
        if (flagPS === "PosTrasitionPma") {
          return !sortedLastWeek.some((sortedItem: any) => sortedItem.pma_cd === item.pma_cd);
        } else if (flagPS === "PosTrasitionPmaSummary") {
          return !sortedLastWeek.some((sortedItem: any) => sortedItem.summary_cd === item.summary_cd);
        }
      });
      return [...sortedData, ...remainingItems];
    };
    if (flagPS === "PosTrasitionPma") {
      // 項目の順序を入れ替える（項目基準の場合元に戻す）
      if (orderBy === "pma_cd") {
        setMapData(pmacdAsc);
      } else {
        setMapData(sortByDataName(pmacdAsc));
      }
    } else if (flagPS === "PosTrasitionPmaSummary") {
      if (orderBy === "pma_cd") {
        setMapData(summarycdAsc);
      } else {
        setMapData(sortByDataName(summarycdAsc));
      }
    }
    // 各データ1週間前の順序を元に入れ替える
    setLastWeek(sortedLastWeek);
    setTowWeekAgo(sortByLastWeek(towWeekAgo));
    setThreeWeekAgo(sortByLastWeek(threeWeekAgo));
    setFourWeekAgo(sortByLastWeek(fourWeekAgo));
  };

  // Header部分出力
  const EnhancedTableHead: React.FC<{}> = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            key={"name"}
            rowSpan={2}
            css={[
              headerCell,
              rightThickBorder,
              overrideItemCell,
              overrideWidth,
            ]}
          >
            <div>
              <div style={{ margin: 0 }}>項目</div>
              <div
                style={{ fontSize: "12px", fontWeight: "normal", margin: 0 }}
              >
                ※網掛けはエコだ値対象分類
              </div>
            </div>
          </TableCell>
          <TableCell
            colSpan={4}
            rowSpan={1}
            css={[headerCell, rightThickBorder]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {fourWeekAgoDate.replace("-", "/").replace("-", "/")}~
            </div>
          </TableCell>
          <TableCell
            colSpan={4}
            rowSpan={1}
            css={[headerCell, rightThickBorder]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {threeWeekAgoDate.replace("-", "/").replace("-", "/")}~
            </div>
          </TableCell>
          <TableCell
            colSpan={4}
            rowSpan={1}
            css={[headerCell, rightThickBorder]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {towWeekAgoDate.replace("-", "/").replace("-", "/")}~
            </div>
          </TableCell>
          <TableCell
            colSpan={4}
            rowSpan={1}
            css={[headerCell]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {lastWeekDate?.replace("-", "/").replace("-", "/")}~
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell css={secondHeaderCell}>販売金額</TableCell>
          <TableCell css={secondHeaderCell}>販売前比</TableCell>
          <TableCell css={secondHeaderCell}>前年差額</TableCell>
          <TableCell
            css={secondHeaderCell}
            style={{ borderRight: "2px solid #c4c4c4" }}
          >
            構成比
          </TableCell>
          <TableCell css={secondHeaderCell}>販売金額</TableCell>
          <TableCell css={secondHeaderCell}>販売前比</TableCell>
          <TableCell css={secondHeaderCell}>前年差額</TableCell>
          <TableCell
            css={secondHeaderCell}
            style={{ borderRight: "2px solid #c4c4c4" }}
          >
            構成比
          </TableCell>
          <TableCell css={secondHeaderCell}>販売金額</TableCell>
          <TableCell css={secondHeaderCell}>販売前比</TableCell>
          <TableCell css={secondHeaderCell}>前年差額</TableCell>
          <TableCell
            css={secondHeaderCell}
            style={{ borderRight: "2px solid #c4c4c4" }}
          >
            構成比
          </TableCell>
          <TableCell css={secondHeaderCell}>販売金額</TableCell>
          <TableCell css={secondHeaderCell}>販売前比</TableCell>
          <TableCell css={secondHeaderCell}>前年差額</TableCell>
          <TableCell
            css={secondHeaderCell}
            style={{ borderRight: "none" }}
          >
            構成比
          </TableCell>
        </TableRow>
        {/* ヘッダーに固定する行 */}
        {flagPS === "PosTrasitionPma" ?
          <TableRow>
            <TableCell
              css={headerFixedNameCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {fixedFourWeekAgo[0]?.name || fixedThreeWeekAgo[0]?.name || fixedTowWeekAgo[0]?.name || fixedLastWeek[0]?.name}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {getIntegerValue(fixedFourWeekAgo[0]?.sales_average / 1000)}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {getRationValue(fixedFourWeekAgo[0]?.sales_average_yoy)}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {getDiffValue(fixedFourWeekAgo[0]?.sales_average_yearly_diff / 1000)}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{
                borderBottom: "2px solid #c4c4c4",
                borderRight: "2px solid #c4c4c4"
              }}
            >
              {fixedFourWeekAgo[0]?.sales_average_class_rate}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {getIntegerValue(fixedThreeWeekAgo[0]?.sales_average / 1000)}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {getRationValue(fixedThreeWeekAgo[0]?.sales_average_yoy)}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {getDiffValue(fixedThreeWeekAgo[0]?.sales_average_yearly_diff / 1000)}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{
                borderBottom: "2px solid #c4c4c4",
                borderRight: "2px solid #c4c4c4"
              }}
            >
              {fixedThreeWeekAgo[0]?.sales_average_class_rate}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {getIntegerValue(fixedTowWeekAgo[0]?.sales_average / 1000)}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {getRationValue(fixedTowWeekAgo[0]?.sales_average_yoy)}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {getDiffValue(fixedTowWeekAgo[0]?.sales_average_yearly_diff / 1000)}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{
                borderBottom: "2px solid #c4c4c4",
                borderRight: "2px solid #c4c4c4"
              }}
            >
              {fixedTowWeekAgo[0]?.sales_average_class_rate}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {getIntegerValue(fixedLastWeek[0]?.sales_average / 1000)}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {getRationValue(fixedLastWeek[0]?.sales_average_yoy)}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{ borderBottom: "2px solid #c4c4c4" }}
            >
              {getDiffValue(fixedLastWeek[0]?.sales_average_yearly_diff / 1000)}
            </TableCell>
            <TableCell
              css={headerFixedCell}
              style={{
                borderRight: "none",
                borderBottom: "2px solid #c4c4c4"
              }}
            >
              {fixedLastWeek[0]?.sales_average_class_rate}
            </TableCell>
          </TableRow>
          :
          <>
            <TableRow>
              <TableCell css={headerFixedNameCell}>
                {fixedFourWeekAgo[0]?.name}
              </TableCell>
              <TableCell css={headerFixedCell}>
                {getIntegerValue(fixedFourWeekAgo[0]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedCell}>
                {getRationValue(fixedFourWeekAgo[0]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedCell}>
                {getDiffValue(fixedFourWeekAgo[0]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedFourWeekAgo[0]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedCell}>
                {getIntegerValue(fixedThreeWeekAgo[0]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedCell}>
                {getRationValue(fixedThreeWeekAgo[0]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedCell}>
                {getDiffValue(fixedThreeWeekAgo[0]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell css={headerFixedCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedThreeWeekAgo[0]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedCell}>
                {getIntegerValue(fixedTowWeekAgo[0]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedCell}>
                {getRationValue(fixedTowWeekAgo[0]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedCell}>
                {getDiffValue(fixedTowWeekAgo[0]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedTowWeekAgo[0]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedCell}>
                {getIntegerValue(fixedLastWeek[0]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedCell}>
                {getRationValue(fixedLastWeek[0]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedCell}>
                {getDiffValue(fixedLastWeek[0]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedCell}
                style={{ borderRight: "none" }}
              >
                {fixedLastWeek[0]?.sales_average_class_rate}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell css={headerFixedNameSecondCell}>
                {fixedFourWeekAgo[1]?.name}
              </TableCell>
              <TableCell css={headerFixedSecondCell}>
                {getIntegerValue(fixedFourWeekAgo[1]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedSecondCell}>
                {getRationValue(fixedFourWeekAgo[1]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedSecondCell}>
                {getDiffValue(fixedFourWeekAgo[1]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedSecondCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedFourWeekAgo[1]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedSecondCell}>
                {getIntegerValue(fixedThreeWeekAgo[1]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedSecondCell}>
                {getRationValue(fixedThreeWeekAgo[1]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedSecondCell}>
                {getDiffValue(fixedThreeWeekAgo[1]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedSecondCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedThreeWeekAgo[1]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedSecondCell}>
                {getIntegerValue(fixedTowWeekAgo[1]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedSecondCell}>
                {getRationValue(fixedTowWeekAgo[1]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedSecondCell}>
                {getDiffValue(fixedTowWeekAgo[1]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedSecondCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedTowWeekAgo[1]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedSecondCell}>
                {getIntegerValue(fixedLastWeek[1]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedSecondCell}>
                {getRationValue(fixedLastWeek[1]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedSecondCell}>
                {getDiffValue(fixedLastWeek[1]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedSecondCell}
                style={{ borderRight: "none" }}
              >
                {fixedLastWeek[1]?.sales_average_class_rate}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell css={headerFixedNameThirdCell}>
                {fixedFourWeekAgo[2]?.name}
              </TableCell>
              <TableCell css={headerFixedThirdCell}>
                {getIntegerValue(fixedFourWeekAgo[2]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedThirdCell}>
                {getRationValue(fixedFourWeekAgo[2]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedThirdCell}>
                {getDiffValue(fixedFourWeekAgo[2]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedThirdCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedFourWeekAgo[2]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedThirdCell}>
                {getIntegerValue(fixedThreeWeekAgo[2]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedThirdCell}>
                {getRationValue(fixedThreeWeekAgo[2]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedThirdCell}>
                {getDiffValue(fixedThreeWeekAgo[2]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedThirdCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedThreeWeekAgo[2]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedThirdCell}>
                {getIntegerValue(fixedTowWeekAgo[2]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedThirdCell}>
                {getRationValue(fixedTowWeekAgo[2]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedThirdCell}>
                {getDiffValue(fixedTowWeekAgo[2]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedThirdCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedTowWeekAgo[2]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedThirdCell}>
                {getIntegerValue(fixedLastWeek[2]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedThirdCell}>
                {getRationValue(fixedLastWeek[2]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedThirdCell}>
                {getDiffValue(fixedLastWeek[2]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedThirdCell}
                style={{ borderRight: "none" }}
              >
                {fixedLastWeek[2]?.sales_average_class_rate}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell css={headerFixedNameFourthCell}>
                {fixedFourWeekAgo[3]?.name}
              </TableCell>
              <TableCell css={headerFixedFourthCell}>
                {getIntegerValue(fixedFourWeekAgo[3]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedFourthCell}>
                {getRationValue(fixedFourWeekAgo[3]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedFourthCell}>
                {getDiffValue(fixedFourWeekAgo[3]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedFourthCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedFourWeekAgo[3]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedFourthCell}>
                {getIntegerValue(fixedThreeWeekAgo[3]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedFourthCell}>
                {getRationValue(fixedThreeWeekAgo[3]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedFourthCell}>
                {getDiffValue(fixedThreeWeekAgo[3]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedFourthCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedThreeWeekAgo[3]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedFourthCell}>
                {getIntegerValue(fixedTowWeekAgo[3]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedFourthCell}>
                {getRationValue(fixedTowWeekAgo[3]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedFourthCell}>
                {getDiffValue(fixedTowWeekAgo[3]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedFourthCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedTowWeekAgo[3]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedFourthCell}>
                {getIntegerValue(fixedLastWeek[3]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedFourthCell}>
                {getRationValue(fixedLastWeek[3]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedFourthCell}>
                {getDiffValue(fixedLastWeek[3]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedFourthCell}
                style={{ borderRight: "none" }}
              >
                {fixedLastWeek[3]?.sales_average_class_rate}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell css={headerFixedNameFifthCell}>
                {fixedFourWeekAgo[4]?.name}
              </TableCell>
              <TableCell css={headerFixedFifthCell}>
                {getIntegerValue(fixedFourWeekAgo[4]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedFifthCell}>
                {getRationValue(fixedFourWeekAgo[4]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedFifthCell}>
                {getDiffValue(fixedFourWeekAgo[4]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedFifthCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedFourWeekAgo[4]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedFifthCell}>
                {getIntegerValue(fixedThreeWeekAgo[4]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedFifthCell}>
                {getRationValue(fixedThreeWeekAgo[4]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedFifthCell}>
                {getDiffValue(fixedThreeWeekAgo[4]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedFifthCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedThreeWeekAgo[4]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedFifthCell}>
                {getIntegerValue(fixedTowWeekAgo[4]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedFifthCell}>
                {getRationValue(fixedTowWeekAgo[4]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedFifthCell}>
                {getDiffValue(fixedTowWeekAgo[4]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedFifthCell}
                style={{ borderRight: "2px solid #c4c4c4" }}
              >
                {fixedTowWeekAgo[4]?.sales_average_class_rate}
              </TableCell>
              <TableCell css={headerFixedFifthCell}>
                {getIntegerValue(fixedLastWeek[4]?.sales_average / 1000)}
              </TableCell>
              <TableCell css={headerFixedFifthCell}>
                {getRationValue(fixedLastWeek[4]?.sales_average_yoy)}
              </TableCell>
              <TableCell css={headerFixedFifthCell}>
                {getDiffValue(fixedLastWeek[4]?.sales_average_yearly_diff / 1000)}
              </TableCell>
              <TableCell
                css={headerFixedFifthCell}
                style={{ borderRight: "none" }}
              >
                {fixedLastWeek[4]?.sales_average_class_rate}
              </TableCell>
            </TableRow>
          </>
        }
      </TableHead>
    );
  };

  // Body部分出力
  const CreateTableBody: React.FC<{}> = () => {
    return (
      <TableBody>
        {mapData?.map((row: any, index: number) => {
          let rowA: any;
          let rowB: any;
          let rowC: any;
          let rowD: any;
          if (flagPS === "PosTrasitionPma") {
            rowA = fourWeekAgo.find((data: any) => data?.pma_cd === row.pma_cd);
            rowB = threeWeekAgo.find((data: any) => data?.pma_cd === row.pma_cd);
            rowC = towWeekAgo.find((data: any) => data?.pma_cd === row.pma_cd);
            rowD = lastWeek.find((data: any) => data?.pma_cd === row.pma_cd);
          } else if (flagPS === "PosTrasitionPmaSummary") {
            rowA = fourWeekAgo.find((data: any) => data?.summary_cd === row.summary_cd);
            rowB = threeWeekAgo.find((data: any) => data?.summary_cd === row.summary_cd);
            rowC = towWeekAgo.find((data: any) => data?.summary_cd === row.summary_cd);
            rowD = lastWeek.find((data: any) => data?.summary_cd === row.summary_cd);
          }
          return (
            <TableRow key={`row-${index}`}>
              <TableCell
                css={itemNameCell}
                style={{
                  background: ekodanePma.includes(row.pma_cd)
                    ? "#ffefe5"
                    : undefined,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {flagPS === "PosTrasitionPma" ? row.pma_name : row.summary_name}
                </div>
              </TableCell>
              {rowA ? (
                <>
                  <TableCell
                    css={valueCell}
                  >
                    {getIntegerValue(rowA.sales_average / 1000)}
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >
                    {getRationValue(rowA.sales_average_yoy)}
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >
                    {getDiffValue(rowA.sales_average_yearly_diff / 1000)}
                  </TableCell>
                  <TableCell
                    css={valueCell}
                    style={{ borderRight: "2px solid #c4c4c4" }}
                  >
                    {rowA.sales_average_class_rate}
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell
                    css={valueCell}
                  >-
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >-
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >-
                  </TableCell>
                  <TableCell
                    css={valueCell}
                    style={{ borderRight: "2px solid #c4c4c4" }}
                  >-
                  </TableCell>
                </>
              )}
              {rowB ? (
                <>
                  <TableCell
                    css={valueCell}
                  >
                    {getIntegerValue(rowB.sales_average / 1000)}
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >
                    {getRationValue(rowB.sales_average_yoy)}
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >
                    {getDiffValue(rowB.sales_average_yearly_diff / 1000)}
                  </TableCell>
                  <TableCell
                    css={valueCell}
                    style={{ borderRight: "2px solid #c4c4c4" }}
                  >
                    {rowB.sales_average_class_rate}
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell
                    css={valueCell}
                  >-
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >-
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >-
                  </TableCell>
                  <TableCell
                    css={valueCell}
                    style={{ borderRight: "2px solid #c4c4c4" }}
                  >-
                  </TableCell>
                </>
              )}
              {rowC ? (
                <>
                  <TableCell
                    css={valueCell}
                  >
                    {getIntegerValue(rowC.sales_average / 1000)}
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >
                    {getRationValue(rowC.sales_average_yoy)}
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >
                    {getDiffValue(rowC.sales_average_yearly_diff / 1000)}
                  </TableCell>
                  <TableCell
                    css={valueCell}
                    style={{ borderRight: "2px solid #c4c4c4" }}
                  >
                    {rowC.sales_average_class_rate}
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell
                    css={valueCell}
                  >-
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >-
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >-
                  </TableCell>
                  <TableCell
                    css={valueCell}
                    style={{ borderRight: "2px solid #c4c4c4" }}
                  >-
                  </TableCell>
                </>
              )}
              {rowD ? (
                <>
                  <TableCell
                    css={valueCell}
                  >
                    {getIntegerValue(rowD.sales_average / 1000)}
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >
                    {getRationValue(rowD.sales_average_yoy)}
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >
                    {getDiffValue(rowD.sales_average_yearly_diff / 1000)}
                  </TableCell>
                  <TableCell
                    css={valueCell}
                    style={{ borderRight: "none" }}
                  >
                    {rowD.sales_average_class_rate}
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell
                    css={valueCell}
                  >-
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >-
                  </TableCell>
                  <TableCell
                    css={valueCell}
                  >-
                  </TableCell>
                  <TableCell
                    css={valueCell}
                    style={{ borderRight: "none" }}
                  >-
                  </TableCell>
                </>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const dispMsg = () => {
    setTimeout(true);
  };

  return timeout ? (
    <TimeOutDisplay setTimeout={setTimeout} />
  ) : (
    <>
      <ThemeProvider theme={fontTheme()}>
        <Header
          windowName={windowName}
          tenpoCode={tenpoCode ? tenpoCode : ""}
          tenpoName={tenpoName ? tenpoName : ""}
        />
        <Grid item container>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={11}>
            <Card css={card} variant="outlined">
              <Modal open={errorAPI} onClose={modalClose}>
                <APIErrorModal />
              </Modal>
              <CardContent css={cardContent}>
                {loading && (
                  <div>
                    <CircularProgress css={circularProgress} size={120} />
                  </div>
                )}
                <div css={operationSpace}>
                  <div css={operationLeft}>
                    <ToggleButtonComponent
                      options={[
                        { value: "PosTrasitionPma", label: "PMA" },
                        { value: "PosTrasitionPmaSummary", label: "サマリ" },
                      ]}
                      selectedValue={flagPS}
                      onChange={changePS}
                      orderBy={"none"}
                      loadFlag={loading}
                    />
                    <ToggleButtonComponent
                      options={[
                        { value: "Weekly", label: "週別" },
                        { value: "Monthly", label: "月別" },
                      ]}
                      selectedValue={flagWM}
                      onChange={changeWM}
                      orderBy={"none"}
                      loadFlag={loading}
                    />
                    <ToggleButtonComponent
                      options={[
                        { value: "pma_cd", label: "項目" },
                        { value: "sales_average", label: "販売金額" },
                        { value: "sales_average_yoy", label: "販売前比" },
                        { value: "sales_average_yearly_diff", label: "前年差額" },
                      ]}
                      selectedValue={orderBy}
                      onChange={changeData}
                      orderBy={orderBy}
                      loadFlag={loading}
                    />
                    <ToggleButtonComponent
                      options={[
                        { value: "asc", label: "昇順" },
                        { value: "desc", label: "降順" },
                      ]}
                      selectedValue={order}
                      onChange={changeAD}
                      orderBy={orderBy}
                      loadFlag={loading}
                    />
                  </div>
                  <div css={operationRight}>
                    （単位：千円 / ％）
                  </div>
                </div>
                <TableContainer component={Paper} css={tableContainer}>
                  <Table stickyHeader>
                    <EnhancedTableHead />
                    <CreateTableBody />
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={0.5}></Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
export default PosTransitionPma;
