// 画面毎の表示制御
export const userAuthCheck = (
  user: any,
  tenpoCode: string,
  mode: string = ""
) => {
  // 対象職種かつ選択している店舗が担当店か判定
  if (mode === "dailyMulti") {
    // 個店経営状況（サブ）
    if (user.tenpo_code.length === 0) {
      // 担当店をもっていなければNG
      return false;
    }
    if (
      // ZM・DMは除外
      (user.job_category_code_OA !== "120" &&
        user.job_category_code_OA !== "210"&&
        user.tenpo_code.length !== 0) ||
      //SOFC（担当店舗有り）
      (user.job_category_code_OA === "210" &&
        user.tenpo_code_special_list.length !== 0)
    ) {
      return true;
    }
  } else {
    if (user.tenpo_code.includes(tenpoCode)) {
      return true;
    }
  }

  // OFCDの管理ユーザーか判定
  if (isAdminUser(user)) {
    return true;
  }
  return false;
};

// OFCDの管理ユーザーを判定する
const isAdminUser = (user: any) => {
  if (adminUsersArr.includes(user.mail_address)) {
    // 個別指定ユーザー（シス企、OP企、OPサポ、DIP、財務会計、松井執行役員、次世代P、業務サポ）
    return true;
  } else if (
    user.department_code_OA === "955000" &&
    user.job_category_code_OA === "090"
  ) {
    // システム本部執行役員
    return true;
  } else {
    return false;
  }
};

// OFCDの管理ユーザーのメールアドレス
const adminUsersArr: string[] = [
  "matsui-hiroshi@sej.7andi.co.jp",
  "hashimoto-yuusuke@sej.7andi.co.jp",
  "fukai-shintarou@sej.7andi.co.jp",
  "nagae-shuuji@sej.7andi.co.jp",
  "egusa-masanori@sej.7andi.co.jp",
  "kikuya-jyunji@sej.7andi.co.jp",
  "matsumura-kazumi@sej.7andi.co.jp",
  "ichikawa-hiroshi@sej.7andi.co.jp",
  "nakamura-naomi@sej.7andi.co.jp",
  "yano-yasumasa@sej.7andi.co.jp",
  "hori-takayuki@sej.7andi.co.jp",
  "iwanuma-rika@sej.7andi.co.jp",
  "kuwahara-miwa@sej.7andi.co.jp",
  "miyazaki-hitomi@sej.7andi.co.jp",
  "ooyama-nobuhiro@sej.7andi.co.jp",
  "inoshita-hiroyuki@sej.7andi.co.jp",
  "takahashi-yukinari@sej.7andi.co.jp",
  "hirota-osamu@sej.7andi.co.jp",
  "tsukahara-koushou@sej.7andi.co.jp",
  "watanabe-noriki@sej.7andi.co.jp",
  "koga-hitoshi@sej.7andi.co.jp",
  "takou-chiduru@sej.7andi.co.jp",
  "maeda-hiroyuki@sej.7andi.co.jp",
  "iijima-kazuhiro@sej.7andi.co.jp",
  "shinozawa-ryouta@sej.7andi.co.jp",
  "nakajima-hikaru@sej.7andi.co.jp",
  "nunokawa-ryouhei@sej.7andi.co.jp",
  "noyori-yoshiki@sej.7andi.co.jp",
  "ikemoto-jyunji@sej.7andi.co.jp",
  "noda-shizuma@sej.7andi.co.jp",
  "mori-hiroyoshi@sej.7andi.co.jp",
  "iinuma-kazutake@sej.7andi.co.jp",
  "akutsu-tomohiro@sej.7andi.co.jp",
  "maeda-hiroyuki@sej.7andi.co.jp",
  "hori-takayuki@sej.7andi.co.jp",
  "inoshita-hiroyuki@sej.7andi.co.jp",
  "murayama-norifumi02@sej.7andi.co.jp",
  "kanzawa-naofumi@sej.7andi.co.jp",
  "yoshimura-hiroshi@sej.7andi.co.jp",
  "nakahashi-yuusuke@sej.7andi.co.jp",
  "kagawa-yoshiaki@sej.7andi.co.jp",
  "nakatani-munetaka01@sej.7andi.co.jp",
  "sawada-yuuki01@sej.7andi.co.jp",
  "kikushima-hirotaka@sej.7andi.co.jp",
  "yamagata-tomohiko@sej.7andi.co.jp",
  "tani-ryuuji@sej.7andi.co.jp",
  "ishii-yuusuke@sej.7andi.co.jp",
  "teduka-kikyou@sej.7andi.co.jp",
  "hino-satomi@sej.7andi.co.jp",
  "morita-hiroshige@sej.7andi.co.jp",
  "kaneshiro-tomohide@sej.7andi.co.jp",
];
