import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getAuth, signInWithCustomToken, signInWithEmailAndPassword } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)
export const auth = getAuth(app)
export const customTokenSignIn = signInWithCustomToken
export const emailAndPasswordSignIn = signInWithEmailAndPassword
export const storage = getStorage(app)
export const analytics = getAnalytics(app)

// Firebase Authentication API Token取得
export const getFirebaseAuthToken = async (isRefresh: boolean = true) => {
  if(!isFirebaseAuth()){
    // リロード時に認証情報が消えてしまうので、再認証を行う
    const user = await initFirebaseAuth();
  }
  return await auth.currentUser?.getIdToken(/* forceRefresh */ isRefresh);
};

// Firebaseユーザー情報取得
export const getFirebaseUserInfo = (): any => {
  return auth.currentUser !== null ? auth.currentUser : {};
};

// Firebaseログイン判定
export const isFirebaseAuth = (): any => {
  return auth.currentUser !== null;
};

// Firebase認証
export const initFirebaseAuth = () => {
  return new Promise((resolve) => {
    var unSub = auth.onAuthStateChanged((user) => {
      // user オブジェクトを resolve
      resolve(user);
      unSub();
    });
  });
};