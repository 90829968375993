import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Header from "../common/Header";

import { rows8 } from "../../dummyData";
import { rows6 } from "../../dummyData";

const card = css`
  margin: 80px 16px 16px 16px;
  // background-color: #f7f9f8;
`;

const top = css`
  overflow: auto;
  width: 100%;
  height: 530px;
  border-top: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  border-collapse: separate;
  border-spacing: 1;
`;
const style = css`
  margin: 0;
  position: sticky;
  position: -webkit-sticky;
  table-layout: fixed;
  top: 0;
  left: 0;
  white-space: nowrap;
  padding: 4px;
  border-top: none;
  border-left: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  border-right: none;
  background-color: #e0ffee;
  border-collapse: collapse;
  border-spacing: 0;
`;

const header = css`
  ${style}
  font-weight: bold;
  background-color: #e0ffee;
  z-index: 5;
`;

const yearDateCell = css`
  ${style}
  background-color: #e0ffee;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-top: none;
  box-sizing: border-box;
  z-index: 5;
`;

const mainItemCell = css`
  ${style}
  background-color: #e0ffee;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  z-index: 3;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

const thisYearDateCell = css`
  ${style}
  width: 80px;
  background-color: #e0ffee;
  font-size: 14px;
  // font-weight: bold;
  text-align: center;
  z-index: 5;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const averageTotalCell = css`
  ${style}
  background-color: #e0ffee;
  text-align: center;
  z-index: 4;
  // &::before{
  //     content: "";
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     top: 0;
  //     left: 0;
  //     border-top: none;
  //     border-right: 2px solid #dcdcdc;
  //     border-bottom: 2px solid #dcdcdc;
  //     border-left: none;
  //     border-collapse: collapse;
  //     border-spacing: 0;
  // }
`;

const averageTotalNumberCell = css`
  ${style}
  background-color: #ffffff;
  font-size: 14px;
  text-align: right;
  z-index: 0;
`;

const minorItemCell = css`
  ${style}
  width: 15px;
  background-color: #e0ffee;
  text-align: center;
  z-index: 2;
  // &::before{
  //     content: "";
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     top: 0;
  //     left: 0;
  //     border-top: none;
  //     border-right: 2px solid #dcdcdc;
  //     border-bottom: 2px solid #dcdcdc;
  //     border-left: none;
  //     border-collapse: collapse;
  //     border-spacing: 0;
  // }
`;

const thisYearDailyCell = css`
  ${style}
  font-size: 14px;
  text-align: center;
  background-color: #ffffff;
  z-index: 2;
  // &::before{
  //     content: "";
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     top: 0;
  //     left: 0;
  //     border-top: none;
  //     border-right: 2px solid #dcdcdc;
  //     border-bottom: 2px solid #dcdcdc;
  //     border-left: none;
  //     border-collapse: collapse;
  //     border-spacing: 0;
  // }
`;

const numberCell = css`
  ${style}
  background-color: #ffffff;
  font-size: 14px;
  width: 50px;
  text-align: right;
  z-index: 0;
`;

const windowName = "廃棄検証";

const WasteVerification: React.FC<{}> = ({}) => {
  return (
    <>
      {/* <Header windowName={windowName}/>  */}
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Card css={card} variant="outlined">
            <CardContent>
              <TableContainer css={top}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead css={header}>
                    <TableRow>
                      <TableCell css={yearDateCell}>月次変更</TableCell>
                      <TableCell css={mainItemCell} colSpan={11}>
                        2022年 4月度
                      </TableCell>
                    </TableRow>
                    <TableCell css={thisYearDateCell}>本年日付</TableCell>
                    <TableCell css={minorItemCell}>商品廃棄</TableCell>
                    <TableCell css={minorItemCell}>米飯</TableCell>
                    <TableCell css={minorItemCell}>調理パン</TableCell>
                    <TableCell css={minorItemCell}>麺類・その他</TableCell>
                    <TableCell css={minorItemCell}>デリカテッセン</TableCell>
                    <TableCell css={minorItemCell}>スイーツ</TableCell>
                    <TableCell css={minorItemCell}>FFおでん</TableCell>
                    <TableCell css={minorItemCell}>
                      FFフライヤー
                      <br />
                      その他
                    </TableCell>
                    <TableCell css={minorItemCell}>牛乳・乳製品</TableCell>
                    <TableCell css={minorItemCell}>生活デイリー</TableCell>
                    <TableCell css={minorItemCell}>パン</TableCell>
                    <TableCell css={minorItemCell}>ペストリー</TableCell>
                    <TableRow>
                      <TableCell css={averageTotalCell}>平均</TableCell>
                      {rows8.map((row8: any) => (
                        <TableCell css={averageTotalNumberCell}>
                          {row8.average}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell css={averageTotalCell}>累計</TableCell>
                      {rows8.map((row8: any) => (
                        <TableCell css={averageTotalNumberCell}>
                          {row8.total}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell css={averageTotalCell}>予算</TableCell>
                      {rows8.map((row8: any) => (
                        <TableCell css={averageTotalNumberCell}>
                          {row8.budget}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows6.map((row6: any) => (
                      <TableRow>
                        <TableCell css={thisYearDailyCell}>
                          {row6.date}
                        </TableCell>
                        <TableCell css={numberCell}>
                          {row6.salesPerformance}
                        </TableCell>
                        <TableCell css={numberCell}>{row6.salesYoy}</TableCell>
                        <TableCell css={numberCell}>
                          {row6.salesPerformanceofPreviousYear}
                        </TableCell>
                        <TableCell css={numberCell}>
                          {row6.customersPerformance}
                        </TableCell>
                        <TableCell css={numberCell}>
                          {row6.customersYoy}
                        </TableCell>
                        <TableCell css={numberCell}>
                          {row6.customersPerformanceofPreviousYear}
                        </TableCell>
                        <TableCell css={numberCell}>
                          {row6.wastePerformance}
                        </TableCell>
                        <TableCell css={numberCell}>{row6.wasteYoy}</TableCell>
                        <TableCell css={numberCell}>
                          {row6.wastePerformanceofPreviousYear}
                        </TableCell>
                        <TableCell css={numberCell}>
                          {row6.customerUnitPriceYoy}
                        </TableCell>
                        <TableCell css={numberCell}>
                          {row6.customerUnitPricePerformanceofPreviousYear}
                        </TableCell>
                        <TableCell css={numberCell}>
                          {row6.customerUnitPricePerformanceofPreviousYear}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </>
  );
};

export default WasteVerification;
