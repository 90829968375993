import { addDecimalPoint } from "../common/utils";

// 表の値に符号と色を付ける
export const getStringDiffValue = (value: number) => {
  let stringValue: string = "";
  let colorCode: string = "";
  if (value > 0) {
    stringValue = "+" + addDecimalPoint(value).toString();
  } else if (value <= 0) {
    stringValue = addDecimalPoint(value).toString();
  }
  if (value >= 0) {
    colorCode = "black";
  } else if (value < 0) {
    colorCode = "red";
  }
  return (
    <>
      <p style={{ color: colorCode, margin: 0 }}>{stringValue}</p>
    </>
  );
};

// 表の比の値に小数点と色を付ける
export const getColoredRationValue = (value: number) => {
  let colorCode: string = "";
  let strValue: string = "";
  if (value >= 100) {
    colorCode = "black";
  } else {
    colorCode = "red";
  }
  strValue = addDecimalPoint(value);

  return (
    <>
      <p style={{ color: colorCode, margin: 0 }}>{strValue}</p>
    </>
  );
};

// 整数の値を表示させる
export const getIntegerValue = (value: number) => {
  let stringValue: string = "";
  if (value > 0) {
    stringValue = addDecimalPoint(value).toString();
  }

  return (
    <>
      <p style={{ margin: 0 }}>{stringValue}</p>
    </>
  );
};

const Utils: React.FC<{}> = ({}) => {
  return <></>;
};

export default Utils;

// 過去8日間のデータを生成
export const formatPastData = (data: any) => {
  let todayDate = new Date();
  const newCreateData = [];
  for (let i = 7; i >= 0; i--) {
    // 過去の日付を計算
    const date = new Date(todayDate);
    date.setDate(todayDate.getDate() - i);
    const newDate =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + date.getDate()).slice(-2);
    const matchData = data.find((data: any) => data.date === newDate);
    if (matchData) {
      newCreateData.push(matchData);
    } else {
      newCreateData.push({
        date: newDate,
      });
    }
  }
  return newCreateData;
};
