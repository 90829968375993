import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Modal,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Header from "../common/Header";
import { getWeekOptions, getMonthOptions, timer, fontTheme } from "../common/utils";
import {
  getStringDiffValue,
  getColoredRationValue,
  getIntegerValue,
} from "../common/Utils";
import { apiRequest } from "../../apis/util";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../data/userInfo";
import TimeOutDisplay from "../common/TimeOutDisplay";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ThemeProvider } from "@mui/material/styles";
import APIErrorModal from "../common/APIErrorModal";
import ToggleButtonComponent from "../common/ToggleButtonComponent";
import { userAuthCheck } from "../auth/authUser";

const card = css`
  background-color: #f7f9f8;
  margin-top: 75px;
  width: 100%;
  @media screen and (min-width: 1194px) {
    height: 630px;
  }
  @media screen and (min-width: 1280px) {
    height: 520px;
  }
  @media screen and (min-width: 1366px) {
    height: 560px;
  }
`;
const cardContent = css`
  padding: 4px 16px 16px 16px;
`;
const tableContainer = css`
  border-spacing: 0;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1194px) {
    height: 552px;
  }
  @media screen and (min-width: 1280px) {
    height: 456px;
  }
  @media screen and (min-width: 1366px) {
    height: 500px;
  }
`;
const circularProgress = css`
  position: absolute;
  top: 45%;
  left: 45%;
  color: #ccc;
  z-index: 10;
`;
const operationSpace = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const operationLeft = css`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
`;
const operationRight = css`
  margin-left: auto;
`;
const selectTerm = css`
  width: 200px;
  height: 35px;
  margin: 8px;
  padding: 0;
  text-align: left;
  background-color: #ffffff;
`;
const headerCell = css`
  font-weight: 700;
  font-size: 16px;
  background-color: #e0ffee;
  border-bottom: 2px solid #c4c4c4;
  box-sizing: border-box;
  padding: 2px;
  z-index: 2;
  min-width: 250px;
  max-height: 60px;
  height: 60px;
  white-space: nowrap;
  text-align: center;
  align-items: center;
  -webkit-appearance: none;
`;
const accordionHeaderCell = css`
  font-size: 16px;
  font-weight: 700;
  min-width: 270px;
  max-width: 270px;
  border-right: 2px solid #c4c4c4;
  background-color: #e0ffee;
  border-bottom: 2px solid #c4c4c4;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  padding: 2px;
`;
const accordionMinHeaderCell = css`
  ${accordionHeaderCell}
  top: 30px;
  max-width: 90px;
  min-width: 90px;
`;
const accordionValueCell = css`
  padding: 4px;
  text-align: right;
  box-sizing: border-box;
  border-right: 2px solid #c4c4c4;
  font-size: 14px;
  background-color: #fff;
  min-width: 90px;
  max-width: 90px;
`;
const accordionHeaderValueCell = css`
  ${accordionValueCell}
  top: 60px;
  border-bottom: 2px solid #c4c4c4;
`;
const overrideWidth = css`
  width: 250px;
  max-width: 250px;
`;
const overrideTotalCell = css`
  top: 60px;
  z-index: 3;
`;
const overrideItemCell = css`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 4;
`;
const rightThickBorder = css`
  border-right: 2px solid #c4c4c4;
`;
const leftThickBorder = css`
  border-left: 2px solid #c4c4c4;
`;
const tableRow = css`
  @media screen and (min-width: 1194px) {
    max-height: 43px;
    height: 43px;
  }
  @media screen and (min-width: 1366px) {
    max-height: 38px;
    height: 38px;
  }
`;
const itemNameCell = css`
  font-size: 16px;
  position: sticky;
  padding: 4px;
  left: 0;
  z-index: 1;
  border-collapse: collapse;
  background-color: #fff;
  border-right: 2px solid #c4c4c4;
  box-sizing: border-box;
  white-space: nowrap;
  -webkit-appearance: none;
`;

const GraphContainer = styled.div<{
  isPositive: boolean;
  value: number;
  maxValue: number;
}>`
  position: absolute;
  width: ${({ value, maxValue }) =>
    `calc(${Math.abs(value / maxValue) * 99}%)`};
  height: 70%;
  top: 15%;
  left: ${({ isPositive }) => (isPositive ? 0 : "auto")};
  right: ${({ isPositive }) => (isPositive ? "auto" : 0)};
  background-color: ${({ isPositive }) => (isPositive ? "#EDB200" : "#58C8EB")};
`;
const graphValueCell = css`
  width: 50px;
  border-right: 1px dashed #c4c4c4;
  text-align: right;
  padding: 4px;
  -webkit-appearance: none;
`;
const GraphCell = styled(TableCell)<{ headerFlag: boolean }>`
  position: sticky;
  border-left: 1px dashed #c4c4c4;
  background-color: ${({ headerFlag }) =>
    headerFlag ? "#fff" : "transparent"};
  -webkit-appearance: none;
`;
const roundIcon = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 50px;
  width: 50px;
  &:hover {
    border-radius: 50%;
  }
`;

const sortButton = css`
  position: absolute;
  padding: 0;
  right: 0;
`;

type Order = "asc" | "desc";

const PosPmaSummary: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>(
    "sales_average_yearly_diff_area_diff"
  );
  const [isOpen, setIsOpen] = useState(false);
  const [weekDateValue, setWeekDateValue] = useState("");
  const [monthDateValue, setMonthDateValue] = useState("");
  const [flagWM, setFlagWM] = useState("PosPmaSummaryWeekly");
  const [flagArea, setFlagArea] = useState("do");
  const [locationFlag, setLocationFlag] = useState(true);
  const [weekDateOptions, setWeekDateOptions] = useState<string[]>([]);
  const [monthDateOptions, setMonthDateOptions] = useState<string[]>([]);
  const [tenpoCode, setTenpoCode] = useState<string | null>("");
  const [tenpoName, setTenpoName] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [timeout, setTimeout] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);
  const windowName = "POS-PMA分析_サマリ";
  const [maxValue, setMaxValue] = useState(0);
  const [resData, setResData] = useState<any>([]);
  const [fixedRow, setFixedRow] = useState<any>([]);
  const modalClose = () => setErrorAPI(false);

  let summaryData: any = [];
  let pmaSummarySalesAverage: any = {};
  let pmaSummaryAreaSalesAverage: any = {};
  let pmaSummarySalesAverageAreaDiff: any = {};
  let pmaSummarySalesAverageYoy: any = {};
  let pmaSummaryAreaSalesAverageYoy: any = {};
  let pmaSummarySalesAverageYoyAreaDiff: any = {};
  let pmaSummarySalesAverageYearlyDiff: any = {};
  let pmaSummaryAreaSalesAverageYearlyDiff: any = {};
  let pmaSummarySalesAverageYearlyDiffAreaDiff: any = {};
  let pmaSummarySalesAverageClassRate: any = {};
  let pmaSummaryAreaSalesAverageClassRate: any = {};
  let pmaSummarySalesAverageClassRateAreaDiff: any = {};
  let pmaSummarySalesAverageUsageRate: any = {};
  let pmaSummaryAreaSalesAverageUsageRate: any = {};
  let pmaSummarySalesAverageUsageRateAreaDiff: any = {};
  let fixedKeys: any = ["01", "02", "15", "03", "04"];
  let fixedData: any = [];
  let filteredData: any = [];

  const search = useLocation().search;
  const usp = new URLSearchParams(search);

  useEffect(() => {
    let optionsW: any[] = getWeekOptions();
    let optionsM: any[] = getMonthOptions();
    setWeekDateOptions(optionsW);
    setMonthDateOptions(optionsM);
    setWeekDateValue(optionsW[0]);
    setMonthDateValue(optionsM[0]);
    const tc: string | null = usp.get("tenpocode");
    const userInfo = getUserInfo();
    if (process.env.REACT_APP_MODE !== "develop") {
      // 担当店と職種で画面表示を制御
      if (tc !== null && userAuthCheck(userInfo, tc)) {
        setTenpoCode(tc);
      } else {
        navigate("/tenpo-error");
      }
    } else {
      // ローカルでは制御しない
      if (tc !== null) {
        setTenpoCode(tc);
      }
    }
    window.setTimeout(dispMsg, timer);
  }, []);

  useEffect(() => {
    if (!timeout) {
      window.setTimeout(dispMsg, timer);
    }
  }, [timeout]);

  useEffect(() => {
    let res: any = {};
    let date: string = "";
    let newDate: string = "";

    if (tenpoCode) {
      if (flagWM === "PosPmaSummaryWeekly") {
        if (!weekDateValue) {
          return;
        }
        date = weekDateValue.slice(0, -1);
      } else if (flagWM === "PosPmaSummaryMonthly") {
        date = monthDateValue.concat("/01");
      }
      newDate = date.replace(/\//g, "-");
      const params = {
        mode: flagWM,
        tenpoCode: tenpoCode,
        date: newDate,
      };
      (async () => {
        try {
          setLoading(true);
          res = await apiRequest(params);
          let tempResData;
          if (flagWM === "PosPmaSummaryWeekly") {
            tempResData = res.data.data.PosPmaSummaryWeeklySearchByParams[0];
          } else if (flagWM === "PosPmaSummaryMonthly") {
            tempResData = res.data.data.PosPmaSummaryMonthlySearchByParams[0];
          }
          setResData(tempResData);
          if (tempResData.location_flag === 0) {
            setLocationFlag(false);
          }
          if (tempResData.tenpo_name !== null) {
            setTenpoName(tempResData.tenpo_name);
          }
          if (tempResData.length === 0) {
            throw new Error("API response error");
          }
          formatData(tempResData);
          setData(filteredData);
          if (fixedData) {
            setFixedRow(fixedData);
          }
        } catch (e) {
          setData([]);
          setFixedRow([]);
          setErrorAPI(true);
        }
        setLoading(false);
      })();
    }
  }, [weekDateValue, monthDateValue, flagWM]);

  useEffect(() => {
    if (resData.length === 0) {
      return;
    }
    formatData(resData);
    setData(filteredData);
    setFixedRow(fixedData);
  }, [flagArea]);

  const formatData = (data: any) => {
    if (flagArea === "do") {
      if (flagWM === "PosPmaSummaryWeekly") {
        pmaSummarySalesAverage = JSON.parse(
          data.pma_summary_weekly_sales_average
        );
        pmaSummaryAreaSalesAverage = JSON.parse(
          data.pma_summary_weekly_do_sales_average
        );
        pmaSummarySalesAverageAreaDiff = JSON.parse(
          data.pma_summary_weekly_sales_average_do_diff
        );
        pmaSummarySalesAverageYoy = JSON.parse(
          data.pma_summary_weekly_sales_average_yoy
        );
        pmaSummaryAreaSalesAverageYoy = JSON.parse(
          data.pma_summary_weekly_do_sales_average_yoy
        );
        pmaSummarySalesAverageYoyAreaDiff = JSON.parse(
          data.pma_summary_weekly_sales_average_yoy_do_diff
        );
        pmaSummarySalesAverageYearlyDiff = JSON.parse(
          data.pma_summary_weekly_sales_average_yearly_diff
        );
        pmaSummaryAreaSalesAverageYearlyDiff = JSON.parse(
          data.pma_summary_weekly_do_sales_average_yearly_diff
        );
        pmaSummarySalesAverageYearlyDiffAreaDiff = JSON.parse(
          data.pma_summary_weekly_sales_average_yearly_diff_do_diff
        );
        pmaSummarySalesAverageClassRate = JSON.parse(
          data.pma_summary_weekly_sales_average_class_rate
        );
        pmaSummaryAreaSalesAverageClassRate = JSON.parse(
          data.pma_summary_weekly_do_sales_average_class_rate
        );
        pmaSummarySalesAverageClassRateAreaDiff = JSON.parse(
          data.pma_summary_weekly_sales_average_class_rate_do_diff
        );
        pmaSummarySalesAverageUsageRate = JSON.parse(
          data.pma_summary_weekly_sales_average_usage_rate
        );
        pmaSummaryAreaSalesAverageUsageRate = JSON.parse(
          data.pma_summary_weekly_do_sales_average_usage_rate
        );
        pmaSummarySalesAverageUsageRateAreaDiff = JSON.parse(
          data.pma_summary_weekly_sales_average_usage_rate_do_diff
        );
      } else if (flagWM === "PosPmaSummaryMonthly") {
        pmaSummarySalesAverage = JSON.parse(
          data.pma_summary_monthly_sales_average
        );
        pmaSummaryAreaSalesAverage = JSON.parse(
          data.pma_summary_monthly_do_sales_average
        );
        pmaSummarySalesAverageAreaDiff = JSON.parse(
          data.pma_summary_monthly_sales_average_do_diff
        );
        pmaSummarySalesAverageYoy = JSON.parse(
          data.pma_summary_monthly_sales_average_yoy
        );
        pmaSummaryAreaSalesAverageYoy = JSON.parse(
          data.pma_summary_monthly_do_sales_average_yoy
        );
        pmaSummarySalesAverageYoyAreaDiff = JSON.parse(
          data.pma_summary_monthly_sales_average_yoy_do_diff
        );
        pmaSummarySalesAverageYearlyDiff = JSON.parse(
          data.pma_summary_monthly_sales_average_yearly_diff
        );
        pmaSummaryAreaSalesAverageYearlyDiff = JSON.parse(
          data.pma_summary_monthly_do_sales_average_yearly_diff
        );
        pmaSummarySalesAverageYearlyDiffAreaDiff = JSON.parse(
          data.pma_summary_monthly_sales_average_yearly_diff_do_diff
        );
        pmaSummarySalesAverageClassRate = JSON.parse(
          data.pma_summary_monthly_sales_average_class_rate
        );
        pmaSummaryAreaSalesAverageClassRate = JSON.parse(
          data.pma_summary_monthly_do_sales_average_class_rate
        );
        pmaSummarySalesAverageClassRateAreaDiff = JSON.parse(
          data.pma_summary_monthly_sales_average_class_rate_do_diff
        );
        pmaSummarySalesAverageUsageRate = JSON.parse(
          data.pma_summary_monthly_sales_average_usage_rate
        );
        pmaSummaryAreaSalesAverageUsageRate = JSON.parse(
          data.pma_summary_monthly_do_sales_average_usage_rate
        );
        pmaSummarySalesAverageUsageRateAreaDiff = JSON.parse(
          data.pma_summary_monthly_sales_average_usage_rate_do_diff
        );
      }
    } else if (flagArea === "location") {
      if (flagWM === "PosPmaSummaryWeekly") {
        pmaSummarySalesAverage = JSON.parse(
          data.pma_summary_weekly_sales_average
        );
        pmaSummaryAreaSalesAverage = JSON.parse(
          data.pma_summary_weekly_location_sales_average
        );
        pmaSummarySalesAverageAreaDiff = JSON.parse(
          data.pma_summary_weekly_sales_average_location_diff
        );
        pmaSummarySalesAverageYoy = JSON.parse(
          data.pma_summary_weekly_sales_average_yoy
        );
        pmaSummaryAreaSalesAverageYoy = JSON.parse(
          data.pma_summary_weekly_location_sales_average_yoy
        );
        pmaSummarySalesAverageYoyAreaDiff = JSON.parse(
          data.pma_summary_weekly_sales_average_yoy_location_diff
        );
        pmaSummarySalesAverageYearlyDiff = JSON.parse(
          data.pma_summary_weekly_sales_average_yearly_diff
        );
        pmaSummaryAreaSalesAverageYearlyDiff = JSON.parse(
          data.pma_summary_weekly_location_sales_average_yearly_diff
        );
        pmaSummarySalesAverageYearlyDiffAreaDiff = JSON.parse(
          data.pma_summary_weekly_sales_average_yearly_diff_location_diff
        );
        pmaSummarySalesAverageClassRate = JSON.parse(
          data.pma_summary_weekly_sales_average_class_rate
        );
        pmaSummaryAreaSalesAverageClassRate = JSON.parse(
          data.pma_summary_weekly_location_sales_average_class_rate
        );
        pmaSummarySalesAverageClassRateAreaDiff = JSON.parse(
          data.pma_summary_weekly_sales_average_class_rate_location_diff
        );
        pmaSummarySalesAverageUsageRate = JSON.parse(
          data.pma_summary_weekly_sales_average_usage_rate
        );
        pmaSummaryAreaSalesAverageUsageRate = JSON.parse(
          data.pma_summary_weekly_location_sales_average_usage_rate
        );
        pmaSummarySalesAverageUsageRateAreaDiff = JSON.parse(
          data.pma_summary_weekly_sales_average_usage_rate_location_diff
        );
      } else if (flagWM === "PosPmaSummaryMonthly") {
        pmaSummarySalesAverage = JSON.parse(
          data.pma_summary_monthly_sales_average
        );
        pmaSummaryAreaSalesAverage = JSON.parse(
          data.pma_summary_monthly_location_sales_average
        );
        pmaSummarySalesAverageAreaDiff = JSON.parse(
          data.pma_summary_monthly_sales_average_location_diff
        );
        pmaSummarySalesAverageYoy = JSON.parse(
          data.pma_summary_monthly_sales_average_yoy
        );
        pmaSummaryAreaSalesAverageYoy = JSON.parse(
          data.pma_summary_monthly_location_sales_average_yoy
        );
        pmaSummarySalesAverageYoyAreaDiff = JSON.parse(
          data.pma_summary_monthly_sales_average_yoy_location_diff
        );
        pmaSummarySalesAverageYearlyDiff = JSON.parse(
          data.pma_summary_monthly_sales_average_yearly_diff
        );
        pmaSummaryAreaSalesAverageYearlyDiff = JSON.parse(
          data.pma_summary_monthly_location_sales_average_yearly_diff
        );
        pmaSummarySalesAverageYearlyDiffAreaDiff = JSON.parse(
          data.pma_summary_monthly_sales_average_yearly_diff_location_diff
        );
        pmaSummarySalesAverageClassRate = JSON.parse(
          data.pma_summary_monthly_sales_average_class_rate
        );
        pmaSummaryAreaSalesAverageClassRate = JSON.parse(
          data.pma_summary_monthly_location_sales_average_class_rate
        );
        pmaSummarySalesAverageClassRateAreaDiff = JSON.parse(
          data.pma_summary_monthly_sales_average_class_rate_location_diff
        );
        pmaSummarySalesAverageUsageRate = JSON.parse(
          data.pma_summary_monthly_sales_average_usage_rate
        );
        pmaSummaryAreaSalesAverageUsageRate = JSON.parse(
          data.pma_summary_monthly_location_sales_average_usage_rate
        );
        pmaSummarySalesAverageUsageRateAreaDiff = JSON.parse(
          data.pma_summary_monthly_sales_average_usage_rate_location_diff
        );
      }
    }
    summaryData = Object.keys(pmaSummaryAreaSalesAverage).map((key) => ({
      summary_cd: key,
      name: pmaSummaryAreaSalesAverage[key].name,
      sales_average: pmaSummarySalesAverage[key].value,
      area_sales_average: pmaSummaryAreaSalesAverage[key].value,
      sales_average_area_diff: pmaSummarySalesAverageAreaDiff[key].value,
      sales_average_yoy: pmaSummarySalesAverageYoy[key].value,
      area_sales_average_yoy: pmaSummaryAreaSalesAverageYoy[key].value,
      sales_average_yoy_area_diff: pmaSummarySalesAverageYoyAreaDiff[key].value,
      sales_average_yearly_diff: pmaSummarySalesAverageYearlyDiff[key].value,
      area_sales_average_yearly_diff:
        pmaSummaryAreaSalesAverageYearlyDiff[key].value,
      sales_average_yearly_diff_area_diff:
        pmaSummarySalesAverageYearlyDiffAreaDiff[key].value,
      sales_average_class_rate: pmaSummarySalesAverageClassRate[key].value,
      area_sales_average_class_rate:
        pmaSummaryAreaSalesAverageClassRate[key].value,
      sales_average_class_rate_area_diff:
        pmaSummarySalesAverageClassRateAreaDiff[key].value,
      sales_average_usage_rate: pmaSummarySalesAverageUsageRate[key].value,
      area_sales_average_usage_rate:
        pmaSummaryAreaSalesAverageUsageRate[key].value,
      sales_average_usage_rate_area_diff:
        pmaSummarySalesAverageUsageRateAreaDiff[key].value,
    }));
    // サマリのヘッダーのみ順番変更
    fixedData = sortOrder(
      summaryData.filter((item: any) => fixedKeys.includes(item.summary_cd))
    );
    filteredData = summaryData.filter(
      (item: any) => !fixedKeys.includes(item.summary_cd)
    );

    calcMaxValue(
      calcMaxObjValue(pmaSummarySalesAverageYearlyDiff),
      calcMaxObjValue(pmaSummaryAreaSalesAverageYearlyDiff),
      calcMaxObjValue(pmaSummarySalesAverageYearlyDiffAreaDiff)
    );
  };

  // 各オブジェクトの最大値を取得
  const calcMaxObjValue = (obj: any) => {
    let maxValue = 0;
    const keys = Object.keys(obj);
    for (let key of keys) {
      if (Math.abs(obj[key].value) > maxValue) {
        maxValue = Math.abs(obj[key].value);
      }
    }
    return maxValue;
  };

  // グラフを表示する3列の最大値を
  const calcMaxValue = (num1: any, num2: any, num3: any) => {
    setMaxValue(Math.max(num1, num2, num3));
  };

  const getDiffValue = (value: number) => {
    if (value === null || value === undefined) {
      return "-";
    }

    const roundValue = Math.round(value * 10) / 10;
    return getStringDiffValue(roundValue);
  };

  const getRationValue = (value: number) => {
    if (value === null || value === undefined) {
      return "-";
    }

    const roundValue = Math.round(value * 10) / 10;
    return getColoredRationValue(roundValue);
  };

  // ヘッダーを特定の順に並び替える
  const sortOrder = (array: any) => {
    const order = ["01", "02", "15", "03", "04"];
    const sortedArray = array.sort((a: any, b: any) => {
      return order.indexOf(a.summary_cd) - order.indexOf(b.summary_cd);
    });
    return sortedArray;
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const dispMsg = () => {
    setTimeout(true);
  };

  const changeWeekMonth = (valueWM: string) => {
    setFlagWM(valueWM);
  };

  const changeArea = (valueArea: string) => {
    setFlagArea(valueArea);
  };
  // ソートボタン出力
  const sortIcon = (property: string): JSX.Element => {
    if (orderBy === property) {
      return order === "asc" ? ascSort() : descSort();
    }
    return nonSort();
  };

  const ascSort = (): JSX.Element => {
    return (
      <IconButton css={roundIcon}>
        <ArrowDropUpIcon style={{ color: "#000000", marginBottom: "-7px" }} />
        <ArrowDropDownIcon color="disabled" style={{ marginTop: "-7px" }} />
      </IconButton>
    );
  };

  const descSort = (): JSX.Element => {
    return (
      <IconButton css={roundIcon}>
        <ArrowDropUpIcon color="disabled" style={{ marginBottom: "-7px" }} />
        <ArrowDropDownIcon style={{ color: "#000000", marginTop: "-7px" }} />
      </IconButton>
    );
  };

  const nonSort = (): JSX.Element => {
    return (
      <IconButton css={roundIcon}>
        <ArrowDropUpIcon color="disabled" style={{ marginBottom: "-7px" }} />
        <ArrowDropDownIcon color="disabled" style={{ marginTop: "-7px" }} />
      </IconButton>
    );
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleTermChange = (event: SelectChangeEvent) => {
    if (flagWM === "PosPmaSummaryWeekly") {
      setWeekDateValue(event.target.value as string);
    } else if (flagWM === "PosPmaSummaryMonthly") {
      setMonthDateValue(event.target.value as string);
    }
  };
  const CreateGraphCell: React.FC<{
    value: number;
    rightColFlag: boolean;
    headerFlag: boolean;
  }> = ({ value, rightColFlag, headerFlag }) => {
    const isPositive = value >= 0;

    return (
      <>
        <TableCell css={graphValueCell}>{getDiffValue(value / 1000)}</TableCell>
        {isPositive ? (
          <>
            <GraphCell headerFlag={headerFlag}></GraphCell>
            <GraphCell
              headerFlag={headerFlag}
              css={rightThickBorder}
              style={{ borderRight: rightColFlag ? 0 : undefined }}
            >
              <GraphContainer
                isPositive={isPositive}
                value={value}
                maxValue={maxValue}
              ></GraphContainer>
            </GraphCell>
          </>
        ) : (
          <>
            <GraphCell headerFlag={headerFlag}>
              <GraphContainer
                isPositive={isPositive}
                value={value}
                maxValue={maxValue}
              ></GraphContainer>
            </GraphCell>
            <GraphCell
              headerFlag={headerFlag}
              css={rightThickBorder}
              style={{ borderRight: rightColFlag ? 0 : undefined }}
            ></GraphCell>
          </>
        )}
      </>
    );
  };
  // ソート用比較関数
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  // 2つのオブジェクトを比較して
  const getComparator = <Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): ((a: { [key in Key]: any }, b: { [key in Key]: any }) => any) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  // 選択した要素以外でもソートを行う
  const stableSort = (data: any, comparator: any) => {
    const stabilizedThis = data.map((pmaObj: any, index: any) => [
      pmaObj,
      index,
    ]);
    stabilizedThis.sort((a: any, b: any) => {
      // index番号の入替アルゴリズム
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  };

  // ソートボタン押下時にデータの順序を変更する
  const sortedRows = React.useMemo(
    () => stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, data]
  );

  // Header部分出力
  const EnhancedTableHead: React.FC<{
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    fixedRow: any[];
    isOpen: boolean;
  }> = ({ onRequestSort, fixedRow, isOpen }) => {
    const createSortHandler = (property: any) => (event: any) => {
      if (property === "summary_cd") {
        setOrder("asc");
        setOrderBy(property);
      } else {
        onRequestSort(event, property);
      }
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell
            key={"name"}
            rowSpan={2}
            css={[
              headerCell,
              rightThickBorder,
              overrideItemCell,
              overrideWidth,
            ]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              項目
              <span css={sortButton} onClick={createSortHandler("summary_cd")}>
                {orderBy === "summary_cd" ? ascSort() : nonSort()}
              </span>
            </div>
          </TableCell>
          <TableCell
            key={"sales_average_yearly_diff"}
            colSpan={3}
            rowSpan={2}
            css={[headerCell, rightThickBorder]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              自店前年差額(千円/日)
              <span
                css={sortButton}
                onClick={createSortHandler("sales_average_yearly_diff")}
              >
                {sortIcon("sales_average_yearly_diff")}
              </span>
            </div>
          </TableCell>
          <TableCell
            key={"area_sales_average_yearly_diff"}
            colSpan={3}
            rowSpan={2}
            css={[headerCell, rightThickBorder]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {flagArea === "do" ? "DO" : "同一立地"}前年差額(千円/日)
              <span
                css={sortButton}
                onClick={createSortHandler("area_sales_average_yearly_diff")}
              >
                {sortIcon("area_sales_average_yearly_diff")}
              </span>
            </div>
          </TableCell>
          <TableCell
            key={"sales_average_yearly_diff_area_diff"}
            colSpan={3}
            rowSpan={2}
            css={[headerCell]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {flagArea === "do" ? "DOとの" : "同一立地との"}差(千円/日)
              <span
                css={sortButton}
                onClick={createSortHandler(
                  "sales_average_yearly_diff_area_diff"
                )}
              >
                {sortIcon("sales_average_yearly_diff_area_diff")}
              </span>
            </div>
          </TableCell>
          {isOpen && (
            <>
              <TableCell
                colSpan={3}
                css={[accordionHeaderCell, leftThickBorder]}
              >
                販売金額(千円/日)
              </TableCell>
              <TableCell colSpan={3} css={accordionHeaderCell}>
                販売金額前年比(％)
              </TableCell>
              <TableCell colSpan={3} css={accordionHeaderCell}>
                販売金額前年差(千円/日)
              </TableCell>
              <TableCell colSpan={3} css={accordionHeaderCell}>
                販売金額構成比(％)
              </TableCell>
              <TableCell
                colSpan={3}
                css={accordionHeaderCell}
                style={{ borderRight: "none" }}
              >
                利用度
              </TableCell>
            </>
          )}
        </TableRow>
        <TableRow>
          {isOpen && (
            <>
              <TableCell css={[accordionMinHeaderCell, leftThickBorder]}>
                自店
              </TableCell>
              <TableCell css={accordionMinHeaderCell}>
                {flagArea === "do" ? "DO" : "同一立地"}
              </TableCell>
              <TableCell css={accordionMinHeaderCell}>差</TableCell>
              <TableCell css={accordionMinHeaderCell}>自店</TableCell>
              <TableCell css={accordionMinHeaderCell}>
                {flagArea === "do" ? "DO" : "同一立地"}
              </TableCell>
              <TableCell css={accordionMinHeaderCell}>差</TableCell>
              <TableCell css={accordionMinHeaderCell}>自店</TableCell>
              <TableCell css={accordionMinHeaderCell}>
                {flagArea === "do" ? "DO" : "同一立地"}
              </TableCell>
              <TableCell css={accordionMinHeaderCell}>差</TableCell>
              <TableCell css={accordionMinHeaderCell}>自店</TableCell>
              <TableCell css={accordionMinHeaderCell}>
                {flagArea === "do" ? "DO" : "同一立地"}
              </TableCell>
              <TableCell css={accordionMinHeaderCell}>差</TableCell>
              <TableCell css={accordionMinHeaderCell}>自店</TableCell>
              <TableCell css={accordionMinHeaderCell}>
                {flagArea === "do" ? "DO" : "同一立地"}
              </TableCell>
              <TableCell
                css={accordionMinHeaderCell}
                style={{ borderRight: "none" }}
              >
                差
              </TableCell>
            </>
          )}
        </TableRow>
        {/* 固定する項目 */}
        {fixedRow.map((row: any, index: number) => (
          <TableRow
            css={tableRow}
            sx={{
              th: {
                borderBottom:
                  row.summary_cd === "04"
                    ? "2px solid #c4c4c4"
                    : "1px dashed #c4c4c4",
                top: {
                  md: (60 + 43 * index) + "px",
                  lg: (60 + 38 * index) + "px",
                },
              },
            }}
          >
            <TableCell
              css={[itemNameCell, overrideTotalCell]}
              style={{
                background: row.summary_cd === "15" ? "#ffefe5" : undefined,
              }}
            >
              {row.name}
            </TableCell>
            <CreateGraphCell
              value={row.sales_average_yearly_diff}
              rightColFlag={false}
              headerFlag={true}
            />
            <CreateGraphCell
              value={row.area_sales_average_yearly_diff}
              rightColFlag={false}
              headerFlag={true}
            />
            <CreateGraphCell
              value={row.sales_average_yearly_diff_area_diff}
              rightColFlag={true}
              headerFlag={true}
            />
            {isOpen && (
              <>
                <TableCell
                  css={accordionHeaderValueCell}
                  style={{
                    borderLeft: "2px solid #c4c4c4",
                  }}
                >
                  {getIntegerValue(row.sales_average / 1000)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getIntegerValue(row.area_sales_average / 1000)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getDiffValue(row.sales_average_area_diff / 1000)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getRationValue(row.sales_average_yoy)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getRationValue(row.area_sales_average_yoy)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getDiffValue(row.sales_average_yoy_area_diff)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getDiffValue(row.sales_average_yearly_diff / 1000)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getDiffValue(row.area_sales_average_yearly_diff / 1000)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getDiffValue(row.sales_average_yearly_diff_area_diff / 1000)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getIntegerValue(row.sales_average_class_rate)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getIntegerValue(row.area_sales_average_class_rate)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getDiffValue(row.sales_average_class_rate_area_diff)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getIntegerValue(row.sales_average_usage_rate)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getIntegerValue(row.area_sales_average_usage_rate)}
                </TableCell>
                <TableCell
                  css={accordionHeaderValueCell}
                  style={{ borderRight: "none" }}
                >
                  {getDiffValue(row.sales_average_usage_rate_area_diff)}
                </TableCell>
              </>
            )}
          </TableRow>
        ))}
      </TableHead>
    );
  };

  return timeout ? (
    <TimeOutDisplay setTimeout={setTimeout} />
  ) : (
    <>
      <ThemeProvider theme={fontTheme()}>
        <Header
          windowName={windowName}
          tenpoCode={tenpoCode ? tenpoCode : ""}
          tenpoName={tenpoName ? tenpoName : ""}
        />
        <Grid item container>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={11}>
            <Card css={card} variant="outlined">
              <Modal open={errorAPI} onClose={modalClose}>
                <APIErrorModal />
              </Modal>
              <CardContent css={cardContent}>
                {loading && (
                  <div>
                    <CircularProgress css={circularProgress} size={120} />
                  </div>
                )}
                <div css={operationSpace}>
                  <div css={operationLeft}>
                    <ToggleButtonComponent
                      options={[
                        { value: "do", label: "DO" },
                        { value: "location", label: "ZO内同一立地" },
                      ]}
                      selectedValue={flagArea}
                      orderBy={"none"}
                      onChange={changeArea}
                      locationFlag={locationFlag}
                      loadFlag={loading}
                    />
                    <ToggleButtonComponent
                      options={[
                        { value: "PosPmaSummaryWeekly", label: "週別" },
                        { value: "PosPmaSummaryMonthly", label: "月別" },
                      ]}
                      selectedValue={flagWM}
                      orderBy={"none"}
                      onChange={changeWeekMonth}
                      loadFlag={loading}
                    />
                  </div>
                  <div css={operationRight}>
                    {flagWM === "PosPmaSummaryWeekly" ? (
                      <Select
                        labelId="select-label"
                        id="simple-select"
                        value={weekDateValue}
                        label="select week"
                        onChange={handleTermChange}
                        css={selectTerm}
                        disabled={loading}
                      >
                        {weekDateOptions.map((option: any) => (
                          <MenuItem
                            key={option}
                            value={option}
                            style={{ height: "20%" }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        labelId="select-label"
                        id="simple-select"
                        value={monthDateValue}
                        label="select week"
                        onChange={handleTermChange}
                        css={selectTerm}
                        disabled={loading}
                      >
                        {monthDateOptions.map((option: any) => (
                          <MenuItem
                            key={option}
                            value={option}
                            style={{ height: "20%" }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    <IconButton onClick={handleToggle}>
                      {isOpen ? (
                        <KeyboardDoubleArrowLeftIcon />
                      ) : (
                        <KeyboardDoubleArrowRightIcon />
                      )}
                    </IconButton>
                  </div>
                </div>
                <TableContainer component={Paper} css={tableContainer}>
                  <Table stickyHeader>
                    <EnhancedTableHead
                      onRequestSort={handleRequestSort}
                      fixedRow={fixedRow}
                      isOpen={isOpen}
                    />
                    <TableBody>
                      {sortedRows.map((row: any) => {
                        return (
                          <TableRow
                            key={row.name}
                            css={tableRow}
                            sx={{
                              "&:last-child td": {
                                borderBottom: "none",
                              },
                              td: {
                                borderBottom: "1px dashed #c4c4c4",
                              },
                            }}
                          >
                            <TableCell css={itemNameCell}>{row.name}</TableCell>
                            <CreateGraphCell
                              value={row.sales_average_yearly_diff}
                              rightColFlag={false}
                              headerFlag={false}
                            />
                            <CreateGraphCell
                              value={row.area_sales_average_yearly_diff}
                              rightColFlag={false}
                              headerFlag={false}
                            />
                            <CreateGraphCell
                              value={row.sales_average_yearly_diff_area_diff}
                              rightColFlag={true}
                              headerFlag={false}
                            />
                            {isOpen && (
                              <>
                                <TableCell
                                  css={accordionValueCell}
                                  style={{ borderLeft: "2px solid #c4c4c4" }}
                                >
                                  {getIntegerValue(row.sales_average / 1000)}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getIntegerValue(
                                    row.area_sales_average / 1000
                                  )}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getDiffValue(
                                    row.sales_average_area_diff / 1000
                                  )}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getRationValue(row.sales_average_yoy)}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getRationValue(row.area_sales_average_yoy)}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getDiffValue(
                                    row.sales_average_yoy_area_diff
                                  )}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getDiffValue(
                                    row.sales_average_yearly_diff / 1000
                                  )}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getDiffValue(
                                    row.area_sales_average_yearly_diff / 1000
                                  )}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getDiffValue(
                                    row.sales_average_yearly_diff_area_diff /
                                      1000
                                  )}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getIntegerValue(
                                    row.sales_average_class_rate
                                  )}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getIntegerValue(
                                    row.area_sales_average_class_rate
                                  )}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getDiffValue(
                                    row.sales_average_class_rate_area_diff
                                  )}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getIntegerValue(
                                    row.sales_average_usage_rate
                                  )}
                                </TableCell>
                                <TableCell css={accordionValueCell}>
                                  {getIntegerValue(
                                    row.area_sales_average_usage_rate
                                  )}
                                </TableCell>
                                <TableCell
                                  css={accordionValueCell}
                                  style={{ borderRight: "none" }}
                                >
                                  {getDiffValue(
                                    row.sales_average_usage_rate_area_diff
                                  )}
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={0.5}></Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default PosPmaSummary;
