import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  auth,
  customTokenSignIn,
  getFirebaseAuthToken,
} from "../src/firebase/firebase";
import axios from "axios";
import { getParam } from "./components/auth/Login";
import { postRequest } from "./apis/axiosAction";
import { CircularProgress } from "@mui/material";
import { setUserInfo } from "./data/userInfo";

interface pathState {
  mode: string;
  code: string;
  codeKey: string;
  infoKey1: string;
  tenpoName: string;
  infoKey2: string;
  pmaCode: string;
  infoKey3: string;
  pmaName: string;
}

const initial: string = "daily-management";

// URLのパス及びパラメータの調整を行う
const setUrlPath = (): pathState => {
  const mode: string =
    getParam("mode", window.location.href) !== ""
      ? getParam("mode", window.location.href)
      : initial;
  const codeKey = mode === "daily-store-multi" ? "docode" : "tenpocode";
  const infoKey1 = mode === "info-class" ? "tenponame" : "";
  const infoKey2 = mode === "info-class" ? "pmacode" : "";
  const infoKey3 = mode === "info-class" ? "pmaname" : "";
  const code: string = getParam(codeKey, window.location.href);
  const tenpoName: string = getParam(infoKey1, window.location.href);
  const pmaCode: string = getParam(infoKey2, window.location.href);
  const pmaName: string = getParam(infoKey3, window.location.href);
  const value: pathState = {
    mode,
    code,
    codeKey,
    infoKey1,
    tenpoName,
    infoKey2,
    pmaCode,
    infoKey3,
    pmaName,
  };
  return value;
};

const App: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [loginLoading, setLoginLoading] = useState(false);
  const ps: pathState = setUrlPath();

  useEffect(() => {
    const unSub = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // firebase認証済み
        // 初期処理
        if (process.env.REACT_APP_MODE !== "develop") {
          initialOperation(authUser);
        } else {
          // ローカル環境
          if (ps.code) {
            if (ps.pmaCode) {
              navigate(
                `${ps.mode}?${ps.codeKey}=${ps.code}&tenponame=${ps.tenpoName}&pmacode=${ps.pmaCode}&pmaname=${ps.pmaName}`
              );
            } else {
              navigate(ps.mode + "?" + ps.codeKey + "=" + ps.code);
            }
          } else {
            navigate(ps.mode + "?tenpocode=190029");
          }
        }
      } else {
        // firebase認証未
        if (
          process.env.REACT_APP_MODE === "develop" ||
          process.env.REACT_APP_MODE === "cloud-dev"
        ) {
          // ローカルまたは開発環境ではログイン画面へ遷移する
          navigate("/login");
        }
      }
    });
    return () => {
      unSub();
    };
  }, []);

  // 初期処理
  const initialOperation = async (authUser: any): Promise<void> => {
    setLoginLoading(true);
    // ユーザー情報APIの呼び出し（デジタルツールのfirestoreのデータ）
    postRequest(
      process.env.REACT_APP_COMMON_API!,
      {
        "api-name": "user-info",
      },
      false
    )
      .then((response: any) => {
        const json = JSON.parse(response.data.dataList[0]);
        setUserInfo(json);
        // URLのパラメータで遷移先を決める
        if (ps.pmaCode) {
          navigate(
            `${ps.mode}?${ps.codeKey}=${ps.code}&tenponame=${ps.tenpoName}&pmacode=${ps.pmaCode}&pmaname=${ps.pmaName}`
          );
        } else {
          navigate(ps.mode + "?" + ps.codeKey + "=" + ps.code);
        }
      })
      .catch((error: any) => {
        console.error("Error App initialOperation1: ", error);
        setLoginLoading(false);
      });
  };

  // Firebaseカスタム認証
  const callCustomFirebaseAuthentication = async () => {
    const url: string = process.env.REACT_APP_GAE_API_URL + "auth/token";
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + (await getFirebaseAuthToken(false)),
      },
    });
    // カスタム認証を行う
    customTokenSignIn(auth, response.data.dataList[0])
      .then(() => {
        console.log("custom");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // Cookie経由認証
  const callCookieAuthentication = async () => {
    const url: string = process.env.REACT_APP_GAE_API_URL + "auth/session-auth";
    return await axios.get(url);
  };

  return (
    <>
      {loginLoading ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress
            size={"40vh"}
            style={{
              color: "#ccc",
              top: "30vh",
              position: "absolute",
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
