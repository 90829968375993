import React, { useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Brush,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { Card, CardContent } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { graphColors, fontTheme } from "../common/utils";

const card = css`
  margin: 16px;
`;
const cardContent = css`
  height: 570px;
  &:last-child {
    padding: 0;
  }
`;
const customTooltip = css`
  padding: 5px;
  border: 1px solid lightgray;
  background-color: rgba(255, 255, 255, 0.9);
  pointer-events: all;
  border-radius: 5%;
`;
const customTooltipContent = css`
  list-style: none;
`;

const DailyStoreMultiGraph: React.FC<{
  checkA: boolean;
  checkB: boolean;
  checkC: boolean;
  checkD: boolean;
  filterTenpoChecked: any;
}> = ({ checkA, checkB, checkC, checkD, filterTenpoChecked }) => {
  // const [activeTooltip, setActiveTooltip] = useState(true);
  const cntCheckbox = () => {
    let num = 6;
    if (filterTenpoChecked.length <= 6) {
      num = filterTenpoChecked.length - 1;
    }
    return num;
  };

  // const handleActiveTooltip = () => {
  //   setActiveTooltip(true);
  // };

  const CustomTooltip = ({ active, payload, label }: any) => {
    // if (active && payload && payload.length && activeTooltip) {
    if (active && payload && payload.length) {
      return (
        <div css={customTooltip}>
          <li css={customTooltipContent}>{payload[0].payload.tenpo_name}</li>
          {payload.map((payload: any, index: any) => {
            return (
              <li
                css={customTooltipContent}
                style={{ color: payload.color }}
                key={index.toString()}
              >
                {payload.name + "：" + addSymbol(payload.value).toString() + "%"}
              </li>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const addSymbol = (value: number) => {
    let symbol = "";
    if (value > 0) {
      symbol = "+";
    } else if (value < 0) {
      symbol = "▲";
    }
    return `${symbol}${Math.abs(value).toFixed(1)}`;
  };

  return (
    <>
      <ThemeProvider theme={fontTheme()}>
        <Card css={card} variant="outlined">
          <CardContent css={cardContent}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                height={400}
                data={filterTenpoChecked}
                margin={{ top: 5, right: 30, left: 30, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="tenpo_code" dy={5} />
                <YAxis
                  tickCount={9}
                  label={{
                    value: "DO差(%)",
                    angle: -90,
                    dx: -30,
                  }}
                  domain={[-20, +20]}
                  tickFormatter={(value) => {
                    return addSymbol(value);
                  }}
                  ticks={[-20, -15, -10, -5, 0, 5, 10, 15, 20]}
                  //指定したdomainより大きい数値は見切れるように設定。trueは省略可
                  allowDataOverflow={true}
                />
                <Tooltip trigger="click" content={<CustomTooltip />} />
                <Legend verticalAlign="top" />
                <ReferenceLine y={0} stroke="#000" />
                {checkA && (
                  <Bar
                    type="monotone"
                    name="売上"
                    dataKey="daily_sales_performance_yoy_diff"
                    fill={graphColors.A}
                    strokeWidth={3}
                    // onClick={() => {
                    //   handleActiveTooltip();
                    // }}
                  />
                )}
                {checkB && (
                  <Bar
                    type="monotone"
                    name="客数"
                    dataKey="daily_customer_count_performance_yoy_diff"
                    fill={graphColors.B}
                    // onClick={() => {
                    //   handleActiveTooltip();
                    // }}
                  />
                )}
                {checkC && (
                  <Bar
                    type="monotone"
                    name="客単価"
                    dataKey="daily_unit_price_performance_yoy_diff"
                    fill={graphColors.C}
                    // onClick={() => {
                    //   handleActiveTooltip();
                    // }}
                  />
                )}
                {checkD && (
                  <Bar
                    type="monotone"
                    name="買上点数"
                    dataKey="daily_quantity_performance_yoy_diff"
                    fill={graphColors.D}
                    // onClick={() => {
                    //   handleActiveTooltip();
                    // }}
                  />
                )}
                <Brush
                  stroke="#448aff"
                  startIndex={0}
                  endIndex={cntCheckbox()}
                  height={20}
                ></Brush>
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </ThemeProvider>
    </>
  );
};

export default DailyStoreMultiGraph;
