import React, { useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Box,
  AppBar,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  ClickAwayListener,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import HamburgerMenu from "./HamburgerMenu";
import InfoButton from "./InfoButton";
import getInfoText from "./Utils";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const appBar = css`
  height: 8vh;
  color: black;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const appBarElement = css`
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const opviewButton = css`
  // background-color: #d9d9d9;
  height: 40px;
  width: 40px;
  padding: 10px;
`;
const headerText = css`
  color: black;
  font-size: 30px;
  font-weight: bold;
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 350,
  },
});

const nameChange = (name: string) => {
  let winName: string = name;
  if (!name.includes("サブ")) {
    winName = " | " + name;
  }
  return winName;
};
const Header: React.FC<{
  windowName: string;
  tenpoCode: string;
  tenpoName: string;
  showOpview?: boolean;
}> = ({ windowName, tenpoCode, tenpoName, showOpview = true }) => {
  const [TooltipOpen, setTooltipOpen] = useState(false);
  const opviewUrl = process.env.REACT_APP_DIGITAL_TOOL;
  const opviewParam =
    "?application=ofc-counselling&mode=opviewgraph&tenpocode=" + tenpoCode;

  const toolTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            textAlign: "left",
            width: "350px",
          },
        },
      },
    },
  });

  const navigateOpview = () => {
    window.open(opviewUrl + opviewParam, "_blank");
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Box>
      <AppBar position="fixed" css={appBar}>
        <Grid container>
          <Grid xs={1} css={appBarElement}>
            {!windowName.includes("サブ") && (
              <HamburgerMenu tenpoCode={tenpoCode} />
            )}
          </Grid>
          <Grid item xs={10} css={appBarElement}>
            <Typography variant="h5" component="p" css={headerText}>
            {windowName.includes("サブ") ? (
              windowName
            ) : (tenpoName + " | "  + windowName)}
            </Typography>
            <Typography
              variant="h5"
              component="p"
              css={headerText}
            ></Typography>
          </Grid>
          <Grid xs={1} css={appBarElement}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div style={{ textAlign: "right" }}>
                <ThemeProvider theme={toolTheme}>
                  {!windowName.includes("個店") && (
                    <CustomWidthTooltip
                      PopperProps={{
                        disablePortal: true,
                        style: { maxWidth: "350px" },
                      }}
                      onClose={handleTooltipClose}
                      open={TooltipOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={getInfoText(windowName)}
                    >
                      <InfoButton windowName={windowName} />
                    </CustomWidthTooltip>
                  )}
                </ThemeProvider>
              </div>
            </ClickAwayListener>
            {showOpview ? (
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>{"OPVIEWを参照する"}</span>
                }
                placement="left"
                disableTouchListener
              >
                <IconButton onClick={navigateOpview} css={opviewButton}>
                  <BarChartIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </AppBar>
    </Box>
  );
};

export default Header;
