import React, { useState, useEffect, useRef } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Checkbox,
  CircularProgress,
  Modal,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import DailyStoreSummaryGraph from "./DailyStoreSummaryGraph";
import Header from "../../common/Header";
import TimeOutDisplay from "../../common/TimeOutDisplay";
import {
  timer,
  getHolidayStyle,
  getDayOfWeek,
  addComma,
  addDecimalPoint,
  arrangeData,
  fontTheme,
} from "../../common/utils";
import {
  getStringDiffValue,
  getColoredRationValue,
  formatPastData,
} from "../../common/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../../data/userInfo";
import WeatherInfo from "../../common/WeatherInfo";
import { apiRequest } from "../../../apis/util";
import APIErrorModal from "../../common/APIErrorModal";
import UpdateTime from "../../common/UpdateTime";
import { userAuthCheck } from "../../auth/authUser";

const card = css`
  background-color: #f7f9f8;
  @media screen and (min-width: 1194px) {
    height: 570px;
    margin: 90px 16px 16px 16px;
  }
  @media screen and (min-width: 1366px) {
    height: 565px;
    margin: 85px 16px 16px 16px;
  }
`;
const commonCell = css`
  padding: 8px;
  box-sizing: border-box;
`;
const dashedBL = css`
  border-left: 1px dashed #ccc;
`;
const headerCell = css`
  ${commonCell}
  font-size: 12px;
  font-weight: bold;
  text-align: center;
`;
const dashedBorderHeaderCell = css`
  ${headerCell}
  border-left: 1px dashed #ccc;
`;
const numberCell = css`
  ${commonCell}
  height: 35px;
  font-size: 12px;
  text-align: right;
`;
const dashedBorderNumberCell = css`
  ${numberCell}
  border-left: 1px dashed #ccc;
`;
const textCell = css`
  ${commonCell}
  font-size: 10px;
  font-weight: bold;
  text-align: left;
`;
const centerCell = css`
  ${commonCell}
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;
const checkBoxCell = css`
  max-width: 30px;
  padding: 4px;
  text-align: center;
  vertical-align: middle;
`;
const weatherIconCell = css`
  padding: 4px;
`;
const dashedBorderWeatherCell = css`
  ${weatherIconCell}
  border-left: 1px dashed #ccc;
`;
const boldBottomBorder = css`
  border-bottom: 2px solid #ccc;
`;
const hightLightGrRow = css`
  background-color: #e0ffee;
`;
const button = css`
  font-size: 10px;
  border-radius: 20px;
  background-color: #00cc5b;
  &:hover {
    background-color: rgba(0, 204, 91, 0.8);
    color: #fff;
  }
`;
const backLayer = css`
  position: relative;
  width: 100%;
  height: 100%;
`;
const circularProgress = css`
  position: absolute;
  top: 35%;
  left: 45%;
  opacity: 0.8;
  color: #ccc;
`;
const deviceWidth = css`
  height: 20px;
  @media screen and (min-width: 1194px) {
    width: 70px;
    maxwidth: 70px;
  }
  @media screen and (min-width: 1366px) {
    width: 80px;
    maxwidth: 80px;
  }
`;

const DailyStoreSummaryTable: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [today, setToday] = useState<any>("");
  const [check1, setCheck1] = useState(true);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);
  const [tenpoCode, setTenpoCode] = useState<string | null>("");
  const [tenpoName, setTenpoName] = useState<string>("");
  const [timeout, setTimeout] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorAPI, setErrorAPI] = useState(false);
  const search = useLocation().search;
  const usp = new URLSearchParams(search);
  const windowName = "個店経営状況（個別）サマリ";
  const zennenhiText = "前年比(%)";
  const doZennenhisaText = "DO差(%)";
  const leftTableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tc: string | null = usp.get("tenpocode");
    const userInfo = getUserInfo();
    if (process.env.REACT_APP_MODE !== "develop") {
      // 担当店と職種で画面表示を制御
      if (tc !== null && userAuthCheck(userInfo, tc)) {
        setTenpoCode(tc);
      } else {
        navigate("/tenpo-error");
      }
    } else {
      // ローカルでは制御しない
      if (tc !== null) {
        setTenpoCode(tc);
      }
    }
    window.setTimeout(dispMsg, timer);
  }, []);

  useEffect(() => {
    let res: any = {};
    let arrangedData: any = [];
    let resData: any = [];
    let todayDate = new Date();
    let formattedDate =
      ("0" + (todayDate.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + todayDate.getDate()).slice(-2);

      if (tenpoCode) {
      const params = {
        mode: "dailyStoreSingle",
        tenpoCode: tenpoCode,
      };
      (async () => {
        try {
          res = await apiRequest(params);
          resData = res.data.data.dailyStoreSingleSearchByParams;
          if (resData.length) {
            arrangedData = arrangeData(resData);
            if (arrangedData.length < 8) {
              arrangedData = formatPastData(arrangedData);
            }
          } else {
            setData([]);
            throw new Error("API response error");
          }
        } catch (e) {
          setErrorAPI(true);
        }
        setData(arrangedData);
        if (arrangedData.tenpo_name !== null) {
          setTenpoName(arrangedData[6]?.tenpo_name);
        }
        if (arrangedData.date !== null) {
          setToday(formattedDate);
        }
        setLoading(false);
      })();
    }
  }, [tenpoCode]);

  useEffect(() => {
    if (!timeout) {
      window.setTimeout(dispMsg, timer);
    }
  }, [timeout]);

  const dispMsg = () => {
    setTimeout(true);
  };

  const modalClose = () => {
    setErrorAPI(false);
  };

  return timeout ? (
    <TimeOutDisplay setTimeout={setTimeout} />
  ) : (
    <>
      <ThemeProvider theme={fontTheme()}>
        {loading && (
          <>
            <Header
              windowName={windowName}
              tenpoCode={tenpoCode ? tenpoCode : ""}
              tenpoName={tenpoName ? tenpoName : ""}
            />
            <CircularProgress css={circularProgress} size={120} />
          </>
        )}
        <div
          css={backLayer}
          style={
            loading
              ? { backgroundColor: "#fff", opacity: "0", pointerEvents: "none" }
              : {}
          }
        >
          <Header
            windowName={windowName}
            tenpoCode={tenpoCode ? tenpoCode : ""}
            tenpoName={tenpoName ? tenpoName : ""}
          />
          <Grid container>
            <Grid xs={1}></Grid>
            <Grid xs={10}>
              <UpdateTime unixtime={data[7]?.realtime_update_unixtime} />
              <Card css={card} variant="outlined">
                <Modal
                  open={errorAPI}
                  onClose={modalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <APIErrorModal />
                </Modal>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item sm={10}>
                      <TableContainer component={Paper} ref={leftTableRef}>
                        <Table>
                          <TableHead
                            style={{
                              width: `max-content`,
                            }}
                          >
                            <TableRow>
                              <TableCell css={centerCell} colSpan={3}>
                                日付
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell
                                  css={[headerCell, dashedBL, deviceWidth]}
                                  style={{
                                    color: getHolidayStyle(data),
                                  }}
                                >
                                  {data.date === today
                                    ? "当日"
                                    : (data?.date || "") +
                                      getDayOfWeek(data?.day_of_week)}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow css={boldBottomBorder}>
                              <TableCell colSpan={3} css={centerCell}>
                                天候・気温
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[weatherIconCell, dashedBL]}>
                                  <WeatherInfo
                                    weatherCode={data?.weather_code}
                                    maxTemp={data?.max_temperature}
                                    minTemp={data?.min_temperature}
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow css={hightLightGrRow}>
                              <TableCell rowSpan={3} css={checkBoxCell}>
                                <Checkbox
                                  indeterminate={false}
                                  checked={check1}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setCheck1(event.target.checked);
                                  }}
                                  style={{
                                    color: "#00cc5b",
                                  }}
                                />
                              </TableCell>
                              <TableCell css={centerCell} rowSpan={3}>
                                <p>売上</p>
                                <Button
                                  variant="contained"
                                  css={button}
                                  onClick={() => {
                                    window.open(
                                      `/?mode=daily-store-sales&tenpocode=${tenpoCode}`,
                                      "_blank"
                                    );
                                  }}
                                >
                                  詳細
                                </Button>
                              </TableCell>
                              <TableCell css={[textCell, dashedBL]}>
                                実績(千円)
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[numberCell, dashedBL]}>
                                  {addComma(data?.daily_sales_performance)}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow css={hightLightGrRow}>
                              <TableCell css={[textCell, dashedBL]}>
                                {zennenhiText}
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[numberCell, dashedBL]}>
                                  {getColoredRationValue(
                                    data?.daily_sales_performance_yoy
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow css={[hightLightGrRow, boldBottomBorder]}>
                              <TableCell css={[textCell, dashedBL]}>
                                {doZennenhisaText}
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[numberCell, dashedBL]}>
                                  {getStringDiffValue(
                                    data?.daily_sales_performance_yoy_diff
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow>
                              <TableCell
                                padding="checkbox"
                                rowSpan={3}
                                css={checkBoxCell}
                              >
                                <Checkbox
                                  indeterminate={false}
                                  checked={check2}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setCheck2(event.target.checked);
                                  }}
                                  style={{
                                    color: "#00cc5b",
                                  }}
                                />
                              </TableCell>
                              <TableCell css={centerCell} rowSpan={3}>
                                <p>客数</p>
                                <Button
                                  variant="contained"
                                  css={button}
                                  onClick={() => {
                                    window.open(
                                      `/?mode=daily-store-customer-count&tenpocode=${tenpoCode}`,
                                      "_blank"
                                    );
                                  }}
                                >
                                  詳細
                                </Button>
                              </TableCell>
                              <TableCell css={[textCell, dashedBL]}>
                                実績(人)
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[numberCell, dashedBL]}>
                                  {addComma(
                                    data?.daily_customer_count_performance
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow>
                              <TableCell css={[textCell, dashedBL]}>
                                {zennenhiText}
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[numberCell, dashedBL]}>
                                  {getColoredRationValue(
                                    data?.daily_customer_count_performance_yoy
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow css={boldBottomBorder}>
                              <TableCell css={[textCell, dashedBL]}>
                                {doZennenhisaText}
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[numberCell, dashedBL]}>
                                  {getStringDiffValue(
                                    data?.daily_customer_count_performance_yoy_diff
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow>
                              <TableCell
                                padding="checkbox"
                                rowSpan={3}
                                css={checkBoxCell}
                              >
                                <Checkbox
                                  indeterminate={false}
                                  checked={check3}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setCheck3(event.target.checked);
                                  }}
                                  style={{
                                    color: "#00cc5b",
                                  }}
                                />
                              </TableCell>
                              <TableCell css={centerCell} rowSpan={3}>
                                <p>客単価</p>
                                <Button
                                  variant="contained"
                                  css={button}
                                  onClick={() => {
                                    window.open(
                                      `/?mode=daily-store-unit-price&tenpocode=${tenpoCode}`,
                                      "_blank"
                                    );
                                  }}
                                >
                                  詳細
                                </Button>
                              </TableCell>
                              <TableCell css={[textCell, dashedBL]}>
                                実績(円)
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[numberCell, dashedBL]}>
                                  {addComma(data?.daily_unit_price_performance)}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow>
                              <TableCell css={[textCell, dashedBL]}>
                                {zennenhiText}
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[numberCell, dashedBL]}>
                                  {getColoredRationValue(
                                    data?.daily_unit_price_performance_yoy
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow css={boldBottomBorder}>
                              <TableCell css={[textCell, dashedBL]}>
                                {doZennenhisaText}
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[numberCell, dashedBL]}>
                                  {getStringDiffValue(
                                    data?.daily_unit_price_performance_yoy_diff
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow>
                            <TableCell
                                padding="checkbox"
                                rowSpan={3}
                                css={checkBoxCell}
                              >
                                <Checkbox
                                  indeterminate={false}
                                  checked={check4}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setCheck4(event.target.checked);
                                  }}
                                  style={{
                                    color: "#00cc5b",
                                  }}
                                />
                              </TableCell>
                              <TableCell css={centerCell} rowSpan={3}>
                                <p>買上点数</p>
                                <Button
                                  variant="contained"
                                  css={button}
                                  onClick={() => {
                                    window.open(
                                      `/?mode=daily-store-quantity&tenpocode=${tenpoCode}`,
                                      "_blank"
                                    );
                                  }}
                                >
                                  詳細
                                </Button>
                              </TableCell>
                              <TableCell css={[textCell, dashedBL]}>
                                実績(点)
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[numberCell, dashedBL]}>
                                  {/* 買上点数は小数点2位で固定 */}
                                  {addDecimalPoint(
                                  data?.daily_quantity_performance,
                                  2
                                )}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow>
                              <TableCell css={[textCell, dashedBL]}>
                                {zennenhiText}
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[numberCell, dashedBL]}>
                                  {getColoredRationValue(
                                  data?.daily_quantity_performance_yoy
                                )}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow>
                              <TableCell css={[textCell, dashedBL]}>
                                {doZennenhisaText}
                              </TableCell>
                              {data.map((data: any) => (
                                <TableCell css={[numberCell, dashedBL]}>
                                  {getStringDiffValue(
                                  data?.daily_quantity_performance_yoy_diff
                                )}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    <Grid item sm={2}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell css={headerCell}>平日</TableCell>
                              <TableCell css={dashedBorderHeaderCell}>
                                土日祝日
                              </TableCell>
                            </TableRow>
                            <TableRow css={boldBottomBorder}>
                              <TableCell css={weatherIconCell}>
                                <WeatherInfo
                                  isBlank={true}
                                  weatherCode={""}
                                  maxTemp={""}
                                  minTemp={""}
                                />
                              </TableCell>
                              <TableCell css={dashedBorderWeatherCell}>
                                <WeatherInfo
                                  isBlank={true}
                                  weatherCode={""}
                                  maxTemp={""}
                                  minTemp={""}
                                />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow css={hightLightGrRow}>
                              <TableCell css={[numberCell, deviceWidth]}>
                                {data[7]?.weekday_sales_performance ? (
                                  addComma(data[7]?.weekday_sales_performance)
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                              <TableCell css={[dashedBorderNumberCell]}>
                                {data[7]?.holiday_sales_performance ? (
                                  addComma(data[7]?.holiday_sales_performance)
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow css={hightLightGrRow}>
                              <TableCell css={[numberCell]}>
                                {data[7]?.weekday_sales_performance_yoy ? (
                                  getColoredRationValue(
                                    data[7]?.weekday_sales_performance_yoy
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                              <TableCell css={[dashedBorderNumberCell]}>
                                {data[7]?.holiday_sales_performance_yoy ? (
                                  getColoredRationValue(
                                    data[7]?.holiday_sales_performance_yoy
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow css={[hightLightGrRow, boldBottomBorder]}>
                              <TableCell css={[numberCell]}>
                                {data[7]?.weekday_sales_performance_yoy_diff ? (
                                  getStringDiffValue(
                                    data[7]?.weekday_sales_performance_yoy_diff
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                              <TableCell css={[dashedBorderNumberCell]}>
                                {data[7]?.holiday_sales_performance_yoy_diff ? (
                                  getStringDiffValue(
                                    data[7]?.holiday_sales_performance_yoy_diff
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell css={[numberCell]}>
                                {data[7]?.weekday_customer_count_performance ? (
                                  addComma(
                                    data[7]?.weekday_customer_count_performance
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                              <TableCell css={[dashedBorderNumberCell]}>
                                {data[7]?.holiday_customer_count_performance ? (
                                  addComma(
                                    data[7]?.holiday_customer_count_performance
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell css={[numberCell]}>
                                {data[7]
                                  ?.weekday_customer_count_performance_yoy ? (
                                  getColoredRationValue(
                                    data[7]
                                      ?.weekday_customer_count_performance_yoy
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                              <TableCell css={[dashedBorderNumberCell]}>
                                {data[7]
                                  ?.holiday_customer_count_performance_yoy ? (
                                  getColoredRationValue(
                                    data[7]
                                      ?.holiday_customer_count_performance_yoy
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow css={boldBottomBorder}>
                              <TableCell css={[numberCell]}>
                                {data[7]
                                  ?.weekday_customer_count_performance_yoy_diff ? (
                                  getStringDiffValue(
                                    data[7]
                                      ?.weekday_customer_count_performance_yoy_diff
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                              <TableCell css={[dashedBorderNumberCell]}>
                                {data[7]
                                  ?.holiday_customer_count_performance_yoy_diff ? (
                                  getStringDiffValue(
                                    data[7]
                                      ?.holiday_customer_count_performance_yoy_diff
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell css={[numberCell]}>
                                {data[7]?.weekday_unit_price_performance ? (
                                  addComma(
                                    data[7]?.weekday_unit_price_performance
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                              <TableCell css={[dashedBorderNumberCell]}>
                                {data[7]?.holiday_unit_price_performance ? (
                                  addComma(
                                    data[7]?.holiday_unit_price_performance
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell css={[numberCell]}>
                                {data[7]?.weekday_unit_price_performance_yoy ? (
                                  getColoredRationValue(
                                    data[7]?.weekday_unit_price_performance_yoy
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                              <TableCell css={[dashedBorderNumberCell]}>
                                {data[7]?.holiday_unit_price_performance_yoy ? (
                                  getColoredRationValue(
                                    data[7]?.holiday_unit_price_performance_yoy
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow css={boldBottomBorder}>
                              <TableCell css={[numberCell]}>
                                {data[7]
                                  ?.weekday_unit_price_performance_yoy_diff ? (
                                  getStringDiffValue(
                                    data[7]
                                      ?.weekday_unit_price_performance_yoy_diff
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                              <TableCell css={[dashedBorderNumberCell]}>
                                {data[7]
                                  ?.holiday_unit_price_performance_yoy_diff ? (
                                  getStringDiffValue(
                                    data[7]
                                      ?.holiday_unit_price_performance_yoy_diff
                                  )
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell css={[numberCell]}>
                                {data[7]?.weekday_quantity_performance
                                ? (addDecimalPoint(
                                    data[7]?.weekday_quantity_performance,
                                    2
                                    )
                                    ) : (
                                      <span>&nbsp;</span>
                                    )}
                              </TableCell>
                              <TableCell css={[dashedBorderNumberCell]}>
                                {data[7]?.holiday_quantity_performance
                                ? (addDecimalPoint(
                                    data[7]?.holiday_quantity_performance,
                                    2
                                    )
                                    ) : (
                                      <span>&nbsp;</span>
                                    )}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell css={[numberCell]}>
                                {data[7]?.weekday_quantity_performance_yoy ? getColoredRationValue(
                                data[7]?.weekday_quantity_performance_yoy
                              ):<span>&nbsp;</span>}
                              </TableCell>
                              <TableCell css={[dashedBorderNumberCell]}>
                                {data[7]?.holiday_quantity_performance_yoy ? getColoredRationValue(
                                data[7]?.holiday_quantity_performance_yoy
                              ):<span>&nbsp;</span>}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell css={[numberCell]}>
                                {data[7]?.weekday_quantity_performance_yoy_diff ? getStringDiffValue(
                                data[7]?.weekday_quantity_performance_yoy_diff
                              ):<span>&nbsp;</span>}
                              </TableCell>
                              <TableCell css={[dashedBorderNumberCell]}>
                                {data[7]?.holiday_quantity_performance_yoy_diff ? getStringDiffValue(
                                data[7]?.holiday_quantity_performance_yoy_diff
                              ):<span>&nbsp;</span>}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={1}></Grid>
            <Grid xs={10}>
              <DailyStoreSummaryGraph
                check1={check1}
                check2={check2}
                check3={check3}
                check4={check4}
                data={data}
              />
            </Grid>
            <Grid xs={1}></Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </>
  );
};

export default DailyStoreSummaryTable;
