import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Checkbox,
  CircularProgress,
  Modal,
} from "@mui/material";
import DailyStoreMultiGraph from "./DailyStoreMultiGraph";
import { ThemeProvider } from "@mui/material/styles";
import Header from "../common/Header";
import TimeOutDisplay from "../common/TimeOutDisplay";
import WeatherInfo from "../common/WeatherInfo";
import { timer, addComma, fontTheme, addDecimalPoint } from "../common/utils";
import { apiRequest } from "../../apis/util";
import { getStringDiffValue, getColoredRationValue } from "../common/Utils";
import { getUserInfo } from "../../data/userInfo";
import APIErrorModal from "../common/APIErrorModal";
import { userAuthCheck } from "../auth/authUser";

const card = css`
  background-color: #f7f9f8;
  @media screen and (min-width: 1194px) {
    height: 570px;
    margin: 90px 16px 16px 16px;
  }
  @media screen and (min-width: 1366px) {
    height: 565px;
    margin: 85px 16px 16px 16px;
  }
`;
const tableContainer = css`
  overflow-x: scroll;
  table-layout: fixed;
`;
const table = css`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
`;
const commonCell = css`
  margin: 0;
  padding: 0;
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
`;
const commonRow = css`
  ${commonCell}
  height: 30px;
  background-color: #fff;
`;
const hightLightGrRow = css`
  ${commonCell}
  height: 30px;
  background-color: #e0ffee;
`;
const secondFixedCell = css`
  ${commonCell}
  position: sticky;
  text-align: center;
  left: 70px;
  background-color: #fff;
  font-weight: bold;
  z-index: 5;
`;
const grSecondFixedCell = css`
  ${commonCell}
  position: sticky;
  text-align: center;
  left: 70px;
  background-color: #e0ffee;
  font-weight: bold;
  z-index: 5;
`;
const thirdFixedCell = css`
  ${commonCell}
  position: sticky;
  text-align: center;
  left: 140px;
  width: 70px;
  font-weight: bold;
  background-color: #fff;
  z-index: 5;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-right: 1px dashed #ccc;
    border-left: 1px dashed #ccc;
    box-sizing: border-box;
  }
`;
const grThirdFixedCell = css`
  ${commonCell}
  position: sticky;
  text-align: center;
  left: 140px;
  width: 70px;
  font-weight: bold;
  background-color: #e0ffee;
  z-index: 5;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-right: 1px dashed #ccc;
    border-left: 1px dashed #ccc;
    box-sizing: border-box;
  }
`;
const headerCell = css`
  ${commonCell}
  position: sticky;
  left: 0;
  width: 210px;
  font-weight: bold;
  background-color: #fff;
  text-align: center;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-right: 1px dashed #ccc;
    box-sizing: border-box;
  }
`;
const headerCheckBoxCell = css`
  padding: 0 8px;
  table-layout: fixed;
  box-sizing: border-box;
  width: 210px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-right: 1px dashed #ccc;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  &:last-child {
    border-right: none;
  }
`;
const numberCell = css`
  margin: 0;
  padding: 0 8px;
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  box-sizing: border-box;
  font-size: 12px;
  text-align: right;
  border-right: 1px dashed #ccc;
  background-color: #fff;
  &:last-child {
    border-right: none;
  }
`;
const grNumberCell = css`
  margin: 0;
  padding: 0 8px;
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  box-sizing: border-box;
  font-size: 12px;
  text-align: right;
  border-right: 1px dashed #ccc;
  background-color: #e0ffee;
  &:last-child {
    border-right: none;
  }
`;
const checkBoxCell = css`
  ${commonCell}
  position: sticky;
  left: 0;
  height: 90px;
  width: 70px;
  background-color: #fff;
  text-align: center;
  vertical-align: middle;
`;
const grCheckBoxCell = css`
  ${checkBoxCell}
  background-color: #e0ffee;
`;
const weatherIconCell = css`
  padding: 4px;
  border-right: 1px dashed #ccc;
  &:last-child {
    border-right: none;
  }
`;
const boldBottomBorder = css`
  border-bottom: 2px solid #ccc;
`;
const backLayer = css`
  position: relative;
  width: 100%;
  height: 100%;
`;
const circularProgress = css`
  position: absolute;
  top: 35%;
  left: 45%;
  opacity: 0.8;
  color: #ccc;
`;
const comment = css`
  font-weight: bold;
  border: 2px solid;
  background-color: #ffffff;
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
const deviceWidth = css`
  height: 20px;
  @media screen and (min-width: 1194px) {
    width: 70px;
    maxwidth: 70px;
  }
  @media screen and (min-width: 1366px) {
    width: 80px;
    maxwidth: 80px;
  }
`;

const DailyStoreMultiTable: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [filterTenpoList, setFilterTenpoList] = useState<string[]>([]);
  const [tenpoCode, setTenpoCode] = useState<string[]>([]);
  const [checkA, setCheckA] = useState(true);
  const [checkB, setCheckB] = useState(false);
  const [checkC, setCheckC] = useState(false);
  const [checkD, setCheckD] = useState(false);
  const [tenpoChecked, setTenpoChecked] = useState<string[]>([]);
  const [timeout, setTimeout] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef<HTMLDivElement>(null);
  const search = useLocation().search;
  const usp = new URLSearchParams(search);
  const zennenhiText = "前年比(%)";
  const doZennenhisaText = "DO差(%)";
  const date = new Date();
  date.setDate(date.getDate() - 1);
  const yesterday =
    date.getFullYear() +
    "/" +
    ("00" + (date.getMonth() + 1).toLocaleString()).slice(-2) +
    "/" +
    ("00" + date.getDate()).slice(-2);
  const windowName = "個店経営状況（サブ） " + yesterday;

  useEffect(() => {
    const dc: string | null = usp.get("docode");
    const userInfo = getUserInfo();
    console.log("userInfo:");
    console.log(userInfo);
    if (process.env.REACT_APP_MODE !== "develop") {
      // 担当DOと職種で画面表示を制御
      if (dc && userAuthCheck(userInfo, "", "dailyMulti")) {
        if (userInfo.job_category_code_OA === "210") {
          setTenpoCode(userInfo.tenpo_code_special_list);
        } else {
          setTenpoCode(userInfo.tenpo_code);
        }
      } else {
        navigate("/tenpo-error");
      }
    } else {
      // ローカルでは制御しない
      if (dc !== null) {
        console.log("userInfo2:");
        console.log(userInfo.tenpo_code);
        setTenpoCode(userInfo.tenpo_code);
      }
    }
    window.setTimeout(dispMsg, timer);
  }, []);

  useEffect(() => {
    if (!timeout) {
      window.setTimeout(dispMsg, timer);
    }
  }, [timeout]);

  useEffect(() => {
    let res: any = {};
    let resData: any = [];

    const strTenpoCode = '["' + tenpoCode.join('","') + '"]';
    const params = {
      mode: "dailyStoreMulti",
      tenpoCode: strTenpoCode,
    };
    if (tenpoCode.length) {
      (async () => {
        try {
          setLoading(true);
          res = await apiRequest(params);
          resData = res.data.data.dailyStoreMultiSearchByParams;
          if (!resData) {
            throw new Error("API response error");
          }
          if (resData.length < 7) {
            for (let i = resData.length; i < 7; i++) {
              resData.push({});
            }
          }
          setData(resData);
          setFilterTenpoList(resData);
          setTenpoChecked(resData);
        } catch (e) {
          setErrorAPI(true);
          setData([]);
          setFilterTenpoList([]);
          setTenpoChecked([]);
        }
        setLoading(false);
      })();
    }
  }, [tenpoCode]);

  useEffect(() => {
    const updatedFilterTenpoList: any = data.filter((tenpo: any) =>
      tenpoChecked.find(
        (checkedTenpo: any) => checkedTenpo.tenpo_code === tenpo.tenpo_code
      )
    );
    setFilterTenpoList(updatedFilterTenpoList);
  }, [tenpoChecked]);

  const changeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetIndex = Number(e.target.value);
    const isChecked = e.target.checked;

    // dataとcheckboxのindex番号で紐づけて新しい配列を作成する
    if (isChecked) {
      setTenpoChecked([...tenpoChecked, data[targetIndex]]);
    } else {
      const newTenpoChecked = tenpoChecked.filter(
        (tenpo: any) => tenpo.tenpo_code !== data[targetIndex].tenpo_code
      );
      setTenpoChecked(newTenpoChecked);
    }
  };

  const dispMsg = () => {
    setTimeout(true);
  };

  // 7店舗以下の場合checkboxを作成しない
  const createCheckBoxCell = (data: any, index: number) => {
    if (Object.keys(data).length) {
      return (
        <Checkbox
          defaultChecked={true}
          value={index}
          onChange={changeChecked}
          style={{
            color: "#00cc5b",
          }}
        />
      );
    }
  };

  // 店名は9文字目で改行する
  const getFormattedName = (tenpoName: string, tenpoCode: string) => {
    let formerStr = "";
    let latterStr = "";
    if (tenpoName) {
      formerStr = tenpoName.substring(0, 9);
      latterStr = tenpoName.substring(9);
    }
    if (formerStr) {
      return (
        <>
          <a
            href={`/?mode=daily-store-summary&tenpocode=${tenpoCode}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: "10px" }}
          >
            {formerStr}
            <br />
            {latterStr}
          </a>
        </>
      );
    }
  };

  const modalClose = () => setErrorAPI(false);

  return timeout ? (
    <TimeOutDisplay setTimeout={setTimeout} />
  ) : (
    <>
      <ThemeProvider theme={fontTheme()}>
        {loading && (
          <>
            <Header
              windowName={windowName}
              tenpoCode={""}
              tenpoName={""}
              showOpview={false}
            />
            <CircularProgress css={circularProgress} size={120} />
          </>
        )}
        <div
          css={backLayer}
          style={
            loading
              ? { backgroundColor: "#fff", opacity: "0", pointerEvents: "none" }
              : {}
          }
        >
          <Header
            windowName={windowName}
            tenpoCode={""}
            tenpoName={""}
            showOpview={false}
          />
          <Grid container>
            <Grid xs={1}></Grid>
            <Grid xs={10}>
              <Card css={card} variant="outlined">
                <Modal
                  open={errorAPI}
                  onClose={modalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <APIErrorModal />
                </Modal>
                <CardContent>
                  <TableContainer
                    component={Paper}
                    css={tableContainer}
                    ref={tableRef}
                  >
                    <Table css={table}>
                      <TableHead>
                        <TableRow css={boldBottomBorder}>
                          <TableCell colSpan={3} css={headerCell}>
                            店名
                          </TableCell>
                          {data.map((data: any, index: number) => (
                            <TableCell
                              css={{ headerCheckBoxCell, deviceWidth }}
                            >
                              {Object.keys(data).length ? (
                                <div style={{ textAlign: "center", top: 0 }}>
                                  <Checkbox
                                    defaultChecked={true}
                                    value={index}
                                    onChange={changeChecked}
                                    style={{
                                      color: "#00cc5b",
                                    }}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                              {getFormattedName(
                                data?.tenpo_name,
                                data?.tenpo_code
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow css={boldBottomBorder}>
                          <TableCell colSpan={3} css={headerCell}>
                            天候・気温
                          </TableCell>
                          {data.map((data: any) => (
                            <TableCell css={weatherIconCell}>
                              <WeatherInfo
                                weatherCode={data?.weather_code}
                                maxTemp={data?.max_temperature}
                                minTemp={data?.min_temperature}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow css={hightLightGrRow}>
                          <TableCell rowSpan={3} css={grCheckBoxCell}>
                            <Checkbox
                              defaultChecked={true}
                              checked={checkA}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setCheckA(event.target.checked);
                              }}
                              style={{
                                color: "#00cc5b",
                              }}
                            />
                          </TableCell>
                          <TableCell rowSpan={3} css={grSecondFixedCell}>
                            売上
                          </TableCell>
                          <TableCell css={grThirdFixedCell}>
                            実績(千円)
                          </TableCell>
                          {data.map((data: any) => (
                            <TableCell css={grNumberCell}>
                              {addComma(data?.daily_sales_performance)}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow css={hightLightGrRow}>
                          <TableCell css={grThirdFixedCell}>
                            {zennenhiText}
                          </TableCell>
                          {data.map((data: any) => (
                            <TableCell css={grNumberCell}>
                              {getColoredRationValue(
                                data?.daily_sales_performance_yoy
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow css={[hightLightGrRow, boldBottomBorder]}>
                          <TableCell css={grThirdFixedCell}>
                            {doZennenhisaText}
                          </TableCell>
                          {data.map((data: any) => (
                            <TableCell css={grNumberCell}>
                              {getStringDiffValue(
                                data?.daily_sales_performance_yoy_diff
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow css={commonRow}>
                          <TableCell
                            rowSpan={3}
                            style={{ padding: "checkbox" }}
                            css={checkBoxCell}
                          >
                            <Checkbox
                              indeterminate={false}
                              checked={checkB}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setCheckB(event.target.checked);
                              }}
                              style={{
                                color: "#00cc5b",
                              }}
                            />
                          </TableCell>
                          <TableCell rowSpan={3} css={secondFixedCell}>
                            客数
                          </TableCell>
                          <TableCell css={thirdFixedCell}>実績(人)</TableCell>
                          {data.map((data: any) => (
                            <TableCell css={numberCell}>
                              {addComma(data?.daily_customer_count_performance)}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow css={commonRow}>
                          <TableCell css={thirdFixedCell}>
                            {zennenhiText}
                          </TableCell>
                          {data.map((data: any) => (
                            <TableCell css={numberCell}>
                              {getColoredRationValue(
                                data?.daily_customer_count_performance_yoy
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow css={[commonRow, boldBottomBorder]}>
                          <TableCell css={thirdFixedCell}>
                            {doZennenhisaText}
                          </TableCell>
                          {data.map((data: any) => (
                            <TableCell css={numberCell}>
                              {getStringDiffValue(
                                data?.daily_customer_count_performance_yoy_diff
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow css={commonRow}>
                          <TableCell rowSpan={3} css={checkBoxCell}>
                            <Checkbox
                              indeterminate={false}
                              checked={checkC}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setCheckC(event.target.checked);
                              }}
                              style={{
                                color: "#00cc5b",
                              }}
                            />
                          </TableCell>
                          <TableCell rowSpan={3} css={secondFixedCell}>
                            客単価
                          </TableCell>
                          <TableCell css={thirdFixedCell}>実績(円)</TableCell>
                          {data.map((data: any) => (
                            <TableCell css={numberCell}>
                              {addComma(data?.daily_unit_price_performance)}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow css={commonRow}>
                          <TableCell css={thirdFixedCell}>
                            {zennenhiText}
                          </TableCell>
                          {data.map((data: any) => (
                            <TableCell css={numberCell}>
                              {getColoredRationValue(
                                data?.daily_unit_price_performance_yoy
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow css={[commonRow, boldBottomBorder]}>
                          <TableCell css={thirdFixedCell}>
                            {doZennenhisaText}
                          </TableCell>
                          {data.map((data: any) => (
                            <TableCell css={numberCell}>
                              {getStringDiffValue(
                                data?.daily_unit_price_performance_yoy_diff
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow css={commonRow}>
                          <TableCell rowSpan={3} css={checkBoxCell}>
                            <Checkbox
                              indeterminate={false}
                              checked={checkD}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setCheckD(event.target.checked);
                              }}
                              style={{
                                color: "#00cc5b",
                              }}
                            />
                          </TableCell>
                          <TableCell rowSpan={3} css={secondFixedCell}>
                            買上点数
                          </TableCell>
                          <TableCell css={thirdFixedCell}>実績(点)</TableCell>
                          {data.map((data: any) => (
                            <TableCell css={numberCell}>
                              {addDecimalPoint(
                                data?.daily_quantity_performance,
                                2
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow css={commonRow}>
                          <TableCell css={thirdFixedCell}>
                            {zennenhiText}
                          </TableCell>
                          {data.map((data: any) => (
                            <TableCell css={numberCell}>
                              {getColoredRationValue(
                                data?.daily_quantity_performance_yoy
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow css={commonRow}>
                          <TableCell css={thirdFixedCell}>
                            {doZennenhisaText}
                          </TableCell>
                          {data.map((data: any) => (
                            <TableCell css={numberCell}>
                              {getStringDiffValue(
                                data?.daily_quantity_performance_yoy_diff
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={1}></Grid>
            <Grid xs={10}>
              <DailyStoreMultiGraph
                checkA={checkA}
                checkB={checkB}
                checkC={checkC}
                checkD={checkD}
                filterTenpoChecked={filterTenpoList}
              />
            </Grid>
            <Grid xs={1}></Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </>
  );
};

export default DailyStoreMultiTable;
