import React, { useEffect, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const toggleButtonContainer = css`
  display: flex;
  align-items: right;
`;
const toggleGroup = css`
  max-height: 30px;
  padding: 4px;
  border-radius: 20px;
  background-color: rgba(215, 220, 217, 0.4);
`;
const toggle = css`
  font-family: "Noto Sans Jp", "Roboto";
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  padding: 0 16px;
  align-items: center;
  height: 30px;
  border: none;
  border-radius: 20px;
  margin-left: 0;
  &:hover:not(:disabled) {
    background-color: rgba(215, 220, 217, 0.6);
  }
`;
interface ToggleButtonComponentProps {
  options: { value: string; label: string }[];
  selectedValue: string;
  onChange: (newValue: string) => void;
  orderBy: string;
  locationFlag?: boolean;
  loadFlag: boolean;
}

const ToggleButtonComponent: React.FC<ToggleButtonComponentProps> = ({
  options,
  selectedValue,
  orderBy,
  onChange,
  locationFlag = true,
  loadFlag
}) => {
  const [value, setValue] = useState<string>(options[0].value);

  useEffect(() => {
    if (value === null) {
      setValue(options[1].value);
    }
  }, [value, options]);

  const handleValueChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div css={toggleButtonContainer}>
      <div css={toggleGroup}>
        {options.map((option: any) => (
          <button
            key={option.value}
            value={option.value}
            disabled={
              selectedValue === option.value ||
              (option.value === "NarrowDown" && orderBy === "pma_cd") ||
              !locationFlag ||
              loadFlag
            }
            css={css`
              ${toggle}
              color: ${selectedValue === option.value
                ? "#00ac4c"
                : "rgba(102, 102, 102, 0.6)"};
              background-color: ${selectedValue === option.value
                ? "#fff"
                : "transparent"};
            `}
            onClick={(e) => handleValueChange(e, option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ToggleButtonComponent;
