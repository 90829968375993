import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Modal,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ThemeProvider } from "@mui/material/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../common/Header";
import { getWeekOptions, getMonthOptions, timer, fontTheme,} from "../common/utils";
import {
  getStringDiffValue,
  getColoredRationValue,
  getIntegerValue,
} from "../common/Utils";
import { apiRequest } from "../../apis/util";
import { getUserInfo } from "../../data/userInfo";
import TimeOutDisplay from "../common/TimeOutDisplay";
import APIErrorModal from "../common/APIErrorModal";
import ToggleButtonComponent from "../common/ToggleButtonComponent";
import { userAuthCheck } from "../auth/authUser";

const card = css`
  background-color: #f7f9f8;
  margin-top: 75px;
  width: 100%;
  @media screen and (min-width: 1194px) {
    height: 630px;
  }
  @media screen and (min-width: 1280px) {
    height: 520px;
  }
  @media screen and (min-width: 1366px) {
    height: 560px;
  }
`;
const cardContent = css`
  padding: 4px 16px 16px 16px;
`;
const tableContainer = css`
  border-spacing: 0;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1194px) {
    max-height: 552px;
  }
  @media screen and (min-width: 1280px) {
    max-height: 456px;
  }
  @media screen and (min-width: 1366px) {
    max-height: 500px;
  }
`;
const cssLoading = css`
  width: 100%;
  height: 100%;
  z-index: 10;
`;
const circularProgress = css`
  position: absolute;
  top: 45%;
  left: 45%;
  color: #ccc;
  z-index: 10;
`;
const operationSpace = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const operationLeft = css`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
`;
const operationRight = css`
  margin-left: auto;
`;
const selectTerm = css`
  width: 200px;
  height: 35px;
  margin: 8px;
  padding: 0;
  text-align: left;
  background-color: #ffffff;
`;
const headerCell = css`
  font-weight: 700;
  font-size: 16px;
  background-color: #e0ffee;
  border-bottom: 2px solid #c4c4c4;
  box-sizing: border-box;
  padding: 2px;
  z-index: 3;
  min-width: 250px;
  max-height: 60px;
  height: 60px;
  white-space: nowrap;
  text-align: center;
  align-items: center;
  -webkit-appearance: none;
`;
const accordionHeaderCell = css`
  font-size: 16px;
  font-weight: 700;
  min-width: 270px;
  border-right: 2px solid #c4c4c4;
  background-color: #e0ffee;
  border-bottom: 2px solid #c4c4c4;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  padding: 2px;
`;
const accordionMinHeaderCell = css`
  ${accordionHeaderCell}
  top: 30px;
  max-width: 90px;
  min-width: 90px;
`;
const accordionValueCell = css`
  padding: 4px;
  text-align: right;
  box-sizing: border-box;
  border-right: 2px solid #c4c4c4;
  border-bottom: 1px dashed #c4c4c4;
  font-size: 14px;
  font-weight: 400;
  background-color: #fff;
  min-width: 90px;
  max-width: 90px;
`;
const accordionHeaderValueCell = css`
  ${accordionValueCell}
  top: 60px;
  border-bottom: 2px solid #c4c4c4;
`;
const overrideTotalCell = css`
  top: 60px;
  z-index: 4;
`;
const overrideItemCell = css`
  position: sticky;
  left: 0;
  z-index: 5;
`;
const rightThickBorder = css`
  border-right: 2px solid #c4c4c4;
`;
const leftThickBorder = css`
  border-left: 2px solid #c4c4c4;
`;
const bottomThickBorder = css`
  border-bottom: 2px solid #c4c4c4;
`;
const tableRow = css`
  @media screen and (min-width: 1194px) {
    max-height: 43px;
    height: 43px;
  }
  @media screen and (min-width: 1366px) {
    max-height: 38px;
    height: 38px;
  }
`;
const itemNameCell = css`
  font-size: 16px;
  position: sticky;
  padding: 4px;
  left: 0;
  z-index: 2;
  border-collapse: collapse;
  background-color: #fff;
  border-right: 2px solid #c4c4c4;
  box-sizing: border-box;
  white-space: nowrap;
  -webkit-appearance: none;
`;
const blankGraphValueCell = css`
  width: 50px;
  top: 60px;
  text-align: right;
  padding: 4px;
  border-right: 1px dashed #c4c4c4;
  -webkit-appearance: none;
`;
const grayBgColor = css`
  top: 60px;
  background-color: #e6e6e6;
`;
const GraphContainer = styled.div<{
  isPositive: boolean;
  value: number;
  maxValue: number;
}>`
  position: absolute;
  width: ${({ value, maxValue }) =>
    `calc(${Math.abs(value / maxValue) * 99}%)`};
  height: 70%;
  top: 15%;
  left: ${({ isPositive }) => (isPositive ? 0 : "auto")};
  right: ${({ isPositive }) => (isPositive ? "auto" : 0)};
  background-color: ${({ isPositive }) => (isPositive ? "#EDB200" : "#58C8EB")};
`;
const graphValueCell = css`
  width: 50px;
  border-right: 1px dashed #c4c4c4;
  text-align: right;
  padding: 4px;
  -webkit-appearance: none;
`;
const graphCell = css`
  position: relative;
  z-index: 1;
  background-color: transparent;
  -webkit-appearance: none;
`;
const sortButton = css`
  position: absolute;
  padding: 0;
  right: 0;
`;
const roundIcon = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 50px;
  width: 50px;
  &:hover {
    border-radius: 50%;
  }
`;

type Order = "asc" | "desc";

const InfoClass: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>(
    "sales_average_yearly_diff_area_diff"
  );
  const [isOpen, setIsOpen] = useState(false);
  const [weekDateValue, setWeekDateValue] = useState("");
  const [monthDateValue, setMonthDateValue] = useState("");
  const [flagWM, setFlagWM] = useState("InfoClassWeekly");
  const [flagArea, setFlagArea] = useState("do");
  const [locationFlag, setLocationFlag] = useState(true);
  const [weekDateOptions, setWeekDateOptions] = useState<string[]>([]);
  const [monthDateOptions, setMonthDateOptions] = useState<string[]>([]);
  const [tenpoCode, setTenpoCode] = useState<string | null>("");
  const [tenpoName, setTenpoName] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [timeout, setTimeout] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);
  const modalClose = () => setErrorAPI(false);
  const windowName = "情報分類";
  const [maxValue, setMaxValue] = useState(0);
  const [fixedRow, setFixedRow] = useState<any>({});
  const [resData, setResData] = useState<any>([]);
  const [pmaCode, setPmaCode] = useState<string>("01");
  const [pmaName, setInfoName] = useState<string>("");

  let infoClassData: any = [];
  let infoClassSalesAverage: any = {};
  let infoClassAreaSalesAverage: any = {};
  let infoClassSalesAverageAreaDiff: any = {};
  let infoClassSalesAverageYoy: any = {};
  let infoClassAreaSalesAverageYoy: any = {};
  let infoClassSalesAverageYoyAreaDiff: any = {};
  let infoClassSalesAverageYearlyDiff: any = {};
  let infoClassAreaSalesAverageYearlyDiff: any = {};
  let infoClassSalesAverageYearlyDiffAreaDiff: any = {};
  let infoClassSalesAverageClassRate: any = {};
  let infoClassAreaSalesAverageClassRate: any = {};
  let infoClassSalesAverageClassRateAreaDiff: any = {};
  let infoClassSalesAverageUsageRate: any = {};
  let infoClassAreaSalesAverageUsageRate: any = {};
  let infoClassSalesAverageUsageRateAreaDiff: any = {};

  const search = useLocation().search;
  const usp = new URLSearchParams(search);

  useEffect(() => {
    let optionsW: any[] = getWeekOptions();
    let optionsM: any[] = getMonthOptions();
    setWeekDateOptions(optionsW);
    setMonthDateOptions(optionsM);
    // 週・月の初期値を設定
    setWeekDateValue(optionsW[0]);
    setMonthDateValue(optionsM[0]);
    const tc: string | null = usp.get("tenpocode");
    const tenpoName: string | null = usp.get("tenponame");
    const pmaCode: string | null = usp.get("pmacode");
    const pmaName: string | null = usp.get("pmaname");
    if (pmaCode !== null && pmaName !== null && tenpoName !== null) {
      setPmaCode(pmaCode);
      setInfoName(pmaName);
      setTenpoName(tenpoName);
    } else {
      navigate("/tenpo-error");
    }
    const userInfo = getUserInfo();
    if (process.env.REACT_APP_MODE !== "develop") {
      // 担当店と職種で画面表示を制御
      if (tc !== null && userAuthCheck(userInfo, tc)) {
        setTenpoCode(tc);
      } else {
        navigate("/tenpo-error");
      }
    } else {
      // ローカルでは制御しない
      if (tc !== null) {
        setTenpoCode(tc);
      }
    }
    window.setTimeout(dispMsg, timer);
  }, []);

  useEffect(() => {
    if (!timeout) {
      window.setTimeout(dispMsg, timer);
    }
  }, [timeout]);

  useEffect(() => {
    let res: any = {};
    let date: string = "";
    let newDate: string = "";
    if (tenpoCode) {
      if (flagWM === "InfoClassWeekly") {
        if (!weekDateValue) {
          return;
        }
        date = weekDateValue.slice(0, -1);
      } else if (flagWM === "InfoClassMonthly") {
        date = monthDateValue.concat("/01");
      }
      newDate = date.replace(/\//g, "-");
      const params = {
        mode: flagWM,
        tenpoCode: tenpoCode,
        date: newDate,
      };
      (async () => {
        try {
          setLoading(true);
          res = await apiRequest(params);
          let tempResData;
          if (flagWM === "InfoClassWeekly") {
            tempResData = res.data.data.InfoClassWeeklySearchByParams[0];
          } else if (flagWM === "InfoClassMonthly") {
            tempResData = res.data.data.InfoClassMonthlySearchByParams[0];
          }
          setResData(tempResData);
          if (tempResData?.location_flag === 0) {
            setLocationFlag(false);
          }
          if (tempResData.tenpo_name !== null) {
            setTenpoName(tempResData.tenpo_name);
          }
          if (tempResData === undefined || tempResData.length === 0) {
            throw new Error("API response error");
          }
          formatData(tempResData);
          setData(infoClassData);
          const tempFixedRow = infoClassData.find(
            (row: any) => row.name === "情報分類合計"
          );
          if (tempFixedRow) {
            setFixedRow(tempFixedRow);
          }
        } catch (e) {
          setData([]);
          setFixedRow({});
          setErrorAPI(true);
        }
        setLoading(false);
      })();
    }
  }, [weekDateValue, monthDateValue, flagWM]);

  useEffect(() => {
    if (resData.length === 0) {
      return;
    }
    formatData(resData);
    setFixedRow(infoClassData.find((row: any) => row.name === "情報分類合計"));
    setData(infoClassData);
  }, [flagArea]);

  const formatData = (data: any) => {
    if (flagArea === "do") {
      if (flagWM === "InfoClassWeekly") {
        infoClassSalesAverage = JSON.parse(
          data.info_class_weekly_sales_average
        )[pmaCode];
        infoClassAreaSalesAverage = JSON.parse(
          data.info_class_weekly_do_sales_average
        )[pmaCode];
        infoClassSalesAverageAreaDiff = JSON.parse(
          data.info_class_weekly_sales_average_do_diff
        )[pmaCode];
        infoClassSalesAverageYoy = JSON.parse(
          data.info_class_weekly_sales_average_yoy
        )[pmaCode];
        infoClassAreaSalesAverageYoy = JSON.parse(
          data.info_class_weekly_do_sales_average_yoy
        )[pmaCode];
        infoClassSalesAverageYoyAreaDiff = JSON.parse(
          data.info_class_weekly_sales_average_yoy_do_diff
        )[pmaCode];
        infoClassSalesAverageYearlyDiff = JSON.parse(
          data.info_class_weekly_sales_average_yearly_diff
        )[pmaCode];
        infoClassAreaSalesAverageYearlyDiff = JSON.parse(
          data.info_class_weekly_do_sales_average_yearly_diff
        )[pmaCode];
        infoClassSalesAverageYearlyDiffAreaDiff = JSON.parse(
          data.info_class_weekly_sales_average_yearly_diff_do_diff
        )[pmaCode];
        infoClassSalesAverageClassRate = JSON.parse(
          data.info_class_weekly_sales_average_class_rate
        )[pmaCode];
        infoClassAreaSalesAverageClassRate = JSON.parse(
          data.info_class_weekly_do_sales_average_class_rate
        )[pmaCode];
        infoClassSalesAverageClassRateAreaDiff = JSON.parse(
          data.info_class_weekly_sales_average_class_rate_do_diff
        )[pmaCode];
        infoClassSalesAverageUsageRate = JSON.parse(
          data.info_class_weekly_sales_average_usage_rate
        )[pmaCode];
        infoClassAreaSalesAverageUsageRate = JSON.parse(
          data.info_class_weekly_do_sales_average_usage_rate
        )[pmaCode];
        infoClassSalesAverageUsageRateAreaDiff = JSON.parse(
          data.info_class_weekly_sales_average_usage_rate_do_diff
        )[pmaCode];
      } else {
        infoClassSalesAverage = JSON.parse(
          data.info_class_monthly_sales_average
        )[pmaCode];
        infoClassAreaSalesAverage = JSON.parse(
          data.info_class_monthly_do_sales_average
        )[pmaCode];
        infoClassSalesAverageAreaDiff = JSON.parse(
          data.info_class_monthly_sales_average_do_diff
        )[pmaCode];
        infoClassSalesAverageYoy = JSON.parse(
          data.info_class_monthly_sales_average_yoy
        )[pmaCode];
        infoClassAreaSalesAverageYoy = JSON.parse(
          data.info_class_monthly_do_sales_average_yoy
        )[pmaCode];
        infoClassSalesAverageYoyAreaDiff = JSON.parse(
          data.info_class_monthly_sales_average_yoy_do_diff
        )[pmaCode];
        infoClassSalesAverageYearlyDiff = JSON.parse(
          data.info_class_monthly_sales_average_yearly_diff
        )[pmaCode];
        infoClassAreaSalesAverageYearlyDiff = JSON.parse(
          data.info_class_monthly_do_sales_average_yearly_diff
        )[pmaCode];
        infoClassSalesAverageYearlyDiffAreaDiff = JSON.parse(
          data.info_class_monthly_sales_average_yearly_diff_do_diff
        )[pmaCode];
        infoClassSalesAverageClassRate = JSON.parse(
          data.info_class_monthly_sales_average_class_rate
        )[pmaCode];
        infoClassAreaSalesAverageClassRate = JSON.parse(
          data.info_class_monthly_do_sales_average_class_rate
        )[pmaCode];
        infoClassSalesAverageClassRateAreaDiff = JSON.parse(
          data.info_class_monthly_sales_average_class_rate_do_diff
        )[pmaCode];
        infoClassSalesAverageUsageRate = JSON.parse(
          data.info_class_monthly_sales_average_usage_rate
        )[pmaCode];
        infoClassAreaSalesAverageUsageRate = JSON.parse(
          data.info_class_monthly_do_sales_average_usage_rate
        )[pmaCode];
        infoClassSalesAverageUsageRateAreaDiff = JSON.parse(
          data.info_class_monthly_sales_average_usage_rate_do_diff
        )[pmaCode];
      }
    } else if (flagArea === "location") {
      if (flagWM === "InfoClassWeekly") {
        infoClassSalesAverage = JSON.parse(
          data.info_class_weekly_sales_average
        )[pmaCode];
        infoClassAreaSalesAverage = JSON.parse(
          data.info_class_weekly_location_sales_average
        )[pmaCode];
        infoClassSalesAverageAreaDiff = JSON.parse(
          data.info_class_weekly_sales_average_location_diff
        )[pmaCode];
        infoClassSalesAverageYoy = JSON.parse(
          data.info_class_weekly_sales_average_yoy
        )[pmaCode];
        infoClassAreaSalesAverageYoy = JSON.parse(
          data.info_class_weekly_location_sales_average_yoy
        )[pmaCode];
        infoClassSalesAverageYoyAreaDiff = JSON.parse(
          data.info_class_weekly_sales_average_yoy_location_diff
        )[pmaCode];
        infoClassSalesAverageYearlyDiff = JSON.parse(
          data.info_class_weekly_sales_average_yearly_diff
        )[pmaCode];
        infoClassAreaSalesAverageYearlyDiff = JSON.parse(
          data.info_class_weekly_location_sales_average_yearly_diff
        )[pmaCode];
        infoClassSalesAverageYearlyDiffAreaDiff = JSON.parse(
          data.info_class_weekly_sales_average_yearly_diff_location_diff
        )[pmaCode];
        infoClassSalesAverageClassRate = JSON.parse(
          data.info_class_weekly_sales_average_class_rate
        )[pmaCode];
        infoClassAreaSalesAverageClassRate = JSON.parse(
          data.info_class_weekly_location_sales_average_class_rate
        )[pmaCode];
        infoClassSalesAverageClassRateAreaDiff = JSON.parse(
          data.info_class_weekly_sales_average_class_rate_location_diff
        )[pmaCode];
        infoClassSalesAverageUsageRate = JSON.parse(
          data.info_class_weekly_sales_average_usage_rate
        )[pmaCode];
        infoClassAreaSalesAverageUsageRate = JSON.parse(
          data.info_class_weekly_location_sales_average_usage_rate
        )[pmaCode];
        infoClassSalesAverageUsageRateAreaDiff = JSON.parse(
          data.info_class_weekly_sales_average_usage_rate_location_diff
        )[pmaCode];
      } else {
        infoClassSalesAverage = JSON.parse(
          data.info_class_monthly_sales_average
        )[pmaCode];
        infoClassAreaSalesAverage = JSON.parse(
          data.info_class_monthly_location_sales_average
        )[pmaCode];
        infoClassSalesAverageAreaDiff = JSON.parse(
          data.info_class_monthly_sales_average_location_diff
        )[pmaCode];
        infoClassSalesAverageYoy = JSON.parse(
          data.info_class_monthly_sales_average_yoy
        )[pmaCode];
        infoClassAreaSalesAverageYoy = JSON.parse(
          data.info_class_monthly_location_sales_average_yoy
        )[pmaCode];
        infoClassSalesAverageYoyAreaDiff = JSON.parse(
          data.info_class_monthly_sales_average_yoy_location_diff
        )[pmaCode];
        infoClassSalesAverageYearlyDiff = JSON.parse(
          data.info_class_monthly_sales_average_yearly_diff
        )[pmaCode];
        infoClassAreaSalesAverageYearlyDiff = JSON.parse(
          data.info_class_monthly_location_sales_average_yearly_diff
        )[pmaCode];
        infoClassSalesAverageYearlyDiffAreaDiff = JSON.parse(
          data.info_class_monthly_sales_average_yearly_diff_location_diff
        )[pmaCode];
        infoClassSalesAverageClassRate = JSON.parse(
          data.info_class_monthly_sales_average_class_rate
        )[pmaCode];
        infoClassAreaSalesAverageClassRate = JSON.parse(
          data.info_class_monthly_location_sales_average_class_rate
        )[pmaCode];
        infoClassSalesAverageClassRateAreaDiff = JSON.parse(
          data.info_class_monthly_sales_average_class_rate_location_diff
        )[pmaCode];
        infoClassSalesAverageUsageRate = JSON.parse(
          data.info_class_monthly_sales_average_usage_rate
        )[pmaCode];
        infoClassAreaSalesAverageUsageRate = JSON.parse(
          data.info_class_monthly_location_sales_average_usage_rate
        )[pmaCode];
        infoClassSalesAverageUsageRateAreaDiff = JSON.parse(
          data.info_class_monthly_sales_average_usage_rate_location_diff
        )[pmaCode];
      }
    }
    infoClassData = Object.keys(infoClassSalesAverage).map((key) => ({
      name: infoClassSalesAverage[key].name,
      sales_average: infoClassSalesAverage[key].value,
      area_sales_average: infoClassAreaSalesAverage[key].value,
      sales_average_area_diff: infoClassSalesAverageAreaDiff[key].value,
      sales_average_yoy: infoClassSalesAverageYoy[key].value,
      area_sales_average_yoy: infoClassAreaSalesAverageYoy[key].value,
      sales_average_yoy_area_diff: infoClassSalesAverageYoyAreaDiff[key].value,
      sales_average_yearly_diff: infoClassSalesAverageYearlyDiff[key].value,
      area_sales_average_yearly_diff:
        infoClassAreaSalesAverageYearlyDiff[key].value,
      sales_average_yearly_diff_area_diff:
        infoClassSalesAverageYearlyDiffAreaDiff[key].value,
      sales_average_class_rate: infoClassSalesAverageClassRate[key].value,
      area_sales_average_class_rate:
        infoClassAreaSalesAverageClassRate[key].value,
      sales_average_class_rate_area_diff:
        infoClassSalesAverageClassRateAreaDiff[key].value,
      sales_average_usage_rate: infoClassSalesAverageUsageRate[key].value,
      area_sales_average_usage_rate:
        infoClassAreaSalesAverageUsageRate[key].value,
      sales_average_usage_rate_area_diff:
        infoClassSalesAverageUsageRateAreaDiff[key].value,
    }));
    calcMaxValue(
      calcMaxObjValue(infoClassSalesAverageYearlyDiff),
      calcMaxObjValue(infoClassAreaSalesAverageYearlyDiff),
      calcMaxObjValue(infoClassSalesAverageYearlyDiffAreaDiff)
    );
  };

  // 各オブジェクトの最大値を取得
  const calcMaxObjValue = (obj: any) => {
    let maxValue = 0;
    const keys = Object.keys(obj);
    // 「情報分類合計」は最大値の対象から除外
    const index = keys.indexOf("ZZZ");
    if (index >= 0) {
      keys.splice(index, 1);
    }
    for (let key of keys) {
      if (Math.abs(obj[key].value) > maxValue) {
        maxValue = Math.abs(obj[key].value);
      }
    }
    return maxValue;
  };

  // グラフを表示する3列の最大値を
  const calcMaxValue = (num1: any, num2: any, num3: any) => {
    setMaxValue(Math.max(num1, num2, num3));
  };

  // ソート用比較関数
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    // 項目を押下した場合、指定の順序で表示する
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  // 上の関数を呼ぶ関数
  const getComparator = <Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): ((a: { [key in Key]: any }, b: { [key in Key]: any }) => any) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  // 選択した要素以外でもソートを行う
  const stableSort = (array: any, comparator: any) => {
    const stabilizedThis = array.map((el: any, index: any) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  };

  const getDiffValue = (value: any) => {
    if (value === null || value === undefined || value === "") {
      return "-";
    }

    const roundValue = Math.round(value * 10) / 10;
    return getStringDiffValue(roundValue);
  };

  const getRationValue = (value: any) => {
    if (value === null || value === undefined || value === "") {
      return "-";
    }

    const roundValue = Math.round(value * 10) / 10;
    return getColoredRationValue(roundValue);
  };

  // ソートボタン出力
  const sortIcon = (property: string): JSX.Element => {
    if (orderBy === property) {
      return order === "asc" ? ascSort() : descSort();
    }
    return nonSort();
  };

  const ascSort = (): JSX.Element => {
    return (
      <IconButton css={roundIcon}>
        <ArrowDropUpIcon style={{ color: "#000", marginBottom: "-7px" }} />
        <ArrowDropDownIcon color="disabled" style={{ marginTop: "-7px" }} />
      </IconButton>
    );
  };

  const descSort = (): JSX.Element => {
    return (
      <IconButton css={roundIcon}>
        <ArrowDropUpIcon color="disabled" style={{ marginBottom: "-7px" }} />
        <ArrowDropDownIcon style={{ color: "#000", marginTop: "-7px" }} />
      </IconButton>
    );
  };

  const nonSort = (): JSX.Element => {
    return (
      <IconButton css={roundIcon}>
        <ArrowDropUpIcon color="disabled" style={{ marginBottom: "-7px" }} />
        <ArrowDropDownIcon color="disabled" style={{ marginTop: "-7px" }} />
      </IconButton>
    );
  };

  // Header部分出力
  const EnhancedTableHead: React.FC<{
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    fixedRow: any;
    isOpen: boolean;
  }> = ({ onRequestSort, fixedRow, isOpen }) => {
    const createSortHandler = (property: any) => (event: any) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          <TableCell
            key={"name"}
            rowSpan={2}
            css={[headerCell, rightThickBorder, overrideItemCell]}
          >
            項目
          </TableCell>
          <TableCell
            key={"sales_average_yearly_diff"}
            colSpan={3}
            rowSpan={2}
            css={[headerCell, rightThickBorder]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              自店前年差額(千円/日)
              <span
                css={sortButton}
                onClick={createSortHandler("sales_average_yearly_diff")}
              >
                {sortIcon("sales_average_yearly_diff")}
              </span>
            </div>
          </TableCell>
          <TableCell
            key={"area_sales_average_yearly_diff"}
            colSpan={3}
            rowSpan={2}
            css={[headerCell, rightThickBorder]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {flagArea === "do" ? "DO" : "同一立地"}前年差額(千円/日)
              <span
                css={sortButton}
                onClick={createSortHandler("area_sales_average_yearly_diff")}
              >
                {sortIcon("area_sales_average_yearly_diff")}
              </span>
            </div>
          </TableCell>
          <TableCell
            key={"sales_average_yearly_diff_area_diff"}
            colSpan={3}
            rowSpan={2}
            css={[headerCell]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {flagArea === "do" ? "DOとの" : "同一立地との"}差(千円/日)
              <span
                css={sortButton}
                onClick={createSortHandler(
                  "sales_average_yearly_diff_area_diff"
                )}
              >
                {sortIcon("sales_average_yearly_diff_area_diff")}
              </span>
            </div>
          </TableCell>
          {isOpen && (
            <>
              <TableCell
                colSpan={3}
                css={[accordionHeaderCell, leftThickBorder]}
              >
                販売金額(千円/日)
              </TableCell>
              <TableCell colSpan={3} css={accordionHeaderCell}>
                販売金額前年比(％)
              </TableCell>
              <TableCell colSpan={3} css={accordionHeaderCell}>
                販売金額前年差(千円/日)
              </TableCell>
              <TableCell colSpan={3} css={accordionHeaderCell}>
                販売金額構成比(％)
              </TableCell>
              <TableCell
                colSpan={3}
                css={accordionHeaderCell}
                style={{ borderRight: "none" }}
              >
                利用度
              </TableCell>
            </>
          )}
        </TableRow>
        <TableRow>
          {isOpen && (
            <>
              <TableCell css={[accordionMinHeaderCell, leftThickBorder]}>
                自店
              </TableCell>
              <TableCell css={accordionMinHeaderCell}>
                {flagArea === "do" ? "DO" : "同一立地"}
              </TableCell>
              <TableCell css={accordionMinHeaderCell}>差</TableCell>
              <TableCell css={accordionMinHeaderCell}>自店</TableCell>
              <TableCell css={accordionMinHeaderCell}>
                {flagArea === "do" ? "DO" : "同一立地"}
              </TableCell>
              <TableCell css={accordionMinHeaderCell}>差</TableCell>
              <TableCell css={accordionMinHeaderCell}>自店</TableCell>
              <TableCell css={accordionMinHeaderCell}>
                {flagArea === "do" ? "DO" : "同一立地"}
              </TableCell>
              <TableCell css={accordionMinHeaderCell}>差</TableCell>
              <TableCell css={accordionMinHeaderCell}>自店</TableCell>
              <TableCell css={accordionMinHeaderCell}>
                {flagArea === "do" ? "DO" : "同一立地"}
              </TableCell>
              <TableCell css={accordionMinHeaderCell}>差</TableCell>
              <TableCell css={accordionMinHeaderCell}>自店</TableCell>
              <TableCell css={accordionMinHeaderCell}>
                {flagArea === "do" ? "DO" : "同一立地"}
              </TableCell>
              <TableCell
                css={accordionMinHeaderCell}
                style={{ borderRight: "none" }}
              >
                差
              </TableCell>
            </>
          )}
        </TableRow>
        {/* 情報分類合計の行 */}
        {Object.keys(fixedRow).length !== 0 && (
          <TableRow
            css={tableRow}
            sx={{
              th: {
                borderBottom: "2px solid #c4c4c4",
              },
            }}
          >
            <TableCell
              css={[itemNameCell, bottomThickBorder, overrideTotalCell]}
            >
              {fixedRow?.name}
            </TableCell>
            <TableCell css={blankGraphValueCell}>
              {getDiffValue(fixedRow?.sales_average_yearly_diff / 1000)}
            </TableCell>
            <TableCell colSpan={2} css={[grayBgColor, rightThickBorder]} />
            <TableCell css={blankGraphValueCell}>
              {getDiffValue(fixedRow?.area_sales_average_yearly_diff / 1000)}
            </TableCell>
            <TableCell colSpan={2} css={[grayBgColor, rightThickBorder]} />
            <TableCell css={blankGraphValueCell}>
              {getDiffValue(
                fixedRow?.sales_average_yearly_diff_area_diff / 1000
              )}
            </TableCell>
            <TableCell colSpan={2} css={grayBgColor} />
            {isOpen && (
              <>
                <TableCell
                  css={accordionHeaderValueCell}
                  style={{
                    borderLeft: "2px solid #c4c4c4",
                  }}
                >
                  {getIntegerValue(fixedRow?.sales_average / 1000)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getIntegerValue(fixedRow?.area_sales_average / 1000)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getDiffValue(fixedRow?.sales_average_area_diff / 1000)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getRationValue(fixedRow?.sales_average_yoy)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getRationValue(fixedRow?.area_sales_average_yoy)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getDiffValue(fixedRow?.sales_average_yoy_area_diff)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getDiffValue(fixedRow?.sales_average_yearly_diff / 1000)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getDiffValue(
                    fixedRow?.area_sales_average_yearly_diff / 1000
                  )}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getDiffValue(
                    fixedRow?.sales_average_yearly_diff_area_diff / 1000
                  )}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getIntegerValue(fixedRow?.sales_average_class_rate)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getIntegerValue(fixedRow?.area_sales_average_class_rate)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getDiffValue(fixedRow?.sales_average_class_rate_area_diff)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getIntegerValue(fixedRow?.sales_average_usage_rate)}
                </TableCell>
                <TableCell css={accordionHeaderValueCell}>
                  {getIntegerValue(fixedRow?.area_sales_average_usage_rate)}
                </TableCell>
                <TableCell
                  css={accordionHeaderValueCell}
                  style={{ borderRight: "none" }}
                >
                  {getDiffValue(fixedRow?.sales_average_usage_rate_area_diff)}
                </TableCell>
              </>
            )}
          </TableRow>
        )}
      </TableHead>
    );
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const dispMsg = () => {
    setTimeout(true);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "asc" : "desc");
    setOrderBy(property);
  };

  const changeWeekMonth = (newValue: string) => {
    setFlagWM(newValue);
  };

  const changeArea = (valueArea: string) => {
    setFlagArea(valueArea);
  };

  const handleTermChange = (event: SelectChangeEvent) => {
    if (flagWM === "InfoClassWeekly") {
      setWeekDateValue(event.target.value as string);
    } else if (flagWM === "InfoClassMonthly") {
      setMonthDateValue(event.target.value as string);
    }
  };

  const CreateGraphCell: React.FC<{
    value: number;
    borderFlag?: boolean;
  }> = ({ value, borderFlag = false }) => {
    const isPositive = value >= 0;

    return (
      <>
        <TableCell css={graphValueCell}>{getDiffValue(value / 1000)}</TableCell>
        {isPositive ? (
          <>
            <TableCell
              css={graphCell}
              style={{
                borderRight: "1px dashed #c4c4c4",
              }}
            ></TableCell>
            <TableCell
              css={graphCell}
              style={{
                borderRight: borderFlag ? "none" : "2px solid #c4c4c4",
              }}
            >
              <GraphContainer
                isPositive={isPositive}
                value={value}
                maxValue={maxValue}
              ></GraphContainer>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              css={graphCell}
              style={{
                borderRight: "1px dashed #c4c4c4",
              }}
            >
              <GraphContainer
                isPositive={isPositive}
                value={value}
                maxValue={maxValue}
              ></GraphContainer>
            </TableCell>
            <TableCell
              css={graphCell}
              style={{
                borderRight: borderFlag ? "none" : "2px solid #c4c4c4",
              }}
            ></TableCell>
          </>
        )}
      </>
    );
  };

  // 画面に表示するためにデータを成形する
  const sortedRows = React.useMemo(
    () => stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, data]
  );

  const slicedRows = React.useMemo(() => {
    return sortedRows.filter((row: any) => row.name !== "情報分類合計");
  }, [sortedRows]);

  return timeout ? (
    <TimeOutDisplay setTimeout={setTimeout} />
  ) : (
    <>
      <ThemeProvider theme={fontTheme()}>
        <Header
          windowName={windowName + "(" + pmaName + ")"}
          tenpoCode={tenpoCode ? tenpoCode : ""}
          tenpoName={tenpoName ? tenpoName : ""}
        />
        <Grid item container>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={11}>
            <Card css={card} variant="outlined">
              <Modal
                open={errorAPI}
                onClose={modalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <APIErrorModal />
              </Modal>
              <CardContent css={cardContent}>
                {loading && (
                  <div css={cssLoading}>
                    <CircularProgress css={circularProgress} size={120} />
                  </div>
                )}
                <div css={operationSpace}>
                  <div css={operationLeft}>
                    <ToggleButtonComponent
                      options={[
                        { value: "do", label: "DO" },
                        { value: "location", label: "ZO内同一立地" },
                      ]}
                      selectedValue={flagArea}
                      orderBy={"none"}
                      onChange={changeArea}
                      locationFlag={locationFlag}
                      loadFlag={loading}
                    />
                    <ToggleButtonComponent
                      options={[
                        { value: "InfoClassWeekly", label: "週別" },
                        { value: "InfoClassMonthly", label: "月別" },
                      ]}
                      selectedValue={flagWM}
                      orderBy={"none"}
                      onChange={changeWeekMonth}
                      loadFlag={loading}
                    />
                  </div>
                  <div css={operationRight}>
                    {flagWM === "InfoClassWeekly" ? (
                      <Select
                        labelId="select-label"
                        id="simple-select"
                        value={weekDateValue}
                        label="select week"
                        onChange={handleTermChange}
                        css={selectTerm}
                        disabled={loading}
                      >
                        {weekDateOptions.map((option: any) => (
                          <MenuItem
                            key={option}
                            value={option}
                            style={{ height: "20%" }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        labelId="select-label"
                        id="simple-select"
                        value={monthDateValue}
                        label="select week"
                        onChange={handleTermChange}
                        css={selectTerm}
                        disabled={loading}
                      >
                        {monthDateOptions.map((option: any) => (
                          <MenuItem
                            key={option}
                            value={option}
                            style={{ height: "20%" }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    <IconButton onClick={handleToggle}>
                      {isOpen ? (
                        <KeyboardDoubleArrowLeftIcon />
                      ) : (
                        <KeyboardDoubleArrowRightIcon />
                      )}
                    </IconButton>
                  </div>
                </div>
                <TableContainer component={Paper} css={tableContainer}>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <EnhancedTableHead
                      onRequestSort={handleRequestSort}
                      fixedRow={fixedRow}
                      isOpen={isOpen}
                    />
                    <TableBody>
                      {slicedRows.map((row: any) => (
                        <TableRow
                          key={row.name}
                          css={tableRow}
                          sx={{
                            "&:last-child td": {
                              borderBottom: "none",
                            },
                            td: {
                              borderBottom: "1px dashed #c4c4c4",
                            },
                          }}
                        >
                          <TableCell css={itemNameCell}>{row.name}</TableCell>
                          <CreateGraphCell
                            value={row.sales_average_yearly_diff}
                          />
                          <CreateGraphCell
                            value={row.area_sales_average_yearly_diff}
                          />
                          <CreateGraphCell
                            value={row.sales_average_yearly_diff_area_diff}
                            borderFlag={true}
                          />
                          {isOpen && (
                            <>
                              <TableCell
                                css={accordionValueCell}
                                style={{
                                  borderLeft: "2px solid #c4c4c4",
                                }}
                              >
                                {getIntegerValue(row.sales_average / 1000)}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getIntegerValue(row.area_sales_average / 1000)}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getDiffValue(
                                  row.sales_average_area_diff / 1000
                                )}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getRationValue(row.sales_average_yoy)}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getRationValue(row.area_sales_average_yoy)}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getDiffValue(row.sales_average_yoy_area_diff)}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getDiffValue(
                                  row.sales_average_yearly_diff / 1000
                                )}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getDiffValue(
                                  row.area_sales_average_yearly_diff / 1000
                                )}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getDiffValue(
                                  row.sales_average_yearly_diff_area_diff / 1000
                                )}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getIntegerValue(row.sales_average_class_rate)}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getIntegerValue(
                                  row.area_sales_average_class_rate
                                )}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getDiffValue(
                                  row.sales_average_class_rate_area_diff
                                )}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getIntegerValue(row.sales_average_usage_rate)}
                              </TableCell>
                              <TableCell css={accordionValueCell}>
                                {getIntegerValue(
                                  row.area_sales_average_usage_rate
                                )}
                              </TableCell>
                              <TableCell
                                css={accordionValueCell}
                                style={{ borderRight: "none" }}
                              >
                                {getDiffValue(
                                  row.sales_average_usage_rate_area_diff
                                )}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={0.5}></Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default InfoClass;
