import React, { Dispatch, SetStateAction } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import { fontTheme } from "../common/utils";

// const [digOpen, setDigOpen] = useState(false);

const grid = css`
  margin-top: 64px;
`;

const card = css`
  padding: 8px;
  // margin: 64px;
  // background-color: #f7f9f8;
`;
const style = css`
  text-align: center;
  font-size: 24px;
`;

const header = css`
  ${style}
  padding: 4px;
  background-color: #e0ffee;
  border: 1px #333333;
`;

const body = css`
  ${style}
  margin: 16px 10px 2px;
  font-weight: bold;
`;

const p = css`
  ${style}
  margin: 2px 10px 8px;
`;

const reload = css`
  font-size: 20px;
  border-radius: 20px;
  background-color: #00cc5b;
  color: #fff;
  display: block;
  margin: 0 0 0 auto;
  &:hover {
    background-color: #00cc5bb3;
  }
`;

const icon = css`
  margin: auto;
  display: inline-flex;
  vertical-align: middle;
`;
type Props = {
  setTimeout: Dispatch<SetStateAction<boolean>>;
};

const TimeOutDisplay: React.FC<Props> = ({ setTimeout }) => {
  return (
    <>
      <ThemeProvider theme={fontTheme()}>
        <Grid container css={grid}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Card variant="outlined">
              <CardContent css={card}>
                {/* <header > */}
                <div css={header}>このセッションはタイムアウトしました。</div>
                {/* </header> */}
                {/* <body> */}
                <div css={body}>画面を表示してから30分以上経過しました。</div>
                <div css={p}>以下のボタンより画面の更新を行ってください。</div>
                <Button onClick={() => setTimeout(false)} css={reload}>
                  <ReplayIcon css={icon} />
                  更新
                </Button>
                {/* </body> */}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
export default TimeOutDisplay;
