// WD：Whole day：全日
// MO：Morning：朝
// NO：Noon：昼
// NT：Night：夜
const createData1 = (
  date: string,
  weather: string,
  temperature: string,
  result: string,
  salesWD: number,
  salesRateLastYearWD: number,
  salesRateDoAvgDiffWD: number,
  salesMO: number,
  salesRateLastYearMO: number,
  salesRateDoAvgDiffMO: number,
  salesNO: number,
  salesRateLastYearNO: number,
  salesRateDoAvgDiffNO: number,
  salesNT: number,
  salesRateLastYearNT: number,
  salesRateDoAvgDiffNT: number
) => {
  return {
    date,
    weather,
    temperature,
    result,
    salesWD,
    salesRateLastYearWD,
    salesRateDoAvgDiffWD,
    salesMO,
    salesRateLastYearMO,
    salesRateDoAvgDiffMO,
    salesNO,
    salesRateLastYearNO,
    salesRateDoAvgDiffNO,
    salesNT,
    salesRateLastYearNT,
    salesRateDoAvgDiffNT,
  };
};

export const rows1 = [
  createData1(
    "12/11(日)",
    "100",
    "28℃ / 15℃",
    "gr",
    873,
    96.8,
    2.4,
    253,
    101.4,
    -0.6,
    349,
    102.1,
    1.1,
    271,
    100.6,
    -0.5
  ),
  createData1(
    "12/12(月)",
    "202",
    "22℃ / 15℃",
    "yw",
    873,
    100.8,
    3.4,
    253,
    95.4,
    1.6,
    349,
    102.1,
    -1.1,
    271,
    98.6,
    -2.4
  ),
  createData1(
    "12/13(火)",
    "101",
    "28℃ / 15℃",
    "gr",
    873,
    92.8,
    5.4,
    253,
    101.4,
    3.2,
    349,
    102.1,
    1.1,
    271,
    100.6,
    -4.5
  ),
  createData1(
    "12/14(水)",
    "104",
    "25℃ / 13℃",
    "gr",
    873,
    100.8,
    1.4,
    253,
    101.4,
    2.1,
    349,
    96.1,
    -1.1,
    271,
    100.6,
    0.5
  ),
  createData1(
    "12/15(木)",
    "302",
    "27℃ / 20℃",
    "gr",
    873,
    100.8,
    5.4,
    253,
    101.4,
    -2.6,
    349,
    102.1,
    2.8,
    271,
    100.6,
    2.5
  ),
  createData1(
    "12/16(金)",
    "313",
    "24℃ / 13℃",
    "rd",
    873,
    91.8,
    2.2,
    253,
    101.4,
    1.6,
    349,
    102.1,
    -3.1,
    271,
    93.6,
    -0.2
  ),
  createData1(
    "12/17(土)",
    "411",
    "27℃ / 30℃",
    "gr",
    873,
    100.8,
    1.4,
    253,
    94.4,
    1.6,
    349,
    102.1,
    0.1,
    271,
    100.6,
    3.5
  ),
  createData1(
    "当日",
    "100",
    "28℃ / 15℃",
    "yw",
    253,
    101.4,
    2.6,
    253,
    94.4,
    1.6,
    0,
    0,
    0,
    0,
    0,
    0
  ),
];

const createData2 = (
  date: string,
  weather: string,
  temperature: string,
  result: string,
  sales: number,
  salesRateLastYear: number,
  salesRateDiff: number,
  kyakusu: number,
  kyakusuRateLastYear: number,
  kyakusuRateDiff: number,
  unitPrice: number,
  unitPriceRateLastYear: number,
  unitPriceRateDiff: number,
  itemsNum: number,
  itemsNumRateLastYear: number,
  itemsNumRateDiff: number
) => {
  return {
    date,
    weather,
    temperature,
    result,
    sales,
    salesRateLastYear,
    salesRateDiff,
    kyakusu,
    kyakusuRateLastYear,
    kyakusuRateDiff,
    unitPrice,
    unitPriceRateLastYear,
    unitPriceRateDiff,
    itemsNum,
    itemsNumRateLastYear,
    itemsNumRateDiff,
  };
};

export const rows2 = [
  createData2(
    "12/11(日)",
    "100",
    "28℃ / 15℃",
    "gr",
    873,
    100.8,
    3.4,
    253,
    94.4,
    -2.6,
    620,
    101.2,
    1.2,
    4.1,
    101.2,
    0.2
  ),
  createData2(
    "12/12(月)",
    "202",
    "22℃ / 15℃",
    "yw",
    873,
    97.8,
    2.9,
    253,
    93.1,
    -2.6,
    620,
    102.7,
    -4.2,
    5.1,
    96.2,
    -0.7
  ),
  createData2(
    "12/13(火)",
    "101",
    "28℃ / 15℃",
    "gr",
    873,
    104.2,
    2.4,
    253,
    101.4,
    -6.6,
    620,
    103.6,
    1.2,
    6.0,
    111.2,
    3.2
  ),
  createData2(
    "12/14(水)",
    "104",
    "25℃ / 13℃",
    "gr",
    873,
    103.8,
    -2.4,
    253,
    106.4,
    2.6,
    620,
    103.2,
    -1.2,
    5.5,
    112.2,
    6.2
  ),
  createData2(
    "12/15(木)",
    "302",
    "27℃ / 20℃",
    "gr",
    873,
    102.8,
    1.4,
    253,
    107.4,
    3.6,
    620,
    109.2,
    4.2,
    4.9,
    103.2,
    2.2
  ),
  createData2(
    "12/16(金)",
    "313",
    "24℃ / 13℃",
    "rd",
    873,
    90.8,
    4.5,
    253,
    105.4,
    2.9,
    620,
    94.2,
    -3.9,
    4.3,
    100.2,
    0.2
  ),
  createData2(
    "12/17(土)",
    "411",
    "27℃ / 30℃",
    "gr",
    873,
    100.8,
    4.4,
    253,
    101.4,
    2.6,
    620,
    101.2,
    1.2,
    4.1,
    101.2,
    1.2
  ),
  createData2(
    "当日",
    "100",
    "28℃ / 15℃",
    "yw",
    873,
    111.8,
    6.4,
    253,
    105.4,
    4.6,
    620,
    107.2,
    2.2,
    5.3,
    104.2,
    3.2
  ),
];

export const rows3 = [
  createData2(
    "平日",
    "100",
    "28℃ / 15℃",
    "gr",
    873,
    100.8,
    3.4,
    253,
    94.4,
    -2.6,
    620,
    101.2,
    1.2,
    4.1,
    101.2,
    0.2
  ),
];

export const rows4 = [
  createData2(
    "土日祝",
    "202",
    "22℃ / 15℃",
    "yw",
    873,
    97.8,
    2.9,
    253,
    93.1,
    -2.6,
    620,
    102.7,
    -4.2,
    5.1,
    96.2,
    -0.7
  ),
];

export const date1 = [
  {
    name: "10/14",
    全日: 105.1,
    朝: 98.2,
    昼: 111.4,
    夜: 90.3,
  },
  {
    name: "10/15",
    全日: 102.1,
    朝: 104.2,
    昼: 103.4,
    夜: 93.3,
  },
  {
    name: "10/16",
    全日: 111.1,
    朝: 94.2,
    昼: 113.4,
    夜: 103.3,
  },
  {
    name: "10/17",
    全日: 99.1,
    朝: 99.2,
    昼: 94.4,
    夜: 103.3,
  },
  {
    name: "10/18",
    全日: 101.1,
    朝: 120.2,
    昼: 100.4,
    夜: 89.3,
  },
  {
    name: "10/19",
    全日: 110.1,
    朝: 120.2,
    昼: 103.4,
    夜: 105.3,
  },
  {
    name: "10/20",
    全日: 99.1,
    朝: 98.2,
    昼: 94.4,
    夜: 95.3,
  },
  {
    name: "当日",
    全日: 111.1,
    朝: 103.2,
    昼: 111.4,
    夜: 102.3,
  },
];

export const date2 = [
  {
    name: "12/11",
    売上: 105.1,
    客数: 98.2,
    客単価: 111.4,
    買上点数: 102.4,
  },
  {
    name: "12/12",
    売上: 102.1,
    客数: 104.2,
    客単価: 103.4,
    買上点数: 95.3,
  },
  {
    name: "12/13",
    売上: 111.1,
    客数: 94.2,
    客単価: 113.4,
    買上点数: 99.4,
  },
  {
    name: "12/14",
    売上: 99.1,
    客数: 99.2,
    客単価: 94.4,
    買上点数: 111.1,
  },
  {
    name: "12/15",
    売上: 101.1,
    客数: 120.2,
    客単価: 100.4,
    買上点数: 123.1,
  },
  {
    name: "12/16",
    売上: 110.1,
    客数: 120.2,
    客単価: 103.4,
    買上点数: 106.1,
  },
  {
    name: "12/17",
    売上: 99.1,
    客数: 98.2,
    客単価: 94.4,
    買上点数: 95.1,
  },
  {
    name: "当日",
    売上: 111.1,
    客数: 103.2,
    客単価: 111.4,
    買上点数: 109.0,
  },
];

// 佐藤分
const createData5 = (item: string) => {
  return {
    item,
  };
};

export const rows5 = [
  createData5("商品売上高(売上日報)"),
  createData5("客数(売上日報)"),
  createData5("商品廃棄"),
  createData5("客単価"),
  createData5("朝帯売上(0~10)"),
  createData5("昼帯売上(10~16)"),
  createData5("夜帯売上(16~24)"),
  createData5("時間帯別売上(0~24)"),
  createData5("朝帯客数(0~10)"),
  createData5("昼帯客数(10~16)"),
  createData5("夜帯客数(16~24)"),
  createData5("時間帯別客数(0~24)"),
];

const createData6 = (
  date: string,
  precendingdate: string,
  salesPerformance: string,
  salesYoy: string,
  salesPerformanceofPreviousYear: string,
  customersPerformance: string,
  customersYoy: string,
  customersPerformanceofPreviousYear: string,
  wastePerformance: string,
  wasteYoy: string,
  wastePerformanceofPreviousYear: string,
  customerUnitPricePerformance: string,
  customerUnitPriceYoy: string,
  customerUnitPricePerformanceofPreviousYear: string,
  morningSalesPerformance: string,
  morningSalesYoy: string,
  morningSalesPerformanceofPreviousYear: string,
  daytimeSalesPerformance: string,
  daytimeSalesYoy: string,
  daytimeSalesPerformanceofPreviousYear: string,
  nightSalesPerformance: string,
  nightSalesYoy: string,
  nightSalesPerformanceofPreviousYear: string,
  salesByTimezonePerformance: string,
  salesByTimezoneYoy: string,
  salesByTimezonePerformanceofPreviousYear: string,
  customersInmoningPerformance: string,
  customersInmoningYoy: string,
  customersInmoningPerformanceofPreviousYear: string,
  customersIndaytimePerformance: string,
  customersIndaytimeYoy: string,
  customersIndaytimePerformanceofPreviousYear: string,
  customersInnightPerformance: string,
  customersInnightYoy: string,
  customersInnightPerformanceofPreviousYear: string,
  customersByTimezonePerformance: string,
  customersByTimezoneYoy: string,
  customersByTimezonePerformanceofPreviousYear: string
) => {
  return {
    date,
    precendingdate,
    salesPerformance,
    salesYoy,
    salesPerformanceofPreviousYear,
    customersPerformance,
    customersYoy,
    customersPerformanceofPreviousYear,
    wastePerformance,
    wasteYoy,
    wastePerformanceofPreviousYear,
    customerUnitPricePerformance,
    customerUnitPriceYoy,
    customerUnitPricePerformanceofPreviousYear,
    morningSalesPerformance,
    morningSalesYoy,
    morningSalesPerformanceofPreviousYear,
    daytimeSalesPerformance,
    daytimeSalesYoy,
    daytimeSalesPerformanceofPreviousYear,
    nightSalesPerformance,
    nightSalesYoy,
    nightSalesPerformanceofPreviousYear,
    salesByTimezonePerformance,
    salesByTimezoneYoy,
    salesByTimezonePerformanceofPreviousYear,
    customersInmoningPerformance,
    customersInmoningYoy,
    customersInmoningPerformanceofPreviousYear,
    customersIndaytimePerformance,
    customersIndaytimeYoy,
    customersIndaytimePerformanceofPreviousYear,
    customersInnightPerformance,
    customersInnightYoy,
    customersInnightPerformanceofPreviousYear,
    customersByTimezonePerformance,
    customersByTimezoneYoy,
    customersByTimezonePerformanceofPreviousYear,
  };
};

export const rows6 = [
  createData6(
    "1(月)",
    "2(月)",
    "262,500",
    "90.0",
    "68,750",
    "900",
    "90.0",
    "1,000",
    "5,000",
    "90.0",
    "6,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "2(火)",
    "3(火)",
    "64,000",
    "91.5",
    "70,250",
    "910",
    "91.5",
    "1,050",
    "5,050",
    "91.5",
    "6,100",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "3(水)",
    "4(水)",
    "65,500",
    "93.0",
    "71,750",
    "920",
    "93.0",
    "1,100",
    "5,100",
    "93.0",
    "6,200",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "4(金)",
    "5(金)",
    "68,500",
    "96.0",
    "74,750",
    "940",
    "96.0",
    "1,200",
    "5,200",
    "96.0",
    "6,400",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "5(土)",
    "6(土)",
    "70,000",
    "97.5",
    "76,250",
    "950",
    "97.5",
    "1,250",
    "5,250",
    "97.5",
    "6,500",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "6(日)",
    "7(日)",
    "71,500",
    "99.0",
    "77,750",
    "960",
    "99.0",
    "1,300",
    "5,300",
    "99.0",
    "6,600",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "7(月)",
    "8(木)",
    "73,000",
    "100.5",
    "79,250",
    "970",
    "100.5",
    "1,350",
    "5,350",
    "100.5",
    "6,700",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "8(火)",
    "9(火)",
    "74,500",
    "102.0",
    "80,750",
    "980",
    "102.0",
    "1,400",
    "5,400",
    "102.0",
    "6,800",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "9(水)",
    "10(水)",
    "76,000",
    "103.5",
    "82,250",
    "990",
    "103.5",
    "1,450",
    "5,450",
    "103.5",
    "6,900",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "10(木)",
    "11(木)",
    "77,500",
    "105.0",
    "83,750",
    "1000",
    "105.0",
    "1,500",
    "5,500",
    "105.0",
    "7,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "11(金)",
    "12(金)",
    "79,000",
    "106.5",
    "85,250",
    "1010",
    "106.5",
    "1,550",
    "5,550",
    "106.5",
    "7,100",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "12(土)",
    "13(土)",
    "80,500",
    "108.0",
    "86,750",
    "1020",
    "108.0",
    "1,600",
    "5,600",
    "108.0",
    "7,200",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "13(日)",
    "14(日)",
    "82,000",
    "109.5",
    "88,250",
    "1030",
    "109.5",
    "1,650",
    "5,650",
    "109.5",
    "7,300",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "14(月)",
    "15(月)",
    "83,500",
    "111.0",
    "89,750",
    "1040",
    "111.0",
    "1,700",
    "5,700",
    "111.0",
    "7,400",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "15(火)",
    "16(金)",
    "85,000",
    "112.5",
    "91,250",
    "1050",
    "112.5",
    "1,750",
    "5,750",
    "112.5",
    "7,500",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "16(水)",
    "17(水)",
    "86,500",
    "114.0",
    "92,750",
    "1060",
    "114.0",
    "1,800",
    "5,800",
    "114.0",
    "7,600",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "17(木)",
    "18(木)",
    "88,000",
    "115.5",
    "94,250",
    "1070",
    "115.5",
    "1,850",
    "5,850",
    "115.5",
    "7,700",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "18(金)",
    "19(金)",
    "89,500",
    "117.0",
    "95,750",
    "1080",
    "117.0",
    "1,900",
    "5,900",
    "117.0",
    "7,800",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "19(土)",
    "20(土)",
    "91,000",
    "118.5",
    "97,250",
    "1090",
    "118.5",
    "1,950",
    "5,950",
    "118.5",
    "7,900",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "20(日)",
    "21(日)",
    "92,500",
    "120.0",
    "98,750",
    "1100",
    "120.0",
    "2,000",
    "6,000",
    "120.0",
    "8,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "21(月)",
    "22(月)",
    "94,000",
    "121.5",
    "100,250",
    "1110",
    "121.5",
    "2,050",
    "6,050",
    "121.5",
    "8,100",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "22(火)",
    "23(火)",
    "95,500",
    "123.0",
    "101,750",
    "1120",
    "123.0",
    "2,100",
    "6,100",
    "123.0",
    "8,200",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "23(水)",
    "24(水)",
    "97,000",
    "124.5",
    "103,250",
    "1130",
    "124.5",
    "2,150",
    "6,150",
    "124.5",
    "8,300",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "24(木)",
    "25(木)",
    "98,500",
    "126.0",
    "104,750",
    "1140",
    "126.0",
    "2,200",
    "6,200",
    "126.0",
    "8,400",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "25(金)",
    "26(金)",
    "100,000",
    "127.5",
    "106,250",
    "1150",
    "127.5",
    "2,250",
    "6,250",
    "127.5",
    "8,500",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "26(土)",
    "27(土)",
    "101,500",
    "129.0",
    "107,750",
    "1160",
    "129.0",
    "2,300",
    "6,300",
    "129.0",
    "8,600",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "27(日)",
    "28(日)",
    "103,000",
    "130.5",
    "109,250",
    "1170",
    "130.5",
    "2,350",
    "6,350",
    "130.5",
    "8,700",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "28(月)",
    "29(月)",
    "104,500",
    "132.0",
    "110,750",
    "1180",
    "132.0",
    "2,400",
    "6,400",
    "132.0",
    "8,800",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "29(火)",
    "30(火)",
    "106,000",
    "133.5",
    "112,250",
    "1190",
    "133.5",
    "2,450",
    "6,450",
    "133.5",
    "8,900",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData6(
    "30(水)",
    "31(水)",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
];
const createData7 = (average: string, total: string) => {
  return {
    average,
    total,
  };
};

export const rows7 = [
  createData7("253", "101"),
  createData7("253.0", "101.4"),
  createData7("253", "101"),
  createData7("253", "101"),
  createData7("253.0", "101.0"),
  createData7("253", "101"),
  createData7("253", "101"),
  createData7("253.0", "101.4"),
  createData7("253", "101"),
];

const createData8 = (average: string, total: string, budget: string) => {
  return {
    average,
    total,
    budget,
  };
};

export const rows8 = [
  createData8("253", "101.4", "10,000,000"),
  createData8("253", "101.4", "22.000"),
  createData8("253", "101.4", "22.000"),
  createData8("253", "101.4", "22.000"),
  createData8("253", "101.4", "10,000,000"),
  createData8("253", "101.4", "22.000"),
  createData8("253", "101.4", "22.000"),
  createData8("253", "101.4", "22.000"),
  createData8("253", "101.4", "22.000"),
  createData8("253", "101.4", "22.000"),
  createData8("253", "101.4", "22.000"),
  createData8("253", "101.4", "22.000"),
];

// 商品売上等の平均・累計
const createData9 = (
  name: string,
  salesPerformance: string,
  salesYoy: string,
  salesPerformanceofPreviousYear: string,
  customersPerformance: string,
  customersYoy: string,
  customersPerformanceofPreviousYear: string,
  wastePerformance: string,
  wasteYoy: string,
  wastePerformanceofPreviousYear: string,
  customerUnitPricePerformance: string,
  customerUnitPriceYoy: string,
  customerUnitPricePerformanceofPreviousYear: string,
  morningSalesPerformance: string,
  morningSalesYoy: string,
  morningSalesPerformanceofPreviousYear: string,
  daytimeSalesPerformance: string,
  daytimeSalesYoy: string,
  daytimeSalesPerformanceofPreviousYear: string,
  nightSalesPerformance: string,
  nightSalesYoy: string,
  nightSalesPerformanceofPreviousYear: string,
  salesByTimezonePerformance: string,
  salesByTimezoneYoy: string,
  salesByTimezonePerformanceofPreviousYear: string,
  customersInmoningPerformance: string,
  customersInmoningYoy: string,
  customersInmoningPerformanceofPreviousYear: string,
  customersIndaytimePerformance: string,
  customersIndaytimeYoy: string,
  customersIndaytimePerformanceofPreviousYear: string,
  customersInnightPerformance: string,
  customersInnightYoy: string,
  customersInnightPerformanceofPreviousYear: string,
  customersByTimezonePerformance: string,
  customersByTimezoneYoy: string,
  customersByTimezonePerformanceofPreviousYear: string
) => {
  return {
    name,
    salesPerformance,
    salesYoy,
    salesPerformanceofPreviousYear,
    customersPerformance,
    customersYoy,
    customersPerformanceofPreviousYear,
    wastePerformance,
    wasteYoy,
    wastePerformanceofPreviousYear,
    customerUnitPricePerformance,
    customerUnitPriceYoy,
    customerUnitPricePerformanceofPreviousYear,
    morningSalesPerformance,
    morningSalesYoy,
    morningSalesPerformanceofPreviousYear,
    daytimeSalesPerformance,
    daytimeSalesYoy,
    daytimeSalesPerformanceofPreviousYear,
    nightSalesPerformance,
    nightSalesYoy,
    nightSalesPerformanceofPreviousYear,
    salesByTimezonePerformance,
    salesByTimezoneYoy,
    salesByTimezonePerformanceofPreviousYear,
    customersInmoningPerformance,
    customersInmoningYoy,
    customersInmoningPerformanceofPreviousYear,
    customersIndaytimePerformance,
    customersIndaytimeYoy,
    customersIndaytimePerformanceofPreviousYear,
    customersInnightPerformance,
    customersInnightYoy,
    customersInnightPerformanceofPreviousYear,
    customersByTimezonePerformance,
    customersByTimezoneYoy,
    customersByTimezonePerformanceofPreviousYear,
  };
};
export const rows9 = [
  createData9(
    "平均",
    "262,500",
    "90.0",
    "68,750",
    "900",
    "90.0",
    "1,000",
    "5,000",
    "90.0",
    "6,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
  createData9(
    "累計",
    "10,000,000",
    "-",
    "68,750",
    "900",
    "-",
    "1,000",
    "5,000",
    "-",
    "6,000",
    "107,500",
    "-",
    "113,750",
    "1200",
    "-",
    "2,500",
    "6,500",
    "-",
    "9,000",
    "107,500",
    "-",
    "113,750",
    "1200",
    "-",
    "2,500",
    "6,500",
    "-",
    "9,000",
    "107,500",
    "-",
    "113,750",
    "1200",
    "-",
    "2,500",
    "6,500",
    "-",
    "9,000"
  ),
];

const createData10 = (
  date: string,
  precendingdate: string,
  salesachievement: string,
  salesYoy: string,
  salespreviousyearresults: string,
  customersachievement: string,
  customersYoy: string,
  customerspreviousyearresults: string,
  wasteachievement: string,
  wasteYoy: string,
  wastepreviousyearresults: string
) => {
  return {
    date,
    precendingdate,
    salesachievement,
    salesYoy,
    salespreviousyearresults,
    customersachievement,
    customersYoy,
    customerspreviousyearresults,
    wasteachievement,
    wasteYoy,
    wastepreviousyearresults,
  };
};

export const rows10 = [
  createData10(
    "1(月)",
    "2(月)",
    "262,500",
    "90.0",
    "68,750",
    "900",
    "90.0",
    "1,000",
    "5,000",
    "90.0",
    "6,000"
  ),
  createData10(
    "2(火)",
    "3(火)",
    "64,000",
    "91.5",
    "70,250",
    "910",
    "91.5",
    "1,050",
    "5,050",
    "91.5",
    "6,100"
  ),
  createData10(
    "3(水)",
    "4(水)",
    "65,500",
    "93.0",
    "71,750",
    "920",
    "93.0",
    "1,100",
    "5,100",
    "93.0",
    "6,200"
  ),
  createData10(
    "4(木)",
    "5(木)",
    "67,000",
    "94.5",
    "73,250",
    "930",
    "94.5",
    "1,150",
    "5,150",
    "94.5",
    "6,300"
  ),
  createData10(
    "5(金)",
    "6(金)",
    "68,500",
    "96.0",
    "74,750",
    "940",
    "96.0",
    "1,200",
    "5,200",
    "96.0",
    "6,400"
  ),
  createData10(
    "6(土)",
    "7(土)",
    "70,000",
    "97.5",
    "76,250",
    "950",
    "97.5",
    "1,250",
    "5,250",
    "97.5",
    "6,500"
  ),
  createData10(
    "7(日)",
    "8(日)",
    "71,500",
    "99.0",
    "77,750",
    "960",
    "99.0",
    "1,300",
    "5,300",
    "99.0",
    "6,600"
  ),
  createData10(
    "8(月)",
    "9(月)",
    "73,000",
    "100.5",
    "79,250",
    "970",
    "100.5",
    "1,350",
    "5,350",
    "100.5",
    "6,700"
  ),
  createData10(
    "9(火)",
    "10(火)",
    "74,500",
    "102.0",
    "80,750",
    "980",
    "102.0",
    "1,400",
    "5,400",
    "102.0",
    "6,800"
  ),
  createData10(
    "10(水)",
    "11(水)",
    "76,000",
    "103.5",
    "82,250",
    "990",
    "103.5",
    "1,450",
    "5,450",
    "103.5",
    "6,900"
  ),
  createData10(
    "11(木)",
    "12(木)",
    "77,500",
    "105.0",
    "83,750",
    "1000",
    "105.0",
    "1,500",
    "5,500",
    "105.0",
    "7,000"
  ),
  createData10(
    "12(金)",
    "13(金)",
    "79,000",
    "106.5",
    "85,250",
    "1010",
    "106.5",
    "1,550",
    "5,550",
    "106.5",
    "7,100"
  ),
  createData10(
    "13(土)",
    "14(土)",
    "80,500",
    "108.0",
    "86,750",
    "1020",
    "108.0",
    "1,600",
    "5,600",
    "108.0",
    "7,200"
  ),
  createData10(
    "14(日)",
    "15(日)",
    "82,000",
    "109.5",
    "88,250",
    "1030",
    "109.5",
    "1,650",
    "5,650",
    "109.5",
    "7,300"
  ),
  createData10(
    "15(月)",
    "16(月)",
    "83,500",
    "111.0",
    "89,750",
    "1040",
    "111.0",
    "1,700",
    "5,700",
    "111.0",
    "7,400"
  ),
  createData10(
    "16(火)",
    "17(火)",
    "85,000",
    "112.5",
    "91,250",
    "1050",
    "112.5",
    "1,750",
    "5,750",
    "112.5",
    "7,500"
  ),
  createData10(
    "17(水)",
    "18(水)",
    "86,500",
    "114.0",
    "92,750",
    "1060",
    "114.0",
    "1,800",
    "5,800",
    "114.0",
    "7,600"
  ),
  createData10(
    "18(木)",
    "19(木)",
    "88,000",
    "115.5",
    "94,250",
    "1070",
    "115.5",
    "1,850",
    "5,850",
    "115.5",
    "7,700"
  ),
  createData10(
    "19(金)",
    "20(金)",
    "89,500",
    "117.0",
    "95,750",
    "1080",
    "117.0",
    "1,900",
    "5,900",
    "117.0",
    "7,800"
  ),
  createData10(
    "20(土)",
    "21(土)",
    "91,000",
    "118.5",
    "97,250",
    "1090",
    "118.5",
    "1,950",
    "5,950",
    "118.5",
    "7,900"
  ),
  createData10(
    "21(日)",
    "22(日)",
    "92,500",
    "120.0",
    "98,750",
    "1100",
    "120.0",
    "2,000",
    "6,000",
    "120.0",
    "8,000"
  ),
  createData10(
    "22(月)",
    "23(月)",
    "94,000",
    "121.5",
    "100,250",
    "1110",
    "121.5",
    "2,050",
    "6,050",
    "121.5",
    "8,100"
  ),
  createData10(
    "23(火)",
    "24(火)",
    "95,500",
    "123.0",
    "101,750",
    "1120",
    "123.0",
    "2,100",
    "6,100",
    "123.0",
    "8,200"
  ),
  createData10(
    "24(水)",
    "25(水)",
    "97,000",
    "124.5",
    "103,250",
    "1130",
    "124.5",
    "2,150",
    "6,150",
    "124.5",
    "8,300"
  ),
  createData10(
    "25(木)",
    "26(木)",
    "98,500",
    "126.0",
    "104,750",
    "1140",
    "126.0",
    "2,200",
    "6,200",
    "126.0",
    "8,400"
  ),
  createData10(
    "26(金)",
    "27(金)",
    "100,000",
    "127.5",
    "106,250",
    "1150",
    "127.5",
    "2,250",
    "6,250",
    "127.5",
    "8,500"
  ),
  createData10(
    "27(土)",
    "28(土)",
    "101,500",
    "129.0",
    "107,750",
    "1160",
    "129.0",
    "2,300",
    "6,300",
    "129.0",
    "8,600"
  ),
  createData10(
    "28(日)",
    "29(日)",
    "103,000",
    "130.5",
    "109,250",
    "1170",
    "130.5",
    "2,350",
    "6,350",
    "130.5",
    "8,700"
  ),
  createData10(
    "29(月)",
    "30(月)",
    "104,500",
    "132.0",
    "110,750",
    "1180",
    "132.0",
    "2,400",
    "6,400",
    "132.0",
    "8,800"
  ),
  createData10(
    "30(火)",
    "1(火)",
    "106,000",
    "133.5",
    "112,250",
    "1190",
    "133.5",
    "2,450",
    "6,450",
    "133.5",
    "8,900"
  ),
  createData10(
    "31(水)",
    "2(水)",
    "107,500",
    "135.0",
    "113,750",
    "1200",
    "135.0",
    "2,500",
    "6,500",
    "135.0",
    "9,000"
  ),
];

export const rows12 = {
  month: [
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ],
  sales_average: [
    { jisseki: "33,505,505", zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  customer_count_average: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  unit_price: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  kaiage: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  kyakutannka: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  asauriage: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  hiruuriage: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  yoruuriage: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  zikanuriage: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  asakyakusuu: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  hirukyakusuu: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  yorukyakusuu: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  zikankyakusuu: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  sousyuunyuu: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  kyuuryou: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  tanaorosi: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  tanaorosi2: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  huryouhin: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  huryouhin2: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  eigyouhi: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  zyunrieki: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  open: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  getuzitorihiki: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  zikoshihon: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
  ararieki: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
    { jisseki: 506, zennenhi: "86.9%", zennenzisseki: 582 },
    { jisseki: 507, zennenhi: "86.9%", zennenzisseki: 583 },
    { jisseki: 508, zennenhi: "86.9%", zennenzisseki: 584 },
    { jisseki: 509, zennenhi: "86.9%", zennenzisseki: 585 },
    { jisseki: 510, zennenhi: "86.9%", zennenzisseki: 586 },
    { jisseki: 511, zennenhi: "86.9%", zennenzisseki: 587 },
    { jisseki: 512, zennenhi: "86.9%", zennenzisseki: 588 },
    { jisseki: 513, zennenhi: "86.9%", zennenzisseki: 589 },
    { jisseki: 514, zennenhi: "86.9%", zennenzisseki: 590 },
    { jisseki: 515, zennenhi: "86.9%", zennenzisseki: 591 },
  ],
};

export const rows13 = {
  month: ["1月"],
  sales_average: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  customer_count_average: [
    { jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 },
  ],
  unit_price: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  kaiage: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  kyakutannka: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  asauriage: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  hiruuriage: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  yoruuriage: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  zikanuriage: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  asakyakusuu: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  hirukyakusuu: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  yorukyakusuu: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  zikankyakusuu: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  sousyuunyuu: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  kyuuryou: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  tanaorosi: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  tanaorosi2: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  huryouhin: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  huryouhin2: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  eigyouhi: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  zyunrieki: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  open: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  getuzitorihiki: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  zikoshihon: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
  ararieki: [{ jisseki: 505, zennenhi: "86.8%", zennenzisseki: 581 }],
};

// 個店経営状況（横断）
const createData14 = (
  name: string,
  weather: string,
  temperature: string,
  result: string,
  sales: number,
  salesRateLastYear: number,
  salesRateDiff: number,
  kyakusu: number,
  kyakusuRateLastYear: number,
  kyakusuRateDiff: number,
  unitPrice: number,
  unitPriceRateLastYear: number,
  unitPriceRateDiff: number,
  itemsNum: number,
  itemsNumRateLastYear: number,
  itemsNumRateDiff: number
) => {
  return {
    name,
    weather,
    temperature,
    result,
    sales,
    salesRateLastYear,
    salesRateDiff,
    kyakusu,
    kyakusuRateLastYear,
    kyakusuRateDiff,
    unitPrice,
    unitPriceRateLastYear,
    unitPriceRateDiff,
    itemsNum,
    itemsNumRateLastYear,
    itemsNumRateDiff,
  };
};

export const rows14 = [
  createData14(
    "麹町３丁目",
    "100",
    "28℃ / 15℃",
    "gr",
    873,
    100.8,
    3.4,
    253,
    94.4,
    -2.6,
    620,
    101.2,
    1.2,
    4.1,
    101.2,
    0.2
  ),
  createData14(
    "神田専大通り",
    "202",
    "22℃ / 15℃",
    "yw",
    873,
    97.8,
    2.9,
    253,
    93.1,
    -2.6,
    620,
    102.7,
    -4.2,
    5.1,
    96.2,
    -0.7
  ),
  createData14(
    "神田すずらん通り",
    "101",
    "28℃ / 15℃",
    "gr",
    873,
    104.2,
    2.4,
    253,
    101.4,
    -6.6,
    620,
    103.6,
    1.2,
    6.0,
    111.2,
    3.2
  ),
  createData14(
    "神田錦町三丁目",
    "104",
    "25℃ / 13℃",
    "gr",
    873,
    103.8,
    -2.4,
    253,
    106.4,
    2.6,
    620,
    103.2,
    -1.2,
    5.5,
    112.2,
    6.2
  ),
  createData14(
    "パレスサイドヒル",
    "302",
    "27℃ / 20℃",
    "gr",
    873,
    102.8,
    1.4,
    253,
    107.4,
    3.6,
    620,
    109.2,
    4.2,
    4.9,
    103.2,
    2.2
  ),
  createData14(
    "神田神保町二丁目",
    "313",
    "24℃ / 13℃",
    "rd",
    873,
    90.8,
    4.5,
    253,
    105.4,
    2.9,
    620,
    94.2,
    -3.9,
    4.3,
    100.2,
    0.2
  ),
  createData14(
    "北の丸スクエア",
    "411",
    "27℃ / 30℃",
    "gr",
    873,
    100.8,
    4.4,
    253,
    101.4,
    2.6,
    620,
    101.2,
    1.2,
    4.1,
    101.2,
    1.2
  ),
  createData14(
    "神田西口通",
    "100",
    "28℃ / 15℃",
    "yw",
    873,
    111.8,
    6.4,
    253,
    105.4,
    4.6,
    620,
    107.2,
    2.2,
    5.3,
    104.2,
    3.2
  ),
];

export const date3 = [
  {
    name: "麹町３丁目",
    DO差: 3.4,
  },
  {
    name: "神田専大通り",
    DO差: -2.9,
  },
  {
    name: "神田すずらん通り",
    DO差: 2.4,
  },
  {
    name: "神田錦町三丁目",
    DO差: -2.4,
  },
  {
    name: "パレスサイドヒル",
    DO差: -0.9,
  },
  {
    name: "神田神保町二丁目",
    DO差: 5.5,
  },
  {
    name: "北の丸スクエア",
    DO差: 2.4,
  },
  {
    name: "神田西口通店",
    DO差: 6.4,
  },
];

const createData15 = (
  jan: number,
  feb: number,
  mar: number,
  apr: number,
  may: number,
  jun: number,
  jul: number,
  aug: number,
  sept: number,
  oct: number,
  nov: number,
  dec: number
) => {
  return {
    jan,
    feb,
    mar,
    apr,
    may,
    jun,
    jul,
    aug,
    sept,
    oct,
    nov,
    dec,
  };
};

export const rows15 = [createData15(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12)];

const createData16 = (
  name: string,
  selfStoreLastyearDifference: number,
  regionLastyearDifference: number,
  regionalDifferences: number,
  aaa: number,
  bbb: number,
  ccc: number,
  ddd: number,
  eee: number,
  fff: number,
  ggg: number,
  hhh: number,
  iii: number,
  jjj: number,
  kkk: number,
  lll: number,
  mmm: number,
  nnn: number,
  ooo: number
) => {
  return {
    name,
    selfStoreLastyearDifference,
    regionLastyearDifference,
    regionalDifferences,
    aaa,
    bbb,
    ccc,
    ddd,
    eee,
    fff,
    ggg,
    hhh,
    iii,
    jjj,
    kkk,
    lll,
    mmm,
    nnn,
    ooo,
  };
};

export const rows16 = [
  createData16(
    "タバコ除く売上",
    3196,
    3408,
    -212,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "タバコ",
    1854,
    -2070,
    3924,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "デイリー計",
    1854,
    -2070,
    3924,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "非デイリー計(煙雑誌除く)",
    5331,
    8602,
    -3271,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "酒類",
    3641,
    2314,
    1327,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "オープンケース",
    3641,
    2314,
    1327,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "カウンターFF",
    3641,
    2314,
    1327,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "パン・ペストリー",
    3641,
    2314,
    1327,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "菓子",
    -4483,
    -5635,
    -1152,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "加工品類",
    1224,
    -3953,
    -2894,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "フローズン",
    5091,
    7985,
    -2894,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "ソフドリ・栄ドリ",
    5091,
    7985,
    -2894,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "雑誌・新聞紙・ｺﾐｯｸ類",
    -455,
    -376,
    -79,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "雑貨",
    5091,
    7985,
    -2894,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData16(
    "エシカル対象分類計",
    854,
    -1270,
    2124,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
];

const createData17 = (
  name: string,
  selfStoreLastyearDifference: number,
  regionLastyearDifference: number,
  regionalDifferences: number,
  aaa: number,
  bbb: number,
  ccc: number,
  ddd: number,
  eee: number,
  fff: number,
  ggg: number,
  hhh: number,
  iii: number,
  jjj: number,
  kkk: number,
  lll: number,
  mmm: number,
  nnn: number,
  ooo: number
) => {
  return {
    name,
    selfStoreLastyearDifference,
    regionLastyearDifference,
    regionalDifferences,
    aaa,
    bbb,
    ccc,
    ddd,
    eee,
    fff,
    ggg,
    hhh,
    iii,
    jjj,
    kkk,
    lll,
    mmm,
    nnn,
    ooo,
  };
};

export const rows17 = [
  createData17(
    "ＰＭＡ合計",
    6258,
    7707,
    -1449,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData17(
    "ビール",
    -3033,
    358,
    -3391,
    200,
    200,
    200,
    200,
    200,
    200,
    200,
    200,
    200,
    200,
    200,
    200,
    200,
    200,
    200
  ),
  createData17(
    "日本酒",
    1463,
    155,
    1308,
    220,
    220,
    220,
    220,
    220,
    220,
    220,
    220,
    220,
    220,
    220,
    220,
    220,
    220,
    220
  ),
  createData17(
    "洋酒・ワイン・雑酒",
    5211,
    1801,
    3410,
    240,
    240,
    240,
    240,
    240,
    240,
    240,
    240,
    240,
    240,
    240,
    240,
    240,
    240,
    240
  ),
  createData17(
    "米飯",
    1074,
    1277,
    -203,
    260,
    260,
    260,
    260,
    260,
    260,
    260,
    260,
    260,
    260,
    260,
    260,
    260,
    260,
    260
  ),
  createData17(
    "調理パン",
    -1384,
    3502,
    -4886,
    280,
    280,
    280,
    280,
    280,
    280,
    280,
    280,
    280,
    280,
    280,
    280,
    280,
    280,
    280
  ),
  createData17(
    "麺類・その他",
    -11312,
    -17364,
    6052,
    300,
    300,
    300,
    300,
    300,
    300,
    300,
    300,
    300,
    300,
    300,
    300,
    300,
    300,
    300
  ),
  createData17(
    "デリカテッセン",
    1712,
    526,
    1186,
    320,
    320,
    320,
    320,
    320,
    320,
    320,
    320,
    320,
    320,
    320,
    320,
    320,
    320,
    320
  ),
  createData17(
    "スイーツ",
    -3948,
    539,
    -4487,
    340,
    340,
    340,
    340,
    340,
    340,
    340,
    340,
    340,
    340,
    340,
    340,
    340,
    340,
    340
  ),
  createData17(
    "ＦＦおでん",
    0,
    0,
    0,
    360,
    360,
    360,
    360,
    360,
    360,
    360,
    360,
    360,
    360,
    360,
    360,
    360,
    360,
    360
  ),
  createData17(
    "ＦＦカップ",
    -2700,
    2620,
    -5320,
    380,
    380,
    380,
    380,
    380,
    380,
    380,
    380,
    380,
    380,
    380,
    380,
    380,
    380,
    380
  ),
  createData17(
    "カウントＦＦ",
    7176,
    2363,
    4813,
    400,
    400,
    400,
    400,
    400,
    400,
    400,
    400,
    400,
    400,
    400,
    400,
    400,
    400,
    400
  ),
  createData17(
    "ＦＦフライヤーその他",
    -523,
    3730,
    -4253,
    420,
    420,
    420,
    420,
    420,
    420,
    420,
    420,
    420,
    420,
    420,
    420,
    420,
    420,
    420
  ),
  createData17(
    "牛乳・乳飲料",
    1867,
    765,
    1102,
    440,
    440,
    440,
    440,
    440,
    440,
    440,
    440,
    440,
    440,
    440,
    440,
    440,
    440,
    440
  ),
  createData17(
    "乳製品",
    -1675,
    170,
    -1845,
    460,
    460,
    460,
    460,
    460,
    460,
    460,
    460,
    460,
    460,
    460,
    460,
    460,
    460,
    460
  ),
  createData17(
    "玉子",
    378,
    122,
    256,
    480,
    480,
    480,
    480,
    480,
    480,
    480,
    480,
    480,
    480,
    480,
    480,
    480,
    480,
    480
  ),
  createData17(
    "生活デイリー",
    -117,
    1801,
    -1918,
    500,
    500,
    500,
    500,
    500,
    500,
    500,
    500,
    500,
    500,
    500,
    500,
    500,
    500,
    500
  ),
  createData17(
    "パン",
    3168,
    1681,
    1487,
    520,
    520,
    520,
    520,
    520,
    520,
    520,
    520,
    520,
    520,
    520,
    520,
    520,
    520,
    520
  ),
  createData17(
    "ペストリー",
    8138,
    -3802,
    11940,
    540,
    540,
    540,
    540,
    540,
    540,
    540,
    540,
    540,
    540,
    540,
    540,
    540,
    540,
    540
  ),
  createData17(
    "和風菓子・豆菓子",
    -6561,
    -8781,
    2220,
    560,
    560,
    560,
    560,
    560,
    560,
    560,
    560,
    560,
    560,
    560,
    560,
    560,
    560,
    560
  ),
  createData17(
    "ポケット・洋風菓子",
    -3645,
    1355,
    -5000,
    580,
    580,
    580,
    580,
    580,
    580,
    580,
    580,
    580,
    580,
    580,
    580,
    580,
    580,
    580
  ),
  createData17(
    "スナック菓子",
    5723,
    1791,
    3932,
    600,
    600,
    600,
    600,
    600,
    600,
    600,
    600,
    600,
    600,
    600,
    600,
    600,
    600,
    600
  ),
  createData17(
    "加工食品",
    10861,
    -4678,
    15539,
    620,
    620,
    620,
    620,
    620,
    620,
    620,
    620,
    620,
    620,
    620,
    620,
    620,
    620,
    620
  ),
  createData17(
    "調味料・乾物",
    -216,
    57,
    -273,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640
  ),
  createData17(
    "米穀",
    0,
    0,
    0,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640,
    640
  ),
  createData17(
    "し好品",
    -349,
    155,
    -504,
    660,
    660,
    660,
    660,
    660,
    660,
    660,
    660,
    660,
    660,
    660,
    660,
    660,
    660,
    660
  ),
  createData17(
    "珍味・缶詰",
    4509,
    809,
    3700,
    680,
    680,
    680,
    680,
    680,
    680,
    680,
    680,
    680,
    680,
    680,
    680,
    680,
    680,
    680
  ),
  createData17(
    "冷凍食品",
    -174,
    498,
    -672,
    700,
    700,
    700,
    700,
    700,
    700,
    700,
    700,
    700,
    700,
    700,
    700,
    700,
    700,
    700
  ),
  createData17(
    "アイスクリーム",
    -9441,
    -2265,
    -7176,
    720,
    720,
    720,
    720,
    720,
    720,
    720,
    720,
    720,
    720,
    720,
    720,
    720,
    720,
    720
  ),
  createData17(
    "ソフトドリンク",
    -9655,
    6974,
    -16629,
    740,
    740,
    740,
    740,
    740,
    740,
    740,
    740,
    740,
    740,
    740,
    740,
    740,
    740,
    740
  ),
  createData17(
    "栄養ドリンク",
    1894,
    1835,
    59,
    760,
    760,
    760,
    760,
    760,
    760,
    760,
    760,
    760,
    760,
    760,
    760,
    760,
    760,
    760
  ),
  createData17(
    "健康栄養食品",
    3795,
    568,
    3227,
    780,
    780,
    780,
    780,
    780,
    780,
    780,
    780,
    780,
    780,
    780,
    780,
    780,
    780,
    780
  ),
  createData17(
    "催事・進物ギフト",
    -142,
    -15,
    -127,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    800
  ),
  createData17(
    "くらしのギフト",
    0,
    0,
    0,
    810,
    810,
    810,
    810,
    810,
    810,
    810,
    810,
    810,
    810,
    810,
    810,
    810,
    810,
    810
  ),
  createData17(
    "ネット（出版その他）",
    0,
    0,
    0,
    820,
    820,
    820,
    820,
    820,
    820,
    820,
    820,
    820,
    820,
    820,
    820,
    820,
    820,
    820
  ),
  createData17(
    "お中元ギフト",
    -3081,
    -2003,
    -1078,
    830,
    830,
    830,
    830,
    830,
    830,
    830,
    830,
    830,
    830,
    830,
    830,
    830,
    830,
    840
  ),
  createData17(
    "お歳暮ギフト",
    0,
    0,
    0,
    840,
    840,
    840,
    840,
    840,
    840,
    840,
    840,
    840,
    840,
    840,
    840,
    840,
    840,
    840
  ),
  createData17(
    "文庫・書籍",
    42,
    10,
    32,
    850,
    850,
    850,
    850,
    850,
    850,
    850,
    850,
    850,
    850,
    850,
    850,
    850,
    850,
    850
  ),
  createData17(
    "タバコ",
    3989,
    3124,
    865,
    860,
    860,
    860,
    860,
    860,
    860,
    860,
    860,
    860,
    860,
    860,
    860,
    860,
    860,
    860
  ),
  createData17(
    "雑誌",
    284,
    -302,
    586,
    870,
    870,
    870,
    870,
    870,
    870,
    870,
    870,
    870,
    870,
    870,
    870,
    870,
    870,
    870
  ),
  createData17(
    "新聞",
    -545,
    0,
    -545,
    880,
    880,
    880,
    880,
    880,
    880,
    880,
    880,
    880,
    880,
    880,
    880,
    880,
    880,
    880
  ),
  createData17(
    "コミックス",
    -236,
    -84,
    -152,
    890,
    890,
    890,
    890,
    890,
    890,
    890,
    890,
    890,
    890,
    890,
    890,
    890,
    890,
    890
  ),
  createData17(
    "映像・音楽ソフト",
    0,
    0,
    0,
    900,
    900,
    900,
    900,
    900,
    900,
    900,
    900,
    900,
    900,
    900,
    900,
    900,
    900,
    900
  ),
  createData17(
    "軽衣料",
    -37,
    87,
    -124,
    910,
    910,
    910,
    910,
    910,
    910,
    910,
    910,
    910,
    910,
    910,
    910,
    910,
    910,
    910
  ),
  createData17(
    "健康・医療品",
    4363,
    -1198,
    5561,
    920,
    920,
    920,
    920,
    920,
    920,
    920,
    920,
    920,
    920,
    920,
    920,
    920,
    920,
    920
  ),
  createData17(
    "紙・生理用品",
    1906,
    600,
    1306,
    930,
    930,
    930,
    930,
    930,
    930,
    930,
    930,
    930,
    930,
    930,
    930,
    930,
    930,
    930
  ),
  createData17(
    "オーラル・シャンプー",
    -176,
    419,
    -595,
    940,
    940,
    940,
    940,
    940,
    940,
    940,
    940,
    940,
    940,
    940,
    940,
    940,
    940,
    940
  ),
  createData17(
    "洗剤・防虫・芳香",
    763,
    49,
    714,
    950,
    950,
    950,
    950,
    950,
    950,
    950,
    950,
    950,
    950,
    950,
    950,
    950,
    950,
    950
  ),
  createData17(
    "生活雑貨・行楽用品",
    -2998,
    220,
    -3218,
    960,
    960,
    960,
    960,
    960,
    960,
    960,
    960,
    960,
    960,
    960,
    960,
    960,
    960,
    960
  ),
  createData17(
    "化粧品",
    -852,
    682,
    -1534,
    970,
    970,
    970,
    970,
    970,
    970,
    970,
    970,
    970,
    970,
    970,
    970,
    970,
    970,
    970
  ),
  createData17(
    "玩具",
    -8260,
    -2010,
    -6250,
    980,
    980,
    980,
    980,
    980,
    980,
    980,
    980,
    980,
    980,
    980,
    980,
    980,
    980,
    980
  ),
  createData17(
    "文具",
    -266,
    204,
    -470,
    90,
    990,
    990,
    990,
    990,
    990,
    990,
    990,
    990,
    990,
    990,
    990,
    990,
    990,
    990
  ),
  createData17(
    "その他雑貨",
    10648,
    8932,
    1716,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData17(
    "切手葉書印紙",
    -157,
    295,
    -452,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData17(
    "サービス商品",
    1915,
    285,
    1630,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData17(
    "委託商品",
    -3342,
    -145,
    -3197,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
  createData17(
    "その他売り上げ",
    204,
    -5,
    209,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100,
    100
  ),
];

const createData18 = (
  name: string,
  sales_average: number,
  do_sales_average: number,
  sales_average_do_diff: number,
  sales_average_yoy: number,
  do_sales_average_yoy: number,
  sales_average_yoy_do_diff: number,
  sales_average_yearly_diff: number,
  do_sales_average_yearly_diff: number,
  sales_average_yearly_diff_do_diff: number,
  sales_average_class_rate: number,
  do_sales_average_class_rate: number,
  sales_average_class_rate_do_diff: number,
  sales_average_usage_rate: number,
  do_sales_average_usage_rate: number,
  sales_average_usage_rate_do_diff: number
) => {
  return {
    name,
    sales_average,
    do_sales_average,
    sales_average_do_diff,
    sales_average_yoy,
    do_sales_average_yoy,
    sales_average_yoy_do_diff,
    sales_average_yearly_diff,
    do_sales_average_yearly_diff,
    sales_average_yearly_diff_do_diff,
    sales_average_class_rate,
    do_sales_average_class_rate,
    sales_average_class_rate_do_diff,
    sales_average_usage_rate,
    do_sales_average_usage_rate,
    sales_average_usage_rate_do_diff,
  };
};

export const rows18 = [
  createData18(
    "瓶・樽ビール",
    698,
    395,
    53,
    822,
    941,
    506,
    187,
    229,
    421,
    867,
    741,
    350,
    929,
    135,
    150
  ),
  createData18(
    "缶ビール",
    504,
    61,
    603,
    776,
    938,
    598,
    977,
    12,
    345,
    654,
    587,
    62,
    813,
    592,
    150
  ),
  createData18(
    "発泡酒",
    919,
    579,
    865,
    192,
    571,
    247,
    838,
    942,
    133,
    986,
    269,
    80,
    888,
    194,
    150
  ),
  createData18(
    "進物・宅配ギフト（ビール）",
    957,
    70,
    362,
    12,
    590,
    182,
    556,
    770,
    861,
    792,
    563,
    361,
    573,
    337,
    150
  ),
  createData18(
    "ビールクーポン・その他",
    356,
    12,
    68,
    640,
    983,
    75,
    436,
    137,
    917,
    211,
    821,
    720,
    707,
    724,
    150
  ),
  createData18(
    "ノンアルコールビール",
    57,
    568,
    431,
    627,
    562,
    909,
    857,
    189,
    760,
    719,
    485,
    20,
    281,
    114,
    150
  ),
  createData18(
    "第３のビール",
    728,
    984,
    26,
    261,
    199,
    475,
    66,
    192,
    952,
    428,
    380,
    423,
    29,
    164,
    150
  ),
  createData18(
    "宅配料（ビール）",
    40,
    145,
    807,
    673,
    712,
    387,
    805,
    581,
    39,
    984,
    884,
    923,
    324,
    627,
    150
  ),
  createData18(
    "包装料（ビール）",
    314,
    811,
    363,
    476,
    839,
    794,
    402,
    835,
    893,
    720,
    996,
    618,
    139,
    143,
    150
  ),
  createData18(
    "留置きギフト（ビール）",
    406,
    398,
    350,
    891,
    525,
    168,
    766,
    233,
    518,
    459,
    947,
    41,
    673,
    251,
    150
  ),
  createData18(
    "ビール（ＳＩＰ）",
    102,
    686,
    276,
    473,
    818,
    974,
    351,
    929,
    773,
    537,
    784,
    556,
    67,
    863,
    150
  ),
  createData18(
    "ビール（ＳＭＳ）",
    316,
    583,
    512,
    851,
    89,
    370,
    148,
    619,
    404,
    716,
    74,
    196,
    189,
    158,
    150
  ),
  createData18(
    "清酒小容量",
    408,
    824,
    579,
    443,
    466,
    810,
    188,
    528,
    439,
    895,
    366,
    580,
    637,
    865,
    150
  ),
  createData18(
    "清酒瓶",
    179,
    593,
    49,
    355,
    115,
    38,
    849,
    904,
    447,
    281,
    558,
    927,
    88,
    396,
    150
  ),
  createData18(
    "清酒瓶",
    675,
    378,
    189,
    860,
    400,
    901,
    441,
    731,
    155,
    214,
    796,
    943,
    385,
    298,
    150
  ),
  createData18(
    "清酒パック",
    209,
    273,
    815,
    91,
    20,
    609,
    114,
    506,
    884,
    628,
    811,
    803,
    599,
    743,
    150
  ),
  createData18(
    "ＰＭＡ合計",
    286,
    851,
    357,
    746,
    996,
    238,
    48,
    109,
    906,
    113,
    207,
    871,
    60,
    809,
    150
  ),
  createData18(
    "清酒リーチイン",
    252,
    598,
    820,
    838,
    394,
    329,
    586,
    828,
    602,
    100,
    866,
    866,
    766,
    228,
    150
  ),
  createData18(
    "焼酎甲類",
    623,
    70,
    980,
    635,
    857,
    947,
    286,
    664,
    984,
    797,
    777,
    604,
    586,
    846,
    150
  ),
  createData18(
    "焼酎乙類",
    769,
    868,
    251,
    387,
    135,
    995,
    849,
    788,
    368,
    474,
    660,
    815,
    89,
    984,
    150
  ),
  createData18(
    "合成・みりん・その他",
    742,
    673,
    843,
    726,
    926,
    308,
    931,
    91,
    460,
    752,
    560,
    675,
    234,
    908,
    150
  ),
  createData18(
    "進物・宅配ギフト（日本酒）",
    346,
    68,
    823,
    630,
    78,
    731,
    567,
    596,
    128,
    176,
    970,
    61,
    820,
    710,
    150
  ),
  createData18(
    "販売用酒クーポン券",
    479,
    567,
    107,
    33,
    469,
    712,
    429,
    902,
    775,
    279,
    619,
    750,
    15,
    163,
    150
  ),
  createData18(
    "宅配料（日本酒）",
    696,
    696,
    175,
    607,
    948,
    797,
    153,
    440,
    29,
    614,
    996,
    783,
    56,
    89,
    150
  ),
  createData18(
    "包装料（日本酒）",
    664,
    548,
    621,
    230,
    964,
    971,
    963,
    780,
    977,
    194,
    746,
    716,
    416,
    888,
    150
  ),
  createData18(
    "留置きギフト（日本酒）",
    218,
    396,
    550,
    535,
    760,
    612,
    24,
    435,
    794,
    343,
    230,
    233,
    677,
    192,
    150
  ),
  createData18(
    "日本酒（ＳＩＰ）",
    595,
    686,
    506,
    54,
    575,
    53,
    61,
    112,
    60,
    435,
    703,
    567,
    654,
    734,
    150
  ),
  createData18(
    "日本酒（ＳＭＳ）",
    605,
    542,
    775,
    321,
    317,
    611,
    654,
    917,
    792,
    717,
    310,
    432,
    274,
    221,
    150
  ),
  createData18(
    "輸入洋酒",
    489,
    77,
    136,
    176,
    243,
    877,
    981,
    385,
    539,
    190,
    185,
    977,
    383,
    336,
    150
  ),
  createData18(
    "国産果実酒",
    654,
    51,
    753,
    584,
    888,
    502,
    636,
    870,
    218,
    224,
    13,
    265,
    65,
    705,
    150
  ),
  createData18(
    "輸入果実酒（オリジナル）",
    368,
    198,
    765,
    680,
    569,
    119,
    116,
    975,
    904,
    159,
    725,
    830,
    373,
    427,
    150
  ),
  createData18(
    "輸入果実酒（ＮＢ）",
    322,
    749,
    383,
    526,
    74,
    222,
    868,
    596,
    155,
    226,
    931,
    900,
    33,
    32,
    150
  ),
  createData18(
    "小容量果実酒（リーチイン）",
    342,
    992,
    206,
    925,
    957,
    762,
    743,
    905,
    235,
    677,
    226,
    703,
    835,
    287,
    150
  ),
  createData18(
    "リキュール（ゴンドラ）",
    512,
    514,
    98,
    577,
    471,
    930,
    609,
    985,
    371,
    295,
    760,
    985,
    810,
    503,
    150
  ),
  createData18(
    "リキュール（リーチイン）",
    369,
    853,
    687,
    735,
    651,
    426,
    492,
    191,
    385,
    993,
    311,
    882,
    96,
    301,
    150
  ),
  createData18(
    "ノンアルコール飲料",
    342,
    619,
    101,
    265,
    300,
    978,
    221,
    838,
    244,
    542,
    865,
    101,
    446,
    11,
    150
  ),
  createData18(
    "進物・宅配ギフト（洋酒）",
    708,
    98,
    442,
    807,
    895,
    768,
    171,
    525,
    163,
    864,
    102,
    975,
    455,
    885,
    150
  ),
  createData18(
    "雑酒",
    929,
    68,
    200,
    619,
    762,
    176,
    826,
    744,
    276,
    511,
    913,
    574,
    189,
    489,
    150
  ),
  createData18(
    "赤ワイン",
    764,
    735,
    142,
    848,
    857,
    914,
    757,
    136,
    601,
    350,
    469,
    665,
    843,
    95,
    150
  ),
  createData18(
    "白ワイン他",
    958,
    407,
    120,
    214,
    261,
    731,
    721,
    330,
    503,
    801,
    555,
    50,
    845,
    225,
    150
  ),
  createData18(
    "洋酒（ゴンドラ）",
    89,
    438,
    768,
    636,
    327,
    391,
    453,
    677,
    200,
    346,
    749,
    113,
    141,
    319,
    150
  ),
  createData18(
    "留置きギフト（洋酒）",
    656,
    666,
    824,
    538,
    200,
    467,
    497,
    449,
    141,
    61,
    471,
    408,
    869,
    678,
    150
  ),
  createData18(
    "洋酒（リーチイン）",
    167,
    497,
    296,
    475,
    709,
    516,
    776,
    283,
    956,
    553,
    999,
    282,
    412,
    368,
    150
  ),
  createData18(
    "宅配料（洋酒）",
    600,
    186,
    175,
    482,
    383,
    779,
    153,
    814,
    129,
    705,
    659,
    33,
    43,
    176,
    150
  ),
  createData18(
    "包装料（洋酒）",
    52,
    930,
    781,
    398,
    404,
    158,
    836,
    947,
    570,
    540,
    912,
    523,
    651,
    582,
    150
  ),
  createData18(
    "洋酒・ワイン・雑酒（ＳＩＰ）",
    393,
    50,
    99,
    229,
    167,
    327,
    128,
    263,
    890,
    789,
    675,
    50,
    391,
    478,
    150
  ),
  createData18(
    "洋酒・ワイン・雑酒（ＳＭＳ）",
    29,
    455,
    355,
    277,
    326,
    626,
    907,
    717,
    146,
    55,
    156,
    59,
    350,
    365,
    150
  ),
  createData18(
    "国産洋酒",
    798,
    775,
    674,
    201,
    441,
    921,
    134,
    430,
    793,
    675,
    317,
    20,
    572,
    563,
    150
  ),
  createData18(
    "おにぎり",
    329,
    967,
    85,
    70,
    399,
    544,
    809,
    663,
    795,
    220,
    569,
    821,
    291,
    409,
    150
  ),
  createData18(
    "こだわりおむすび",
    779,
    216,
    433,
    67,
    77,
    447,
    369,
    655,
    641,
    283,
    95,
    227,
    118,
    739,
    150
  ),
  createData18(
    "弁当",
    948,
    414,
    366,
    64,
    638,
    65,
    920,
    78,
    624,
    795,
    470,
    361,
    752,
    569,
    150
  ),
  createData18(
    "寿司",
    881,
    915,
    910,
    444,
    757,
    41,
    750,
    159,
    654,
    441,
    472,
    297,
    647,
    168,
    150
  ),
  createData18(
    "御飯",
    412,
    739,
    420,
    325,
    972,
    79,
    743,
    892,
    110,
    344,
    115,
    868,
    697,
    871,
    150
  ),
  createData18(
    "チルド弁当",
    528,
    491,
    176,
    169,
    920,
    515,
    366,
    158,
    132,
    656,
    173,
    347,
    836,
    914,
    150
  ),
  createData18(
    "予約弁当",
    603,
    722,
    752,
    999,
    232,
    149,
    415,
    761,
    144,
    807,
    737,
    859,
    627,
    158,
    150
  ),
  createData18(
    "米飯（ＳＩＰ）",
    414,
    415,
    774,
    996,
    780,
    83,
    713,
    495,
    965,
    812,
    858,
    862,
    638,
    557,
    150
  ),
  createData18(
    "米飯（ＳＭＳ）",
    218,
    997,
    609,
    828,
    31,
    384,
    549,
    148,
    991,
    390,
    711,
    657,
    383,
    796,
    150
  ),
  createData18(
    "消耗品（箸・フォーク等）",
    995,
    115,
    132,
    844,
    661,
    352,
    870,
    817,
    980,
    812,
    852,
    341,
    984,
    224,
    150
  ),
  createData18(
    "サンドイッチ",
    412,
    948,
    382,
    368,
    743,
    317,
    973,
    658,
    892,
    117,
    99,
    824,
    410,
    899,
    150
  ),
  createData18(
    "ロール",
    529,
    756,
    789,
    788,
    534,
    763,
    673,
    368,
    294,
    385,
    876,
    447,
    907,
    847,
    150
  ),
  createData18(
    "ブリトー・その他",
    858,
    657,
    276,
    941,
    874,
    752,
    867,
    29,
    621,
    19,
    963,
    588,
    600,
    207,
    150
  ),
  createData18(
    "調理パン（ＳＩＰ）",
    593,
    674,
    910,
    698,
    932,
    233,
    564,
    418,
    184,
    593,
    484,
    374,
    900,
    434,
    150
  ),
  createData18(
    "調理パン（ＳＭＳ）",
    505,
    988,
    450,
    533,
    808,
    769,
    455,
    925,
    574,
    817,
    158,
    47,
    307,
    714,
    150
  ),
  createData18(
    "調理麺",
    763,
    440,
    503,
    825,
    189,
    973,
    214,
    333,
    326,
    171,
    903,
    972,
    478,
    946,
    150
  ),
  createData18(
    "麺鍋",
    421,
    428,
    256,
    424,
    976,
    675,
    420,
    359,
    528,
    415,
    552,
    335,
    818,
    726,
    150
  ),
  createData18(
    "カップ麺",
    656,
    806,
    536,
    316,
    208,
    992,
    236,
    576,
    119,
    688,
    531,
    873,
    765,
    946,
    150
  ),
  createData18(
    "スパゲティ・パスタ",
    481,
    136,
    650,
    66,
    311,
    886,
    112,
    857,
    808,
    401,
    55,
    248,
    770,
    581,
    150
  ),
  createData18(
    "グラタン・ドリア類",
    312,
    684,
    326,
    451,
    529,
    316,
    445,
    79,
    516,
    265,
    268,
    89,
    629,
    110,
    150
  ),
  createData18(
    "うどん・焼そば",
    568,
    364,
    955,
    961,
    591,
    234,
    604,
    245,
    872,
    718,
    545,
    695,
    834,
    158,
    150
  ),
  createData18(
    "麺類・その他（ＳＩＰ）",
    997,
    506,
    968,
    715,
    774,
    313,
    12,
    620,
    231,
    201,
    29,
    422,
    69,
    507,
    150
  ),
  createData18(
    "麺類・その他（ＳＭＳ）",
    866,
    568,
    119,
    572,
    567,
    395,
    504,
    808,
    767,
    173,
    927,
    381,
    688,
    179,
    150
  ),
  createData18(
    "その他",
    920,
    806,
    19,
    269,
    574,
    283,
    816,
    839,
    999,
    789,
    782,
    869,
    77,
    661,
    150
  ),
  createData18(
    "サラダ（旧）",
    283,
    27,
    337,
    352,
    835,
    651,
    55,
    599,
    98,
    652,
    787,
    225,
    362,
    916,
    150
  ),
  createData18(
    "ドレッシング",
    734,
    542,
    376,
    857,
    820,
    481,
    115,
    74,
    306,
    294,
    995,
    744,
    298,
    720,
    150
  ),
  createData18(
    "惣菜",
    55,
    619,
    991,
    202,
    779,
    904,
    286,
    322,
    780,
    214,
    14,
    911,
    985,
    13,
    150
  ),
  createData18(
    "７プレミアム（デリカ）",
    943,
    985,
    469,
    948,
    114,
    538,
    528,
    480,
    920,
    396,
    919,
    604,
    537,
    494,
    150
  ),
  createData18(
    "７プレミアム（副菜）",
    222,
    40,
    723,
    976,
    292,
    293,
    270,
    323,
    532,
    745,
    848,
    658,
    178,
    421,
    150
  ),
  createData18(
    "７プレミアム（その他）",
    867,
    622,
    505,
    470,
    83,
    387,
    888,
    442,
    152,
    753,
    531,
    186,
    252,
    428,
    150
  ),
  createData18(
    "煮物・豆類",
    16,
    486,
    592,
    322,
    575,
    215,
    899,
    601,
    123,
    457,
    335,
    496,
    111,
    479,
    150
  ),
  createData18(
    "漬物",
    286,
    922,
    669,
    851,
    194,
    25,
    940,
    115,
    233,
    180,
    395,
    399,
    15,
    656,
    150
  ),
  createData18(
    "サラダ",
    842,
    47,
    805,
    709,
    824,
    825,
    473,
    50,
    853,
    985,
    187,
    732,
    502,
    955,
    150
  ),
  createData18(
    "食事サラダ",
    555,
    916,
    913,
    119,
    783,
    414,
    585,
    148,
    844,
    237,
    552,
    946,
    89,
    134,
    150
  ),
  createData18(
    "７プレミアム（主菜）",
    125,
    730,
    620,
    669,
    689,
    856,
    357,
    509,
    773,
    726,
    827,
    731,
    813,
    920,
    150
  ),
  createData18(
    "鍋惣菜",
    734,
    960,
    589,
    270,
    791,
    760,
    737,
    202,
    743,
    254,
    76,
    147,
    385,
    429,
    150
  ),
  createData18(
    "練物",
    353,
    325,
    729,
    296,
    642,
    184,
    556,
    652,
    32,
    486,
    42,
    84,
    347,
    285,
    150
  ),
  createData18(
    "水物",
    589,
    624,
    168,
    422,
    364,
    560,
    871,
    331,
    554,
    650,
    691,
    548,
    363,
    262,
    150
  ),
  createData18(
    "麺類",
    947,
    717,
    42,
    262,
    431,
    292,
    640,
    958,
    706,
    733,
    333,
    654,
    74,
    989,
    150
  ),
  createData18(
    "その他チルド商品",
    876,
    291,
    837,
    240,
    618,
    339,
    495,
    824,
    381,
    979,
    787,
    760,
    928,
    974,
    150
  ),
  createData18(
    "催事商品",
    996,
    576,
    359,
    337,
    600,
    685,
    419,
    577,
    488,
    167,
    175,
    291,
    609,
    178,
    150
  ),
  createData18(
    "デリカテッセン（ＳＩＰ）",
    324,
    500,
    163,
    443,
    504,
    459,
    799,
    691,
    731,
    725,
    200,
    994,
    875,
    729,
    150
  ),
  createData18(
    "デリカテッセン（ＳＭＳ）",
    807,
    139,
    905,
    502,
    845,
    339,
    474,
    454,
    580,
    768,
    807,
    731,
    949,
    481,
    150
  ),
  createData18(
    "消耗品",
    493,
    396,
    208,
    290,
    29,
    513,
    263,
    360,
    408,
    263,
    647,
    269,
    969,
    445,
    150
  ),
  createData18(
    "チルド洋菓子",
    686,
    500,
    859,
    170,
    534,
    278,
    951,
    185,
    501,
    219,
    560,
    395,
    272,
    865,
    150
  ),
  createData18(
    "チルド和菓子",
    721,
    996,
    633,
    598,
    234,
    259,
    294,
    367,
    396,
    150,
    83,
    565,
    340,
    255,
    150
  ),
  createData18(
    "チルド洋菓子（ヤマザキ・他）",
    528,
    312,
    940,
    380,
    93,
    737,
    62,
    527,
    383,
    246,
    92,
    656,
    756,
    798,
    150
  ),
  createData18(
    "チルド和菓子（ヤマザキ・他）",
    950,
    915,
    53,
    272,
    446,
    762,
    972,
    49,
    635,
    620,
    99,
    635,
    237,
    831,
    150
  ),
  createData18(
    "ヨーグルト",
    846,
    849,
    238,
    867,
    540,
    547,
    895,
    829,
    917,
    824,
    102,
    28,
    603,
    607,
    150
  ),
  createData18(
    "催事（クリスマス等）",
    22,
    812,
    913,
    803,
    925,
    622,
    847,
    257,
    392,
    850,
    996,
    135,
    133,
    547,
    150
  ),
  createData18(
    "和菓子（デイリー）",
    125,
    284,
    211,
    322,
    950,
    274,
    697,
    274,
    455,
    586,
    902,
    264,
    879,
    966,
    150
  ),
  createData18(
    "プリン・ゼリー他",
    370,
    610,
    493,
    628,
    952,
    142,
    296,
    733,
    681,
    959,
    312,
    835,
    429,
    884,
    150
  ),
  createData18(
    "常温スイーツ",
    153,
    445,
    701,
    675,
    543,
    16,
    709,
    473,
    777,
    606,
    53,
    483,
    240,
    790,
    150
  ),
  createData18(
    "スイーツ（ＳＩＰ）",
    718,
    412,
    751,
    434,
    350,
    664,
    422,
    822,
    666,
    525,
    891,
    948,
    217,
    636,
    150
  ),
  createData18(
    "スイーツ（ＳＭＳ）",
    14,
    619,
    424,
    33,
    195,
    541,
    645,
    230,
    135,
    800,
    780,
    749,
    921,
    666,
    150
  ),
  createData18(
    "消耗品（スプーン等）",
    525,
    323,
    300,
    265,
    40,
    898,
    487,
    539,
    587,
    378,
    664,
    388,
    581,
    383,
    150
  ),
  createData18(
    "おでん",
    190,
    30,
    106,
    374,
    79,
    391,
    160,
    386,
    328,
    123,
    235,
    392,
    401,
    611,
    150
  ),
  createData18(
    "ＦＦおでん（ＳＩＰ）",
    384,
    990,
    772,
    728,
    423,
    646,
    328,
    311,
    198,
    545,
    880,
    500,
    716,
    489,
    150
  ),
  createData18(
    "おでん消耗品",
    865,
    206,
    344,
    324,
    62,
    105,
    269,
    209,
    377,
    971,
    166,
    580,
    969,
    423,
    150
  ),
  createData18(
    "スラーピー",
    727,
    615,
    978,
    680,
    641,
    316,
    443,
    411,
    87,
    151,
    697,
    479,
    201,
    659,
    150
  ),
  createData18(
    "ソフトアイス",
    102,
    729,
    331,
    986,
    71,
    810,
    348,
    98,
    849,
    819,
    690,
    637,
    704,
    423,
    150
  ),
  createData18(
    "コーヒー",
    645,
    447,
    133,
    94,
    724,
    786,
    479,
    271,
    806,
    735,
    185,
    355,
    833,
    342,
    150
  ),
  createData18(
    "スムージー",
    231,
    428,
    980,
    188,
    751,
    493,
    929,
    773,
    168,
    575,
    568,
    462,
    57,
    727,
    150
  ),
  createData18(
    "ＦＦカップ（ＳＩＰ）",
    947,
    74,
    612,
    815,
    133,
    112,
    31,
    380,
    174,
    979,
    453,
    240,
    699,
    651,
    150
  ),
  createData18(
    "消耗品",
    531,
    563,
    566,
    988,
    673,
    245,
    668,
    275,
    272,
    409,
    780,
    750,
    988,
    662,
    150
  ),
  createData18(
    "カウントＦＦ",
    395,
    286,
    810,
    299,
    759,
    198,
    905,
    485,
    280,
    958,
    658,
    393,
    377,
    578,
    150
  ),
  createData18(
    "７カフェ",
    704,
    542,
    602,
    568,
    73,
    498,
    779,
    201,
    519,
    642,
    113,
    653,
    627,
    445,
    150
  ),
  createData18(
    "スラーピー",
    536,
    638,
    718,
    700,
    669,
    253,
    97,
    955,
    277,
    263,
    347,
    671,
    936,
    800,
    150
  ),
  createData18(
    "カウンタースイーツ",
    225,
    564,
    824,
    430,
    187,
    679,
    581,
    597,
    741,
    868,
    956,
    18,
    541,
    22,
    150
  ),
  createData18(
    "カウントＦＦ（ＳＩＰ）",
    835,
    387,
    758,
    265,
    601,
    719,
    585,
    213,
    31,
    26,
    224,
    852,
    325,
    364,
    150
  ),
  createData18(
    "その他",
    699,
    398,
    707,
    163,
    380,
    332,
    570,
    746,
    132,
    398,
    179,
    116,
    103,
    472,
    150
  ),
  createData18(
    "中華まん",
    967,
    557,
    923,
    454,
    609,
    767,
    718,
    559,
    692,
    490,
    632,
    195,
    512,
    841,
    150
  ),
  createData18(
    "フランク",
    659,
    20,
    491,
    607,
    149,
    819,
    885,
    594,
    710,
    715,
    610,
    491,
    971,
    632,
    150
  ),
  createData18(
    "フライヤー",
    128,
    659,
    792,
    521,
    878,
    437,
    861,
    79,
    518,
    374,
    281,
    762,
    681,
    606,
    150
  ),
  createData18(
    "店内調理",
    986,
    607,
    348,
    603,
    49,
    154,
    248,
    714,
    376,
    63,
    387,
    933,
    308,
    713,
    150
  ),
  createData18(
    "ＦＦフライヤーその他（ＳＩＰ）",
    944,
    988,
    153,
    238,
    372,
    106,
    152,
    598,
    64,
    915,
    401,
    37,
    352,
    735,
    150
  ),
  createData18(
    "その他",
    167,
    452,
    118,
    366,
    428,
    930,
    858,
    851,
    466,
    203,
    300,
    261,
    39,
    278,
    150
  ),
  createData18(
    "消耗品",
    940,
    60,
    330,
    897,
    187,
    779,
    412,
    170,
    907,
    31,
    321,
    833,
    101,
    20,
    150
  ),
  createData18(
    "５００ＭＬ未満",
    101,
    956,
    499,
    811,
    450,
    841,
    690,
    604,
    27,
    901,
    284,
    147,
    948,
    890,
    150
  ),
  createData18(
    "５００ＭＬパック",
    692,
    409,
    318,
    394,
    214,
    962,
    31,
    471,
    269,
    485,
    29,
    66,
    15,
    881,
    150
  ),
  createData18(
    "１Ｌパック",
    23,
    248,
    380,
    142,
    426,
    478,
    541,
    972,
    987,
    55,
    544,
    330,
    652,
    929,
    150
  ),
  createData18(
    "牛乳１Ｌ",
    316,
    958,
    13,
    433,
    16,
    792,
    34,
    98,
    651,
    498,
    184,
    130,
    121,
    850,
    150
  ),
  createData18(
    "コーヒーミルク・その他",
    769,
    894,
    735,
    323,
    944,
    826,
    903,
    592,
    880,
    60,
    659,
    376,
    241,
    661,
    150
  ),
  createData18(
    "牛乳・乳飲料（選択）",
    95,
    345,
    278,
    333,
    508,
    317,
    249,
    913,
    384,
    450,
    539,
    540,
    74,
    950,
    150
  ),
  createData18(
    "牛乳・乳飲料（ＳＩＰ）",
    25,
    68,
    99,
    910,
    219,
    486,
    478,
    806,
    67,
    83,
    729,
    519,
    966,
    12,
    150
  ),
  createData18(
    "牛乳・乳飲料（ＳＭＳ）",
    505,
    733,
    580,
    575,
    607,
    810,
    644,
    208,
    289,
    495,
    749,
    330,
    519,
    669,
    150
  ),
  createData18(
    "チーズ",
    515,
    749,
    492,
    162,
    795,
    10,
    129,
    262,
    390,
    258,
    462,
    141,
    908,
    773,
    150
  ),
  createData18(
    "バター・マーガリン",
    301,
    76,
    258,
    672,
    426,
    835,
    661,
    230,
    307,
    64,
    345,
    634,
    374,
    697,
    150
  ),
  createData18(
    "乳製品（ＳＩＰ）",
    544,
    320,
    993,
    88,
    210,
    987,
    771,
    320,
    150,
    531,
    760,
    863,
    765,
    295,
    150
  ),
  createData18(
    "乳製品（ＳＭＳ）",
    912,
    500,
    667,
    632,
    923,
    487,
    429,
    267,
    118,
    277,
    20,
    297,
    428,
    252,
    150
  ),
  createData18(
    "たまご",
    334,
    766,
    723,
    441,
    398,
    874,
    781,
    68,
    598,
    141,
    169,
    66,
    348,
    377,
    150
  ),
  createData18(
    "玉子（ＳＩＰ）",
    663,
    532,
    218,
    182,
    603,
    829,
    368,
    416,
    649,
    850,
    384,
    312,
    723,
    258,
    686
  ),
  createData18(
    "玉子（ＳＭＳ）",
    747,
    321,
    483,
    768,
    588,
    848,
    834,
    927,
    217,
    692,
    285,
    562,
    535,
    390,
    157
  ),
  createData18(
    "加工肉",
    789,
    732,
    633,
    280,
    611,
    848,
    470,
    747,
    804,
    806,
    720,
    221,
    267,
    529,
    375
  ),
  createData18(
    "精肉",
    619,
    487,
    721,
    338,
    922,
    543,
    454,
    285,
    466,
    916,
    97,
    824,
    199,
    75,
    666
  ),
  createData18(
    "野菜・果物（旧）",
    989,
    822,
    802,
    571,
    513,
    249,
    952,
    589,
    401,
    288,
    969,
    701,
    158,
    852,
    735
  ),
  createData18(
    "鮮魚・塩干",
    47,
    726,
    82,
    916,
    688,
    915,
    530,
    342,
    993,
    794,
    408,
    742,
    90,
    542,
    804
  ),
  createData18(
    "練物",
    810,
    248,
    413,
    854,
    816,
    976,
    507,
    80,
    672,
    923,
    577,
    319,
    599,
    29,
    133
  ),
  createData18(
    "水物",
    65,
    727,
    171,
    143,
    941,
    684,
    57,
    953,
    532,
    95,
    91,
    618,
    280,
    476,
    810
  ),
  createData18(
    "麺類",
    973,
    617,
    468,
    593,
    730,
    890,
    530,
    196,
    417,
    670,
    539,
    82,
    448,
    790,
    413
  ),
  createData18(
    "野菜・果物",
    979,
    955,
    38,
    488,
    496,
    317,
    184,
    571,
    542,
    230,
    817,
    273,
    989,
    364,
    348
  ),
  createData18(
    "調理の素",
    518,
    223,
    165,
    916,
    902,
    383,
    362,
    583,
    556,
    783,
    977,
    865,
    496,
    726,
    434
  ),
  createData18(
    "カット野菜・カットフルーツ",
    955,
    673,
    872,
    741,
    254,
    808,
    145,
    958,
    291,
    940,
    558,
    749,
    864,
    264,
    512
  ),
  createData18(
    "生活デイリー（ＳＩＰ）",
    985,
    189,
    629,
    155,
    346,
    799,
    582,
    633,
    383,
    135,
    689,
    119,
    97,
    721,
    620
  ),
  createData18(
    "生活デイリー（ＳＭＳ）",
    18,
    697,
    637,
    116,
    303,
    609,
    29,
    282,
    83,
    629,
    783,
    423,
    182,
    29,
    252
  ),
  createData18(
    "食パン",
    841,
    143,
    230,
    211,
    857,
    365,
    590,
    951,
    788,
    946,
    701,
    833,
    818,
    356,
    180
  ),
  createData18(
    "マルチパック（複数個）",
    347,
    391,
    995,
    508,
    852,
    353,
    726,
    903,
    43,
    728,
    378,
    602,
    367,
    28,
    767
  ),
  createData18(
    "食事パン",
    179,
    818,
    478,
    473,
    580,
    22,
    196,
    348,
    858,
    452,
    224,
    772,
    779,
    432,
    654
  ),
  createData18(
    "パン（ＳＩＰ）",
    857,
    214,
    80,
    59,
    358,
    610,
    900,
    856,
    650,
    670,
    862,
    574,
    825,
    717,
    946
  ),
  createData18(
    "パン（ＳＭＳ）",
    477,
    318,
    188,
    532,
    940,
    84,
    457,
    168,
    386,
    420,
    755,
    49,
    202,
    372,
    857
  ),
  createData18(
    "ストアパック",
    421,
    531,
    299,
    713,
    511,
    734,
    139,
    122,
    571,
    423,
    210,
    211,
    898,
    98,
    529
  ),
  createData18(
    "焼きたて直送便（菓子パン）",
    181,
    878,
    371,
    451,
    466,
    668,
    589,
    354,
    376,
    63,
    389,
    368,
    97,
    271,
    879
  ),
  createData18(
    "焼きたて直送便（惣菜パン）",
    906,
    432,
    240,
    687,
    644,
    407,
    871,
    656,
    964,
    721,
    40,
    891,
    848,
    963,
    62
  ),
  createData18(
    "味わいベーカリー",
    35,
    227,
    782,
    393,
    393,
    166,
    868,
    939,
    64,
    477,
    389,
    449,
    299,
    434,
    199
  ),
  createData18(
    "食卓パン",
    826,
    433,
    435,
    852,
    614,
    784,
    409,
    643,
    928,
    747,
    631,
    538,
    638,
    564,
    719
  ),
  createData18(
    "ＮＢ菓子パン",
    195,
    304,
    100,
    467,
    263,
    206,
    839,
    653,
    952,
    762,
    901,
    591,
    690,
    336,
    59
  ),
  createData18(
    "洋菓子",
    905,
    830,
    234,
    721,
    98,
    65,
    772,
    287,
    525,
    630,
    148,
    980,
    181,
    562,
    198
  ),
  createData18(
    "焼きたて直送便（Ｓ）",
    944,
    201,
    39,
    676,
    452,
    432,
    290,
    526,
    628,
    142,
    407,
    850,
    652,
    909,
    73
  ),
  createData18(
    "焼きたて直送便（Ｌ）",
    844,
    522,
    514,
    793,
    141,
    408,
    665,
    781,
    938,
    640,
    867,
    611,
    65,
    911,
    923
  ),
  createData18(
    "焼きたて直送（その他）",
    746,
    338,
    446,
    253,
    148,
    794,
    771,
    327,
    713,
    388,
    485,
    913,
    880,
    385,
    631
  ),
  createData18(
    "焼きたて直送（菓子パン）",
    479,
    516,
    479,
    478,
    465,
    936,
    677,
    539,
    173,
    559,
    850,
    473,
    647,
    394,
    922
  ),
  createData18(
    "焼きたて直送（惣菜パン）",
    120,
    438,
    534,
    431,
    795,
    655,
    978,
    799,
    653,
    498,
    839,
    777,
    780,
    632,
    957
  ),
  createData18(
    "菓子パン",
    910,
    48,
    292,
    470,
    135,
    475,
    978,
    750,
    277,
    92,
    841,
    705,
    112,
    718,
    767
  ),
  createData18(
    "惣菜パン",
    134,
    265,
    118,
    191,
    749,
    411,
    641,
    118,
    248,
    903,
    794,
    77,
    839,
    147,
    505
  ),
  createData18(
    "ドーナツ",
    419,
    240,
    612,
    621,
    428,
    447,
    61,
    786,
    470,
    709,
    234,
    253,
    821,
    187,
    901
  ),
  createData18(
    "７プレミアム（ペストリー）",
    202,
    426,
    255,
    630,
    387,
    628,
    633,
    540,
    970,
    810,
    757,
    273,
    998,
    693,
    454
  ),
  createData18(
    "ペストリー（ＳＩＰ）",
    241,
    996,
    417,
    729,
    137,
    321,
    955,
    19,
    347,
    427,
    794,
    541,
    143,
    58,
    453
  ),
  createData18(
    "ペストリー（ＳＭＳ）",
    325,
    733,
    975,
    466,
    97,
    617,
    859,
    651,
    302,
    72,
    63,
    304,
    63,
    167,
    57
  ),
  createData18(
    "米菓",
    388,
    530,
    555,
    397,
    618,
    171,
    49,
    417,
    952,
    917,
    112,
    28,
    11,
    64,
    443
  ),
  createData18(
    "７プレミアム菓子",
    873,
    557,
    515,
    279,
    929,
    68,
    615,
    896,
    884,
    792,
    131,
    31,
    145,
    73,
    581
  ),
  createData18(
    "７プレミアム菓子（１００円）",
    467,
    398,
    951,
    499,
    194,
    564,
    421,
    868,
    702,
    689,
    281,
    799,
    598,
    66,
    936
  ),
  createData18(
    "豆菓子",
    690,
    563,
    517,
    678,
    116,
    996,
    550,
    423,
    136,
    235,
    945,
    626,
    516,
    640,
    699
  ),
  createData18(
    "和菓子（菓子）",
    845,
    753,
    586,
    747,
    36,
    973,
    976,
    534,
    887,
    668,
    538,
    738,
    421,
    122,
    537
  ),
  createData18(
    "季節品",
    79,
    513,
    94,
    810,
    486,
    788,
    264,
    727,
    95,
    523,
    752,
    988,
    954,
    335,
    180
  ),
  createData18(
    "和風菓子・豆菓子（ＳＩＰ）",
    798,
    746,
    165,
    815,
    303,
    816,
    476,
    706,
    902,
    311,
    663,
    40,
    451,
    749,
    947
  ),
  createData18(
    "和風菓子・豆菓子（ＳＭＳ）",
    351,
    682,
    165,
    527,
    278,
    236,
    786,
    889,
    243,
    153,
    801,
    784,
    161,
    990,
    634
  ),
  createData18(
    "その他",
    155,
    830,
    830,
    904,
    823,
    981,
    567,
    958,
    698,
    695,
    632,
    365,
    405,
    334,
    425
  ),
  createData18(
    "ガム",
    314,
    739,
    412,
    264,
    93,
    382,
    183,
    922,
    18,
    471,
    784,
    294,
    223,
    201,
    599
  ),
  createData18(
    "スティックタイプキャンディ旧",
    159,
    596,
    476,
    941,
    887,
    17,
    181,
    899,
    597,
    786,
    598,
    429,
    597,
    304,
    224
  ),
  createData18(
    "グミ・素材菓子",
    865,
    349,
    127,
    31,
    720,
    767,
    52,
    606,
    488,
    305,
    209,
    18,
    465,
    126,
    563
  ),
  createData18(
    "スティックタイプキャンディ",
    209,
    218,
    721,
    340,
    12,
    913,
    996,
    136,
    178,
    696,
    146,
    334,
    693,
    63,
    532
  ),
  createData18(
    "チョコレート",
    829,
    354,
    430,
    618,
    178,
    429,
    908,
    33,
    837,
    38,
    380,
    290,
    110,
    893,
    833
  ),
  createData18(
    "袋チョコ",
    442,
    678,
    412,
    656,
    67,
    830,
    733,
    626,
    602,
    109,
    831,
    314,
    90,
    750,
    681
  ),
  createData18(
    "ポッキーポケットビス",
    228,
    959,
    168,
    951,
    759,
    858,
    596,
    278,
    218,
    299,
    405,
    977,
    163,
    244,
    85
  ),
  createData18(
    "駄菓子",
    313,
    542,
    678,
    451,
    660,
    648,
    103,
    874,
    498,
    923,
    252,
    159,
    680,
    861,
    930
  ),
  createData18(
    "袋キャンディ",
    268,
    773,
    103,
    173,
    41,
    699,
    714,
    425,
    992,
    516,
    826,
    203,
    998,
    643,
    352
  ),
  createData18(
    "焼き菓子",
    161,
    363,
    791,
    198,
    877,
    839,
    450,
    398,
    302,
    801,
    624,
    554,
    304,
    508,
    944
  ),
  createData18(
    "チルドチョコレート",
    382,
    531,
    908,
    724,
    554,
    945,
    92,
    930,
    537,
    617,
    90,
    901,
    934,
    86,
    819
  ),
  createData18(
    "７プレミアム（焼菓子）他",
    397,
    98,
    23,
    34,
    218,
    27,
    587,
    512,
    589,
    23,
    308,
    90,
    275,
    640,
    164
  ),
  createData18(
    "季節品１",
    562,
    614,
    700,
    503,
    571,
    295,
    675,
    291,
    356,
    732,
    188,
    324,
    379,
    166,
    261
  ),
  createData18(
    "季節品２",
    237,
    694,
    83,
    280,
    834,
    566,
    812,
    369,
    321,
    974,
    857,
    840,
    565,
    895,
    407
  ),
  createData18(
    "季節品３",
    670,
    555,
    43,
    899,
    810,
    964,
    927,
    174,
    342,
    384,
    304,
    425,
    929,
    187,
    247
  ),
  createData18(
    "子供菓子（選択）",
    106,
    400,
    15,
    194,
    858,
    374,
    711,
    533,
    819,
    338,
    863,
    732,
    622,
    625,
    841
  ),
  createData18(
    "オフィス・事業所立地（選択）",
    269,
    911,
    813,
    583,
    468,
    598,
    817,
    584,
    209,
    883,
    114,
    874,
    111,
    150,
    804
  ),
  createData18(
    "小物チョコ",
    32,
    772,
    486,
    84,
    36,
    340,
    486,
    565,
    143,
    330,
    938,
    966,
    133,
    964,
    508
  ),
  createData18(
    "チョコレート菓子",
    383,
    476,
    183,
    761,
    527,
    238,
    538,
    347,
    965,
    425,
    416,
    845,
    890,
    248,
    974
  ),
  createData18(
    "ポケット・洋風菓子（ＳＩＰ）",
    583,
    748,
    438,
    564,
    376,
    417,
    441,
    811,
    408,
    72,
    257,
    618,
    627,
    897,
    20
  ),
  createData18(
    "ポケット・洋風菓子（ＳＭＳ）",
    744,
    606,
    443,
    693,
    51,
    391,
    152,
    837,
    890,
    26,
    147,
    180,
    663,
    835,
    603
  ),
  createData18(
    "ポテトチップス",
    650,
    670,
    142,
    740,
    648,
    299,
    377,
    977,
    832,
    561,
    191,
    199,
    265,
    599,
    544
  ),
  createData18(
    "袋スナック",
    452,
    949,
    57,
    48,
    484,
    25,
    433,
    717,
    360,
    824,
    759,
    920,
    901,
    691,
    790
  ),
  createData18(
    "箱スナック",
    304,
    427,
    106,
    693,
    233,
    41,
    900,
    760,
    23,
    498,
    977,
    160,
    106,
    747,
    700
  ),
  createData18(
    "スナック菓子（ＳＩＰ）",
    98,
    354,
    239,
    802,
    253,
    442,
    628,
    667,
    250,
    393,
    681,
    772,
    518,
    233,
    438
  ),
  createData18(
    "スナック菓子（ＳＭＳ）",
    971,
    540,
    174,
    295,
    191,
    188,
    935,
    922,
    401,
    173,
    792,
    545,
    119,
    685,
    555
  ),
  createData18(
    "袋ラーメン",
    801,
    604,
    865,
    742,
    314,
    854,
    809,
    944,
    389,
    279,
    353,
    282,
    34,
    863,
    99
  ),
  createData18(
    "カップラーメン",
    192,
    844,
    846,
    861,
    844,
    318,
    916,
    513,
    315,
    967,
    795,
    289,
    773,
    513,
    663
  ),
  createData18(
    "カップみそ汁・スープ",
    313,
    928,
    585,
    21,
    863,
    675,
    14,
    262,
    719,
    775,
    755,
    169,
    680,
    171,
    398
  ),
  createData18(
    "袋みそ汁・スープ",
    182,
    317,
    162,
    940,
    465,
    241,
    632,
    840,
    544,
    228,
    153,
    894,
    974,
    423,
    499
  ),
  createData18(
    "カップその他",
    188,
    88,
    686,
    182,
    446,
    231,
    646,
    371,
    424,
    692,
    256,
    381,
    740,
    406,
    454
  ),
  createData18(
    "お茶漬け・ふりかけ",
    100,
    524,
    488,
    247,
    474,
    369,
    790,
    39,
    799,
    901,
    285,
    805,
    139,
    450,
    16
  ),
  createData18(
    "乾麺",
    914,
    598,
    444,
    542,
    699,
    645,
    538,
    347,
    216,
    968,
    736,
    875,
    483,
    174,
    732
  ),
  createData18(
    "パスタ・パスタソース",
    736,
    998,
    76,
    536,
    935,
    748,
    237,
    398,
    647,
    808,
    823,
    955,
    921,
    924,
    400
  ),
  createData18(
    "加工米飯",
    118,
    827,
    228,
    493,
    24,
    388,
    124,
    684,
    864,
    937,
    624,
    969,
    603,
    117,
    761
  ),
  createData18(
    "レトルト（カレー・その他）",
    225,
    597,
    19,
    570,
    140,
    229,
    436,
    989,
    149,
    531,
    916,
    42,
    130,
    984,
    218
  ),
  createData18(
    "レトルト（その他）",
    777,
    248,
    279,
    606,
    27,
    639,
    76,
    387,
    479,
    523,
    742,
    331,
    227,
    319,
    965
  ),
  createData18(
    "ベビーフード",
    231,
    398,
    695,
    173,
    647,
    109,
    756,
    119,
    613,
    25,
    431,
    485,
    290,
    330,
    415
  ),
  createData18(
    "加工食品（選択）",
    59,
    669,
    629,
    592,
    137,
    284,
    210,
    393,
    402,
    412,
    609,
    247,
    441,
    185,
    473
  ),
  createData18(
    "加工食品（ＳＩＰ）",
    327,
    186,
    197,
    957,
    198,
    407,
    333,
    963,
    474,
    124,
    596,
    281,
    795,
    73,
    349
  ),
  createData18(
    "加工食品（ＳＭＳ）",
    707,
    323,
    72,
    735,
    327,
    114,
    427,
    971,
    667,
    691,
    391,
    693,
    793,
    441,
    657
  ),
  createData18(
    "乾物",
    319,
    311,
    467,
    855,
    599,
    894,
    526,
    738,
    721,
    586,
    483,
    27,
    966,
    507,
    994
  ),
  createData18(
    "醤油・味噌・つゆ・その他",
    163,
    141,
    102,
    691,
    986,
    873,
    886,
    590,
    507,
    620,
    224,
    526,
    275,
    597,
    212
  ),
  createData18(
    "マヨネーズ・ドレッシング",
    708,
    86,
    730,
    468,
    789,
    975,
    191,
    265,
    610,
    232,
    811,
    123,
    728,
    440,
    62
  ),
  createData18(
    "ソース・ケチャップ",
    777,
    866,
    119,
    805,
    423,
    946,
    266,
    694,
    938,
    703,
    131,
    350,
    550,
    448,
    671
  ),
  createData18(
    "香辛料・調味料",
    832,
    829,
    936,
    417,
    490,
    291,
    738,
    426,
    347,
    561,
    207,
    856,
    685,
    146,
    55
  ),
  createData18(
    "酢・油",
    899,
    909,
    802,
    886,
    947,
    789,
    785,
    129,
    961,
    334,
    70,
    434,
    743,
    208,
    266
  ),
  createData18(
    "調理の素",
    881,
    157,
    962,
    179,
    497,
    762,
    635,
    764,
    876,
    952,
    711,
    237,
    770,
    338,
    772
  ),
  createData18(
    "砂糖",
    677,
    707,
    322,
    67,
    447,
    456,
    140,
    249,
    153,
    367,
    684,
    202,
    453,
    754,
    773
  ),
  createData18(
    "調味料・乾物（選択）",
    239,
    74,
    299,
    486,
    843,
    383,
    370,
    802,
    669,
    73,
    196,
    165,
    673,
    627,
    783
  ),
  createData18(
    "調味料・乾物（ＳＩＰ）",
    216,
    883,
    899,
    428,
    986,
    299,
    383,
    400,
    914,
    310,
    974,
    525,
    924,
    788,
    182
  ),
  createData18(
    "調味料・乾物（ＳＭＳ）",
    301,
    675,
    616,
    466,
    532,
    961,
    709,
    517,
    556,
    230,
    186,
    820,
    909,
    904,
    607
  ),
  createData18(
    "米穀",
    137,
    75,
    954,
    502,
    62,
    543,
    597,
    196,
    588,
    540,
    824,
    112,
    760,
    532,
    320
  ),
  createData18(
    "米穀（ＳＩＰ）",
    817,
    435,
    892,
    826,
    940,
    936,
    238,
    400,
    357,
    471,
    194,
    912,
    715,
    562,
    48
  ),
  createData18(
    "米穀（ＳＭＳ）",
    83,
    871,
    399,
    380,
    357,
    853,
    114,
    970,
    587,
    719,
    463,
    898,
    310,
    880,
    375
  ),
  createData18(
    "レギュラーコーヒー",
    719,
    403,
    885,
    510,
    294,
    707,
    251,
    413,
    962,
    649,
    563,
    573,
    498,
    224,
    956
  ),
  createData18(
    "マイボトル・コーヒーマシン他",
    863,
    948,
    669,
    52,
    283,
    313,
    401,
    819,
    226,
    319,
    116,
    511,
    957,
    339,
    662
  ),
  createData18(
    "インスタントコーヒー・クリーム",
    603,
    474,
    471,
    921,
    534,
    107,
    216,
    181,
    475,
    979,
    392,
    564,
    907,
    307,
    61
  ),
  createData18(
    "紅茶・ココア・その他",
    400,
    313,
    210,
    156,
    650,
    184,
    704,
    501,
    591,
    130,
    193,
    709,
    157,
    791,
    469
  ),
  createData18(
    "お茶・その他",
    661,
    810,
    169,
    634,
    568,
    742,
    145,
    726,
    710,
    877,
    240,
    877,
    848,
    422,
    756
  ),
  createData18(
    "ジャム・はちみつ・その他",
    493,
    865,
    558,
    530,
    617,
    784,
    60,
    832,
    864,
    810,
    515,
    66,
    541,
    533,
    608
  ),
  createData18(
    "炭酸水（酒類用）・その他",
    373,
    33,
    656,
    650,
    873,
    257,
    815,
    784,
    544,
    744,
    531,
    416,
    64,
    923,
    426
  ),
  createData18(
    "し好品（選択）",
    669,
    993,
    692,
    407,
    900,
    901,
    842,
    655,
    710,
    792,
    674,
    618,
    942,
    362,
    458
  ),
  createData18(
    "し好品（ＳＩＰ）",
    944,
    940,
    499,
    377,
    296,
    771,
    634,
    379,
    613,
    335,
    236,
    916,
    120,
    459,
    123
  ),
  createData18(
    "し好品（ＳＭＳ）",
    279,
    470,
    648,
    785,
    529,
    612,
    107,
    277,
    897,
    895,
    694,
    270,
    627,
    666,
    90
  ),
  createData18(
    "珍味",
    295,
    859,
    755,
    58,
    589,
    783,
    888,
    338,
    625,
    659,
    668,
    148,
    960,
    89,
    61
  ),
  createData18(
    "魚肉ソーセージ",
    504,
    874,
    69,
    337,
    479,
    488,
    417,
    770,
    335,
    999,
    879,
    135,
    440,
    356,
    151
  ),
  createData18(
    "ビン詰",
    282,
    484,
    159,
    633,
    171,
    831,
    859,
    235,
    586,
    359,
    742,
    539,
    418,
    145,
    127
  ),
  createData18(
    "缶詰",
    624,
    443,
    781,
    449,
    978,
    508,
    600,
    919,
    823,
    566,
    878,
    915,
    597,
    817,
    189
  ),
  createData18(
    "珍味（選択）",
    507,
    843,
    793,
    720,
    256,
    891,
    863,
    216,
    515,
    345,
    299,
    556,
    310,
    528,
    677
  ),
  createData18(
    "缶詰他（選択）",
    61,
    998,
    193,
    313,
    371,
    294,
    559,
    95,
    482,
    78,
    284,
    869,
    570,
    109,
    411
  ),
  createData18(
    "珍味・缶詰（ＳＩＰ）",
    96,
    176,
    487,
    465,
    981,
    701,
    805,
    63,
    63,
    464,
    11,
    784,
    330,
    376,
    442
  ),
  createData18(
    "珍味・缶詰（ＳＭＳ）",
    207,
    129,
    683,
    134,
    729,
    32,
    72,
    389,
    843,
    677,
    836,
    908,
    824,
    453,
    199
  ),
  createData18(
    "冷凍米飯",
    303,
    394,
    940,
    121,
    705,
    526,
    84,
    998,
    944,
    531,
    195,
    765,
    176,
    274,
    492
  ),
  createData18(
    "調理食品",
    302,
    750,
    710,
    615,
    520,
    549,
    283,
    25,
    791,
    250,
    976,
    493,
    820,
    423,
    640
  ),
  createData18(
    "農水産・素材",
    977,
    693,
    473,
    535,
    874,
    624,
    127,
    831,
    208,
    295,
    485,
    39,
    366,
    889,
    63
  ),
  createData18(
    "冷凍麺類・軽食",
    56,
    574,
    298,
    525,
    88,
    57,
    530,
    553,
    790,
    237,
    858,
    974,
    42,
    365,
    948
  ),
  createData18(
    "冷凍惣菜",
    927,
    453,
    150,
    808,
    928,
    677,
    910,
    984,
    772,
    458,
    508,
    454,
    760,
    596,
    751
  ),
  createData18(
    "デザート",
    623,
    777,
    813,
    753,
    121,
    492,
    677,
    566,
    95,
    893,
    471,
    750,
    905,
    890,
    809
  ),
  createData18(
    "ロックアイス（旧）",
    568,
    201,
    975,
    397,
    416,
    760,
    888,
    916,
    514,
    926,
    68,
    630,
    573,
    667,
    910
  ),
  createData18(
    "冷凍食品（ＳＩＰ）",
    862,
    825,
    266,
    872,
    656,
    501,
    935,
    44,
    754,
    66,
    807,
    246,
    435,
    316,
    173
  ),
  createData18(
    "冷凍食品（ＳＭＳ）",
    459,
    537,
    947,
    367,
    818,
    475,
    90,
    271,
    889,
    711,
    375,
    315,
    722,
    134,
    30
  ),
  createData18(
    "その他",
    907,
    519,
    910,
    697,
    557,
    471,
    792,
    541,
    664,
    593,
    953,
    231,
    648,
    613,
    435
  ),
  createData18(
    "ノベルティアイス",
    825,
    332,
    483,
    979,
    760,
    158,
    931,
    184,
    942,
    42,
    845,
    314,
    681,
    374,
    763
  ),
  createData18(
    "プレミアムアイス",
    872,
    871,
    886,
    997,
    781,
    762,
    245,
    532,
    305,
    993,
    403,
    954,
    177,
    849,
    773
  ),
  createData18(
    "ファミリータイプアイス",
    593,
    937,
    570,
    110,
    34,
    702,
    189,
    399,
    336,
    600,
    274,
    159,
    700,
    148,
    992
  ),
  createData18(
    "ロックアイス",
    836,
    711,
    870,
    370,
    414,
    165,
    855,
    400,
    553,
    351,
    392,
    445,
    73,
    979,
    851
  ),
  createData18(
    "アイスクリーム（選択）",
    179,
    449,
    588,
    878,
    317,
    255,
    144,
    11,
    27,
    609,
    347,
    555,
    837,
    412,
    587
  ),
  createData18(
    "アイスクリーム（ＳＩＰ）",
    45,
    79,
    38,
    464,
    418,
    11,
    889,
    676,
    36,
    626,
    383,
    579,
    69,
    83,
    666
  ),
  createData18(
    "炭酸飲料",
    854,
    173,
    125,
    140,
    279,
    965,
    167,
    798,
    845,
    776,
    41,
    177,
    236,
    179,
    861
  ),
  createData18(
    "果汁飲料",
    941,
    626,
    222,
    465,
    107,
    596,
    53,
    798,
    612,
    139,
    422,
    360,
    214,
    997,
    445
  ),
  createData18(
    "缶コーヒー",
    522,
    105,
    661,
    572,
    669,
    528,
    503,
    721,
    243,
    813,
    232,
    75,
    709,
    605,
    433
  ),
  createData18(
    "お茶系飲料",
    278,
    424,
    943,
    89,
    485,
    622,
    995,
    911,
    379,
    153,
    508,
    591,
    28,
    463,
    121
  ),
  createData18(
    "スポーツ・水・その他",
    173,
    628,
    911,
    980,
    179,
    941,
    330,
    43,
    417,
    23,
    593,
    692,
    79,
    187,
    424
  ),
  createData18(
    "炭酸・果汁・コーヒー",
    470,
    744,
    153,
    650,
    347,
    309,
    765,
    944,
    348,
    190,
    158,
    269,
    986,
    429,
    668
  ),
  createData18(
    "お茶・紅茶飲料",
    641,
    280,
    724,
    620,
    584,
    489,
    148,
    792,
    689,
    49,
    702,
    972,
    453,
    138,
    116
  ),
  createData18(
    "スポーツ・水・健康飲料",
    296,
    450,
    12,
    574,
    943,
    420,
    502,
    722,
    716,
    184,
    465,
    225,
    569,
    57,
    194
  ),
  createData18(
    "炭酸・果汁・コーヒーリッター",
    230,
    505,
    482,
    654,
    772,
    966,
    40,
    286,
    286,
    350,
    550,
    36,
    772,
    74,
    231
  ),
  createData18(
    "お茶系飲料リッター",
    252,
    721,
    165,
    48,
    769,
    65,
    584,
    761,
    247,
    197,
    271,
    992,
    360,
    771,
    394
  ),
  createData18(
    "スポーツ・水・健康飲料リッター",
    101,
    813,
    516,
    919,
    877,
    89,
    11,
    815,
    477,
    401,
    345,
    488,
    803,
    837,
    977
  ),
  createData18(
    "ホット飲料",
    790,
    177,
    287,
    954,
    768,
    398,
    165,
    916,
    972,
    35,
    352,
    254,
    447,
    593,
    417
  ),
  createData18(
    "ギフト券",
    607,
    712,
    984,
    459,
    352,
    895,
    157,
    774,
    775,
    542,
    779,
    722,
    500,
    49,
    257
  ),
  createData18(
    "ソフトドリンク（選択）",
    735,
    141,
    560,
    695,
    939,
    181,
    664,
    627,
    740,
    357,
    200,
    510,
    133,
    463,
    328
  ),
  createData18(
    "小型飲料（３５０ＭＬ以下）",
    238,
    325,
    202,
    548,
    736,
    50,
    314,
    416,
    358,
    555,
    347,
    692,
    251,
    849,
    583
  ),
  createData18(
    "ソフトドリンク（ＳＩＰ）",
    701,
    231,
    54,
    545,
    701,
    466,
    165,
    169,
    20,
    28,
    636,
    115,
    503,
    784,
    502
  ),
  createData18(
    "ソフトドリンク（ＳＭＳ）",
    559,
    842,
    278,
    340,
    759,
    30,
    557,
    633,
    223,
    185,
    663,
    318,
    589,
    937,
    591
  ),
  createData18(
    "機能性ドリンク",
    938,
    688,
    131,
    266,
    597,
    984,
    792,
    223,
    111,
    428,
    382,
    917,
    607,
    162,
    959
  ),
  createData18(
    "医薬部外品ドリンク剤",
    883,
    189,
    303,
    464,
    568,
    769,
    98,
    601,
    264,
    857,
    760,
    778,
    101,
    416,
    661
  ),
  createData18(
    "医薬部外品",
    763,
    505,
    346,
    135,
    903,
    357,
    68,
    699,
    810,
    403,
    452,
    284,
    744,
    137,
    406
  ),
  createData18(
    "女性向け",
    111,
    54,
    68,
    515,
    128,
    535,
    341,
    556,
    704,
    453,
    537,
    847,
    157,
    412,
    11
  ),
  createData18(
    "飲酒対策",
    739,
    852,
    290,
    25,
    567,
    271,
    899,
    414,
    260,
    801,
    704,
    854,
    330,
    512,
    667
  ),
  createData18(
    "眠気対策",
    685,
    617,
    806,
    182,
    132,
    811,
    159,
    300,
    632,
    582,
    744,
    524,
    972,
    31,
    502
  ),
  createData18(
    "機能性飲料",
    800,
    537,
    820,
    573,
    910,
    573,
    570,
    434,
    28,
    913,
    684,
    496,
    605,
    327,
    205
  ),
  createData18(
    "パウチゼリー",
    471,
    354,
    561,
    376,
    873,
    778,
    552,
    788,
    865,
    715,
    967,
    455,
    536,
    565,
    737
  ),
  createData18(
    "機能性ドリンク（選択）",
    881,
    137,
    797,
    481,
    345,
    833,
    787,
    796,
    170,
    390,
    666,
    278,
    240,
    353,
    906
  ),
  createData18(
    "栄養ドリンク（選択）",
    839,
    713,
    321,
    982,
    958,
    779,
    573,
    296,
    172,
    904,
    766,
    221,
    305,
    709,
    612
  ),
  createData18(
    "栄養ドリンク（ＳＩＰ）",
    813,
    300,
    328,
    962,
    440,
    342,
    65,
    95,
    459,
    654,
    177,
    980,
    343,
    316,
    913
  ),
  createData18(
    "栄養ドリンク（ＳＭＳ）",
    312,
    786,
    614,
    752,
    596,
    602,
    793,
    640,
    873,
    229,
    712,
    294,
    835,
    900,
    898
  ),
  createData18(
    "サプリメント",
    483,
    561,
    425,
    924,
    479,
    471,
    337,
    440,
    253,
    793,
    740,
    284,
    816,
    820,
    533
  ),
  createData18(
    "栄養バランス食品",
    383,
    780,
    397,
    412,
    150,
    233,
    182,
    284,
    38,
    427,
    210,
    678,
    918,
    32,
    710
  ),
  createData18(
    "その他健康食品",
    675,
    416,
    772,
    375,
    196,
    782,
    512,
    219,
    845,
    286,
    985,
    499,
    885,
    352,
    95
  ),
  createData18(
    "医薬部外品内服その他",
    558,
    523,
    30,
    999,
    937,
    772,
    477,
    928,
    520,
    844,
    213,
    12,
    283,
    821,
    998
  ),
  createData18(
    "サイクルミー",
    817,
    634,
    204,
    862,
    362,
    860,
    134,
    12,
    702,
    192,
    17,
    981,
    768,
    375,
    659
  ),
  createData18(
    "健康栄養食品（選択）",
    822,
    728,
    513,
    165,
    62,
    409,
    955,
    371,
    748,
    416,
    494,
    583,
    439,
    543,
    435
  ),
  createData18(
    "医薬品",
    375,
    852,
    996,
    180,
    294,
    84,
    697,
    359,
    557,
    73,
    204,
    620,
    265,
    210,
    579
  ),
  createData18(
    "健康栄養食品（ＳＩＰ）",
    934,
    860,
    655,
    667,
    490,
    106,
    154,
    804,
    633,
    551,
    408,
    662,
    535,
    901,
    659
  ),
  createData18(
    "健康栄養食品（ＳＭＳ）",
    982,
    152,
    976,
    547,
    687,
    804,
    69,
    51,
    816,
    516,
    991,
    167,
    490,
    224,
    111
  ),
  createData18(
    "通常進物",
    622,
    63,
    419,
    562,
    789,
    910,
    258,
    424,
    137,
    506,
    16,
    317,
    64,
    789,
    360
  ),
  createData18(
    "季節進物",
    432,
    350,
    493,
    141,
    942,
    579,
    469,
    319,
    515,
    243,
    318,
    312,
    840,
    832,
    947
  ),
  createData18(
    "ギフトコレクション",
    876,
    504,
    512,
    737,
    986,
    416,
    163,
    642,
    231,
    115,
    228,
    315,
    506,
    481,
    268
  ),
  createData18(
    "催事商品（菓子・雑貨）",
    194,
    659,
    883,
    918,
    396,
    64,
    227,
    647,
    941,
    84,
    949,
    214,
    473,
    180,
    837
  ),
  createData18(
    "クリスマス",
    911,
    226,
    426,
    842,
    278,
    805,
    830,
    239,
    562,
    33,
    861,
    342,
    475,
    651,
    179
  ),
  createData18(
    "バレンタインデー",
    390,
    864,
    799,
    443,
    528,
    519,
    657,
    77,
    470,
    239,
    391,
    880,
    836,
    533,
    699
  ),
  createData18(
    "ホワイトデー",
    407,
    297,
    102,
    827,
    683,
    791,
    766,
    478,
    226,
    258,
    405,
    875,
    191,
    486,
    31
  ),
  createData18(
    "季節進物２",
    97,
    808,
    361,
    129,
    331,
    254,
    820,
    876,
    239,
    22,
    75,
    836,
    493,
    955,
    372
  ),
  createData18(
    "宅配料（母・父ギフト）",
    372,
    856,
    644,
    984,
    414,
    286,
    913,
    679,
    458,
    862,
    537,
    40,
    871,
    326,
    115
  ),
  createData18(
    "包装料（母・父ギフト）",
    869,
    571,
    965,
    381,
    695,
    511,
    177,
    951,
    38,
    283,
    72,
    655,
    599,
    806,
    98
  ),
  createData18(
    "催事・進物ギフト（ＳＩＰ）",
    739,
    36,
    879,
    997,
    177,
    175,
    401,
    131,
    12,
    187,
    340,
    526,
    103,
    366,
    812
  ),
  createData18(
    "母父ギフト（飲食料品）",
    617,
    361,
    325,
    99,
    708,
    971,
    925,
    461,
    646,
    738,
    10,
    507,
    990,
    228,
    644
  ),
  createData18(
    "母父ギフト（雑貨・その他）",
    142,
    882,
    457,
    745,
    930,
    466,
    209,
    849,
    267,
    664,
    188,
    408,
    559,
    657,
    465
  ),
  createData18(
    "母父ギフト（非課税）",
    400,
    144,
    310,
    757,
    769,
    202,
    821,
    118,
    860,
    998,
    862,
    739,
    936,
    712,
    645
  ),
  createData18(
    "くらしのギフト（非課税）",
    879,
    639,
    252,
    750,
    589,
    535,
    400,
    196,
    762,
    396,
    39,
    353,
    116,
    194,
    442
  ),
  createData18(
    "留置きギフト",
    506,
    664,
    929,
    716,
    328,
    49,
    627,
    299,
    881,
    763,
    275,
    875,
    203,
    16,
    282
  ),
  createData18(
    "宅配料（くらしのギフト）",
    956,
    436,
    157,
    304,
    305,
    509,
    36,
    990,
    940,
    472,
    719,
    675,
    257,
    251,
    230
  ),
  createData18(
    "包装料（くらしのギフト）",
    505,
    341,
    880,
    109,
    696,
    802,
    283,
    585,
    565,
    768,
    433,
    785,
    253,
    592,
    641
  ),
  createData18(
    "くらしのギフト（飲食料品）",
    58,
    688,
    932,
    530,
    411,
    733,
    700,
    646,
    403,
    680,
    320,
    168,
    982,
    584,
    480
  ),
  createData18(
    "くらしのギフト（雑貨・その他）",
    92,
    222,
    17,
    60,
    336,
    279,
    662,
    922,
    748,
    691,
    278,
    392,
    429,
    722,
    357
  ),
  createData18(
    "お歳暮ギフト",
    663,
    343,
    339,
    365,
    265,
    740,
    728,
    289,
    495,
    310,
    881,
    774,
    155,
    380,
    159
  ),
  createData18(
    "お中元ギフト",
    630,
    298,
    784,
    135,
    143,
    957,
    987,
    133,
    438,
    196,
    996,
    200,
    390,
    837,
    59
  ),
  createData18(
    "出版その他（ネット）",
    808,
    333,
    828,
    270,
    154,
    316,
    405,
    18,
    889,
    555,
    479,
    282,
    422,
    268,
    546
  ),
  createData18(
    "本・コミック",
    626,
    568,
    927,
    473,
    33,
    534,
    805,
    882,
    323,
    348,
    38,
    798,
    887,
    997,
    351
  ),
  createData18(
    "雑誌",
    393,
    291,
    836,
    947,
    308,
    800,
    997,
    348,
    694,
    480,
    123,
    931,
    451,
    319,
    936
  ),
  createData18(
    "ＣＤ・グッズ",
    684,
    274,
    893,
    259,
    538,
    674,
    835,
    323,
    802,
    796,
    449,
    463,
    848,
    372,
    970
  ),
  createData18(
    "ＤＶＤ・Ｂｌｕ－ｒａｙ",
    288,
    224,
    278,
    901,
    80,
    451,
    254,
    792,
    245,
    537,
    256,
    336,
    285,
    332,
    319
  ),
  createData18(
    "ゲーム・ホビー",
    226,
    453,
    954,
    131,
    449,
    841,
    844,
    141,
    886,
    493,
    501,
    198,
    195,
    955,
    148
  ),
  createData18(
    "ギフトその他",
    80,
    248,
    660,
    611,
    907,
    170,
    341,
    307,
    610,
    51,
    77,
    142,
    914,
    132,
    653
  ),
  createData18(
    "宅配料（お中元ギフト）",
    500,
    522,
    275,
    404,
    245,
    867,
    613,
    859,
    456,
    614,
    910,
    12,
    25,
    963,
    227
  ),
  createData18(
    "包装料（お中元ギフト）",
    240,
    439,
    210,
    741,
    479,
    768,
    692,
    602,
    878,
    726,
    99,
    250,
    414,
    976,
    688
  ),
  createData18(
    "お中元ギフト（飲食料品）",
    335,
    553,
    530,
    608,
    473,
    868,
    466,
    591,
    184,
    687,
    226,
    70,
    480,
    824,
    678
  ),
  createData18(
    "お中元ギフト（雑貨・その他）",
    872,
    649,
    867,
    839,
    598,
    481,
    747,
    718,
    101,
    215,
    147,
    157,
    69,
    634,
    315
  ),
  createData18(
    "お中元ギフト（非課税）",
    100,
    267,
    891,
    205,
    522,
    894,
    954,
    796,
    882,
    165,
    855,
    675,
    744,
    304,
    811
  ),
  createData18(
    "宅配料（お歳暮ギフト）",
    543,
    711,
    991,
    930,
    424,
    75,
    136,
    564,
    77,
    984,
    73,
    560,
    188,
    442,
    576
  ),
  createData18(
    "包装料（お歳暮ギフト）",
    67,
    699,
    745,
    707,
    394,
    909,
    681,
    735,
    758,
    562,
    342,
    701,
    680,
    340,
    707
  ),
  createData18(
    "お歳暮ギフト（飲食料品）",
    571,
    983,
    505,
    954,
    431,
    528,
    552,
    446,
    780,
    384,
    124,
    784,
    342,
    418,
    394
  ),
  createData18(
    "お歳暮ギフト（雑貨・その他）",
    257,
    959,
    490,
    784,
    290,
    450,
    252,
    900,
    134,
    203,
    270,
    21,
    129,
    570,
    749
  ),
  createData18(
    "お歳暮ギフト（非課税）",
    632,
    620,
    230,
    780,
    724,
    204,
    80,
    373,
    290,
    942,
    772,
    795,
    383,
    101,
    486
  ),
  createData18(
    "専門誌",
    968,
    350,
    217,
    407,
    236,
    511,
    771,
    407,
    21,
    718,
    692,
    55,
    300,
    548,
    878
  ),
  createData18(
    "レディースコミック誌",
    984,
    647,
    865,
    260,
    179,
    522,
    510,
    747,
    435,
    662,
    529,
    542,
    250,
    704,
    869
  ),
  createData18(
    "女性誌",
    690,
    792,
    535,
    782,
    907,
    388,
    30,
    873,
    605,
    392,
    860,
    919,
    17,
    185,
    139
  ),
  createData18(
    "児童誌",
    605,
    416,
    495,
    60,
    225,
    884,
    213,
    742,
    541,
    450,
    480,
    191,
    337,
    688,
    570
  ),
  createData18(
    "週刊誌（一般）",
    359,
    397,
    958,
    982,
    742,
    456,
    797,
    260,
    228,
    478,
    617,
    112,
    442,
    855,
    189
  ),
  createData18(
    "テレビ・音楽情報誌",
    685,
    802,
    457,
    460,
    713,
    609,
    862,
    966,
    864,
    48,
    183,
    736,
    399,
    892,
    103
  ),
  createData18(
    "コンピューター情報誌",
    134,
    608,
    431,
    131,
    483,
    426,
    803,
    383,
    960,
    356,
    888,
    603,
    852,
    253,
    410
  ),
  createData18(
    "趣味情報誌",
    160,
    522,
    465,
    397,
    351,
    462,
    280,
    792,
    730,
    309,
    933,
    542,
    420,
    827,
    358
  ),
  createData18(
    "スポーツ情報誌",
    877,
    825,
    157,
    45,
    383,
    379,
    482,
    642,
    10,
    438,
    622,
    173,
    879,
    307,
    964
  ),
  createData18(
    "娯楽情報誌",
    938,
    39,
    12,
    813,
    844,
    482,
    88,
    125,
    525,
    296,
    151,
    350,
    916,
    734,
    812
  ),
  createData18(
    "ゲーム情報誌",
    799,
    483,
    233,
    875,
    129,
    270,
    414,
    951,
    469,
    794,
    606,
    412,
    496,
    884,
    122
  ),
  createData18(
    "総合情報誌",
    888,
    484,
    999,
    668,
    172,
    988,
    545,
    931,
    484,
    752,
    332,
    116,
    983,
    450,
    107
  ),
  createData18(
    "地域情報誌",
    699,
    786,
    302,
    545,
    244,
    978,
    397,
    759,
    585,
    367,
    549,
    104,
    149,
    80,
    148
  ),
  createData18(
    "モーター誌",
    463,
    438,
    604,
    755,
    164,
    446,
    819,
    354,
    364,
    19,
    555,
    558,
    708,
    980,
    200
  ),
  createData18(
    "青年コミック誌",
    916,
    161,
    869,
    534,
    918,
    93,
    94,
    408,
    575,
    82,
    875,
    692,
    175,
    561,
    366
  ),
  createData18(
    "コミック誌・その他",
    80,
    710,
    549,
    623,
    213,
    214,
    284,
    149,
    652,
    517,
    252,
    116,
    166,
    173,
    758
  ),
  createData18(
    "文庫・新書",
    204,
    522,
    371,
    469,
    60,
    92,
    164,
    787,
    172,
    322,
    919,
    137,
    61,
    230,
    818
  ),
  createData18(
    "一般書",
    667,
    794,
    705,
    467,
    736,
    494,
    703,
    416,
    990,
    517,
    909,
    792,
    649,
    818,
    465
  ),
  createData18(
    "ムック・その他",
    988,
    465,
    620,
    333,
    721,
    426,
    410,
    55,
    304,
    781,
    700,
    41,
    290,
    98,
    394
  ),
  createData18(
    "その他書籍",
    988,
    381,
    524,
    391,
    133,
    564,
    212,
    890,
    456,
    523,
    978,
    21,
    681,
    841,
    841
  ),
  createData18(
    "地図",
    581,
    863,
    231,
    888,
    956,
    491,
    647,
    168,
    682,
    975,
    532,
    612,
    922,
    188,
    19
  ),
  createData18(
    "文庫・書籍（ＳＭＳ）",
    533,
    773,
    83,
    697,
    524,
    493,
    668,
    188,
    947,
    610,
    841,
    778,
    591,
    756,
    265
  ),
  createData18(
    "国内たばこ",
    355,
    82,
    620,
    521,
    72,
    382,
    724,
    259,
    760,
    471,
    993,
    713,
    592,
    368,
    74
  ),
  createData18(
    "外国たばこ",
    100,
    783,
    489,
    844,
    70,
    931,
    285,
    237,
    674,
    820,
    131,
    866,
    654,
    942,
    344
  ),
  createData18(
    "加熱式たばこ",
    777,
    953,
    714,
    904,
    158,
    517,
    371,
    415,
    662,
    233,
    599,
    306,
    96,
    520,
    746
  ),
  createData18(
    "たばこ（選択）",
    705,
    502,
    978,
    558,
    84,
    569,
    761,
    390,
    794,
    384,
    653,
    197,
    297,
    172,
    793
  ),
  createData18(
    "定期購読",
    791,
    865,
    670,
    480,
    544,
    209,
    568,
    503,
    335,
    462,
    360,
    682,
    334,
    305,
    19
  ),
  createData18(
    "文庫本・書籍",
    707,
    130,
    563,
    458,
    550,
    806,
    738,
    844,
    507,
    364,
    433,
    591,
    378,
    454,
    141
  ),
  createData18(
    "レディースコミック誌",
    33,
    463,
    568,
    961,
    849,
    659,
    108,
    863,
    346,
    604,
    865,
    360,
    201,
    985,
    54
  ),
  createData18(
    "女性誌",
    707,
    395,
    644,
    184,
    830,
    428,
    845,
    520,
    630,
    272,
    175,
    340,
    706,
    808,
    863
  ),
  createData18(
    "児童誌",
    731,
    797,
    305,
    248,
    752,
    670,
    753,
    664,
    916,
    491,
    607,
    452,
    649,
    622,
    749
  ),
  createData18(
    "週刊誌（一般）",
    859,
    804,
    880,
    589,
    669,
    155,
    960,
    359,
    536,
    207,
    583,
    102,
    775,
    435,
    663
  ),
  createData18(
    "テレビ・音楽情報誌",
    667,
    735,
    130,
    134,
    631,
    741,
    674,
    54,
    589,
    707,
    151,
    955,
    648,
    351,
    196
  ),
  createData18(
    "コンピューター情報誌",
    397,
    933,
    267,
    400,
    168,
    289,
    616,
    924,
    419,
    941,
    511,
    436,
    841,
    888,
    753
  ),
  createData18(
    "趣味情報誌",
    482,
    846,
    317,
    463,
    172,
    158,
    869,
    34,
    638,
    622,
    155,
    734,
    177,
    919,
    863
  ),
  createData18(
    "スポーツ情報誌",
    888,
    274,
    331,
    258,
    583,
    872,
    212,
    137,
    676,
    40,
    899,
    308,
    431,
    346,
    778
  ),
  createData18(
    "娯楽情報誌",
    861,
    474,
    962,
    417,
    752,
    25,
    569,
    894,
    442,
    102,
    394,
    396,
    771,
    200,
    676
  ),
  createData18(
    "ゲーム情報誌",
    700,
    385,
    476,
    49,
    115,
    18,
    638,
    650,
    567,
    656,
    727,
    311,
    478,
    124,
    308
  ),
  createData18(
    "総合情報誌",
    769,
    565,
    976,
    460,
    689,
    454,
    856,
    56,
    279,
    459,
    484,
    556,
    305,
    126,
    708
  ),
  createData18(
    "地域情報誌",
    695,
    140,
    363,
    837,
    667,
    645,
    116,
    441,
    460,
    32,
    311,
    285,
    204,
    686,
    428
  ),
  createData18(
    "モーター誌",
    635,
    605,
    868,
    975,
    874,
    345,
    803,
    532,
    413,
    381,
    568,
    660,
    916,
    118,
    292
  ),
  createData18(
    "成人向け雑誌",
    23,
    457,
    412,
    989,
    484,
    636,
    607,
    297,
    422,
    76,
    364,
    238,
    597,
    935,
    51
  ),
  createData18(
    "青年コミック誌",
    44,
    452,
    655,
    181,
    848,
    616,
    170,
    199,
    377,
    604,
    217,
    463,
    327,
    541,
    986
  ),
  createData18(
    "コミック誌・その他",
    282,
    945,
    225,
    466,
    36,
    328,
    730,
    307,
    492,
    233,
    979,
    950,
    570,
    984,
    681
  ),
  createData18(
    "少年コミックス本",
    125,
    351,
    550,
    347,
    715,
    73,
    996,
    272,
    470,
    108,
    492,
    868,
    775,
    170,
    535
  ),
  createData18(
    "青年コミックス本",
    934,
    58,
    16,
    292,
    418,
    821,
    309,
    751,
    62,
    74,
    262,
    965,
    334,
    524,
    721
  ),
  createData18(
    "ムック・その他",
    451,
    118,
    267,
    627,
    71,
    744,
    73,
    141,
    435,
    943,
    325,
    763,
    89,
    200,
    239
  ),
  createData18(
    "地方誌",
    529,
    695,
    62,
    272,
    790,
    579,
    764,
    978,
    320,
    795,
    447,
    953,
    636,
    649,
    861
  ),
  createData18(
    "地図・その他",
    371,
    106,
    328,
    273,
    664,
    650,
    272,
    580,
    215,
    267,
    510,
    328,
    92,
    973,
    909
  ),
  createData18(
    "日刊紙",
    990,
    224,
    190,
    639,
    351,
    328,
    397,
    83,
    463,
    571,
    104,
    206,
    653,
    866,
    488
  ),
  createData18(
    "スポーツ紙",
    757,
    165,
    15,
    756,
    630,
    59,
    469,
    534,
    450,
    397,
    831,
    88,
    851,
    959,
    365
  ),
  createData18(
    "競馬新聞",
    928,
    568,
    814,
    964,
    502,
    366,
    402,
    457,
    366,
    581,
    613,
    410,
    786,
    864,
    529
  ),
  createData18(
    "地方紙・その他",
    871,
    549,
    158,
    276,
    534,
    48,
    764,
    723,
    700,
    470,
    453,
    895,
    460,
    214,
    637
  ),
  createData18(
    "青年コミックス本",
    301,
    551,
    863,
    842,
    472,
    490,
    674,
    924,
    472,
    328,
    349,
    57,
    695,
    359,
    537
  ),
  createData18(
    "少年コミックス本",
    980,
    762,
    698,
    859,
    197,
    981,
    445,
    718,
    324,
    914,
    791,
    789,
    483,
    615,
    545
  ),
  createData18(
    "文庫本・書籍",
    577,
    83,
    504,
    825,
    912,
    115,
    303,
    558,
    80,
    313,
    159,
    673,
    485,
    790,
    531
  ),
  createData18(
    "地図・その他",
    431,
    613,
    93,
    235,
    176,
    810,
    155,
    467,
    557,
    34,
    687,
    433,
    455,
    914,
    899
  ),
  createData18(
    "地図・ガイド誌・その他",
    844,
    686,
    444,
    322,
    533,
    957,
    478,
    856,
    320,
    159,
    630,
    46,
    402,
    755,
    250
  ),
  createData18(
    "単行本コミックス",
    33,
    716,
    501,
    477,
    869,
    119,
    851,
    28,
    730,
    771,
    105,
    751,
    483,
    920,
    860
  ),
  createData18(
    "廉価版コミックス",
    883,
    929,
    334,
    855,
    100,
    578,
    607,
    672,
    635,
    399,
    70,
    537,
    580,
    137,
    767
  ),
  createData18(
    "その他エンタメ",
    330,
    53,
    764,
    727,
    918,
    147,
    291,
    900,
    585,
    93,
    188,
    770,
    852,
    394,
    681
  ),
  createData18(
    "ＤＶＤ・ブルーレイソフト",
    932,
    476,
    247,
    174,
    29,
    923,
    276,
    918,
    215,
    230,
    542,
    788,
    990,
    86,
    236
  ),
  createData18(
    "音楽ＣＤ",
    418,
    468,
    991,
    369,
    259,
    552,
    891,
    414,
    748,
    359,
    163,
    854,
    173,
    503,
    50
  ),
  createData18(
    "映像・音楽ソフト（ネット）",
    899,
    995,
    857,
    461,
    248,
    21,
    861,
    536,
    411,
    159,
    632,
    451,
    419,
    898,
    996
  ),
  createData18(
    "バンドルチケット（ＳＭＳ）",
    550,
    267,
    499,
    901,
    514,
    315,
    508,
    858,
    867,
    578,
    497,
    86,
    289,
    40,
    813
  ),
  createData18(
    "パンスト",
    351,
    801,
    510,
    390,
    330,
    593,
    994,
    320,
    85,
    185,
    594,
    124,
    417,
    630,
    606
  ),
  createData18(
    "ソックス",
    49,
    197,
    301,
    221,
    312,
    245,
    831,
    189,
    352,
    265,
    413,
    112,
    987,
    926,
    298
  ),
  createData18(
    "肌着",
    562,
    186,
    104,
    998,
    123,
    512,
    936,
    381,
    235,
    281,
    102,
    100,
    430,
    20,
    982
  ),
  createData18(
    "服飾小物・季節品",
    828,
    305,
    847,
    917,
    957,
    835,
    384,
    585,
    579,
    108,
    403,
    170,
    279,
    368,
    383
  ),
  createData18(
    "軽衣料（選択）",
    551,
    851,
    116,
    20,
    551,
    385,
    110,
    529,
    768,
    769,
    212,
    548,
    184,
    876,
    146
  ),
  createData18(
    "軽衣料（ＳＩＰ）",
    950,
    153,
    234,
    921,
    249,
    33,
    149,
    185,
    168,
    722,
    983,
    38,
    411,
    477,
    372
  ),
  createData18(
    "軽衣料（ＳＭＳ）",
    380,
    435,
    404,
    534,
    26,
    886,
    943,
    96,
    572,
    569,
    867,
    204,
    956,
    647,
    724
  ),
  createData18(
    "健康・衛生用品",
    158,
    39,
    700,
    476,
    864,
    75,
    365,
    850,
    187,
    779,
    219,
    763,
    527,
    266,
    406
  ),
  createData18(
    "医薬部外品",
    541,
    895,
    884,
    20,
    495,
    401,
    246,
    780,
    180,
    172,
    23,
    535,
    522,
    992,
    485
  ),
  createData18(
    "健康・医療品（選択）",
    356,
    63,
    33,
    208,
    510,
    285,
    914,
    170,
    462,
    535,
    653,
    506,
    769,
    828,
    47
  ),
  createData18(
    "健康・医療品（ＳＩＰ）",
    733,
    929,
    66,
    560,
    817,
    408,
    33,
    733,
    861,
    168,
    702,
    122,
    614,
    44,
    677
  ),
  createData18(
    "健康・医療品（ＳＭＳ）",
    693,
    233,
    39,
    700,
    494,
    639,
    658,
    769,
    463,
    672,
    787,
    476,
    877,
    883,
    46
  ),
  createData18(
    "生理用品",
    830,
    711,
    936,
    386,
    895,
    666,
    981,
    522,
    588,
    246,
    89,
    408,
    58,
    31,
    36
  ),
  createData18(
    "紙類",
    169,
    826,
    854,
    306,
    321,
    957,
    995,
    286,
    339,
    949,
    987,
    869,
    308,
    275,
    821
  ),
  createData18(
    "紙・生理用品（選択）",
    972,
    460,
    217,
    467,
    311,
    106,
    161,
    270,
    607,
    476,
    897,
    707,
    291,
    120,
    313
  ),
  createData18(
    "紙・生理用品（ＳＩＰ）",
    757,
    906,
    267,
    389,
    334,
    90,
    977,
    507,
    726,
    952,
    419,
    667,
    489,
    656,
    123
  ),
  createData18(
    "紙・生理用品（ＳＭＳ）",
    592,
    987,
    349,
    437,
    315,
    887,
    787,
    477,
    153,
    876,
    909,
    262,
    563,
    110,
    580
  ),
  createData18(
    "ハミガキ",
    108,
    200,
    718,
    85,
    940,
    908,
    730,
    202,
    484,
    231,
    20,
    498,
    261,
    751,
    522
  ),
  createData18(
    "ハブラシ",
    813,
    864,
    704,
    563,
    196,
    940,
    499,
    611,
    218,
    53,
    450,
    463,
    798,
    13,
    426
  ),
  createData18(
    "シャンプー",
    836,
    828,
    322,
    747,
    870,
    460,
    897,
    920,
    333,
    252,
    629,
    301,
    199,
    146,
    472
  ),
  createData18(
    "コンディショナー",
    199,
    501,
    178,
    422,
    864,
    979,
    732,
    892,
    575,
    568,
    275,
    997,
    213,
    90,
    290
  ),
  createData18(
    "石鹸・入浴剤",
    192,
    163,
    565,
    814,
    999,
    842,
    48,
    303,
    657,
    484,
    85,
    227,
    203,
    331,
    776
  ),
  createData18(
    "オーラル・シャンプー（選択）",
    371,
    613,
    169,
    699,
    167,
    896,
    662,
    420,
    413,
    855,
    158,
    511,
    191,
    411,
    987
  ),
  createData18(
    "オーラル・シャンプー（ＳＩＰ）",
    849,
    19,
    677,
    684,
    967,
    833,
    58,
    879,
    328,
    894,
    552,
    413,
    173,
    36,
    832
  ),
  createData18(
    "オーラル・シャンプー（ＳＭＳ）",
    346,
    975,
    967,
    979,
    713,
    17,
    515,
    599,
    910,
    624,
    646,
    154,
    362,
    902,
    514
  ),
  createData18(
    "日用品・季節品",
    272,
    710,
    48,
    498,
    356,
    121,
    414,
    776,
    46,
    728,
    710,
    397,
    360,
    67,
    94
  ),
  createData18(
    "住居・台所洗剤",
    595,
    906,
    393,
    575,
    458,
    677,
    506,
    586,
    375,
    505,
    913,
    371,
    818,
    310,
    450
  ),
  createData18(
    "洗濯洗剤",
    725,
    707,
    28,
    258,
    982,
    789,
    719,
    789,
    891,
    466,
    709,
    959,
    53,
    210,
    270
  ),
  createData18(
    "洗剤・防虫・芳香（選択）",
    565,
    248,
    35,
    992,
    166,
    674,
    492,
    11,
    467,
    421,
    939,
    912,
    486,
    681,
    989
  ),
  createData18(
    "台所用洗剤",
    231,
    725,
    703,
    554,
    706,
    358,
    395,
    286,
    691,
    66,
    280,
    261,
    886,
    876,
    517
  ),
  createData18(
    "カイロ・殺虫剤",
    799,
    78,
    98,
    296,
    992,
    315,
    365,
    34,
    344,
    773,
    601,
    509,
    573,
    169,
    88
  ),
  createData18(
    "消臭芳香剤・その他",
    879,
    377,
    988,
    108,
    999,
    55,
    538,
    613,
    625,
    641,
    784,
    532,
    366,
    52,
    314
  ),
  createData18(
    "住居用洗剤",
    951,
    117,
    797,
    980,
    689,
    342,
    133,
    955,
    460,
    215,
    668,
    238,
    470,
    501,
    224
  ),
  createData18(
    "衣料用洗剤",
    562,
    990,
    308,
    457,
    899,
    552,
    518,
    884,
    106,
    896,
    908,
    976,
    64,
    13,
    879
  ),
  createData18(
    "洗剤・防虫・芳香（ＳＩＰ）",
    852,
    150,
    727,
    243,
    828,
    804,
    786,
    153,
    943,
    49,
    161,
    661,
    595,
    378,
    334
  ),
  createData18(
    "洗剤・防虫・芳香（ＳＭＳ）",
    429,
    999,
    888,
    144,
    679,
    207,
    781,
    259,
    581,
    880,
    135,
    316,
    510,
    930,
    177
  ),
  createData18(
    "台所・流し用品",
    542,
    933,
    403,
    299,
    938,
    272,
    723,
    809,
    400,
    868,
    827,
    635,
    174,
    150,
    826
  ),
  createData18(
    "行楽用品",
    134,
    610,
    477,
    334,
    203,
    110,
    495,
    126,
    836,
    232,
    63,
    977,
    460,
    364,
    427
  ),
  createData18(
    "タオル・浴用",
    702,
    102,
    432,
    484,
    587,
    225,
    884,
    456,
    730,
    740,
    879,
    21,
    760,
    977,
    742
  ),
  createData18(
    "スリッパ・サンダル",
    346,
    106,
    706,
    504,
    24,
    211,
    532,
    959,
    234,
    179,
    484,
    11,
    988,
    108,
    194
  ),
  createData18(
    "荷造・清掃用品",
    619,
    250,
    877,
    903,
    216,
    965,
    836,
    436,
    246,
    832,
    205,
    491,
    35,
    700,
    792
  ),
  createData18(
    "防寒用手袋",
    500,
    592,
    914,
    824,
    416,
    583,
    432,
    341,
    300,
    979,
    76,
    60,
    185,
    478,
    519
  ),
  createData18(
    "カー用品",
    304,
    904,
    825,
    979,
    538,
    606,
    997,
    795,
    540,
    675,
    803,
    484,
    481,
    751,
    788
  ),
  createData18(
    "地区指定ゴミ袋・券",
    373,
    450,
    961,
    180,
    21,
    905,
    930,
    778,
    232,
    604,
    347,
    893,
    851,
    915,
    593
  ),
  createData18(
    "季節品・その他",
    685,
    764,
    935,
    921,
    641,
    983,
    471,
    954,
    696,
    796,
    510,
    690,
    351,
    992,
    920
  ),
  createData18(
    "生活雑貨・行楽用品（選択）",
    416,
    245,
    900,
    728,
    777,
    377,
    317,
    943,
    634,
    967,
    271,
    535,
    669,
    672,
    98
  ),
  createData18(
    "洗濯・清掃用品",
    453,
    236,
    438,
    104,
    139,
    311,
    45,
    790,
    10,
    112,
    495,
    270,
    887,
    360,
    159
  ),
  createData18(
    "課税委託ゴミ袋・券",
    644,
    924,
    619,
    31,
    695,
    935,
    586,
    187,
    795,
    157,
    719,
    233,
    546,
    538,
    908
  ),
  createData18(
    "荷造・作業用品",
    145,
    84,
    635,
    505,
    176,
    882,
    268,
    950,
    141,
    337,
    304,
    305,
    607,
    138,
    473
  ),
  createData18(
    "非課税委託ゴミ袋・券",
    779,
    59,
    113,
    749,
    621,
    982,
    206,
    657,
    734,
    625,
    91,
    656,
    16,
    828,
    280
  ),
  createData18(
    "生活雑貨・行楽用品（ＳＩＰ）",
    555,
    551,
    761,
    612,
    702,
    311,
    241,
    807,
    580,
    429,
    35,
    249,
    971,
    287,
    78
  ),
  createData18(
    "生活雑貨・行楽用品（ＳＭＳ）",
    109,
    757,
    681,
    864,
    599,
    171,
    843,
    348,
    478,
    149,
    644,
    430,
    165,
    121,
    241
  ),
  createData18(
    "カミソリ",
    777,
    851,
    820,
    251,
    510,
    715,
    511,
    722,
    891,
    928,
    205,
    402,
    475,
    875,
    789
  ),
  createData18(
    "装粧・頭髪・化粧雑貨",
    162,
    154,
    508,
    860,
    328,
    779,
    957,
    712,
    892,
    481,
    998,
    663,
    947,
    485,
    248
  ),
  createData18(
    "男性化粧品",
    642,
    816,
    105,
    714,
    155,
    202,
    857,
    515,
    547,
    460,
    96,
    678,
    529,
    313,
    503
  ),
  createData18(
    "女性化粧品",
    329,
    398,
    395,
    216,
    390,
    567,
    797,
    389,
    12,
    447,
    586,
    304,
    335,
    786,
    53
  ),
  createData18(
    "資生堂化粧品",
    111,
    960,
    741,
    741,
    146,
    123,
    516,
    184,
    433,
    32,
    380,
    53,
    205,
    978,
    574
  ),
  createData18(
    "カネボウ化粧品",
    297,
    67,
    857,
    699,
    438,
    701,
    617,
    582,
    218,
    78,
    734,
    848,
    284,
    273,
    446
  ),
  createData18(
    "化粧品（選択）",
    41,
    503,
    895,
    861,
    653,
    719,
    457,
    590,
    803,
    238,
    158,
    479,
    369,
    769,
    74
  ),
  createData18(
    "女性化粧品（ブランド）",
    185,
    868,
    772,
    541,
    334,
    618,
    384,
    145,
    224,
    974,
    53,
    506,
    622,
    545,
    102
  ),
  createData18(
    "女性基礎化粧品",
    22,
    467,
    840,
    210,
    373,
    806,
    92,
    91,
    580,
    838,
    841,
    384,
    841,
    785,
    984
  ),
  createData18(
    "女性ヘア・ボディケア",
    532,
    779,
    277,
    185,
    552,
    222,
    499,
    242,
    86,
    284,
    114,
    633,
    827,
    980,
    361
  ),
  createData18(
    "女性メイクアップ",
    104,
    731,
    402,
    108,
    210,
    368,
    944,
    167,
    688,
    926,
    490,
    202,
    256,
    881,
    665
  ),
  createData18(
    "乾燥・日焼対策（男女）",
    190,
    254,
    410,
    428,
    10,
    766,
    137,
    539,
    680,
    436,
    606,
    42,
    563,
    105,
    695
  ),
  createData18(
    "化粧品（ＳＩＰ）",
    848,
    872,
    572,
    184,
    429,
    996,
    347,
    702,
    452,
    77,
    958,
    330,
    861,
    108,
    813
  ),
  createData18(
    "化粧品（ＳＭＳ）",
    716,
    745,
    742,
    599,
    252,
    595,
    202,
    312,
    460,
    541,
    408,
    354,
    303,
    125,
    743
  ),
  createData18(
    "児童玩具",
    527,
    536,
    123,
    31,
    927,
    64,
    773,
    77,
    21,
    187,
    931,
    843,
    248,
    592,
    372
  ),
  createData18(
    "ファンシー雑貨",
    997,
    622,
    566,
    760,
    711,
    141,
    170,
    212,
    431,
    10,
    657,
    262,
    311,
    509,
    702
  ),
  createData18(
    "ホビー玩具",
    594,
    153,
    190,
    571,
    743,
    126,
    11,
    402,
    434,
    450,
    120,
    411,
    455,
    94,
    562
  ),
  createData18(
    "トイゲーム",
    707,
    407,
    570,
    576,
    636,
    130,
    46,
    629,
    69,
    274,
    900,
    286,
    843,
    227,
    472
  ),
  createData18(
    "キャラクター玩具",
    578,
    427,
    88,
    944,
    770,
    332,
    126,
    231,
    825,
    193,
    488,
    270,
    147,
    724,
    839
  ),
  createData18(
    "玩具菓子（玩具）",
    638,
    540,
    445,
    617,
    930,
    115,
    837,
    609,
    348,
    582,
    831,
    482,
    74,
    407,
    369
  ),
  createData18(
    "花火",
    343,
    793,
    749,
    150,
    13,
    760,
    982,
    369,
    497,
    711,
    642,
    187,
    109,
    126,
    471
  ),
  createData18(
    "季節品",
    503,
    928,
    853,
    378,
    933,
    723,
    144,
    600,
    24,
    909,
    904,
    469,
    15,
    191,
    191
  ),
  createData18(
    "キャラクターくじ",
    795,
    374,
    805,
    91,
    260,
    297,
    765,
    268,
    921,
    781,
    537,
    487,
    523,
    182,
    273
  ),
  createData18(
    "カードゲーム",
    351,
    982,
    354,
    776,
    126,
    238,
    617,
    903,
    143,
    639,
    466,
    176,
    66,
    650,
    341
  ),
  createData18(
    "玩具菓子（菓子）",
    215,
    107,
    632,
    846,
    890,
    873,
    927,
    672,
    220,
    862,
    151,
    412,
    24,
    925,
    804
  ),
  createData18(
    "玩具（ＳＩＰ）",
    936,
    673,
    776,
    139,
    392,
    91,
    569,
    778,
    746,
    791,
    487,
    585,
    678,
    172,
    623
  ),
  createData18(
    "玩具（ＳＭＳ）",
    419,
    28,
    150,
    841,
    892,
    357,
    258,
    185,
    774,
    225,
    439,
    180,
    431,
    147,
    705
  ),
  createData18(
    "筆記用品",
    623,
    605,
    794,
    843,
    272,
    271,
    70,
    747,
    494,
    600,
    991,
    589,
    411,
    911,
    487
  ),
  createData18(
    "袋製品",
    729,
    346,
    97,
    491,
    343,
    424,
    242,
    407,
    772,
    994,
    822,
    42,
    53,
    102,
    352
  ),
  createData18(
    "ノート・紙製品",
    623,
    157,
    335,
    602,
    351,
    960,
    253,
    209,
    909,
    738,
    358,
    58,
    60,
    908,
    552
  ),
  createData18(
    "一般事務用文具・その他",
    790,
    97,
    66,
    177,
    328,
    280,
    125,
    43,
    337,
    171,
    650,
    659,
    959,
    760,
    30
  ),
  createData18(
    "学童文具",
    814,
    435,
    232,
    152,
    292,
    727,
    342,
    287,
    324,
    20,
    231,
    355,
    687,
    953,
    168
  ),
  createData18(
    "キャラクター文具",
    192,
    448,
    114,
    91,
    871,
    806,
    791,
    276,
    582,
    811,
    277,
    894,
    120,
    928,
    572
  ),
  createData18(
    "文具季節品１",
    12,
    453,
    669,
    416,
    373,
    380,
    578,
    86,
    662,
    878,
    574,
    82,
    948,
    959,
    403
  ),
  createData18(
    "文具季節品２",
    195,
    537,
    429,
    30,
    580,
    515,
    911,
    484,
    688,
    198,
    287,
    165,
    896,
    390,
    422
  ),
  createData18(
    "文具（選択）",
    954,
    422,
    764,
    599,
    438,
    60,
    595,
    95,
    661,
    871,
    175,
    939,
    814,
    579,
    645
  ),
  createData18(
    "文具（ＳＩＰ）",
    385,
    618,
    827,
    157,
    874,
    933,
    516,
    743,
    420,
    990,
    246,
    641,
    244,
    778,
    671
  ),
  createData18(
    "文具（ＳＭＳ）",
    858,
    996,
    970,
    918,
    998,
    703,
    803,
    914,
    856,
    31,
    922,
    210,
    758,
    222,
    187
  ),
  createData18(
    "記録媒体",
    338,
    454,
    814,
    172,
    282,
    492,
    339,
    823,
    357,
    63,
    41,
    834,
    512,
    417,
    692
  ),
  createData18(
    "ビデオテープ・ＤＶＤ・その他",
    918,
    864,
    198,
    561,
    351,
    650,
    436,
    841,
    484,
    989,
    108,
    895,
    458,
    181,
    209
  ),
  createData18(
    "乾電池",
    883,
    54,
    976,
    213,
    855,
    236,
    508,
    540,
    384,
    722,
    662,
    563,
    898,
    42,
    797
  ),
  createData18(
    "電気小物",
    265,
    336,
    79,
    623,
    775,
    739,
    737,
    236,
    527,
    267,
    365,
    309,
    220,
    901,
    590
  ),
  createData18(
    "喫煙具",
    688,
    351,
    282,
    378,
    484,
    220,
    753,
    780,
    577,
    268,
    890,
    428,
    95,
    802,
    767
  ),
  createData18(
    "ペットフード",
    122,
    841,
    867,
    489,
    527,
    319,
    242,
    237,
    90,
    160,
    845,
    189,
    99,
    196,
    97
  ),
  createData18(
    "雨具",
    763,
    431,
    317,
    415,
    861,
    976,
    655,
    880,
    959,
    411,
    237,
    357,
    747,
    310,
    178
  ),
  createData18(
    "手提袋",
    62,
    125,
    14,
    779,
    20,
    882,
    524,
    658,
    241,
    480,
    264,
    417,
    238,
    547,
    669
  ),
  createData18(
    "喫煙し好品",
    42,
    754,
    541,
    512,
    955,
    846,
    594,
    197,
    292,
    844,
    925,
    554,
    643,
    997,
    542
  ),
  createData18(
    "季節品・その他",
    532,
    75,
    884,
    186,
    519,
    296,
    167,
    238,
    737,
    308,
    152,
    187,
    896,
    64,
    716
  ),
  createData18(
    "ペットフード（選択）",
    466,
    454,
    267,
    658,
    721,
    40,
    587,
    243,
    301,
    921,
    641,
    980,
    274,
    769,
    406
  ),
  createData18(
    "季節品・その他２",
    646,
    835,
    540,
    948,
    530,
    272,
    774,
    678,
    279,
    660,
    398,
    374,
    957,
    482,
    147
  ),
  createData18(
    "季節品・その他１",
    853,
    518,
    932,
    932,
    215,
    497,
    54,
    715,
    601,
    746,
    830,
    850,
    909,
    597,
    710
  ),
  createData18(
    "季節品・その他３",
    810,
    714,
    700,
    692,
    274,
    696,
    811,
    427,
    329,
    818,
    257,
    254,
    540,
    184,
    330
  ),
  createData18(
    "買い物袋",
    721,
    884,
    383,
    113,
    367,
    331,
    790,
    248,
    341,
    43,
    710,
    106,
    178,
    132,
    731
  ),
  createData18(
    "その他雑貨（ＳＭＳ）",
    945,
    936,
    186,
    83,
    714,
    149,
    76,
    931,
    870,
    549,
    501,
    500,
    108,
    759,
    407
  ),
  createData18(
    "その他雑貨（ＳＩＰ）",
    374,
    938,
    121,
    953,
    864,
    241,
    990,
    853,
    41,
    106,
    708,
    303,
    340,
    607,
    365
  ),
  createData18(
    "テレカ・携帯電話・音楽関連",
    571,
    357,
    419,
    165,
    718,
    758,
    68,
    651,
    180,
    621,
    550,
    263,
    154,
    422,
    988
  ),
  createData18(
    "切手",
    786,
    305,
    343,
    257,
    709,
    85,
    125,
    846,
    468,
    103,
    658,
    16,
    848,
    412,
    754
  ),
  createData18(
    "はがき",
    122,
    400,
    123,
    343,
    663,
    923,
    460,
    910,
    69,
    118,
    391,
    746,
    885,
    18,
    610
  ),
  createData18(
    "印紙",
    665,
    702,
    259,
    531,
    843,
    228,
    643,
    819,
    967,
    507,
    853,
    83,
    988,
    336,
    815
  ),
  createData18(
    "宅配便",
    235,
    39,
    503,
    901,
    817,
    880,
    559,
    727,
    358,
    725,
    511,
    789,
    66,
    814,
    589
  ),
  createData18(
    "ＤＰＥ",
    677,
    513,
    210,
    896,
    142,
    263,
    743,
    954,
    515,
    904,
    737,
    850,
    256,
    318,
    262
  ),
  createData18(
    "コピー・プリント",
    175,
    157,
    927,
    368,
    551,
    141,
    676,
    205,
    360,
    133,
    70,
    585,
    587,
    433,
    518
  ),
  createData18(
    "印刷サービス",
    890,
    310,
    286,
    153,
    986,
    664,
    434,
    696,
    550,
    861,
    762,
    489,
    500,
    839,
    157
  ),
  createData18(
    "取次サービス",
    243,
    338,
    426,
    470,
    275,
    296,
    813,
    416,
    858,
    480,
    715,
    388,
    800,
    390,
    621
  ),
  createData18(
    "値引き用クーポン",
    791,
    314,
    196,
    791,
    616,
    987,
    300,
    979,
    590,
    803,
    745,
    612,
    906,
    580,
    200
  ),
  createData18(
    "７ＮＯＷ",
    175,
    416,
    529,
    521,
    546,
    173,
    82,
    661,
    715,
    996,
    701,
    684,
    467,
    935,
    550
  ),
  createData18(
    "前売券１",
    530,
    582,
    599,
    109,
    52,
    519,
    145,
    306,
    622,
    250,
    222,
    697,
    901,
    200,
    950
  ),
  createData18(
    "前売券２",
    754,
    265,
    72,
    123,
    557,
    717,
    974,
    144,
    786,
    580,
    981,
    701,
    414,
    125,
    157
  ),
  createData18(
    "ハイウェイカード",
    614,
    330,
    310,
    358,
    427,
    342,
    580,
    524,
    473,
    559,
    945,
    683,
    254,
    423,
    790
  ),
  createData18(
    "クオカード",
    806,
    484,
    590,
    278,
    199,
    902,
    586,
    216,
    937,
    774,
    278,
    917,
    286,
    620,
    956
  ),
  createData18(
    "ｎａｎａｃｏカード",
    74,
    912,
    22,
    243,
    778,
    58,
    572,
    32,
    180,
    322,
    311,
    425,
    222,
    421,
    31
  ),
  createData18(
    "ＰＯＳＡカード",
    307,
    865,
    165,
    937,
    492,
    753,
    513,
    279,
    568,
    617,
    901,
    447,
    740,
    95,
    22
  ),
  createData18(
    "ＰＯＳＡカード（会計用）",
    53,
    534,
    495,
    527,
    51,
    331,
    560,
    143,
    330,
    254,
    448,
    726,
    39,
    527,
    296
  ),
  createData18(
    "前売券３",
    548,
    165,
    626,
    459,
    836,
    710,
    699,
    689,
    985,
    720,
    896,
    25,
    774,
    610,
    41
  ),
];

const createData19 = (
  date: string,
  day_of_week: number,
  pma00: number,
  pma04: number,
  pma05: number,
  pma09: number,
  pma10: number,
  pma11: number,
  pma12: number,
  pma15: number,
  pma21: number,
  pma25: number,
  pma29: number,
  pma30: number,
) => {
  return {
    date,
    day_of_week,
    pma00,
    pma04,
    pma05,
    pma09,
    pma10,
    pma11,
    pma12,
    pma15,
    pma21,
    pma25,
    pma29,
    pma30,
  };
};

export const rows19 = [
  createData19(
    "1",
    4,
    21578,
    5330,
    1460,
    2740,
    810 ,
    546 ,
    4121,
    3159,
    336 ,
    2196,
    424 ,
    456 ,
  ),
  createData19(
    "2",
    5,
    15974,
    2930,
    600 ,
    4180,
    986,
    510,
    1283,
    3201,
    0,
    996,
    148,
    0,
  ),
  createData19(
    "3",
    6,
    21329,
    5390,
    900,
    2120,
    3524,
    158,
    2355,
    5996,
    0,
    746,
    140,
    0,
  ),
  createData19(
    "4",
    7,
    34343,
    15140,
    750,
    1490,
    6772,
    0,
    1396,
    7539,
    178,
    1078,
    0,
    0,
  ),
  createData19(
    "5",
    1,
    26181,
    9655,
    180,
    3010,
    1866,
    1734,
    2068,
    5784,
    0,
    266,
    0,
    1152,
  ),
  createData19(
    "6",
    2,
    41288,
    11175,
    340,
    7170,
    10182,
    170,
    2069,
    5044,
    0,
    624,
    918,
    2868,
  ),
  createData19(
    "7",
    3,
    60145,
    17075,
    2974,
    2220,
    23622,
    2540,
    1172,
    5546,
    0,
    598,
    910,
    3488,
  ),
  createData19(
    "8",
    4,
    21578,
    5330,
    1460,
    2740,
    810 ,
    546 ,
    4121,
    3159,
    336 ,
    2196,
    424 ,
    456 ,
  ),
  createData19(
    "9",
    5,
    15974,
    2930,
    600 ,
    4180,
    986,
    510,
    1283,
    3201,
    0,
    996,
    148,
    0,
  ),
  createData19(
    "10",
    6,
    21329,
    5390,
    900,
    2120,
    3524,
    158,
    2355,
    5996,
    0,
    746,
    140,
    0,
  ),
  createData19(
    "11",
    7,
    34343,
    15140,
    750,
    1490,
    6772,
    0,
    1396,
    7539,
    178,
    1078,
    0,
    0,
  ),
  createData19(
    "12",
    1,
    26181,
    9655,
    180,
    3010,
    1866,
    1734,
    2068,
    5784,
    0,
    266,
    0,
    1152,
  ),
  createData19(
    "13",
    2,
    41288,
    11175,
    340,
    7170,
    10182,
    170,
    2069,
    5044,
    0,
    624,
    918,
    2868,
  ),
  createData19(
    "14",
    3,
    60145,
    17075,
    2974,
    2220,
    23622,
    2540,
    1172,
    5546,
    0,
    598,
    910,
    3488,
  ),
  createData19(
    "15",
    4,
    21578,
    5330,
    1460,
    2740,
    810 ,
    546 ,
    4121,
    3159,
    336 ,
    2196,
    424 ,
    456 ,
  ),
  createData19(
    "16",
    5,
    15974,
    2930,
    600 ,
    4180,
    986,
    510,
    1283,
    3201,
    0,
    996,
    148,
    0,
  ),
  createData19(
    "17",
    6,
    21329,
    5390,
    900,
    2120,
    3524,
    158,
    2355,
    5996,
    0,
    746,
    140,
    0,
  ),
  createData19(
    "18",
    7,
    34343,
    15140,
    750,
    1490,
    6772,
    0,
    1396,
    7539,
    178,
    1078,
    0,
    0,
  ),
  createData19(
    "19",
    1,
    26181,
    9655,
    180,
    3010,
    1866,
    1734,
    2068,
    5784,
    0,
    266,
    0,
    1152,
  ),
  createData19(
    "20",
    2,
    41288,
    11175,
    340,
    7170,
    10182,
    170,
    2069,
    5044,
    0,
    624,
    918,
    2868,
  ),
  createData19(
    "21",
    3,
    60145,
    17075,
    2974,
    2220,
    23622,
    2540,
    1172,
    5546,
    0,
    598,
    910,
    3488,
  ),
  createData19(
    "22",
    4,
    21578,
    5330,
    1460,
    2740,
    810 ,
    546 ,
    4121,
    3159,
    336 ,
    2196,
    424 ,
    456 ,
  ),
  createData19(
    "23",
    5,
    15974,
    2930,
    600 ,
    4180,
    986,
    510,
    1283,
    3201,
    0,
    996,
    148,
    0,
  ),
  createData19(
    "24",
    6,
    21329,
    5390,
    900,
    2120,
    3524,
    158,
    2355,
    5996,
    0,
    746,
    140,
    0,
  ),
  createData19(
    "25",
    7,
    34343,
    15140,
    750,
    1490,
    6772,
    0,
    1396,
    7539,
    178,
    1078,
    0,
    0,
  ),
  createData19(
    "26",
    1,
    26181,
    9655,
    180,
    3010,
    1866,
    1734,
    2068,
    5784,
    0,
    266,
    0,
    1152,
  ),
  createData19(
    "27",
    2,
    41288,
    11175,
    340,
    7170,
    10182,
    170,
    2069,
    5044,
    0,
    624,
    918,
    2868,
  ),
  createData19(
    "28",
    3,
    60145,
    17075,
    2974,
    2220,
    23622,
    2540,
    1172,
    5546,
    0,
    598,
    910,
    3488,
  ),
  createData19(
    "29",
    4,
    21578,
    5330,
    1460,
    2740,
    810 ,
    546 ,
    4121,
    3159,
    336 ,
    2196,
    424 ,
    456 ,
  ),
  createData19(
    "30",
    5,
    15974,
    2930,
    600 ,
    4180,
    986,
    510,
    1283,
    3201,
    0,
    996,
    148,
    0,
  ),
  createData19(
    "31",
    6,
    21329,
    5390,
    900,
    2120,
    3524,
    158,
    2355,
    5996,
    0,
    746,
    140,
    0,
  ),
];

const createData20 = (
  name: string,
  pma00: number,
  pma04: number,
  pma05: number,
  pma09: number,
  pma10: number,
  pma11: number,
  pma12: number,
  pma15: number,
  pma21: number,
  pma25: number,
  pma29: number,
  pma30: number,
) => {
  return {
    name,
    pma00,
    pma04,
    pma05,
    pma09,
    pma10,
    pma11,
    pma12,
    pma15,
    pma21,
    pma25,
    pma29,
    pma30,
  };
};

export const rows20 = [
  createData20(
    "average",
    43899,
    13721,
    3358,
    5930,
    7182,
    770,
    2121,
    5284,
    100,
    950,
    718,
    2066,
  ),
  createData20(
    "total",
    1360881,
    425355,
    104108,
    183820,
    222632,
    23870,
    65751,
    163798,
    3098,
    29460,
    22260,
    64047,
  ),
  createData20(
    "budget",
    36013,
    9300,
    2250,
    4963,
    5000,
    2000,
    3500,
    6000,
    100,
    900,
    450,
    1550,
  ),
];
