/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const timeMsg = css`
  font-size: 12px;
  height: 12px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: right;
  top: 5%;
`;

const UpdateTime: React.FC<{ unixtime: string }> = ({ unixtime }) => {
  
  const convertUnixTime = (unixtime: string) => {
    if (unixtime) {
      let dt = new Date(parseInt(unixtime, 10) * 1000);
      // 更新時間は「更新時間帯」なので+1時間する
      dt.setHours(dt.getHours() + 1);
      return `${dt.toLocaleDateString("ja-JP")}  
      ${dt.toLocaleTimeString("ja-JP")}`;
    } else {
      return "";
    }
  };

  return (
    <div
      css={timeMsg}
      style={{
        position: "absolute",
        right: "10%",
      }}
    >
      {"当日データの更新時間：" + convertUnixTime(unixtime)}
    </div>
  );
};

export default UpdateTime;
