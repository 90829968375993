import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Modal,
  Button,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import Header from "../common/Header";
import TimeOutDisplay from "../common/TimeOutDisplay";
import {
  timer,
  dataChange,
  getHolidayStyle,
  getLastYearHolidayStyle,
  fontTheme,
} from "../common/utils";
import { apiRequest } from "../../apis/util";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../data/userInfo";
import { ThemeProvider } from "@mui/material/styles";
import APIErrorModal from "../common/APIErrorModal";
import { userAuthCheck } from "../auth/authUser";

const card = css`
  margin: 80px 16px 16px 16px;
  width: 100%;
  @media screen and (min-width: 1194px) {
    height: 620px;
  }
  @media screen and (min-width: 1280px) {
    margin: 70px 16px 16px 16px;
    height: 560x;
  }
  @media screen and (min-width: 1366px) {
    height: 545px;
  }
`;
const tableContainer = css`
  overflow: scroll;
  border-spacing: 1;
  @media screen and (min-width: 1194px) {
    height: 580px;
  }
  @media screen and (min-width: 1280px) {
    height: 450px;
  }
  @media screen and (min-width: 1366px) {
    height: 500px;
  }
`;
const commonCell = css`
  margin: 0;
  padding: 0;
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  box-sizing: border-box;
`;
const header = css`
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  border-top: none;
  border-right: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  border-left: none;
  background-color: #e0ffee;
  z-index: 5;
`;
const changeButtonCell = css`
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  padding: 4px;
  font-weight: bold;
  text-align: center;
  background-color: #e0ffee;
  border: 2px solid #c4c4c4;
  z-index: 5;
`;
const yearDateCell = css`
  ${changeButtonCell}
  left: 76px;
  background-color: #e0ffee;
  border-left: 0;
  font-weight: bold;
  text-align: center;
  z-index: 5;
`;
const mainHeaderCell = css`
  ${changeButtonCell}
  max-width: 210px;
  min-width: 210px;
  font-weight: bold;
  text-align: center;
  border-left: 0;
  z-index: 3;
`;
const thisYearDateCell = css`
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  font-weight: bold;
  text-align: center;
  background-color: #e0ffee;
  border-top: 0;
  border-right: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  border-left: 2px solid #c4c4c4;
  z-index: 4;
`;
const lastYearDateCell = css`
  ${thisYearDateCell}
  left: 76px;
  border-left: 0;
`;
const averageTotalCell = css`
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  font-weight: normal;
  background-color: #ffffff;
  text-align: center;
  border-top: 0;
  border-right: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  border-left: 2px solid #c4c4c4;
  z-index: 4;
`;
const secondHeaderCell = css`
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  width: 70px;
  text-align: center;
  font-weight: bold;
  border-right: 2px dotted #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  background-color: #e0ffee;
  z-index: 2;
`;
const borderSecondHeaderCell = css`
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  width: 70px;
  text-align: center;
  font-weight: bold;
  border-right: 2px solid #C4C4C4;
  border-bottom: 2px solid #c4c4c4;
  background-color: #e0ffee;
  z-index: 2;
`;
const thisYearDailyCell = css`
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  text-align: center;
  height: 26px;
  background-color: #ffffff;
  border-left: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  z-index: 2;
`;
const lastYearDailyCell = css`
  ${commonCell}
  position: sticky;
  top: 0;
  left: 0;
  text-align: center;
  background-color: #ffffff;
  border-left: none;
  border-right: 2px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  z-index: 2;
`;
const numberCell = css`
  margin: 0;
  white-space: nowrap;
  font-size: 12px;
  padding: 4px;
  font-weight: normal;
  background-color: #ffffff;
  border-right: 2px dotted #c4c4c4;
  border-left: 0px;
  border-bottom: 1px solid #c4c4c4;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
  text-align: right;
`;
const borderNumberCell = css`
  margin: 0;
  white-space: nowrap;
  font-size: 12px;
  padding: 4px;
  font-weight: normal;
  background-color: #ffffff;
  border-right: 2px solid #c4c4c4;
  border-left: 0px;
  border-bottom: 1px solid #c4c4c4;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
  text-align: right;
`;
const averageNumberCell = css`
  ${commonCell}
  font-weight: normal;
  padding-right: 4px;
  border-right: 2px dotted #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  text-align: right;
`;
const averageBorderNumberCell = css`
  ${commonCell}
  font-weight: normal;
  padding-right: 4px;
  border-right: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  text-align: right;
`;
const calendar = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  box-shadow: 24px;
  text-align: center;
`;
const change = css`
  font-size: 12px;
  border-radius: 12px;
  background-color: #00cc5b;
  color: #fff;
  display: block;
  margin: auto;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #00cc5bb3;
  }
`;
const circularProgress = css`
  position: absolute;
  top: 45%;
  left: 45%;
  opacity: 0.8;
  color: #ccc;
`;

const DailyManagement: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [dateCount, setDateCount] = useState<number>(0);
  const [tenpoCode, setTenpoCode] = useState<string | null>("");
  const [tenpoName, setTenpoName] = useState<string>("");
  const [getWidthLeft, setGetWidthLeft] = useState("");
  const [date, setDate] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [timeout, setTimeout] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [errorAPI, setErrorAPI] = useState(false);
  const modalClose = () => setErrorAPI(false);

  const search = useLocation().search;
  const usp = new URLSearchParams(search);
  const windowName = "日別管理表";
  let res: any = {};
  let arrangeData: any = [];
  let resSort: any = [];

  useEffect(() => {
    const tc: string | null = usp.get("tenpocode");
    const userInfo = getUserInfo();
    if (process.env.REACT_APP_MODE !== "develop") {
      // 担当店と職種で画面表示を制御
      if (tc !== null && userAuthCheck(userInfo, tc)) {
        setTenpoCode(tc);
      } else {
        navigate("/tenpo-error");
      }
    } else {
      // ローカルでは制御しない
      if (tc !== null) {
        setTenpoCode(tc);
      }
    }
    let dt = new Date();
    const year = dt.getFullYear();
    const month = ("00" + (dt.getMonth() + 1)).slice(-2);
    setDate(year + month);
    window.setTimeout(dispMsg, timer);
  }, []);

  useEffect(() => {
    const params = {
      mode: "dailyManagement",
      tenpoCode: tenpoCode,
      yearMonth: date,
    };
    if (tenpoCode && date) {
      (async () => {
        try {
          setLoading(true);
          res = await apiRequest(params);
          resSort = res.data.data.dailyManagementSearchByParams;
          if (resSort.length) {
            arrangeData = resSort.sort(function (a: any, b: any) {
              return a.date < b.date ? -1 : 1;
            });
          } else {
            setData([]);
            throw new Error("API response error");
          }
        } catch (e) {
          setErrorAPI(true);
        }
        if (resSort.length !== 0) {
          setData(resSort);
          setDateCount(resSort.length - 1);
          if (resSort[0]?.tenpo_name !== null) {
            setTenpoName(resSort[0]?.tenpo_name);
          }
        }
        setLoading(false);
      })();
    }
  }, [tenpoCode, date]);

  useEffect(() => {
    let changeButtonCell = document.getElementById("changeButtonCell");
    let x = "76";
    if (data !== null) {
      x = changeButtonCell?.getBoundingClientRect().width + "px";
    }
    setGetWidthLeft(x);
  }, [loading]);

  useEffect(() => {
    if (!timeout) {
      window.setTimeout(dispMsg, timer);
    }
  }, [timeout]);

  const week = (data: any) => {
    const mm = Number(data.date.slice(-2));
    let weekNum = "";
    switch (data.day_of_week) {
      case 1:
        weekNum = "(日)";
        break;
      case 2:
        weekNum = "(月)";
        break;
      case 3:
        weekNum = "(火)";
        break;
      case 4:
        weekNum = "(水)";
        break;
      case 5:
        weekNum = "(木)";
        break;
      case 6:
        weekNum = "(金)";
        break;
      case 7:
        weekNum = "(土)";
        break;
    }
    const newDate = mm + weekNum;
    return newDate;
  };

  const weekLastYear = (data: any) => {
    const mm = Number(data.date_last_year.slice(-2));
    let weekNum = "";
    switch (data.day_of_week_last_year) {
      case 1:
        weekNum = "(日)";
        break;
      case 2:
        weekNum = "(月)";
        break;
      case 3:
        weekNum = "(火)";
        break;
      case 4:
        weekNum = "(水)";
        break;
      case 5:
        weekNum = "(木)";
        break;
      case 6:
        weekNum = "(金)";
        break;
      case 7:
        weekNum = "(土)";
        break;
    }
    const newDate = mm + weekNum;
    return newDate;
  };

  const yyyymm = (date: string) => {
    const yyyy = date.slice(0, 4);
    const mm = date.slice(4);
    const daily = yyyy + "年" + mm + "月";
    return daily;
  };

  const dispMsg = () => {
    setTimeout(true);
  };

  const mainClassification = [
    "商品売上高(売上日報)",
    "客数(売上日報)",
    "商品廃棄",
    "客単価",
    "買上点数",
    "朝帯売上(0~10)",
    "昼帯売上(10~16)",
    "夜帯売上(16~24)",
    "時間帯別売上(0~24)",
    "朝帯客数(0~10)",
    "昼帯客数(10~16)",
    "夜帯客数(16~24)",
    "時間帯別客数(0~24)",
  ];

  const units = [
    "(円)",
    "(人)",
    "(円)",
    "(円)",
    "(点)",
    "(円)",
    "(円)",
    "(円)",
    "(円)",
    "(人)",
    "(人)",
    "(人)",
    "(人)",
  ];

  //　過去12か月分取得
  let arr: string[] = [];
  for (let i = 0; i < 12; i++) {
    let dt = new Date();
    let startDate = new Date(dt.getFullYear(), dt.getMonth(), 1);
    startDate.setMonth(startDate.getMonth() - i);
    const year = startDate.getFullYear();
    const month = ("00" + (startDate.getMonth() + 1)).slice(-2);
    arr.push(year + month);
  }

  const thisY = new Date().getFullYear();
  const lastY = thisY - 1;

  // 去年の月一覧
  const lastYearMonths = [
    lastY + "01",
    lastY + "02",
    lastY + "03",
    lastY + "04",
    lastY + "05",
    lastY + "06",
    lastY + "07",
    lastY + "08",
    lastY + "09",
    lastY + "10",
    lastY + "11",
    lastY + "12",
  ];

  // 今年度の月一覧
  const thisYearMonths = [
    thisY + "01",
    thisY + "02",
    thisY + "03",
    thisY + "04",
    thisY + "05",
    thisY + "06",
    thisY + "07",
    thisY + "08",
    thisY + "09",
    thisY + "10",
    thisY + "11",
    thisY + "12",
  ];

  // ボタン活性化
  const activeLastYear = (lastMonth: string) => {
    let disabled: boolean = false;
    if (arr.includes(lastMonth)) {
      disabled = true;
    }
    return disabled;
  };

  const activeThisYear = (thisMonth: string) => {
    let disabled: boolean = false;
    if (arr.includes(thisMonth)) {
      disabled = true;
    }
    return disabled;
  };

  const getSecondHeader = () => {
    const items = [];
    for (let i = 0; i < mainClassification.length; i++) {
      items.push(
        <>
          <TableCell css={secondHeaderCell}>
            実績
            <br />
            {units[i]}
          </TableCell>
          <TableCell css={secondHeaderCell}>
            前年比
            <br />
            (%)
          </TableCell>
          <TableCell css={borderSecondHeaderCell}>
            前年実績
            <br />
            {units[i]}
          </TableCell>
        </>
      );
    }
    return items;
  };

  return timeout ? (
    <TimeOutDisplay setTimeout={setTimeout} />
  ) : (
    <>
      <ThemeProvider theme={fontTheme()}>
        <Header
          windowName={windowName}
          tenpoCode={tenpoCode ? tenpoCode : ""}
          tenpoName={tenpoName ? tenpoName : ""}
        />
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Card css={card} variant="outlined">
              <Modal open={errorAPI} onClose={modalClose}>
                <APIErrorModal />
              </Modal>
              <CardContent>
                {loading && (
                  <div>
                    <CircularProgress css={circularProgress} size={120} />
                  </div>
                )}
                <TableContainer css={tableContainer}>
                  <Table stickyHeader>
                    <TableHead css={header}>
                      <TableRow css>
                        <TableCell css={changeButtonCell} id="changeButtonCell">
                          <Button onClick={handleOpen} css={change}>
                            月度変更
                          </Button>
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <div css={calendar}>
                              <Table>
                                <TableHead>
                                  <TableCell css={lastYearDateCell} colSpan={4}>
                                    {lastY}
                                  </TableCell>
                                </TableHead>
                                <TableBody>
                                  {lastYearMonths.map((lastMonth, index) => (
                                    <Button
                                      disabled={!activeLastYear(lastMonth)}
                                      onClick={() => {
                                        setDate(lastMonth);
                                        setOpen(false);
                                      }}
                                      key={index}
                                    >
                                      {index + 1}月
                                    </Button>
                                  ))}
                                </TableBody>
                              </Table>
                              <Table>
                                <TableHead>
                                  <TableCell css={lastYearDateCell} colSpan={4}>
                                    {thisY}
                                  </TableCell>
                                </TableHead>
                                <TableBody>
                                  {thisYearMonths.map((thisMonth, index) => (
                                    <Button
                                      disabled={!activeThisYear(thisMonth)}
                                      onClick={() => {
                                        setDate(thisMonth);
                                        setOpen(false);
                                      }}
                                      key={index}
                                    >
                                      {index + 1}月
                                    </Button>
                                  ))}
                                </TableBody>
                              </Table>
                            </div>
                          </Modal>
                        </TableCell>
                        <TableCell
                          css={yearDateCell}
                        >
                          {yyyymm(date)}
                        </TableCell>
                        {mainClassification.map(
                          (mainClassification: any, index) => (
                            <TableCell
                              css={mainHeaderCell}
                              colSpan={3}
                              key={index}
                            >
                              {mainClassification}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell css={thisYearDateCell} id={"id"}>
                          本年日付
                        </TableCell>
                        <TableCell
                          css={lastYearDateCell}
                          >
                            前年日付
                            </TableCell>
                        {getSecondHeader()}
                      </TableRow>
                      <TableRow>
                        <TableCell css={averageTotalCell} colSpan={2}>
                          平均
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.sales_monthly_average_report)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.sales_monthly_average_yoy_report,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.sales_monthly_average_last_year_report
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.customer_count_monthly_average_report
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.customer_count_monthly_average_yoy_report,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]
                              ?.customer_count_monthly_average_last_year_report
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.waste_monthly_average_report)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.waste_monthly_average_yoy_report,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.waste_monthly_average_last_year_report
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.unit_price_monthly_average_report
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.unit_price_monthly_average_yoy_report,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.unit_price_monthly_average_last_year_report
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.quantity_monthly_average_report,
                            true,
                            2
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.quantity_monthly_average_yoy_report,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.quantity_monthly_average_last_year_report,
                            true,
                            2
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.morning_sales_monthly_average)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.morning_sales_monthly_average_yoy,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.morning_sales_monthly_average_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.daytime_sales_monthly_average)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.daytime_sales_monthly_average_yoy,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.daytime_sales_monthly_average_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.night_sales_monthly_average)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.night_sales_monthly_average_yoy,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.night_sales_monthly_average_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.allday_sales_monthly_average)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.allday_sales_monthly_average_yoy,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.allday_sales_monthly_average_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.morning_customer_count_monthly_average
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.morning_customer_count_monthly_average_yoy,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]
                              ?.morning_customer_count_monthly_average_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.daytime_customer_count_monthly_average
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.daytime_customer_count_monthly_average_yoy,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]
                              ?.daytime_customer_count_monthly_average_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.night_customer_count_monthly_average
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.night_customer_count_monthly_average_yoy,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]
                              ?.night_customer_count_monthly_average_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.allday_customer_count_monthly_average
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.allday_customer_count_monthly_average_yoy,
                            true
                          )}
                        </TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]
                              ?.allday_customer_count_monthly_average_last_year
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell css={averageTotalCell} colSpan={2}>
                          累計
                        </TableCell>
                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.sales_monthly_total_report)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.sales_monthly_total_last_year_report
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.customer_count_monthly_total_report
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]
                              ?.customer_count_monthly_total_last_year_report
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.waste_monthly_total_report)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.waste_monthly_total_last_year_report
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.unit_price_monthly_total_report)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.unit_price_monthly_total_last_year_report
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.quantity_monthly_total_report,
                            true,
                            2
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.quantity_monthly_total_last_year_report,
                            true,
                            2
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.morning_sales_monthly_total)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.morning_sales_monthly_total_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.daytime_sales_monthly_total)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.daytime_sales_monthly_total_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.night_sales_monthly_total)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.night_sales_monthly_total_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(data[dateCount]?.allday_sales_monthly_total)}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]?.allday_sales_monthly_total_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.morning_customer_count_monthly_total
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]
                              ?.morning_customer_count_monthly_total_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.daytime_customer_count_monthly_total
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]
                              ?.daytime_customer_count_monthly_total_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.night_customer_count_monthly_total
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]
                              ?.night_customer_count_monthly_total_last_year
                          )}
                        </TableCell>

                        <TableCell css={averageNumberCell}>
                          {dataChange(
                            data[dateCount]?.allday_customer_count_monthly_total
                          )}
                        </TableCell>
                        <TableCell css={averageNumberCell}>-</TableCell>
                        <TableCell css={averageBorderNumberCell}>
                          {dataChange(
                            data[dateCount]
                              ?.allday_customer_count_monthly_total_last_year
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((data: any, index: number) => (
                        <TableRow>
                          <TableCell
                            css={thisYearDailyCell}
                            style={{ color: getHolidayStyle(data) }}
                            key={index}
                          >
                            {week(data)}
                          </TableCell>
                          <TableCell
                            css={lastYearDailyCell}
                            style={{
                              color: getLastYearHolidayStyle(data),
                              left: getWidthLeft,
                            }}
                          >
                            {weekLastYear(data)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(data?.sales_daily_report)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(data?.sales_daily_yoy_report, true)}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(data?.sales_daily_last_year_report)}
                          </TableCell>

                          <TableCell css={numberCell}>
                            {dataChange(data?.customer_count_daily_report)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(
                              data?.customer_count_daily_yoy_report,
                              true
                            )}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(
                              data?.customer_count_daily_last_year_report
                            )}
                          </TableCell>

                          <TableCell css={numberCell}>
                            {dataChange(data?.waste_daily_report)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(data?.waste_daily_yoy_report, true)}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(data?.waste_daily_last_year_report)}
                          </TableCell>

                          <TableCell css={numberCell}>
                            {dataChange(data?.unit_price_daily_report)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(
                              data?.unit_price_daily_yoy_report,
                              true
                            )}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(
                              data?.unit_price_daily_last_year_report
                            )}
                          </TableCell>

                          <TableCell css={numberCell}>
                            {dataChange(data?.quantity_daily_report, true, 2)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(data?.quantity_daily_yoy_report, true)}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(
                              data?.quantity_daily_last_year_report,
                              true,
                              2
                            )}
                          </TableCell>

                          <TableCell css={numberCell}>
                            {dataChange(data?.morning_sales_daily)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(data?.morning_sales_daily_yoy, true)}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(data?.morning_sales_daily_last_year)}
                          </TableCell>

                          <TableCell css={numberCell}>
                            {dataChange(data?.daytime_sales_daily)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(data?.daytime_sales_daily_yoy, true)}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(data?.daytime_sales_daily_last_year)}
                          </TableCell>

                          <TableCell css={numberCell}>
                            {dataChange(data?.night_sales_daily)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(data?.night_sales_daily_yoy, true)}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(data?.night_sales_daily_last_year)}
                          </TableCell>

                          <TableCell css={numberCell}>
                            {dataChange(data?.allday_sales_daily)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(data?.allday_sales_daily_yoy, true)}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(data?.allday_sales_daily_last_year)}
                          </TableCell>

                          <TableCell css={numberCell}>
                            {dataChange(data?.morning_customer_count_daily)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(
                              data?.morning_customer_count_daily_yoy,
                              true
                            )}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(
                              data?.morning_customer_count_daily_last_year
                            )}
                          </TableCell>

                          <TableCell css={numberCell}>
                            {dataChange(data?.daytime_customer_count_daily)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(
                              data?.daytime_customer_count_daily_yoy,
                              true
                            )}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(
                              data?.daytime_customer_count_daily_last_year
                            )}
                          </TableCell>

                          <TableCell css={numberCell}>
                            {dataChange(data?.night_customer_count_daily)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(
                              data?.night_customer_count_daily_yoy,
                              true
                            )}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(
                              data?.night_customer_count_daily_last_year
                            )}
                          </TableCell>

                          <TableCell css={numberCell}>
                            {dataChange(data?.allday_customer_count_daily)}
                          </TableCell>
                          <TableCell css={numberCell}>
                            {dataChange(
                              data?.allday_customer_count_daily_yoy,
                              true
                            )}
                          </TableCell>
                          <TableCell css={borderNumberCell}>
                            {dataChange(
                              data?.allday_customer_count_daily_last_year
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
export default DailyManagement;
