import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import {
  LineChart,
  Line,
  ReferenceLine,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Label,
} from "recharts";
import { Card, CardContent } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { graphColors, addDecimalPoint, fontTheme } from "../../common/utils";

const card = css`
  margin: 16px;
  @media screen and (min-width: 1194px) {
    height: 690px;
  }
  @media screen and (min-width: 1366px) {
    height: 565px;
  }
`;
const cardContent = css`
  height: 100%;
  width: 100%;
`;
const customTooltip = css`
  padding: 5px;
  border: 1px solid lightgray;
  background-color: rgba(255, 255, 255, 0.9);
  pointer-events: all;
  border-radius: 5%;
`;
const customTooltipContent = css`
  list-style: none;
`;

const DailyStoreCustomerCountGraph: React.FC<{
  check1: boolean;
  check2: boolean;
  check3: boolean;
  check4: boolean;
  data: any;
}> = ({ check1, check2, check3, check4, data }) => {
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div css={customTooltip}>
          <li css={customTooltipContent}>{payload[0].payload.date}</li>
          {payload.map((payload: any, index: any) => {
            return (
              <li
                css={customTooltipContent}
                style={{ color: payload.color }}
                key={index.toString()}
              >
                {payload.name +
                  "：" +
                  addDecimalPoint(payload.value).toString() +
                  "%"}
              </li>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <ThemeProvider theme={fontTheme()}>
        <Card css={card} variant="outlined">
          <CardContent css={cardContent}>
            <ResponsiveContainer width="95%" height="95%">
              <LineChart
                width={730}
                height={250}
                data={data}
                margin={{ top: 5, right: 30, left: 30, bottom: 5 }}
              >
                <ReferenceLine
                  y={100}
                  stroke="#808080"
                  strokeWidth={3}
                  strokeDasharray="3 3"
                >
                  <Label
                    value={100}
                    position={"right"}
                    style={{ fill: "#000000a8" }}
                  />
                </ReferenceLine>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis
                  domain={[
                    (dataMin: number) => Math.round(dataMin - 10),
                    (dataMax: number) => Math.round(dataMax + 10),
                  ]}
                  tickCount={8}
                  label={{
                    value: "前年比（％）",
                    angle: -90,
                    dx: -30,
                  }}
                />
                <Tooltip trigger="click" content={<CustomTooltip />} />
                <Legend />
                {check1 ? (
                  <Line
                    type="monotone"
                    name="全日"
                    dataKey="daily_customer_count_performance_yoy"
                    stroke={graphColors.A}
                    strokeWidth={4}
                  />
                ) : (
                  <></>
                )}
                {check2 ? (
                  <Line
                    type="monotone"
                    name="朝"
                    dataKey="morning_customer_count_performance_yoy"
                    stroke={graphColors.B}
                    strokeWidth={2}
                  />
                ) : (
                  <></>
                )}
                {check3 ? (
                  <Line
                    type="monotone"
                    name="昼"
                    dataKey="daytime_customer_count_performance_yoy"
                    stroke={graphColors.C}
                    strokeWidth={2}
                  />
                ) : (
                  <></>
                )}
                {check4 ? (
                  <Line
                    type="monotone"
                    name="夜"
                    dataKey="night_customer_count_performance_yoy"
                    stroke={graphColors.D}
                    strokeWidth={2}
                  />
                ) : (
                  <></>
                )}
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </ThemeProvider>
    </>
  );
};

export default DailyStoreCustomerCountGraph;
