import React, { useState, useEffect, useRef } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import {
  Grid,
  Card,
  CardContent,
  Modal,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import Header from "../common/Header";
import TimeOutDisplay from "../common/TimeOutDisplay";
import { timer, dataChange, fontTheme } from "../common/utils";
import { apiRequest } from "../../apis/util";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../data/userInfo";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import APIErrorModal from "../common/APIErrorModal";

const card = css`
  margin: 80px 16px 16px 16px;
  width: 100%;
  background-color: #fff;
  @media screen and (min-width: 1194px) {
    height: 650px;
  }
  @media screen and (min-width: 1366px) {
    height: 545px;
  }
`;

const top = css`
  overflow: scroll;
  // padding-bottom: 8px;
  height: 555px;
  background-color: #fff;
  border-spacing: 1;
  @media screen and (min-width: 1194px) {
    height: 555px;
  }
  @media screen and (min-width: 1366px) {
    height: 450px;
  }
`;

const table = css`
  border-collapse: separate;
  border-spacing: 0;
  background-color: #fff;
  width: 100%;
  table-layout: fixed;
  @media screen and (min-width: 1194px) {
    height: 630px;
  }
  @media screen and (min-width: 1366px) {
    height: 510px;
  }
`;

const style = css`
  margin: 0;
  position: sticky;
  top: 0;
  left: 0;
  white-space: nowrap;
  padding: 4px;
  font-size: 12px;
  border-top: none;
  border-right: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  border-left: none;
  background-color: #e0ffee;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
`;

const itemNameCell = css`
  ${style}
  // width: 200px;
  // width: 25%;
  font-weight: bold;
  text-align: center;
  border: 2px solid #c4c4c4;
  z-index: 6;
`;

const rowMainItemCell = css`
  ${style}
  padding:2px;
  background-color: #ffffff;
  border-left: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-bottom: 3px solid #c4c4c4;
  text-align: center;
  z-index: 5;
`;

const borderBottomMainItemCell = css`
  ${rowMainItemCell}
  width: 60px;
  border-bottom: 0;
`;

const borderTopMainItemCell = css`
  ${rowMainItemCell}
  border-top: 3px solid #C4C4C4;
`;

const rowMinorItemCell = css`
  ${rowMainItemCell}
  padding: 2px;
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  border-left: 0;
  z-index: 5;
`;

const bottomBorderLessRowMinorItemCell = css`
  ${rowMinorItemCell}
  border-bottom: 0;
`;

const borderRowMinorItemCell = css`
  ${rowMinorItemCell}
  border-bottom: 3px solid #C4C4C4;
`;

const borderTopRowMinorItemCell = css`
  ${rowMinorItemCell}
  border-top: 3px solid #C4C4C4;
`;

const pastYearMonthItemCell = css`
  ${style}
  // width: 255px;
  // width: 25%;
  font-weight: bold;
  text-align: center;
  border-top: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  z-index: 3;
`;

const yearMonthItemCell = css`
  ${style}
  // width: 255px;
  // width: 25%;
  height: 20px;
  right: 0;
  font-weight: bold;
  border-top: 2px solid #666666;
  border-right: 2px solid #666666;
  border-bottom: 2px solid #666666;
  border-left: 3px solid #666666;
  text-align: center;
  z-index: 3;
  background-color: #ccffcc;
`;

const columnItemCell = css`
  ${style}
  top: 30px;
  font-weight: bold;
  text-align: center;
  border-right: 2px dotted #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  z-index: 3;
`;

const borderColumnItemCell = css`
  ${columnItemCell}
  border-right: 2px solid #C4C4C4;
`;

const currentMonthColumnMinorItemCell = css`
  ${style}
  top:30px;
  right: 0;
  font-weight: bold;
  border-right: 2px solid #666666;
  border-bottom: 2px solid #666666;
  text-align: center;
  z-index: 4;
  background-color: #ccffcc;
`;

const currentMonth1ColumnMinorItemCell = css`
  ${currentMonthColumnMinorItemCell}
  border-right: 2px dotted #666666;
`;

const currentMonth2ColumnMinorItemCell = css`
  ${currentMonth1ColumnMinorItemCell}
  border-left: 3px solid #666666;
`;

const numberCell = css`
  ${style}
  font-weight: normal;
  background-color: #ffffff;
  border-right: dotted 2px #c4c4c4;
  border-left: 0px;
  text-align: right;
  z-index: 0;
`;

const borderNumberCell = css`
  ${numberCell}
  border-right: 2px solid #C4C4C4;
`;

const zennenBottomBorderNumberCell = css`
  ${borderNumberCell}
  border-bottom: 0;
`;

const bottomBorderLessNumberCell = css`
  ${numberCell}
  border-bottom: 0;
`;

const borderMoneyNumberCell = css`
  ${numberCell}
  border-right: 2px solid #C4C4C4;
  border-top: 3px solid #c4c4c4;
`;

const bottomBorderNumberCell = css`
  ${numberCell}
  border-bottom: 3px solid #C4C4C4;
`;

const topBorderNumberCell = css`
  ${numberCell}
  border-top: 3px solid #C4C4C4;
`;

const bottomBorderZennenzissekiCell = css`
  ${borderNumberCell}
  border-bottom: 3px solid #C4C4C4;
`;

const currentMonthLastYearNumberCell = css`
  ${style}
  font-size: 12px;
  // width: 81.85px;
  right: 0;
  background-color: #ffffff;
  // border-right: 0;
  border-right: 2px solid #666666;
  text-align: right;
  z-index: 0;
`;

const borderTopCurrentMonthNumberCell = css`
  ${currentMonthLastYearNumberCell}
  border-top: 3px solid #C4C4C4;
`;

const currentMonthYoYNumberCell = css`
  ${currentMonthLastYearNumberCell}
  border-right: 2px dotted #C4C4C4;
`;

const borderTopCurrentMonth1NumberCell = css`
  ${currentMonthYoYNumberCell}
  border-top: 3px solid #C4C4C4;
`;

const currentMonthNumberCell = css`
  ${currentMonthLastYearNumberCell}
  // right: 173.74px;
  border-right: 2px dotted #C4C4C4;
  border-left: 3px solid #666666;
`;

const currentBorderLessMonth2NumberCell = css`
  ${currentMonthNumberCell}
  border-bottom: 0;
`;

const currentBorderLessMonth1NumberCell = css`
  ${currentMonthYoYNumberCell}
  border-bottom: 0;
`;

const currentBorderLessMonthNumberCell = css`
  ${currentMonthLastYearNumberCell}
  border-bottom: 0;
`;

const borderTopCurrentMonth2NumberCell = css`
  ${currentMonthNumberCell}
  border-top: 3px solid #C4C4C4;
`;

const borderBottomCurrentMonthNumberCell = css`
  ${currentMonthLastYearNumberCell}
  border-bottom: 3px solid #C4C4C4;
`;

const borderBottomCurrentMonth1NumberCell = css`
  ${currentMonthYoYNumberCell}
  border-bottom: 3px solid #C4C4C4;
`;

const borderBottomCurrentMonth2NumberCell = css`
  ${currentMonthNumberCell}
  border-bottom: 3px solid #C4C4C4;
`;
const circularProgress = css`
  position: absolute;
  top: 35%;
  left: 45%;
  opacity: 0.8;
  color: #ccc;
  // opacity: 0.5;
`;

const tooltipInner = css`
  // width: 40px;
  font-size: 11px;
`;

const MonthlyManagement: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  // 今月の日付　↓のyear_monthと一致させるため
  const [data, setData] = useState<any>([]);
  const [pastData, setPastData] = useState<any>([]);
  const [date, setDate] = useState("");
  const [tenpoCode, setTenpoCode] = useState<string | null>("");
  const [tenpoName, setTenpoName] = useState<string>("");
  const [getWidthLeft, setGetWidthLeft] = useState(0);
  const [getWidthRight, setGetWidthRight] = useState(0);
  const [getSecondWidthRight, setGetSecondWidthRight] = useState(0);
  const [getWidthOneQuarter, setGetWidthOneQuarter] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [TooltipOpen, setTooltipOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [errorAPI, setErrorAPI] = useState(false);
  const modalClose = () => setErrorAPI(false);
  const [timeout, setTimeout] = useState(false);

  const search = useLocation().search;
  const usp = new URLSearchParams(search);

  const windowName = "月別管理表";

  let res: any = {};
  let resSort: any = [];
  let arrangeData: any = [];
  let pastArrangeData: any = [];

  useEffect(() => {
    const tc: string | null = usp.get("tenpocode");
    const userInfo = getUserInfo();
    if (process.env.REACT_APP_MODE !== "develop") {
      if (
        tc !== null &&
        userInfo.job_category !== "zm" &&
        userInfo.tenpo_code.includes(tc)
      ) {
        setTenpoCode(tc);
      } else {
        navigate("/tenpo-error");
      }
    } else {
      if (tc !== null) {
        setTenpoCode(tc);
      }
    }
    let dt = new Date();
    const year = dt.getFullYear();
    const month = ("00" + (dt.getMonth() + 1)).slice(-2);
    setDate(year + "/" + month);
    window.setTimeout(dispMsg, timer);
  }, []);

  useEffect(() => {
    if (!timeout) {
      window.setTimeout(dispMsg, timer);
    }
  }, [timeout]);

  useEffect(() => {
    const params = {
      mode: "monthlyManagement",
      tenpoCode: tenpoCode,
    };
    if (tenpoCode) {
      (async () => {
        try {
          setLoading(true);
          res = await apiRequest(params);
          console.log(res);
          resSort = res.data.data.monthlyManagementSearchByParams;
          if (resSort.length) {
            arrangeData = resSort.sort(function (a: any, b: any) {
              return a.year_month < b.year_month ? -1 : 1;
            });
          } else {
            setData([]);
            throw new Error("API response error");
          }
        } catch (e) {
          setErrorAPI(true);
        }
        setDataLength(arrangeData.length - 1);
        if (arrangeData.length !== 0) {
          setData(res.data.data.dailyManagementSearchByParams);
          if (arrangeData.tenpo_name !== null) {
            setTenpoName(arrangeData[0]?.tenpo_name);
          }
        }
        pastArrangeData = arrangeData.splice(0, arrangeData.length - 1);
        setPastData(pastArrangeData);
        setData(arrangeData);
        setLoading(false);
      })();
    }
  }, [tenpoCode, date]);

  useEffect(() => {
    let b = 0;
    let c = 0;
    let a = document.getElementById("scroll-inner");
    if (a) {
      b = a.clientWidth;
      c = b / 4;
    }
    let mainCell = c / 2;
    let lastYearReport = c / 3;
    let averageYoYReport = lastYearReport * 2;
    setGetWidthLeft(mainCell);
    setGetWidthRight(lastYearReport);
    setGetSecondWidthRight(averageYoYReport);
    setGetWidthOneQuarter(c);
  }, [data]);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const dispMsg = () => {
    setTimeout(true);
  };

  const addCharacter = (yyyymm: any) => {
    const yyyy = yyyymm.slice(0, 4);
    const mm = yyyymm.slice(4, 6);
    const newDate = yyyy + "年 " + mm + "月";
    return newDate;
  };

  const defaultTheme = createTheme();
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            textAlign: "left",
            maxWidth: "360px",
          },
        },
      },
    },
  });

  const getSecondHeader = () => {
    const items = [];
    for (let i = 0; i < dataLength; i++) {
      items.push(
        <>
          <th css={columnItemCell}>実績</th>
          <th css={columnItemCell}>前年比</th>
          <th css={borderColumnItemCell}>前年実績</th>
        </>
      );
    }
    return items;
  };

  const rounding = (num: number) => {
    let data = Math.round(num / 1000);
    return data;
  };

  return timeout ? (
    <TimeOutDisplay setTimeout={setTimeout} />
  ) : !loading ? (
    <>
      <ThemeProvider theme={fontTheme()}>
        <Header
          windowName={windowName}
          tenpoCode={tenpoCode ? tenpoCode : ""}
          tenpoName={tenpoName ? tenpoName : ""}
        />
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Card css={card} variant="outlined">
              <Modal
                open={errorAPI}
                onClose={modalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <APIErrorModal />
              </Modal>
              <CardContent>
                <div css={top} id="scroll-inner">
                  <table css={table} id="table">
                    <thead>
                      <tr>
                        <th
                          css={itemNameCell}
                          rowSpan={2}
                          colSpan={2}
                          style={{ width: getWidthOneQuarter + "px" }}
                        >
                          項目名(千円)
                        </th>
                        {pastData.map((pastData: any, index: number) => (
                          <th
                            css={pastYearMonthItemCell}
                            colSpan={3}
                            style={{ width: getWidthOneQuarter + "px" }}
                            key={index}
                          >
                            {addCharacter(pastData.year_month)}
                          </th>
                        ))}
                        {data.map((data: any, index: number) => (
                          <th
                            css={yearMonthItemCell}
                            colSpan={3}
                            style={{ width: getWidthOneQuarter + "px" }}
                            key={index}
                          >
                            {addCharacter(data.year_month)}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        <>{getSecondHeader()}</>
                        <th
                          css={currentMonth2ColumnMinorItemCell}
                          style={{ right: getSecondWidthRight + "px" }}
                        >
                          概算実績
                        </th>
                        <th
                          css={currentMonth1ColumnMinorItemCell}
                          style={{ right: getWidthRight + "px" }}
                          id="averageYoYReport"
                        >
                          概算前年比
                        </th>
                        <th
                          css={currentMonthColumnMinorItemCell}
                          id="lastYearReport"
                        >
                          前年実績
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          css={borderBottomMainItemCell}
                          rowSpan={14}
                          id="mainCell"
                        >
                          売上
                        </td>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          平均日販
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(pastData.sales_monthly_average_report)}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.sales_monthly_average_yoy_report,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.sales_monthly_average_last_year_report
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(data.sales_monthly_average_report)}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.sales_monthly_average_yoy_report,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.sales_monthly_average_last_year_report
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          平均客数(人)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                pastData.customer_count_monthly_average_report
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.customer_count_monthly_average_yoy_report,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.customer_count_monthly_average_last_year_report
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                data.customer_count_monthly_average_report
                              )}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.customer_count_monthly_average_yoy_report,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.customer_count_monthly_average_last_year_report
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          客単価(円)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                pastData.unit_price_monthly_average_report
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.unit_price_monthly_average_yoy_report,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.unit_price_monthly_average_last_year_report
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(data.unit_price_monthly_average_report)}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.unit_price_monthly_average_yoy_report,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.unit_price_monthly_average_last_year_report
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          買上点数(点)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                pastData.quantity_monthly_average_report,
                                true,
                                2
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.quantity_monthly_average_yoy_report,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.quantity_monthly_average_last_year_report,
                                true,
                                2
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                data.quantity_monthly_average_report,
                                true,
                                2
                              )}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.quantity_monthly_average_yoy_report,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.quantity_monthly_average_last_year_report,
                                true,
                                2
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td css={rowMinorItemCell} style={{ left: getWidthLeft }}>
                          朝帯売上( 0-10)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                rounding(pastData.morning_sales_monthly_average)
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.morning_sales_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                rounding(
                                  pastData.morning_sales_monthly_average_last_year
                                )
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                rounding(data.morning_sales_monthly_average)
                              )}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.morning_sales_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                rounding(
                                  data.morning_sales_monthly_average_last_year
                                )
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          昼帯売上(10-16)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                rounding(pastData.daytime_sales_monthly_average)
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.daytime_sales_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                rounding(
                                  pastData.daytime_sales_monthly_average_last_year
                                )
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                rounding(data.daytime_sales_monthly_average)
                              )}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.daytime_sales_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                rounding(
                                  data.daytime_sales_monthly_average_last_year
                                )
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          夜帯売上( 16-24)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                rounding(pastData.night_sales_monthly_average)
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.night_sales_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                rounding(
                                  pastData.night_sales_monthly_average_last_year
                                )
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                rounding(data.night_sales_monthly_average)
                              )}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.night_sales_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                rounding(
                                  data.night_sales_monthly_average_last_year
                                )
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          時間帯売上( 0-24)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                rounding(pastData.allday_sales_monthly_average)
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.allday_sales_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                rounding(
                                  pastData.allday_sales_monthly_average_last_year
                                )
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                rounding(data.allday_sales_monthly_average)
                              )}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.allday_sales_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                rounding(
                                  data.allday_sales_monthly_average_last_year
                                )
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          朝帯客数( 0-10)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                pastData.morning_customer_count_monthly_average
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.morning_customer_count_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.morning_customer_count_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                data.morning_customer_count_monthly_average
                              )}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.morning_customer_count_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.morning_customer_count_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          昼帯客数( 10-16)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                pastData.daytime_customer_count_monthly_average
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.daytime_customer_count_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.daytime_customer_count_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                data.daytime_customer_count_monthly_average
                              )}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.daytime_customer_count_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.daytime_customer_count_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          夜帯客数(16-24)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                pastData.night_customer_count_monthly_average
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.night_customer_count_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.night_customer_count_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                data.night_customer_count_monthly_average
                              )}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.night_customer_count_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.night_customer_count_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          時間帯客数( 0-24)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                pastData.allday_customer_count_monthly_average
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.allday_customer_count_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.allday_customer_count_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                data.allday_customer_count_monthly_average
                              )}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.allday_customer_count_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.allday_customer_count_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          売上合計
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(pastData.allday_sales_monthly_total)}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.allday_sales_monthly_total_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.allday_sales_monthly_total_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(data.allday_sales_monthly_total)}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.allday_sales_monthly_total_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.allday_sales_monthly_total_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={bottomBorderLessRowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          総収入
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={bottomBorderLessNumberCell} key={index}>
                              {dataChange(pastData.income_monthly_total)}
                            </td>
                            <td css={bottomBorderLessNumberCell}>
                              {dataChange(
                                pastData.income_monthly_total_yoy,
                                true
                              )}
                            </td>
                            <td css={zennenBottomBorderNumberCell}>
                              {dataChange(
                                pastData.income_monthly_total_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentBorderLessMonth2NumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(data.income_monthly_total)}
                            </td>
                            <td
                              css={currentBorderLessMonth1NumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(data.income_monthly_total_yoy, true)}
                            </td>
                            <td css={currentBorderLessMonthNumberCell}>
                              {dataChange(data.income_monthly_total_last_year)}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td css={borderTopMainItemCell} rowSpan={6}>
                          営業費
                        </td>
                        <td
                          css={borderTopRowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          給料
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={topBorderNumberCell} key={index}>
                              {dataChange(pastData.salary_monthly_total)}
                            </td>
                            <td css={topBorderNumberCell}>
                              {dataChange(
                                pastData.salary_monthly_total_yoy,
                                true
                              )}
                            </td>
                            <td css={borderMoneyNumberCell}>
                              {dataChange(
                                pastData.salary_monthly_total_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={borderTopCurrentMonth2NumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(data.salary_monthly_total)}
                            </td>
                            <td
                              css={borderTopCurrentMonth1NumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(data.salary_monthly_total_yoy, true)}
                            </td>
                            <td css={borderTopCurrentMonthNumberCell}>
                              {dataChange(data.salary_monthly_total_last_year)}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          棚卸増減
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                pastData.inventory_diff_monthly_average
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.inventory_diff_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.inventory_diff_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(data.inventory_diff_monthly_average)}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.inventory_diff_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.inventory_diff_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          (棚卸増減売価)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                pastData.inventory_price_diff_monthly_average
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.inventory_price_diff_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.inventory_price_diff_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                data.inventory_price_diff_monthly_average
                              )}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.inventory_price_diff_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.inventory_price_diff_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          不良品
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                pastData.defective_product_monthly_average
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.defective_product_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.defective_product_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(data.defective_product_monthly_average)}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.defective_product_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.defective_product_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          (不良品売価)
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(
                                pastData.defective_product_price_monthly_average
                              )}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.defective_product_price_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.defective_product_price_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                data.defective_product_price_monthly_average
                              )}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.defective_product_price_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(
                                data.defective_product_price_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={borderRowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          営業費合計
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={bottomBorderNumberCell} key={index}>
                              {dataChange(pastData.operating_cost_monthly_total)}
                            </td>
                            <td css={bottomBorderNumberCell}>
                              {dataChange(
                                pastData.operating_cost_monthly_total_yoy,
                                true
                              )}
                            </td>
                            <td css={bottomBorderZennenzissekiCell}>
                              {dataChange(
                                pastData.operating_cost_monthly_total_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={borderBottomCurrentMonth2NumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(data.operating_cost_monthly_total)}
                            </td>
                            <td
                              css={borderBottomCurrentMonth1NumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.operating_cost_monthly_total_yoy,
                                true
                              )}
                            </td>
                            <td css={borderBottomCurrentMonthNumberCell}>
                              {dataChange(
                                data.operating_cost_monthly_total_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td css={rowMainItemCell} colSpan={2}>
                          純利益
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={bottomBorderNumberCell} key={index}>
                              {dataChange(pastData.net_income_monthly_average)}
                            </td>
                            <td css={bottomBorderNumberCell}>
                              {dataChange(
                                pastData.net_income_product_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={bottomBorderZennenzissekiCell}>
                              {dataChange(
                                pastData.net_income_product_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={borderBottomCurrentMonth2NumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(data.net_income_monthly_average)}
                            </td>
                            <td
                              css={borderBottomCurrentMonth1NumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.net_income_product_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderBottomCurrentMonthNumberCell}>
                              {dataChange(
                                data.net_income_product_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td css={rowMainItemCell} colSpan={2}>
                          オープンアカウント
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={bottomBorderNumberCell} key={index}>
                              {dataChange(pastData.open_account_monthly_average)}
                            </td>
                            <td css={bottomBorderNumberCell}>
                              {dataChange(
                                pastData.open_account_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={bottomBorderZennenzissekiCell}>
                              {dataChange(
                                pastData.open_account_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={borderBottomCurrentMonth2NumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(data.open_account_monthly_average)}
                            </td>
                            <td
                              css={borderBottomCurrentMonth1NumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.open_account_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderBottomCurrentMonthNumberCell}>
                              {dataChange(
                                data.open_account_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td css={rowMainItemCell} rowSpan={2}>
                          自己資本
                        </td>
                        <td
                          css={rowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          月次引出金
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={numberCell} key={index}>
                              {dataChange(pastData.drawing_monthly_average)}
                            </td>
                            <td css={numberCell}>
                              {dataChange(
                                pastData.drawing_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderNumberCell}>
                              {dataChange(
                                pastData.drawing_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={currentMonthNumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(data.drawing_monthly_average)}
                            </td>
                            <td
                              css={currentMonthYoYNumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(data.drawing_monthly_average_yoy, true)}
                            </td>
                            <td css={currentMonthLastYearNumberCell}>
                              {dataChange(data.drawing_monthly_average_last_year)}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td
                          css={borderRowMinorItemCell}
                          style={{ left: getWidthLeft + "px" }}
                        >
                          自己資本合計
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={bottomBorderNumberCell} key={index}>
                              {dataChange(
                                pastData.tangible_net_worth_monthly_average
                              )}
                            </td>
                            <td css={bottomBorderNumberCell}>
                              {dataChange(
                                pastData.tangible_net_worth_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={bottomBorderZennenzissekiCell}>
                              {dataChange(
                                pastData.tangible_net_worth_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={borderBottomCurrentMonth2NumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(
                                data.tangible_net_worth_monthly_average
                              )}
                            </td>
                            <td
                              css={borderBottomCurrentMonth1NumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.tangible_net_worth_monthly_average_yoy,
                                true
                              )}
                            </td>
                            <td css={borderBottomCurrentMonthNumberCell}>
                              {dataChange(
                                data.tangible_net_worth_monthly_average_last_year
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                      <tr>
                        <td css={rowMainItemCell} colSpan={2}>
                          荒利益率（％）
                        </td>
                        {pastData.map((pastData: any, index: number) => (
                          <>
                            <td css={bottomBorderNumberCell} key={index}>
                              {dataChange(
                                pastData.gross_profit_monthly_total,
                                true
                              )}
                            </td>
                            <td css={bottomBorderNumberCell}>
                              {dataChange(
                                pastData.gross_profit_monthly_total_yoy,
                                true
                              )}
                            </td>
                            <td css={bottomBorderZennenzissekiCell}>
                              {dataChange(
                                pastData.gross_profit_monthly_total_last_year,
                                true
                              )}
                            </td>
                          </>
                        ))}
                        {data.map((data: any, index: number) => (
                          <>
                            <td
                              css={borderBottomCurrentMonth2NumberCell}
                              style={{ right: getSecondWidthRight + "px" }}
                              key={index}
                            >
                              {dataChange(data.gross_profit_monthly_total, true)}
                            </td>
                            <td
                              css={borderBottomCurrentMonth1NumberCell}
                              style={{ right: getWidthRight + "px" }}
                            >
                              {dataChange(
                                data.gross_profit_monthly_total_yoy,
                                true
                              )}
                            </td>
                            <td css={borderBottomCurrentMonthNumberCell}>
                              {dataChange(
                                data.gross_profit_monthly_total_last_year,
                                true
                              )}
                            </td>
                          </>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </ThemeProvider>
    </>
  ) : (
    <div>
      <CircularProgress css={circularProgress} size={120} />
      <Header
        windowName={windowName}
        tenpoCode={tenpoCode ? tenpoCode : ""}
        tenpoName={tenpoName ? tenpoName : ""}
      />
    </div>
  );
};
export default MonthlyManagement;
