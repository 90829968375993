import React, { FC, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { getWeatherIcon } from "./weatherIcon";
import "../../index.css";

const root = css`
  padding: 4px;
  text-align: center;
`;
const tempText = css`
  margin: 0;
  font-size: 12px;
  padding-top: 4px;
`;

const weatherIcon = css`
  zoom: 0.25;
  display: inline-block;
  position: relative;
  width: 150px;
  height: 80px;
  overflow: hidden;
  pointer-events: none;
`;

const WeatherInfo: FC<{
  isBlank?: boolean;
  weatherCode: string;
  maxTemp: string;
  minTemp: string;
}> = ({ isBlank = false, weatherCode, maxTemp, minTemp }) => {
  const path = `${window.location.origin}/image/`;
  const [iconNo, setIconNo] = React.useState("000");
  let nullFrag: boolean = false;
  if ( !weatherCode && !maxTemp && !minTemp ) {
    nullFrag = true;
  }
  useEffect(() => {
    initWeather();
  }, []);

  const initWeather = () => {
    setIconNo(weatherCode ? getWeatherIcon(weatherCode) : "000");
  };

  return nullFrag ? (
    <div css={root}>
      <a href="#" css={weatherIcon}>
        <img src={path + "blank.png"} alt={""} />
      </a>
      <p css={tempText}>&nbsp;</p>
    </div>
  ) : isBlank ? (
    <div css={root}>
      <a href="#" css={weatherIcon}>
        <img src={path + "blank.png"} alt={""} />
      </a>
      <p css={tempText}>- / -</p>
    </div>
  ) : (
    <div css={root}>
      <a href="#" css={weatherIcon}>
        <img src={path + "1000.webp"} className={"item" + iconNo} alt={""} />
      </a>
      <p css={tempText}>
        <span style={{ color: "red" }}>{maxTemp}</span>
        <span> / </span>
        <span style={{ color: "blue" }}>{minTemp}</span>
      </p>
    </div>
  );
};

export default WeatherInfo;
