import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { initialOperation, isUserInfo } from "./data/userInfo";

const ProtectedRoute = () => {
    const [auth, setAuth] = useState(false);
    if (!isUserInfo()&&!auth) {
        try {
            (async () => {
                await initialOperation();
                setAuth(true);
            })();
        } catch (e) {
            console.log(e)
        }
    }
    return !isUserInfo() ? (
        <></>
    ) : (
        <Outlet/>
    )
}

export default ProtectedRoute;